
// import React, { useState } from 'react';
// import { Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, Button, Box, Grid, IconButton, Typography, Stack } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

// const DocumentSelection = ({ documents, onClose, onSelect, utilityName }) => {
//     const [selectedDocs, setSelectedDocs] = useState([]);

//     const handleToggle = (doc) => {
//         setSelectedDocs((prev) =>
//             prev.includes(doc) ? prev.filter(d => d !== doc) : [...prev, doc]
//         );
//     };

//     const handleConfirm = () => {
//         onSelect(selectedDocs);

//         onClose();
//     };

//     return (


//         <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
//             <div style={{ position: 'relative' }}>
//                 <div style={{ height: '10px', backgroundColor: '#2F80ED' }}></div>

//                 <Grid container justifyContent="center" alignItems="center">
//                     <Grid item md={10}>
//                         <Box
//                             sx={{
//                                 minHeight: '20vh',
//                                 display: 'flex',
//                                 justifyContent: 'space-between',
//                                 alignItems: 'center',
//                                 padding: 2,
//                                 color: 'black',
//                                 boxSizing: 'border-box',

//                             }}
//                         >

//                             <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 700 }}>
//                                 {utilityName}
//                             </Typography>
//                             <IconButton
//                                 aria-label="close"
//                                 onClick={onClose}
//                                 sx={{ color: 'black' }}
//                             >
//                                 <CloseIcon />
//                             </IconButton>
//                         </Box>
//                         {/* <Box sx={{ paddingX: 2, minHeight: '70vh', }}>
//                             <DialogContent>
//                                 {documents.map((doc) => (
//                                     <FormControlLabel
//                                         key={doc.value}
//                                         control={
//                                             <Checkbox
//                                                 checked={selectedDocs.includes(doc.value)}
//                                                 onChange={() => handleToggle(doc.value)}
//                                             />
//                                         }
//                                         label={doc.label}
//                                     />
//                                 ))}
//                             </DialogContent>
//                             <DialogActions>
//                                 <Button onClick={onClose}>Cancel</Button>
//                                 <Button onClick={handleConfirm}>Confirm</Button>
//                             </DialogActions>
//                         </Box> */}
//                         <Box sx={{ paddingX: 2, minHeight: '70vh' }}>

//                             <DialogContent>
//                                 <Stack spacing={1}>
//                                     {documents.map((doc) => (
//                                         <FormControlLabel
//                                             key={doc.value}
//                                             control={
//                                                 <Checkbox
//                                                     checked={selectedDocs.includes(doc.value)}
//                                                     onChange={() => handleToggle(doc.value)}
//                                                 />
//                                             }
//                                             label={doc.label}
//                                         />
//                                     ))}
//                                 </Stack>
//                             </DialogContent>
//                             <Box mt={'20%'}>
//                                 <DialogActions>
//                                     <Button onClick={onClose}>Cancel</Button>
//                                     <Button variant='contained' sx={{ width: '25%' }} onClick={handleConfirm}>OK</Button>
//                                 </DialogActions>

//                             </Box>

//                         </Box>
//                     </Grid>
//                 </Grid>
//             </div>
//         </Dialog>
//     );
// };

// export default DocumentSelection;


import React, { useState } from 'react';
import {
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Box,
    Grid,
    IconButton,
    Typography,
    Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DocumentSelection = ({ documents, onClose, onSelect, utilityName }) => {
    const [selectedDocIds, setSelectedDocIds] = useState([]);

    const handleToggle = (docId) => {
        setSelectedDocIds((prev) =>
            prev.includes(docId) ? prev.filter(id => id !== docId) : [...prev, docId]
        );
    };

    const handleConfirm = () => {
        // Retrieve the full document details based on selected IDs
        const selectedDocs = documents.filter(doc => selectedDocIds.includes(doc.id));
        onSelect(selectedDocs);
        onClose();
    };

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
            <div style={{ position: 'relative' }}>
                <div style={{ height: '10px', backgroundColor: '#2F80ED' }}></div>

                <Grid container justifyContent="center" alignItems="center">
                    <Grid item md={10}>
                        <Box
                            sx={{
                                minHeight: '20vh',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: 2,
                                color: 'black',
                                boxSizing: 'border-box',
                            }}
                        >
                            <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 700 }}>
                                {utilityName}
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                sx={{ color: 'black' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ paddingX: 2, minHeight: '70vh' }}>
                            <DialogContent>
                                <Stack spacing={1}>
                                    {documents.map((doc) => (
                                        <FormControlLabel
                                            key={doc.id}
                                            control={
                                                <Checkbox
                                                    checked={selectedDocIds.includes(doc.id)}
                                                    onChange={() => handleToggle(doc.id)}
                                                />
                                            }
                                            label={
                                                <Box>
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                        {doc.documentName}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: 'gray' }}>
                                                        {doc.fileName}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    ))}
                                </Stack>
                            </DialogContent>
                            <Box mt={'20%'}>
                                <DialogActions>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button variant='contained' sx={{ width: '25%' }} onClick={handleConfirm}>
                                        OK
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
};

export default DocumentSelection;
