import React, { useEffect, useState, useContext } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaymentTerm from "./payment-setup/payment-term";
import PaymentContact from "./payment-setup/payment-contact";
import PaymentMethod from "./payment-setup/payment-method";
import { PAYMENT_SETUP_TABS } from "../../../constants/constatnt-variable";
import { BillContext } from "./BillContext";
import PaymentTermWithoutOtp from "./payment-setup/payment-term-no-otp";

export default function PaymentSetup({ returnedFromPayment, paymentStatus }) {
    const { billingTerm, paymentSetUpDone, paymentSetUpDate, ssn } = useContext(BillContext);
    const [value, setValue] = useState(PAYMENT_SETUP_TABS.PaymentTerm);

    const [ssnSubmited, setSsnSubmitted] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // useEffect(() => {
    //     if (returnedFromPayment) {
    //         setValue(PAYMENT_SETUP_TABS.PaymentMethod);
    //     }
    // }, [returnedFromPayment]);

    useEffect(() => {

        if (ssnSubmited === true) {
            setValue(PAYMENT_SETUP_TABS.PaymentMethod);
        }

    }, [ssnSubmited]);

    useEffect(() => {

        if (billingTerm === "No") {
            setValue(PAYMENT_SETUP_TABS.PaymentTerm);
        }

        else if (billingTerm === "Yes" && ssn === null) {
            setValue(PAYMENT_SETUP_TABS.PaymentContact);
        }

        else if (billingTerm === "Yes" && ssn != null) {
            setValue(PAYMENT_SETUP_TABS.PaymentMethod);
        }

    }, [ssn, ssnSubmited]);

    const tabs = [
        { label: "Payment Term", value: PAYMENT_SETUP_TABS.PaymentTerm },
        { label: "Payment Contact", value: PAYMENT_SETUP_TABS.PaymentContact },
        { label: "Payment Method", value: PAYMENT_SETUP_TABS.PaymentMethod }
    ];

    return (
        <Box>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        variant="scrollable"
                        allowScrollButtonsMobile
                    >
                        {tabs.map(tab => (
                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                        ))}
                    </TabList>
                </Box>
                {tabs.map(tab => (
                    <TabPanel key={tab.value} value={tab.value} sx={{ px: { xs: 0, md: 3 } }}>
                        {/* {tab.value === PAYMENT_SETUP_TABS.PaymentTerm && <PaymentTerm />} */}
                        {tab.value === PAYMENT_SETUP_TABS.PaymentTerm && <PaymentTermWithoutOtp />}
                        {tab.value === PAYMENT_SETUP_TABS.PaymentContact && <PaymentContact setSsnSubmitted={setSsnSubmitted} />}
                        {tab.value === PAYMENT_SETUP_TABS.PaymentMethod && <PaymentMethod
                            paymentSetUpDone={paymentSetUpDone}
                            paymentSetUpDate={paymentSetUpDate} ssnSubmited={ssnSubmited} />}
                    </TabPanel>
                ))}
            </TabContext>
        </Box>
    );
}
