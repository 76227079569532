import React from "react";
import { DoneAll } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useChannelStateContext } from "stream-chat-react";

export default function ChatBody({ userId }) {
  const { messages } = useChannelStateContext();

  return (
    <Box
      bgcolor={"#D7E9FD"}
      py={1}
      display={"flex"}
      flexDirection={"column-reverse"}
      height={"100%"}
      overflow={"auto"}
    >
      {messages
        .slice(0)
        .reverse()
        .map((message) => {
          if (message.user.id === userId) {
            // Sent message styling or logic
            return (
              <Stack
                key={message.id}
                width={"80%"}
                ml={"auto"}
                mr={2}
                gap={0.5}
                my={1.5}
              >
                {/* Chat right communication */}
                <Typography variant="caption" textAlign={"right"}>
                  {message.user.name} ({message.user.business_role})
                </Typography>
                <Box
                  bgcolor={"#E3F1FF"}
                  borderRadius={"0 0 5px 5px"}
                  direction={"column"}
                  p={1}
                  border={"1px solid #C4C4C4"}
                  position={"relative"}
                >
                  <Box
                    position={"absolute"}
                    top={0}
                    right={0}
                    borderRight={"1px solid #C4C4C4"}
                    borderTop={"1px solid #C4C4C4"}
                    height={"12px"}
                    width={"12px"}
                    bgcolor={"#E3F1FF"}
                    sx={{
                      transform: "translate(50%, 9%) rotate(45deg)",
                    }}
                  />
                  <Typography component={"p"}>{message.text}</Typography>
                  <Stack
                    direction={"row-reverse"}
                    alignItems={"center"}
                    gap={0.5}
                  >
                    <DoneAll fontSize="10px" color="primary" />
                    <Typography variant="caption" component={"span"}>
                      {new Date(message.created_at).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            );
          }
          return (
            <Stack
              key={message.id}
              width={"80%"}
              mr={"auto"}
              ml={2}
              gap={0.5}
              my={1.5}
            >
              {/* Chat right communication */}
              <Typography variant="caption">
                {message.user.name} ({message.user.business_role})
              </Typography>
              <Box
                bgcolor={"white"}
                borderRadius={"0 5px 5px 5px"}
                direction={"column"}
                p={1}
                border={"1px solid #C4C4C4"}
                position={"relative"}
              >
                <Box
                  position={"absolute"}
                  top={0}
                  left={0}
                  borderLeft={"1px solid #C4C4C4"}
                  borderTop={"1px solid #C4C4C4"}
                  height={"12px"}
                  width={"12px"}
                  bgcolor={"white"}
                  sx={{
                    transform: "translate(-50%, 9%) rotate(-45deg)",
                  }}
                />
                <Typography component={"p"}>{message.text}</Typography>
                <Stack direction={"row-reverse"} alignItems={"center"}>
                  <Typography variant="caption" component={"span"}>
                    {new Date(message.created_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          );
        })}
    </Box>
  );
}
