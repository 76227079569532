import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import MainLoyOut from "../../../../components/main-layout";
import CustomInput from "../../../../helpers/custom-input";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { PURPOSE_LIST, LOAN_TYPE_LIST, UPDATE } from "../../../../constants/constatnt-variable";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import { DateToTimestapmFormater, TimeStampToMonthDateYearForInput } from "../../../../helpers/time-formater";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import { downloadReportFromServer, getFileUrl } from "../../../../helpers/file-upload-download";
import { PreApprovalFormValidation } from "../../../../constants/validation-rules";
import FilePreview from "../file-preview";
import { DialogCommonHooks, PreviewPDFModal } from "../../../../helpers/modal/modal";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../../../recoil/profile-atom";
import { ssnDataFormat } from "../../../../constants/validation";
import { LOAN_TERM_TYPE, LOAN_TERM_TYPE_ARM_OPTION } from "../../../../constants/constatnt-variable";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import { useWatch } from "react-hook-form";
import { formatNumericValue, parseNumericValue } from '../../../../helpers/numeric-format'
import { AttachMoney } from '@mui/icons-material'

export default function PreapprovalForm({ leadData, profileData }) {
  let pull = true;


  const router = useNavigate();
  const location = useLocation();
  const leadId = location.pathname.split("/")[location.pathname.split("/").length - 1];

  const [modalOpen, setModalOpen] = useState(false);
  let [, setSpinner] = useRecoilState(globalSpinner);

  const handleClose = () => {
    router("/broker/leads")
  }




  const onSubmit = async data => {
    setSpinner(true)

    let apiCallData = {
      id: leadId,
      leadInfo: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        dateOfBirth: DateToTimestapmFormater(data?.dateOfBirth),
        phone: data?.phone,
        ssn: data?.ssn,
        monthlyIncome: data?.monthlyIncome,
        totalLiabilities: data?.totalLiabilities,
        averageScore: data?.averageScore,
        loanType: data?.loanType,
        loanPurpose: data?.loanPurpose,
        propertyZip: data?.propertyZip,
        propertyAddress: data?.propertyAddress,
        salePrice: data?.salePrice,
        issueDate: DateToTimestapmFormater(data?.issueDate),
        loanTerm: data?.loanTerm,
        loanTermOption: data?.loanTermOption,
        mmpAmountPerMonth: data?.mmpAmountPerMonth,
        interestRate: data?.interestRate,
        nmlsId: data?.nmlsId,
        downPaymentFixed: data?.downPaymentFixed,
        brokerFirstName: data?.brokerFirstName,
        brokerLastName: data?.brokerLastName,
        brokerPhone: data?.brokerPhone,

      },
      leadForm: {
        maxPurchasePriceEligibility: data?.maxPurchasePriceEligibility,
        loanToValue: data?.loanToValue,
        loanAmount: data?.loanAmount,
      }
    }
    await postApiCallDynamicWithOutReturn({
      data: apiCallData, refresh: () => { setSpinner(false); setModalOpen(true); }, path: API.lead, header: UPDATE
    })



  }

  const downloadPdf = () => {
    downloadReportFromServer(API.leadDownload, { id: leadId, serviceType: "preapproval" })

  }
  const handlePdfClose = () => {
    setModalOpen(false)
  }


  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      brokerFirstName: profileData?.firstName,
      brokerLastName: profileData?.lastName,
      nmlsId: profileData?.nmlsId,
      brokerPhone: profileData?.phone,
      firstName: leadData?.leadInfo?.firstName,
      lastName: leadData?.leadInfo?.lastName,
      email: leadData?.leadInfo?.email,
      dateOfBirth: TimeStampToMonthDateYearForInput(leadData?.leadInfo?.dateOfBirth),
      phone: leadData?.leadInfo?.phone,
      ssn: leadData?.leadInfo?.ssn,
      monthlyIncome: leadData?.leadInfo?.monthlyIncome,
      totalLiabilities: leadData?.leadInfo?.totalLiabilities,
      averageScore: leadData?.leadInfo?.averageScore,
      loanType: leadData?.leadInfo?.loanType,
      loanPurpose: leadData?.leadInfo?.loanPurpose,
      propertyZip: leadData?.leadInfo?.propertyZip,
      propertyAddress: leadData?.leadInfo?.propertyAddress,
      salePrice: leadData?.leadInfo?.propertyValue,
      issueDate: TimeStampToMonthDateYearForInput(leadData?.leadInfo?.issueDate),
      loanTerm: leadData?.leadInfo?.loanTerm,
      loanTermOption: leadData?.leadInfo?.loanTermOption,
      maxPurchasePriceEligibility: leadData?.leadInfo?.propertyValue,
      loanAmount: leadData?.leadInfo?.loanPurpose === 'Purchasing'
        ? parseFloat(parseFloat(leadData?.leadInfo?.propertyValue) - parseFloat(leadData?.leadInfo?.downPaymentFixed)).toFixed(2)
        : leadData?.leadInfo?.loanPurpose === 'Refinancing'
          ? leadData?.leadInfo?.loanAmount
          : 0,
      loanToValue: ((parseFloat(leadData?.leadInfo?.propertyValue) - parseFloat(leadData?.leadInfo?.downPaymentFixed)) / parseFloat(leadData?.leadInfo?.propertyValue) * 100).toFixed(2),
      downPaymentFixed: leadData?.leadInfo?.loanPurpose === 'Purchasing'
        ? leadData?.leadInfo?.downPaymentFixed
        : leadData?.leadInfo?.loanPurpose === 'Refinancing'
          ? (parseFloat(leadData?.leadInfo?.propertyValue) - parseFloat(leadData?.leadForm?.loanAmount)).toFixed(2)
          : 0,
      downPaymentPercentage: leadData?.leadInfo?.loanPurpose === 'Purchasing'
        ? leadData?.leadInfo?.downPaymentPercentage
        : leadData?.leadInfo?.loanPurpose === 'Refinancing'
          ? ((parseFloat(leadData?.leadInfo?.propertyValue) - parseFloat(leadData?.leadInfo?.loanAmount)) * 100 / parseFloat(leadData?.leadInfo?.propertyValue)).toFixed(2)
          : 0,
      mmpAmountPerMonth: leadData?.leadInfo?.mmpAmountPerMonth,
      interestRate: leadData?.leadInfo?.interestRate
    }
  })



  const handleSSNChange = (e) => {
    const formattedSSN = e.target.value
      .replace(/\D/g, '')
      .slice(0, 9)
      .replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');

    setValue('ssn', formattedSSN);
  };


  const { loanTermOption, issueDate, loanTerm, loanPurpose,
    downPaymentPercentage, downPaymentFixed, salePrice,
    interestRate, loanAmount } = useWatch({ control });



  useEffect(() => {

    if (loanPurpose === 'Purchasing') {
      setValue('totalLiabilities', leadData?.leadInfo?.totalLiabilities)


    }
    else if (loanPurpose === 'Refinancing') {
      const downPay = (parseFloat(salePrice) - parseFloat(loanAmount))
      const downPer = downPay * 100 / parseFloat(salePrice)
      setValue('totalLiabilities', leadData?.leadInfo?.monthlyMortgageExpense)
      setValue('downPaymentFixed', downPay.toFixed(2))
      setValue('downPaymentPercentage', downPer.toFixed(2))
    }

  }, [loanPurpose])

  useEffect(() => {
    setValue('maxPurchasePriceEligibility', salePrice)
    if (loanPurpose === 'Purchasing') {
      const downPaymentPer = parseFloat(downPaymentPercentage);
      const propertyVal = parseFloat(salePrice);

      if (
        !isNaN(downPaymentPer) &&
        !isNaN(propertyVal) && downPaymentPer <= 100
      ) {
        const downPaymentAmount = (propertyVal * downPaymentPer / 100).toFixed(2)
        const loanAmt = (propertyVal - downPaymentAmount).toFixed(2)
        setValue('downPaymentFixed', downPaymentAmount);
        setValue('loanAmount', loanAmt);
        setValue('loanToValue', (loanAmt / propertyVal * 100).toFixed(2))
      }
      setValue('maxPurchasePriceEligibility', propertyVal);
    }
    if (loanPurpose === 'Refinancing') {

      const propertyVal = parseFloat(salePrice);

      const downPerVal = parseFloat(downPaymentPercentage)
      if (
        !isNaN(propertyVal) &&
        !isNaN(downPerVal) && downPerVal <= 100
      ) {
        const downPayAmountVal = (propertyVal * downPerVal / 100).toFixed(2)
        const loanAmtVal = (propertyVal - (propertyVal * downPerVal / 100)).toFixed(2)


        setValue('downPaymentFixed', downPayAmountVal);

        setValue('loanToValue', (loanAmtVal / propertyVal * 100).toFixed(2))
        setValue('loanAmount', loanAmtVal)

      }

    }

  }, [salePrice]);

  useEffect(() => {

    if (loanPurpose === 'Refinancing') {

      const loanVal = parseFloat(loanAmount);
      const propertyVal = parseFloat(salePrice);

      if (
        !isNaN(loanVal) &&
        !isNaN(propertyVal) && loanVal <= propertyVal
      ) {

        const downPaymentVal = (propertyVal - loanVal).toFixed(2)
        const downPerVal = (downPaymentVal * 100 / propertyVal).toFixed(2)
        setValue('downPaymentFixed', downPaymentVal);
        setValue('downPaymentPercentage', downPerVal);
        setValue('loanToValue', (loanVal / propertyVal * 100).toFixed(2))

      }

    }

  }, [loanAmount]);



  const handleDownPaymentPercentageBlur = () => {
    if (loanPurpose === 'Purchasing') {
      const downPaymentPer = parseFloat(downPaymentPercentage);
      const propertyVal = parseFloat(salePrice);

      if (
        !isNaN(downPaymentPer) &&
        !isNaN(propertyVal) && downPaymentPer <= 100
      ) {


        const downPaymentAmount = (propertyVal * downPaymentPer / 100).toFixed(2)
        const loanAmt = (propertyVal - downPaymentAmount).toFixed(2)
        setValue('downPaymentFixed', downPaymentAmount);
        setValue('loanAmount', loanAmt);
        setValue('loanToValue', (loanAmt / propertyVal * 100).toFixed(2))
      }
    }
    if (loanPurpose === 'Refinancing') {
      const downPaymentPer = parseFloat(downPaymentPercentage);
      const propertyVal = parseFloat(salePrice);

      if (
        !isNaN(downPaymentPer) &&
        !isNaN(propertyVal) && downPaymentPer <= 100
      ) {


        const downPaymentAmount = (propertyVal * downPaymentPer / 100).toFixed(2)
        const loanAmt = (propertyVal - downPaymentAmount).toFixed(2)
        setValue('downPaymentFixed', downPaymentAmount);
        setValue('loanAmount', loanAmt);
        setValue('loanToValue', (loanAmt / propertyVal * 100).toFixed(2))
      }
    }

  }



  const handleDownPaymentAmountBlur = () => {
    if (loanPurpose === 'Purchasing') {

      const downPaymentAmount = parseFloat(downPaymentFixed);
      const propertyVal = parseFloat(salePrice);

      if (
        !isNaN(downPaymentAmount) &&
        !isNaN(propertyVal)
      ) {
        const downPaymentPer = (downPaymentAmount * 100 / propertyVal).toFixed(2)
        setValue('downPaymentPercentage', downPaymentPer);
        setValue('loanAmount', (propertyVal - downPaymentAmount).toFixed(2))
      }
    }
    if (loanPurpose === 'Refinancing') {
      const downPaymentAmount = parseFloat(downPaymentFixed);
      const propertyVal = parseFloat(salePrice);
      setValue('downPaymentPercentage', ((downPaymentAmount * 100 / propertyVal)).toFixed(2))
      setValue('loanAmount', (propertyVal - downPaymentAmount).toFixed(2))
    }
  };

  useEffect(() => {
    const loanAmountValue = parseFloat(loanAmount);
    const rateMortgagePaymentValue = parseFloat(interestRate);
    const loanTermValue = parseInt(loanTerm);



    if (
      !isNaN(loanAmountValue) &&
      !isNaN(rateMortgagePaymentValue)
    ) {

      const monthlyInterestRate = (rateMortgagePaymentValue / 100) / 12;
      const denominator = Math.pow(1 + monthlyInterestRate, loanTermValue * 12) - 1;
      const numerator = (loanAmountValue * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTermValue * 12));
      const firstPayment = numerator / denominator;
      setValue('mmpAmountPerMonth', firstPayment.toFixed(2));


    }
  }, [loanAmount, interestRate, loanTerm]);

  const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(leadData?.leadInfo?.phone));
  const [unformatWorkPhone, setUnformatWorkPhone] = useState(leadData?.leadInfo?.phone || '');


  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  }

  function handleWorkPhoneChange(event) {
    let unformattedValue = event.target.value.replace(/\D/g, '');

    if (unformattedValue.length > 10) {
      unformattedValue = unformattedValue.slice(0, 10);
    }

    setUnformatWorkPhone(unformattedValue);
    setFormatWorkPhone(formatPhoneNumber(unformattedValue));
  }

  function handleWorkPhoneChangeDB() {
    setValue('phone', unformatWorkPhone)
  }





  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container p={5} direction={"column"} spacing={5}>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="h3" fontWeight={700}>Lead info</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>

                <Controller
                  name="firstName"
                  control={control}
                  rules={PreApprovalFormValidation.firstName}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"First Name"}
                      placeholder={"Enter your first name"}
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>

                <Controller
                  name="lastName"
                  control={control}
                  rules={PreApprovalFormValidation.lastName}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Last Name"}
                      placeholder={"Enter your last name"}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="email"
                  control={control}
                  rules={PreApprovalFormValidation.email}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'email'}
                      required
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Email Id"}
                      placeholder={"Enter your email id"}
                      error={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={PreApprovalFormValidation.dateOfBirth}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'date'}
                      required
                      boxProps={{ my: 2 }}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      size="small"
                      fullWidth
                      label={"Date of Birth"}
                      placeholder={"Mar-15-1994"}
                      error={errors?.dateOfBirth?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="phone"
                  control={control}
                  rules={PreApprovalFormValidation.phone}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      sicon={<CallOutlinedIcon />}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Mobile Number"}
                      placeholder={"(887) 509 - 5904"}
                      error={errors?.phone?.message}
                      value={formatWorkPhone}
                      onChange={handleWorkPhoneChange}
                      onBlur={handleWorkPhoneChangeDB}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="ssn"
                  control={control}
                  rules={PreApprovalFormValidation.ssn}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      fullWidth
                      label={"Enter your SSN"}
                      error={errors?.ssn?.message}
                      onChange={handleSSNChange}
                      pattern="\d{3}-?\d{2}-?\d{4}"
                      placeholder={"AAA-GG-SSSS"}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="monthlyIncome"
                  control={control}
                  rules={PreApprovalFormValidation.monthlyIncome}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}

                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Total Monthly Income"}
                      placeholder={"$10000"}
                      error={errors?.monthlyIncome?.message}
                      sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />
                  )}
                />
                <Controller
                  name="averageScore"
                  control={control}
                  rules={PreApprovalFormValidation.averageScore}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Your Average Credit Score"}
                      placeholder={"$698"}
                      error={errors?.averageScore?.message}

                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="totalLiabilities"
                  control={control}
                  rules={PreApprovalFormValidation.totalLiabilities}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Total Liabilities"}
                      placeholder={"$5000"}
                      error={errors?.totalLiabilities?.message}
                      sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>


          <Grid item container direction={"column"}>
            <Grid item my={3}>
              <Typography variant="h3" fontWeight={700}>Loan details</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item container md={5} direction={"column"}>
                <Grid item>
                  <InputLabel>
                    Loan Purpose
                    <Typography variant="small" color="error">
                      *
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    name='loanPurpose'
                    control={control}
                    rules={PreApprovalFormValidation.loanPurpose}
                    render={({ field }) => (
                      <RadioButtonGroup
                        field={field}
                        data={PURPOSE_LIST}
                        style={{ flexWrap: 'nowrap', display: 'flex' }}
                        direction="row"
                        labelColor={true}
                        error={errors?.loanPurpose?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item container md={5} direction={"column"}>
                <Grid item>
                  <InputLabel>
                    Loan Type
                    <Typography variant="small" color="error">
                      *
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    name='loanType'
                    control={control}
                    rules={PreApprovalFormValidation.loanType}
                    render={({ field }) => (
                      <RadioButtonGroup
                        field={field}
                        style={{ flexWrap: 'nowrap', display: 'flex' }}
                        data={LOAN_TYPE_LIST}
                        direction="row"
                        labelColor={true}
                        error={errors?.loanType?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name='propertyAddress'
                  control={control}
                  rules={PreApprovalFormValidation.propertyAddress}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="text"
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Full Property Address"}
                      placeholder={"To be determined"}
                      error={errors?.propertyAddress?.message}
                    />

                  )}
                />

              </Grid>
              <Grid item md={5}>

                <Controller
                  name="loanTerm"
                  control={control}
                  rules={PreApprovalFormValidation.loanTerm}
                  render={({ field }) => (

                    <CustomDropDown
                      {...field}
                      field={field}
                      type={'text'}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Loan Term"}
                      state={loanTerm}
                      option={LOAN_TERM_TYPE}
                      placeholder={"Years"}
                      error={errors?.loanTerm?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name='propertyZip'
                  control={control}
                  rules={PreApprovalFormValidation.propertyZip}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="number"
                      boxProps={{ my: 2 }}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      size="small"
                      fullWidth
                      label={"Property Zip Code"}
                      placeholder={"Enter your property zip code"}
                      error={errors?.propertyZip?.message}
                    />
                  )}
                />

              </Grid>
              <Grid item md={5}>
                <Controller
                  name="loanTermOption"
                  control={control}
                  rules={PreApprovalFormValidation.loanTerm}
                  render={({ field }) => (
                    < CustomDropDown
                      {...field}
                      field={field}
                      type={'text'}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Loan Product Type"}
                      state={loanTermOption}
                      option={LOAN_TERM_TYPE_ARM_OPTION}
                      placeholder={"Years"}
                      error={errors?.loanTerm?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name='salePrice'
                  control={control}
                  rules={PreApprovalFormValidation.salePrice}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="text"
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Estimated Property Value/Purchase Price/Sales Price"}
                      placeholder={"Enter the sale price"}
                      error={errors?.salePrice?.message}
                      sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />
                  )}
                />

              </Grid>

              <Grid item md={5}>
                <Controller
                  name="issueDate"
                  control={control}
                  rules={PreApprovalFormValidation.issueDate}
                  render={({ field }) => {
                    if (!field.value) {
                      field.onChange(new Date().toISOString().split('T')[0]);
                    }
                    return (
                      <CustomInput
                        {...field}
                        type="date"
                        boxProps={{ my: 2 }}
                        style={{ backgroundColor: 'white', borderRadius: '8px' }}
                        size="small"
                        fullWidth
                        label={"Date Issued"}
                        placeholder={"25/56/2023"}
                        error={errors?.issueDate?.message}
                        disabled={true}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item md={5}>
                <Controller
                  name='downPaymentPercentage'
                  control={control}
                  rules={PreApprovalFormValidation.salePrice}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="number"
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Down Payment Percentage "}
                      placeholder={"Enter the down payment %"}
                      error={errors?.salePrice?.message}
                      onBlur={handleDownPaymentPercentageBlur}

                    />
                  )}
                />

              </Grid>
              <Grid item md={5}>
                <Controller
                  name="downPaymentFixed"
                  control={control}
                  rules={PreApprovalFormValidation.salePrice}
                  render={({ field }) => (

                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Down Payment Amount"}
                      placeholder={"$ 350,000"}
                      error={errors?.maxPurchasePriceEligibility?.message}
                      onBlur={handleDownPaymentAmountBlur}
                      sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />

                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="interestRate"
                  control={control}
                  rules={PreApprovalFormValidation.interestRate}
                  render={({ field }) => (
                    <CustomInput
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Interst Rate"}
                      placeholder={"6.000%"}
                      error={errors?.interestRate?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="h3" fontWeight={700}>Transactional details</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="maxPurchasePriceEligibility"
                  control={control}
                  rules={PreApprovalFormValidation.maxPurchasePriceEligibility}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Estimated Property Value/Purchase Price/Sales Price"}
                      placeholder={"$ 350,000"}
                      error={errors?.maxPurchasePriceEligibility?.message}
                      sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="loanToValue"
                  control={control}
                  rules={PreApprovalFormValidation.loanToValue}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"LTV (%)"}
                      placeholder={"80%"}
                      error={errors?.loanToValue?.message}
                      disabled={true}
                    />
                  )}
                />
              </Grid>

            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="loanAmount"
                  control={control}
                  rules={PreApprovalFormValidation.loanAmount}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Loan Amount"}
                      placeholder={"$ 280,000"}
                      error={errors?.loanAmount?.message}
                      disabled={loanPurpose === 'Refinancing' ? false : true}
                      sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="mmpAmountPerMonth"
                  control={control}
                  rules={PreApprovalFormValidation.mmpAmountPerMonth}
                  render={({ field }) => (
                    <CustomInput
                      style={{ backgroundColor: 'white', borderRadius: '8px' }}
                      {...field}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Monthly Mortgage payment Amount per month"}
                      placeholder={"$ 4,000"}
                      disabled={true}
                      error={errors?.mmpAmountPerMonth?.message}
                      sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}

                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container md={12} justifyContent={"end"} spacing={2}>
            <Grid item>
              <Button onClick={handleClose} variant="outlined"> Cancel</Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained"> Preview & Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <PreviewPDFModal
        size={'lg'}
        show={modalOpen}
        handleClose={handlePdfClose}
        title={`Generate Quote`}
        body={
          <FilePreview
            fileUrl={getFileUrl(leadId, "preapproval")}
            downloadPdf={downloadPdf}
          />
        }
      />
    </>
  );
}
