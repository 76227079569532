import React, { useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { Button, FormHelperText, Grid, Link as MuLink, Stack } from "@mui/material";

import "../../styles/admin.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CustomInput from "../../helpers/custom-input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getApiCallDynamic, postApiCallDynamic, } from "../../services/api-service";
import { API } from "../../services/api-config";
import useInput from "../../helpers/use-input";
import { MAXLength, ValidationConstant } from "../../constants/validation";
import { SERVICE_TYPE } from "../../constants/constatnt-variable";
import { useFormik } from "formik";
import { CountDown } from "../../helpers/time-counter";
import { ServerSuccessMsg } from "../../helpers/alert-msg";
import { profileState } from "../../recoil/profile-atom";
import { useRecoilValue } from "recoil";

export default function Account(props) {
  let [passwordChange, setPasswordChange] = useState("password");
  let [tempToken, setTempToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let profileInfo = useRecoilValue(profileState);

  let [emailOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [phoneOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [password] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [confirmPassword] = useInput({
    data: "",
    status: false,
    initial: "",
  });


  const passwordUpdateRequest = (event) => {
    if (event.target.id === "verify") {
      let data = {
        service: SERVICE_TYPE.changePassword,
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.info
        }
      ).then((res) => {
        if (res?.status === 200) {
          emailOtpCount.setCounter(res.data.emailOtpCount);
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      });
    }

    if (event.target.id === "setVerifyOtp") {
      setPasswordChange("setVerifyOtp");
      let data = {
        emailOTP: emailOTP.value,
        phoneOTP: phoneOTP.value,
        service: SERVICE_TYPE.changePassword,
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.securedOTP
        }
      ).then((res) => {
        if (
          res &&
          (res.status === 200 || res.status === 201 || res.status === 202)
        ) {
          setTempToken(res.data.tempToken);
        }
      });
      // nmlsIdChangeCancel();
    }

    if (event.target.id === "setPassword") {
      let data = {
        tempToken: tempToken,
        password: password.value,
        service: SERVICE_TYPE.changePassword
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.setPassword
        }
      ).then((res) => {
        if (
          res &&
          (res.status === 200 || res.status === 201 || res.status === 202)
        ) {
          ServerSuccessMsg(res);
          passwordChangeCancel();
        }
      });
    }
  };

  const passwordChangeCancel = () => {
    setPasswordChange("password");
    password.saveValue("");
    confirmPassword.saveValue("");
    emailOTP.saveValue("");
    phoneOTP.saveValue("");

  }

  const handleValidationOTP = (values) => {
    let errors = {};

    if (emailOTP.value.trim() === "") {
      errors.emailOTP = "Please enter your email otp";
    }
    if (phoneOTP.value.trim() === "") {
      errors.phoneOTP = "Please enter your phone otp";
    }
    return errors;
  };

  let [emailOtpCount] = CountDown({ sec: 0 });
  let [phoneOtpCount] = CountDown({ sec: 0 });

  const formikOTP = useFormik({
    initialValues: {
      emailOTP: emailOTP.value,
      phoneOTP: phoneOTP.value,
    },
    validate: (values) => handleValidationOTP(values),
    onSubmit: (values) => {
      passwordUpdateRequest({ target: { id: "setVerifyOtp" } });

    },
  });

  const handleValidation = (values) => {
    let errors = {};
    if (!ValidationConstant.regNumber.test(password.value)) {
      errors.password = "At least one number required";
    }

    if (!ValidationConstant.regCapitalAlpha.test(password.value)) {
      errors.password = "At least one uppercase letter";
    }
    if (!ValidationConstant.regSmallAlpha.test(password.value)) {
      errors.password = "At least one lowercase letter";
    }

    if (!ValidationConstant.regSpecialChar.test(password.value)) {
      errors.password = "Special character required";
    }
    if (!(password.value.length >= ValidationConstant.passwordLength)) {
      errors.password = "Password is short";
    }

    if (password.value !== confirmPassword.value) {
      errors.confirmPassword = "Password didn't match";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: password.value,
      confirmPassword: confirmPassword.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      passwordUpdateRequest({ target: { id: "setPassword" } });
    },
  });

  const resendOtp = (event) => {
    event.preventDefault();

    let param = {
      email: profileInfo.email,
      type: event.target.value,
      service: SERVICE_TYPE.changePassword,
    };

    getApiCallDynamic({ param: param, path: API.verifyOTP }).then((res) => {
      if (res?.status === 200) {
        if (event.target.value === "email") {
          emailOtpCount.setCounter(res.data.emailOtpCount);
        }
        if (event.target.value === "phone") {
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      }
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={7} my={1} maxWidth={{ xs: '100%', md: '40%' }}>
          <Typography variant="h2" style={{ fontSize: 24 }}>Change Password</Typography>
          <Typography variant="body1" color="text.secondary" mt={2}>
            Verify OTP and set new password
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} my={1}>
          <Box display={passwordChange === "password" ? "" : "none"}>
            <Button
              variant="contained"
              size="large"
              onClick={() => { passwordUpdateRequest({ target: { id: "verify" } }); setPasswordChange("verify"); }}
            >
              Change Password
            </Button>
          </Box>

          {/* Use for OTP */}
          <form onSubmit={formikOTP.handleSubmit}>
            <Box display={passwordChange === "verify" ? "" : "none"}>
              <Typography my={2}>
                {`We have sent OTP to your registered mobile number & email ID`}
              </Typography>

              {/* Used for  making inline*/}
              <Grid container spacing={1} alignItems="center" mb={2}>
                <Grid item xs={12}>
                  <FormHelperText
                    sx={{
                      color: "blue",
                      width: "25%",
                      float: "right",
                      textAlign: "right",
                    }}
                  >
                    {emailOtpCount.counter == 0 ? (
                      <MuLink
                        component="button"
                        id={"passwordChange"}
                        value="email"
                        onClick={resendOtp}
                      >
                        {" "}
                        Resend
                      </MuLink>
                    ) : (
                      emailOtpCount.obj.m + " min " + emailOtpCount.obj.s + " sec"
                    )}
                  </FormHelperText>
                  <CustomInput
                    label={"Enter OTP sent on your email"}
                    size="small"
                    error={formikOTP.errors.emailOTP}
                    change={emailOTP}
                    fullWidth
                    placeholder="Email OTP"
                    maxLength={MAXLength.otp}
                  />
                </Grid>
              </Grid>
              {/* Used for  making inline*/}
              <Grid container spacing={1} alignItems="center" mb={2}>
                <Grid item xs={12}>
                  <FormHelperText
                    sx={{
                      color: "blue",
                      width: "25%",
                      float: "right",
                      textAlign: "right",
                    }}
                  >
                    {phoneOtpCount.counter == 0 ? (
                      <MuLink
                        component="button"
                        id={"passwordChange"}
                        value="phone"
                        onClick={resendOtp}
                      >
                        {" "}
                        Resend
                      </MuLink>
                    ) : (
                      phoneOtpCount.obj.m + " min " + phoneOtpCount.obj.s + " sec"
                    )}
                  </FormHelperText>
                  <CustomInput
                    label={"Enter OTP sent on your phone"}
                    size="small"
                    error={formikOTP.errors.phoneOTP}
                    change={phoneOTP}
                    fullWidth
                    placeholder="Phone OTP"
                    maxLength={MAXLength.otp}
                  />
                </Grid>
              </Grid>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  type="button"
                  onClick={passwordChangeCancel}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  id="verify"
                  type="submit"
                >
                  Verify
                </Button>
              </Box>
            </Box>
          </form>
          <form onSubmit={formik.handleSubmit}>
            <Box my={2} display={passwordChange === "setVerifyOtp" ? "flex" : "none"}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <CustomInput
                    type={showPassword ? "text" : "password"}
                    eicon={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    }
                    label={"New Password"}
                    helperText={"Invalid Password"}
                    id="password"
                    placeholder={"Enter your new passowrd"}
                    change={password}
                    error={formik.errors.password}
                    maxLength={MAXLength.password}
                  />


                  <CustomInput
                    type={showConfirmPassword ? "text" : "password"}
                    eicon={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    }
                    label={"Re-enter New Password"}
                    placeholder={"Enter your new passowrd"}
                    change={confirmPassword}
                    id="password"
                    error={formik.errors.confirmPassword}
                    maxLength={MAXLength.password}
                  />
                  {/* Add this sx to make it inline */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    my={2}
                    p={2}
                    sx={{ background: "#2F80ED20", borderRadius: "8px" }}
                  >
                    <Box display="flex" my={1}>
                      {password.value.length >=
                        ValidationConstant.passwordLength ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <CircleOutlinedIcon color="primary" />
                      )}
                      <Typography ml={1}>At least 8 characters</Typography>
                    </Box>
                    <Box display="flex" my={1}>
                      {ValidationConstant.regSpecialChar.test(password.value) ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <CircleOutlinedIcon color="primary" />
                      )}
                      <Typography ml={1}>At least one special character</Typography>
                    </Box>
                    <Box display="flex" my={1}>
                      {ValidationConstant.regNumber.test(password.value) ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <CircleOutlinedIcon color="primary" />
                      )}
                      <Typography ml={1}>At least one number</Typography>
                    </Box>

                    <Box display="flex" my={1}>
                      {ValidationConstant.regCapitalAlpha.test(password.value) &&
                        ValidationConstant.regSmallAlpha.test(password.value) ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <CircleOutlinedIcon color="primary" />
                      )}
                      <Typography ml={1}>
                        At least one uppercase and lowercase letter
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item container xs={12} md={6} justifyContent={"space-between"}>
                  <Stack direction={{ xs: 'column-reverse', md: 'row' }} gap={1} justifyContent={'space-between'} width={'100%'}>
                    <Button
                      variant="text"
                      size="large"
                      onClick={() => { passwordChangeCancel() }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      Reset Password
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
