// import { Box, Button } from "@mui/material";
// import React from "react";
// import { CREATE, ERROR_MESSAGE, MODAL_ACTION } from "../../../constants/constatnt-variable";
// import { Controller, useForm } from "react-hook-form";
// import CustomInput from "../../../helpers/custom-input";

// export default function DocumentLibraryForm({
//     data,
//     action = CREATE,
//     onSubmit,
// }) {
//     const DemoRe1uestNoteRules = {
//         note: {
//             required: { value: true, message: ERROR_MESSAGE.required },
//         },

//     }
//     const { control, handleSubmit, formState: { errors } } = useForm({
//         defaultValues: {
//             note: data?.note,
//             id: data?.id
//         }
//     })

//     return (
//         <form onSubmit={handleSubmit(onSubmit)}>
//             <Controller
//                 name='note'
//                 control={control}
//                 rules={DemoRe1uestNoteRules.note}
//                 render={({ field }) => (
//                     <CustomInput
//                         {...field}
//                         label={'Notes'}
//                         required
//                         placeholder={'This loan is in the process'}
//                         size="large"
//                         multiline
//                         rows={10}
//                         error={errors?.note?.message}
//                     />
//                 )}
//             />

//             <Box display="flex" justifyContent="flex-end" m={1}>
//                 <Button type='submit' id={action} variant="contained" onClick={handleSubmit}>
//                     {/* {MODAL_ACTION[action]} */} Save
//                 </Button>
//             </Box>
//         </form>
//     );
// }



import { Box, Button, FormLabel, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {

    BORROWER_ONLY_STATUS,

} from '../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../helpers/custom-input'

import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm, useWatch } from 'react-hook-form'

import RadioButtonGroupDoc from '../../../../../helpers/radio-button-group-doc'
import { LoanDocumentValidation, TaskTypeValidation } from '../../../../../constants/validation-rules'
import { KSCloudUploadIcon, KSDocumentSearchIcon } from '../../../../../icons/custome-icon'
import { getApiCallDynamic, uploadFile } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { toastNotify } from '../../../../../helpers/alert-msg'

import MultiSelectNotAllDropDown from '../../../../../helpers/dropdown/multi-select-noall-dropdown'
import { docPreview, documentPreview } from '../../../../../helpers/file-upload-download'


export default function LoanDocumentMainForm({
    handleClose,
    onSubmit,
    editItem,

    docList,
    formId,

}) {



    const [uploadData, setUploadData] = useState(null);








    const [fileNameToView, setFileNameToView] = useState(editItem?.fileName);




    const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {

            id: editItem?.id,

            documentType: editItem?.userDefinedDocType === true ? '' : editItem?.documentName || '',
            customDocumentType: editItem?.userDefinedDocType === true ? editItem?.documentName : '',

            taskType: editItem?.taskMaster?.id,
            borrowerOnlyDocument: editItem?.borrowerOnlyDocument === true ? 'true' : editItem?.borrowerOnlyDocument === false ? 'false' : '',
            documentDescriptor: editItem?.documentDescriptor,
            visibility: [
                editItem?.borrowerVisibility ? 'Borrower' : '',
                editItem?.realtorsVisibility ? 'Realtors' : ''
            ].filter(Boolean),
            userDefinedDocType: editItem?.documentType ? false : true,
            uploadType: editItem?.uploadType,
            fileName: editItem?.fileName,
            storageKey: editItem?.storageKey,
            storageDocId: editItem?.storageDocId,
            docType: editItem?.docType

        }
    })

    const {
        visibility }
        =
        useWatch({ control });



    const {

        documentType,

        customDocumentType,


    } = useWatch({ control });


    useEffect(() => {
        if (uploadData) {
            setValue('fileName', uploadData?.fileName)
            setValue('storageKey', uploadData?.storageKey)
            setValue('docType', uploadData?.docType)
            setValue('storageDocId', uploadData?.docId)

        }


    }, [uploadData])

    const [moreOptionBorrower, setMoreOptionBorrower] = useState(null)

    useEffect(() => {

        setMoreOptionBorrower(visibility.includes('Borrower'));


    }, [visibility])




    useEffect(() => {
        if (editItem?.uploadType === "LinkedDocument") {
            setValue('documentType', editItem?.documentName)
            setValue('uploadType', "LinkedDocument")
            // setValue('docType', uploadData?.docType)
            // setValue('storageDocId', uploadData?.docId)

        }


    }, [editItem?.uploadType])


    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);

    const [titles, setTitles] = useState(null)

    const fileUpload = async (event) => {
        let file = event.target.files[0];
        if (file) {
            const response = await uploadFile({
                formId: formId,
                file: file,
                serviceType: "Loan",
                path: API.proxyDocumentOthersUpload,
            });

            if (response.status === 201) {

                const modifiedResponseData = {
                    ...response.data,
                    docType: 'LocalUpload'
                };

                // Set the modified data
                setUploadData(modifiedResponseData);
                // setUploadData(response.data)
                setFileNameToView(response.data.fileName)
                setUploadError(null)
                setUploadResponse(modifiedResponseData);
                toastNotify("success", 'File uploaded successfully');


            } else {
                toastNotify("error", res.data.message);
            }
        } else {
            console.log("not found");
        }
    };

    const isUploadDisabled = !documentType && !customDocumentType;



    const previewDocument = (row) => {

        documentPreview(API.proxyDocumentDownload, {
            id: uploadData?.docId,
            serviceType: "Loan",
        });

    }

    const [uploadError, setUploadError] = useState(null);


    const isOneRequired = () => {
        const { documentType, customDocumentType } = getValues();

        if (documentType || customDocumentType) {
            if (fileNameToView) {
                return true;
            } else {
                setUploadError("Upload a file.");
                return false;
            }


        } else {

            return "Select or enter a document type";
        }
    };

    const [docListLibrary, setDocListLibrary] = useState([]);

    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTaskDoc,
                // param: { formId: formId, status: status }, 
            }).then((res) => {
                if (res?.status === 200) {
                    setDocListLibrary(res?.data);
                    //  updateBorrowerTask(res?.data)
                    updateThirdPartyTask(res?.data)

                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, []);
    const localUploadDocType = docListLibrary
        // .filter(item => item.taskMaster.taskTypeFor === "other")
        .map(item => ({
            label: item.documentType,
            value: item.documentType
        }));



    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Box width={'100%'} my={3} >
                <Box>
                    {/* {(editItem?.uploadType !== "LinkedDocument") && (<> */}
                    <Controller
                        name='documentType'
                        control={control}
                        rules={{ validate: isOneRequired }}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Select Document Type'}
                                required
                                disabled={editItem?.uploadType === "LinkedDocument"}
                                state={documentType}
                                option={localUploadDocType}
                                error={errors?.documentType?.message}
                            />
                        )}
                    />

                    <Box>
                        <Typography sx={{ color: '#D94166' }}>
                            OR
                        </Typography>

                    </Box>


                    <Controller
                        name="customDocumentType"
                        control={control}
                        rules={{ validate: isOneRequired }}
                        disabled={editItem?.uploadType === "LinkedDocument"}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter document type'}
                                type={'text'} label={'Enter Document Type'}


                                error={errors?.customDocumentType?.message} />

                        )}
                    />

                    {/* </>)} */}

                    <Controller
                        name="visibility"
                        control={control}
                        //  rules={LoanDocumentValidation.visibility}

                        render={({ field }) => (
                            <MultiSelectNotAllDropDown
                                label='Visibility To'
                                field={{
                                    ...field,
                                    value: field.value || [],
                                }}
                                options={["Borrower", "Realtors"]}
                                errors={errors.visibility}
                            />
                        )}
                    />


                    {(moreOptionBorrower) &&
                        <Controller
                            name="borrowerOnlyDocument"
                            control={control}
                            rules={LoanDocumentValidation.borrowerOnlyDocument}
                            render={({ field }) => (
                                <RadioButtonGroupDoc
                                    {...field}
                                    field={field}
                                    required
                                    direction="row"
                                    labelComponent={<FormLabel>{` Is this a Borrower Document?`}</FormLabel>}
                                    data={BORROWER_ONLY_STATUS}
                                    error={errors?.borrowerOnlyDocument?.message}
                                />
                            )}
                        />
                    }
                    {(editItem?.uploadType !== "LinkedDocument") &&
                        (uploadResponse &&
                            <Box
                                sx={{
                                    border: '1px solid lightGrey',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '8px',
                                }}
                            >
                                <Typography>
                                    {uploadResponse?.fileName || editItem?.fileName}
                                </Typography>

                                <IconButton
                                    color="primary"
                                    onClick={() => previewDocument(uploadResponse)}
                                // disabled={editItem?.uploadType !== "LinkedDocument"}
                                >
                                    <KSDocumentSearchIcon />
                                </IconButton>
                            </Box>
                        )
                    }





                </Box>

                <Box marginY={4} textAlign={'right'}>




                    <Stack
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        my={2}
                        justifyContent={"space-between"}
                    >
                        {(editItem?.uploadType !== "LinkedDocument") && (
                            <>
                                <Stack
                                    gap={1}
                                    my={2}
                                >
                                    <Stack
                                        direction={"row"}
                                        gap={1}
                                        alignItems={"center"}
                                        my={2}
                                        justifyContent={"space-between"}
                                    >
                                        <KSCloudUploadIcon color={"primary"} />
                                        <Button
                                            component="label"
                                            size="small"
                                            disabled={isUploadDisabled
                                                // || (editItem?.uploadType !== "LinkedDocument")
                                            }
                                        >
                                            <input
                                                onChange={fileUpload}
                                                hidden
                                                //accept="image/*"
                                                type="file"
                                            />

                                            <Typography >
                                                {fileNameToView ? `Reupload` : `Upload`}
                                            </Typography>

                                        </Button>




                                    </Stack>
                                    {uploadError && (
                                        <Typography color="error" sx={{ marginTop: 1, marginLeft: 1 }}>
                                            {uploadError}
                                        </Typography>
                                    )}
                                </Stack>




                            </>)}



                        <Button
                            type="button"
                            onClick={handleClose}
                            //variant="contained"
                            sx={{
                                //  width: '200px', 
                                width: '50%',
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            // disabled={isUploadDisabled} 
                            sx={{
                                //  width: '200px', 
                                width: '50%',
                            }}
                        >
                            {editItem?.length !== 0 ? 'Update' : 'Add'}
                        </Button>
                    </Stack>

                </Box>
            </Box>
        </form>

    )
}