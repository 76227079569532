import { Outlet, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "../../styles/admin.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ConfirmationModel from "../../helpers/modal/confirm-modal";
import { Grid, ListItemButton, Toolbar, useMediaQuery } from "@mui/material";
import { useRecoilState } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import { ROLE_LIST, roleOwner, title_MAP } from "../../constants/constatnt-variable";
import SideMenu from "./side-menu";
import { kstheme } from "../../theme/theme";
import KSLoanAppbar from "../../components/app-bar";
import Logo from '../../icons/cliqloan.png'

const drawerWidth = 310;


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    zIndex: 1300,
    position: "absolute",
    height: "100%",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.down("sm")]: {
    width: `calc(${theme.spacing(0)})`,
    zIndex: 1300,
    position: "absolute",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function BrokerIndex(props) {
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [appbarBackNavigation, setAppbarBackNavigation] = useState(false)
  let [toptitle, setToptitle] = useState("");
  const { id } = useParams();

  const pageTitleCondition = () => {
    if (location.pathname.includes('/loan-application/loan-summary/')) {
      setToptitle(`Application Number # ${id}`)
      setAppbarBackNavigation(false)
    }
    else if ((location.pathname.includes('/leads/preapproval-form/')) || ((location.pathname.includes('/leads/quote-form/')))) {
      setToptitle(`Lead Id # ${id}`)
      setAppbarBackNavigation(false)
    }
    else {
      setAppbarBackNavigation(false)
      if (title_MAP[location.pathname.split("/").pop()]) setToptitle(title_MAP[location.pathname.split("/").pop()]);
      else setToptitle(location.pathname.split("/").at(-1).split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
    }
  }

  useEffect(() => {
    pageTitleCondition()
  }, [location.pathname]);
  let [profileData, setProfileData] = useRecoilState(profileState);



  return (
    <>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#F5F9FA",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <CssBaseline />
        {open && isMobile ? (
          <Box
            position={"absolute"}
            width={"100%"}
            height={"100%"}
            bgcolor={"#00000030"}
            zIndex={1299}
            onClick={handleDrawerClose}
          />
        ) : null}


        <KSLoanAppbar
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          toptitle={toptitle}
          appbarBackNavigation={appbarBackNavigation}
          {...props}
        />


        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "#000435 !important",
              color: "white",
            },
          }}
        >

          <DrawerHeader
            className={"drawerHeader"}
            sx={{ justifyContent: "unset !important" }}
          >
            <IconButton style={[ROLE_LIST.borrower, ROLE_LIST.admin, ROLE_LIST.mlo, ROLE_LIST.mlp].includes(profileData.role) ? { marginBottom: '-6px' } : { marginBottom: '7px' }} onClick={handleDrawerClose} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
            <Box display={'flex'} flexDirection={'column'}>
              <Box mt={2} display={'flex'}>
                <img width={110} height={23} src={Logo} />
                <div>
                  <Typography sx={{ color: "white", fontWeight: "bold", textAlign: 'right', marginTop: '2px' }} ml={1} style={{ float: 'left' }}>| {profileData.role}</Typography>
                </div>
              </Box>
              <Typography mt={1} component={'span'} variant="overline" style={{ float: 'right' }} fontSize={'10px'} color={'#DBDBDB'} lineHeight={'150%'}>
                {roleOwner(profileData.role) && `(A/c no: #${profileData.accountNo})`}
              </Typography>
            </Box>
          </DrawerHeader>
          <Divider />


          <Grid
            container
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "nowrap",
              whiteSpace: "break-spaces",
            }}
          >
            <Grid item>
              <SideMenu
                role={profileData.role}
                position={"top"}
                navOpen={open}
                setOpen={setOpen}
              />
            </Grid>


            <Grid item>
              <SideMenu
                role={profileData.role}
                position="bottom"
                navOpen={open}
                setOpen={setOpen}
              />
              <List sx={{ p: 0 }}>
                <ConfirmationModel
                  body={
                    <ListItem sx={{ p: 0 }}>
                      <ListItemButton>
                        <ListItemIcon>
                          <LogoutOutlinedIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText>{"Logout"}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  }
                  handleSubmit={props.handleLogout}
                  message="Are you sure, You want to Logout?"
                  title={"Confirmation"}
                />
              </List>
            </Grid>
          </Grid>
        </Drawer>

        {/* Body Content */}
        <Grid
          name={"indexJs"}
          container
          direction={"column"}
          flexWrap={"nowrap"}
          overflow={"hidden"}
        >
          <Grid item flexGrow={0}>
            <Toolbar />
          </Grid>
          <Grid item flexGrow={1} overflow={"auto"}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
