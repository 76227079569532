import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfirmationDialogMsg from "../../../../helpers/dialog-boxes/ConfirmationDialogMsg"
import { API } from '../../../../services/api-config'
import { BillContext } from '../BillContext';


const AddUser = ({ userCode, shouldRender, onVerifySuccess }) => {

    // const { userCode } = useContext(BillContext);

    // const BASE_URL = API.billUser;

    // const BASE_URL = "https://stage.kramasoft.com/ks-bill/api/v1/user";
    const BASE_URL = API.billUser
    const ACCEPT_BILL_TERM_URL = 'acceptBillTerm?usercode=';
    //const userCod = 'c71yk492hi_1701877640159';

    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    useEffect(() => {
        const BillingAPI_URL = `${BASE_URL}/${ACCEPT_BILL_TERM_URL}${userCode}`;
        axios
            .get(BillingAPI_URL)
            .then((response) => {
                setConfirmationDialogMessage('HTTP Success');

                onVerifySuccess('SUCCESS_OTP');
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
                if (error.response && error.response.status === 500) {
                    setConfirmationDialogMessage('HTTP Fail: Internal Server Error');
                } else {
                    setConfirmationDialogMessage('HTTP Fail: ' + error.message);
                }
            })
            .finally(() => {
                setOpenConfirmationDialog(true);
            });
    }, [shouldRender]);

    return (
        // <div>
        //     <ConfirmationDialogMsg
        //         open={openConfirmationDialog}
        //         onClose={() => setOpenConfirmationDialog(false)}
        //         title="Add User"
        //         message={confirmationDialogMessage}
        //         width={400}
        //         height={200}
        //     />
        // </div>
        <></>
    );
};

export default AddUser;
