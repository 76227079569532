import Badge from "@mui/material/Badge";
import {styled} from "@mui/styles";

export const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        padding: "0 4px",
        marginLeft: '8px',
        borderRadius: "5px ",
        position: 'relative',
        transform: 'initial',
        alignSelf: 'self-end'
    },

}));
