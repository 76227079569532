import React, { useState } from "react";

import { Button, InputAdornment, Stack, } from "@mui/material";
import Grid from "@mui/material/Grid";
import useInput from "../../../helpers/use-input";
import { postApiCallDynamic, } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import CustomInput from "../../../helpers/custom-input";
import { useFormik } from "formik";
import { MAXLength, ValidationConstant } from "../../../constants/validation";
import LoadingOverlay from "@ronchalant/react-loading-overlay";

import { useRecoilState } from "recoil";
import { formState, profileState } from "../../../recoil/profile-atom";
import { ROLE_LIST, SERVICE_TYPE, UPDATE } from "../../../constants/constatnt-variable";
import { CustomAutocomplete } from "../../../helpers/custome-autocomplete";
import { KSMailIcon, KSPhoneIcon } from "../../../icons/custome-icon";

export default function InvitationForm({ data, ...props }) {

  const [spinner, setspinner] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState('');
  let [profileData, setProfileData] = useRecoilState(profileState);
  let [formValue, setFormValue] = useRecoilState(formState)

  let [firstName] = useInput({
    data: data.firstName,
    status: props.update,
    initial: "",
  });
  let [lastName] = useInput({
    data: data.lastName,
    status: props.update,
    initial: "",
  });
  // let [phone] = useInput({
  //   data: data.phone,
  //   status: props.update,
  //   initial: "",
  // });
  let [phone, setPhone] = useInput({
    data: data.phone,
    status: props.update,
    initial: "",
  });
  let [email] = useInput({
    data: data.email,
    status: props.update,
    initial: "",
  });
  let [message] = useInput({
    data: data.message,
    status: props.update,
    initial: "",
  });



  const [unformatWorkPhone, setUnformatWorkPhone] = useState(phone.value || '');
  const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(phone.value));


  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  }

  function handleWorkPhoneChange(event) {
    let unformattedValue = event.target.value.replace(/\D/g, '');

    if (unformattedValue.length > 10) {
      unformattedValue = unformattedValue.slice(0, 10);
    }

    setUnformatWorkPhone(unformattedValue);
    setFormatWorkPhone(formatPhoneNumber(unformattedValue));
  }


  function handleWorkPhoneChangeDB() {
    formik.setFieldValue('phone', unformatWorkPhone);
  }


  const handleDropDownChange =
    (val, name, multi = false) =>
      (event) => {
        if (multi) {
          if (event.length === 0) {
            val.saveValue("");

            return;
          }
          event = event[0];
        }
        val.saveValue(event.value);
      };

  const handleSubmit = () => {
    setspinner(true);
    let apiData = {
      id: data.id,
      firstName: firstName.value,
      lastName: lastName.value,
      phone: unformatWorkPhone,
      email: email.value,
      message: message.value,
      role: ROLE_LIST.borrower,
      service: SERVICE_TYPE.invitation
    };
    postApiCallDynamic(
      {
        data: apiData,
        refresh: (res) => {
          return res;
        },

        path: API.teamInvitaion,
        header: props.update && UPDATE
      }
    ).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        props.handleSubmit(apiData);
      }
      setspinner(false);
    });
  };

  const handleValidation = (values) => {
    const re = ValidationConstant.emailRegx;

    let errors = {};

    if (firstName.value === "") {
      errors.firstName = "Enter a Name to proceed";
    }

    if (lastName.value === "") {
      errors.lastName = "Enter a Name to proceed";
    }
    if (re.test(String(email.value.trim()).toLowerCase()) === false) {
      errors.email = "Please enter valid Email Id";
    }


    if (ValidationConstant.usPhoneNumber.test(String(values.phone)) === false) {
      errors.phone = "Please enter valid Contact Number";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      lastName: lastName.value,
      firstName: firstName.value,
      email: email.value,

      phone: unformatWorkPhone,
      message: message.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleInvitationMessageChange = (event) => {
    setInvitationMessage(event.target.value);
  };
  const remainingChars = 500 - invitationMessage.length;










  return (
    <LoadingOverlay
      active={spinner}

      spinner={true}
      text="Please wait a moment"
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 0 }}>

        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 0 }}>
          <CustomInput
            label={"First Name"}
            required
            size="small"
            fullWidth
            placeholder="Enter the name of the person "
            change={firstName}
            maxLength={MAXLength.name}
            error={formik.errors.firstName}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"Last Name"}
            required
            size="small"
            fullWidth
            placeholder="Enter the name of the person "
            change={lastName}
            maxLength={MAXLength.name}
            error={formik.errors.lastName}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"Phone Number"}
            required
            size="small"
            fullWidth
            placeholder="(655)349-4367"
            sicon={
              <InputAdornment position="start">
                <KSPhoneIcon />
              </InputAdornment>
            }
            type="text"

            error={formik.errors.phone}
            value={formatWorkPhone}
            onChange={handleWorkPhoneChange}
            onBlur={handleWorkPhoneChangeDB}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"Email Id"}
            required
            sicon={<KSMailIcon />}
            size="small"
            fullWidth
            placeholder="Enter recipent Email Id"
            change={email}
            // disabled={data}
            disabled={props.update}
            error={formik.errors.email}
            maxLength={MAXLength.email}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2, mb: 2 }}>
          <div>
            <CustomInput
              label={"What can we help you with ?"}
              size="large"
              fullWidth

              placeholder="Note"
              multiline

              value={invitationMessage}
              onChange={handleInvitationMessageChange}
            />
            <span>{remainingChars} characters remaining</span>
          </div>
        </Grid>

        <Stack direction={'row'} justifyContent={'end'} gap={2}>
          <Button onClick={props.handleClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            {props.update ? "Update" : "Send"}
          </Button>
        </Stack>
      </form>
    </LoadingOverlay>
  );
}
