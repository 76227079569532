import * as React from "react";
import { Box, Button, Divider, Link as MuiLink } from "@mui/material";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import UserImg from "../../src/assets/profileDemo.png";
import SystemImg from "../../src/assets/System-icon.svg";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { profileState } from "../recoil/profile-atom";
import { useState } from "react";
import { postApiCallDynamic } from "../services/api-service";
import { API } from "../services/api-config";
let stream = require('getstream');
import { StreamApp, NotificationDropdown, FlatFeed, Activity, LikeButton } from 'react-activity-feed';

const customStyle = makeStyles({
  UserImage: {
    width: "50px",
    height: "50px",
    borderRadius: "10px",
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -15,
    padding: "0 4px",
    borderRadius: "5px ",
    backgroundColor: "#F2F2F2",
  },
}));

const getTime = (date) => {
  const today = new Date()
  /*
  if (!(date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear())) {
    return `${date.getMonth() + 1} \\ ${date.getDate()} \\ ${date.getFullYear()}`
  }
  const dateObject = new Date(date);
  const todayObject = new Date(today);
  const timeDifference = todayObject - dateObject;
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  return `${Math.floor(hoursDifference)}h ago` */

  /* after sonu 
  const formattedDate = `${date.getMonth() + 1} / ${date.getDate()} / ${date.getFullYear()}`;

  // Format the time
  const hours = date.getHours().toString().padStart(2, '0'); // Ensure two digits
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits
  const seconds = date.getSeconds().toString().padStart(2, '0'); // Ensure two digits
  const formattedTime = `${hours}:${minutes}:${seconds}`;  sonu fix ends */

  const formattedDate = date.toLocaleDateString(); // Get localized date string
  const formattedTime = date.toLocaleTimeString();
  // Combine date and time
  return `${formattedDate} ${formattedTime}`;
}

export default function Notificaiton() {
  const classes = customStyle();
  const [value, setValue] = React.useState("1");
  let profileInfo = useRecoilValue(profileState);
  const [userToken, setUserToken] = useState('');

  useEffect(async () => {
    await postApiCallDynamic({
      data: { userId: profileInfo?.userCode }, refresh: (res) => {
        return res;
      }, path: API.notificationUserToken
    })
      .then((res) => {
        setUserToken(res.data.UserToken);
      });
    if (userToken) fetchNotifications()
  }, [userToken]);

  const fetchNotifications = async () => {
    const client = stream.connect(process.env.REACT_APP_GETSTREAM_SECRECT_KEY, null, process.env.REACT_APP_GETSTREAM_APP_ID); // api key & app id
    //console.log(userToken, profileInfo?.userCode, 'client')
    const notificationScott = client.feed('notification', profileInfo?.userCode, userToken);
    function callback(data) {
      //   console.log(data.new[0].message, 'notif added123421');
    }
    function successCallback(res) {
      //  console.log(res, 'result of success')
      // alert('Now listening to changes in realtime. Add an activity to see how realtime works.');
    }
    function failCallback(data) {
      alert('Something went wrong, check the console logs');
      //   console.log(data);
    }
    notificationScott.subscribe(callback).then(successCallback, failCallback);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width={{ xl: "20vw", lg: "30vw", md: "40vw", sm: "50vw", xs: "90vw" }}>
      <Typography variant="h4" p={3}>Notifications</Typography>
      {userToken &&
        <StreamApp apiKey={process.env.REACT_APP_GETSTREAM_SECRECT_KEY} appId={process.env.REACT_APP_GETSTREAM_APP_ID} token={userToken}>
          {/* <NotificationDropdown
            feedGroup="notification"
            // Header={(props) => (<>{console.log(props, 'header')}</>)}
            // Footer={(props) => (<>{console.log(props, 'footer')}</>)}
            notify /> */}
          <FlatFeed
            feedGroup="notification"
            notify
            Activity={(props) => (
              <Activity
                {...props}
                Header={() => (<></>)}
                Footer={() => (
                  <>
                    {props.activity.activities.length &&
                      <div style={{ padding: '8px 16px' }}>
                        {props.activity.activities.map((activity, i) => {
                          const date = new Date(activity.time)
                          return (
                            <>
                              <Box display={"flex"} my={1}>
                                <img src={SystemImg} className={classes.UserImage} />
                                <Box ml={2}>
                                  <Box mb={1}>
                                    <Typography variant="body2" fontWeight={"bold"}>
                                      {activity.message}
                                    </Typography>
                                    <Typography variant="caption">{getTime(date)}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Divider />
                            </>
                          )
                        })
                        }
                      </div>}
                  </>
                )}
              />
            )}
          />
        </StreamApp>
      }
    </Box >
  );
}
