import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Grid, Stack, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form';
import { LOAN_PARTNER_STATUS, ADDITIONAL_BORROWER_STATUS } from '../../../../../constants/constatnt-variable';
import CustomInput from '../../../../../helpers/custom-input';
import CustomLargeLabel from '../../../../../helpers/large-label';
import CustomRadioChip from '../../../../../helpers/radio-chip';
import loanPageSideImage from '../../../../../assets/loanPageSideImage.png'
import { GettingStartedValidation } from '../../../../../constants/validation-rules';
import { useLoanContext } from './LoanContext';
import { kstheme } from '../../../../../theme/theme';

export default function Partner({ data, onSubmit, onDataChange }) {
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const [formatHomePhone, setFormatHomePhone] = useState(formatPhoneNumber(data?.partner?.phone));
    const [unformatHomePhone, setUnformatHomePhone] = useState(data?.partner?.phone || '');

    useEffect(() => {

        onDataChange(data);
    }, [data]);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            firstName: data?.partner?.firstName,
            lastName: data?.partner?.lastName,
            phone: data?.partner?.phone,
            email: data?.partner?.email,
            loanPartnerStatus: data?.hasPartner,

        },
    })
    const { loanPartnerStatus } = useWatch({ control })

    useEffect(() => {
        if (loanPartnerStatus === 'No') {
            setValue('firstName', null)
            setValue('lastName', null)
            setValue('phone', null)
            setValue('email', null)
            setFormatHomePhone(null)
            setValue('loanPartnerStatus', 'No');
        }


    }, [loanPartnerStatus]);

    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }


    function handleHomePhoneChange(event) {
        let unformattedValue = event.target.value.replace(/\D/g, '');

        if (unformattedValue.length > 10) {
            unformattedValue = unformattedValue.slice(0, 10);
        }
        setUnformatHomePhone(unformattedValue);
        setFormatHomePhone(formatPhoneNumber(unformattedValue));
    }

    function handleHomePhoneChangeDB() {
        setValue('phone', unformatHomePhone)
    }




    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <Grid container height={'inherit'} px={{ xs: 1, md: 4 }}>
                    <Grid item xs={12} md={8}>
                        <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
                            <Box ml={3}>

                                <>
                                    <Controller
                                        name='loanPartnerStatus'
                                        control={control}
                                        rules={GettingStartedValidation.loanPartnerStatus}
                                        render={({ field }) => (
                                            <CustomRadioChip
                                                {...field}
                                                field={field}
                                                required
                                                labelComponent={<CustomLargeLabel required value={'Do you have a co-borrower(Spouse) for this loan?   '} />}

                                                state={loanPartnerStatus}
                                                data={LOAN_PARTNER_STATUS}
                                                error={errors?.loanPartnerStatus?.message}
                                            />
                                        )}
                                    />
                                    {loanPartnerStatus === 'Yes' ? (
                                        <>
                                            <>

                                                <CustomLargeLabel value={'Enter your spouse details'} />
                                                <Box width={{ xs: '100%', md: '50%' }} my={2} >
                                                    <Controller
                                                        name="firstName"
                                                        control={control}
                                                        rules={GettingStartedValidation.firstName}
                                                        render={({ field }) => (
                                                            <CustomInput
                                                                {...field}
                                                                label="First Name"
                                                                required
                                                                error={errors?.firstName?.message}
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name="lastName"
                                                        control={control}
                                                        rules={GettingStartedValidation.lastName}
                                                        render={({ field }) => (
                                                            <CustomInput
                                                                {...field}
                                                                label="Last Name"
                                                                required
                                                                error={errors?.lastName?.message}
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name="phone"
                                                        control={control}
                                                        rules={GettingStartedValidation.phone}
                                                        render={({ field }) => (
                                                            <CustomInput
                                                                {...field}
                                                                label="Phone Number"
                                                                required
                                                                type="text"
                                                                error={errors?.phone?.message}
                                                                value={formatHomePhone}
                                                                onChange={handleHomePhoneChange}
                                                                onBlur={handleHomePhoneChangeDB}
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        rules={GettingStartedValidation.email}
                                                        render={({ field }) => (
                                                            <CustomInput
                                                                {...field}
                                                                label="Email"
                                                                required
                                                                error={errors?.email?.message}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </>
                                        </>
                                    ) : null}
                                </>
                            </Box>
                            <Box my={2} ml={3}>
                                <Button type="submit" endIcon={<ArrowForwardIos />} disabled={data.loanPartnerStatus === ''}>
                                    Save and Next
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                    {!isMobile &&
                        <Grid item xs={12} md={4} display={'flex'} height={'100%'}>
                            <Box display={{ xs: 'none', md: 'block' }} pl={3} my={'auto'}>
                                <img style={{ transform: 'rotate(90deg)' }} src={loanPageSideImage} width={"100%"} alt="Loan Page Side Image" />
                            </Box>
                        </Grid>}
                </Grid>
            </form>
        </>
    )
}