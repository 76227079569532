

import { Box, Button } from "@mui/material";
import React from "react";
import { CREATE, ERROR_MESSAGE, MODAL_ACTION } from "../../../constants/constatnt-variable";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../helpers/custom-input";

export default function NoteForm({
  data,
  action = CREATE,
  onSubmit,
}) {
  const DemoRequestNoteRules = {
    note: {
      required: { value: true, message: ERROR_MESSAGE.required },
    },
  };

  const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      note: data?.note || '',
      id: data?.id || ''
    }
  });

  const invitationMessage = watch('note', data?.note || '');

  const remainingChars = 500 - invitationMessage.length;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='note'
        control={control}
        rules={DemoRequestNoteRules.note}
        render={({ field }) => (
          <div>
            <CustomInput
              {...field}
              label='Notes'
              required
              placeholder='This loan is in the process'
              size="large"
              multiline
              rows={10}
              error={errors?.note?.message}
              onChange={(e) => {
                field.onChange(e);
                setValue('note', e.target.value);
              }}
            />
            <span>{remainingChars} characters remaining</span>
          </div>
        )}
      />
      <Box display="flex" justifyContent="flex-end" m={1}>
        <Button type='submit' id={action} variant="contained">
          {MODAL_ACTION[action]}
        </Button>
      </Box>
    </form>
  );
}
