import React, { useEffect, useMemo, useRef, useState } from "react";
import MainLoyOut from "../../../components/main-layout";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import { Link, useNavigate } from "react-router-dom";
import ProfileImg from "../../../assets/Avatar-mlo.png";
import LoanCard from "../../../components/loan/loan-card";
import { StyledBadge } from "../../../theme/badge";
import {
  getApiCallDynamic,
  postApiCallDynamicWithOutReturn,
} from "../../../services/api-service";
import { API } from "../../../services/api-config";
import { LOAN_STATUS, ROLE_LIST, UPDATE } from "../../../constants/constatnt-variable";
import {
  KSChatSmIcon,
  KSClipBoardListIcon,
  KSClockIcon,
  KSEditUserSmIcon,
  KSPencilAltIcon,
  KSUserGroupIcon,
} from "../../../icons/custome-icon";
import { Path } from "../../../routes/route-config";
import ChatApp from "../../chat/chat-app";
import ConfirmationModel from "../../../helpers/modal/confirm-modal";
import { getImageUrl } from "../../../helpers/file-upload-download";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../recoil/profile-atom";
import MLOAvatar from '../../../icons/avatar_mlo.png';
import MLPAvatar from '../../../icons/avatar_mlp.png';

export default function LoanList(props) {
  const [chatData, setChatData] = useState({
    firstScreen: false,
    externalChat: {},
    internalChat: {},
    appNo: "",
    formId: ""
  });

  const LOAN_LIST_TABS = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'In Progress', value: 'inProgress' },
    { label: 'Closed', value: 'closed' },
    { label: 'Rejected', value: 'rejected' },
    // { label: 'Locked', value: 'locked' },
    { label: 'Incomplete', value: 'incomplete' },
    { label: 'Archived', value: 'archived' },
  ]
  let profileInfo = useRecoilValue(profileState);

  const [loanTabValue, setLoanTabValue] = useState("all");
  const [reloadTable, setreloadTable] = useState(false);

  const tableref = useRef(null);

  const [count, setCount] = useState({});

  const handleChange = (event, newValue) => {
    setLoanTabValue(newValue);
  };

  useEffect(() => {
    loanCount()
  }, []);

  const loanCount = () => {
    getApiCallDynamic({ path: API.loanCount }).then((res) => {
      if (res?.status === 200) {
        setCount(res?.data);

      }
    });
  };

  const router = useNavigate();

  // nevigate the loan application from edit icon
  const goToLoanApplication = (formId) => {
    router(window.open(Path.loanApplication + `/${formId}`, "_blank"));
  };
  // nevigate the Additional Loan Contact
  const goToAdditionalLoanContact = (formId) => {
    router(`${formId}/` + Path.additionalLoanContact);
  };
  // nevigate the Loan Notes
  const goToLoanNotes = (formId) => {
    router(`${formId}/` + Path.loanNotes);
  };

  // handle Edit Permission
  const handleEditPermission = async (formId) => {
    let apiCallData = {
      formId: formId,
    };

    await postApiCallDynamicWithOutReturn({
      data: apiCallData,
      path: API.loanEditPermission,
      header: UPDATE,
    });
  };

  // This const used for adding space for post api which is different from get api
  const fixingTabValue = (value) => {
    if (value == 'inProgress') {
      return 'in Progress'
    } else {
      return value
    }
  }
  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorKey: "appNo",
        header: "App No.",
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <Link to={Path.loanSummary + "/" + cell.getValue()}>
            {cell.getValue()}
          </Link>
        ),
      },
      // Other column definitions...

      {
        accessorKey: "mloName",
        header: "MLO",
        enableColumnActions: false,
        Cell: ({ cell, row }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {cell.getValue() === null ? (
                "Not assigned"
              ) : (
                <img
                  height={30}
                  width={30}
                  src={(row.original.mloAvatarDocId && row.original.mloAvatarDocFileName) ? getImageUrl(row.original.mloAvatarDocId, 'Avatar', 'avatar') : MLOAvatar}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              )}
              <Typography>{cell.getValue()}</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: "mlpName",
        header: "MLP",
        enableColumnActions: false,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {cell.getValue() === null ? (
              "Not assigned"
            ) : (
              <img
                height={30}
                width={30}
                src={(row.original.mlpAvatarDocId && row.original.mlpAvatarDocFileName) ? getImageUrl(
                  row.original.mlpAvatarDocId,
                  'Avatar',
                  "avatar"
                ) : MLPAvatar}
                loading="lazy"
                style={{ borderRadius: "50%" }}
              />
            )}
            <Typography>{cell.getValue()}</Typography>
          </Box>
        ),
      },
      {
        accessorFn: (row) => (
          <>
            {row.borrowerInfo.map((item, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{
                  backgroundColor:
                    item.formStatus === LOAN_STATUS.active
                      ? ""
                      : item.formStatus === LOAN_STATUS.inProgress
                        ? "#FFB21026"
                        : "#D82E5726",

                  borderRadius: "44px",
                  color:
                    item.formStatus === LOAN_STATUS.active
                      ? ""
                      : item.formStatus === LOAN_STATUS.inProgress
                        ? "#FFB210"
                        : "#D82E57",
                  maxWidth: "12ch",
                  p: "0.25rem",
                  textAlign: "center",
                  m: "0.25rem",
                }}
              >
                {item.formStatus}
              </Typography>
            ))}
          </>
        ),
        id: "formStatus",
        header: "Status",
        enableColumnActions: false,
      },
      {
        accessorFn: (row) => (

          <>
            {row.borrowerInfo.map((item, index) => (
              <Typography key={index}>
                {item.name}
                {item.type
                  ? ` (${item.type === "Primary"
                    ? "Primary Borrower"
                    : "Co-Applicant"
                  })`
                  : null}
              </Typography>
            ))}
          </>
        ),
        accessorKey: "borrowerInfo",
        header: "Borrower Info",
        enableColumnActions: false,
      },

    ];

    if (
      props.user.data.role === ROLE_LIST.mlo ||
      props.user.data.role === ROLE_LIST.mlp
    ) {
      baseColumns.push({
        accessorKey: "brokerName",
        header: "Broker",
        enableColumnActions: false,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {cell.getValue() === null ? (
              "Not assigned"
            ) : (
              <img
                height={30}
                width={30}
                src={(row.original.brokerAvatarDocId && row.original.brokerAvatarDocFileName) ? getImageUrl(
                  row.original.brokerAvatarDocId,
                  'Avatar',
                  "avatar"
                ) : MLOAvatar}
                loading="lazy"
                style={{ borderRadius: "50%" }}
              />
            )}
            <Typography>{cell.getValue()}</Typography>
          </Box>
        ),
      });
    }

    return baseColumns;
  }, []);



  // const columns = useMemo(
  //   () => [
  //     {
  //       accessorKey: "appNo",
  //       header: "App No.",
  //       enableColumnActions: false,
  //       Cell: ({ cell }) => {
  //         return (
  //           <Link to={Path.loanSummary + "/" + cell.getValue()}>
  //             {cell.getValue()}
  //           </Link>
  //         );
  //       },
  //     },
  //     //column definitions...

  //     {
  //       accessorKey: "mloName",
  //       header: "MLO",
  //       enableColumnActions: false,
  //       Cell: ({ cell, row }) => {
  //         return (
  //           <Box
  //             sx={{
  //               display: "flex",
  //               alignItems: "center",
  //               gap: "10px",
  //             }}
  //           >
  //             {cell.getValue() === null ? (
  //               "Not assigned"
  //             ) : (
  //               <img
  //                 height={30}
  //                 width={30}
  //                 src={getImageUrl(row.original.mloAvatarDocId, row.original.mloAvatarDocFileName, 'avatar')}
  //                 loading="lazy"
  //                 style={{ borderRadius: "50%" }}
  //               />
  //             )}
  //             <Typography>{cell.getValue()}</Typography>
  //           </Box>
  //         );
  //       },
  //     },



  //     {
  //       accessorKey: "mlpName",
  //       header: "MLP",
  //       enableColumnActions: false,
  //       Cell: ({ cell, row }) => {
  //         return (
  //           <Box
  //             sx={{
  //               display: "flex",
  //               alignItems: "center",
  //               gap: "10px",
  //             }}
  //           >
  //             {cell.getValue() === null ? (
  //               "Not assigned"
  //             ) : (
  //               <img
  //                 height={30}
  //                 width={30}
  //                 src={getImageUrl(row.original.mlpAvatarDocId, row.original.mlpAvatarDocFileName, 'avatar')}
  //                 loading="lazy"
  //                 style={{ borderRadius: "50%" }}
  //               />
  //             )}
  //             <Typography>{cell.getValue()}</Typography>
  //           </Box>
  //         );
  //       },
  //     },

  //     {
  //       accessorFn: (row) => {
  //         return (
  //           <>
  //             {row.borrowerInfo.map((item, index) => (
  //               <Typography
  //                 key={index}
  //                 variant="body2"
  //                 sx={{
  //                   backgroundColor:
  //                     item.formStatus === LOAN_STATUS.active
  //                       ? ""
  //                       : item.formStatus === LOAN_STATUS.inProgress
  //                         ? "#FFB21026"
  //                         : "#D82E5726",

  //                   borderRadius: "44px",
  //                   color:
  //                     item.formStatus === LOAN_STATUS.active
  //                       ? ""
  //                       : item.formStatus === LOAN_STATUS.inProgress
  //                         ? "#FFB210"
  //                         : "#D82E57",
  //                   maxWidth: "12ch",
  //                   p: "0.25rem",
  //                   textAlign: "center",
  //                   m: "0.25rem",
  //                 }}
  //               >
  //                 {item.formStatus}
  //               </Typography>
  //             ))}
  //           </>
  //         );
  //       },
  //       id: "formStatus",
  //       header: "Status",
  //       enableColumnActions: false,
  //     },
  //     {
  //       accessorFn: (row) => {
  //         return (
  //           <>
  //             {row.borrowerInfo.map((item, index) => (
  //               <Typography key={{ index }}>
  //                 {item.name}
  //                 {item.type ? ` (${item.type === 'Primary' ? 'Primary Borrower' : 'Borrower 2'})` : null}
  //               </Typography>
  //             ))}
  //           </>
  //         );
  //       },
  //       accessorKey: "borrowerInfo",
  //       header: "Borrower Info",
  //       enableColumnActions: false,
  //     },
  //   ],
  //   []
  // );

  return (
    <MainLoyOut>
      <ChatApp setChatData={setChatData} chatData={chatData} />
      <Box display={"flex"} flexDirection={"row-reverse"}>
        <LoanCard data={count?.activeBorrowers} title={"Active Borrowers"} />
      </Box>
      <Grid item md={12} xs={12}>
        <Paper>
          <TabContext value={loanTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
              >
                {
                  LOAN_LIST_TABS.map((loanTab) => (<Tab
                    key={loanTab.value}
                    label={loanTab.label}
                    value={fixingTabValue(loanTab.value)}
                    style={{ fontWeight: 700 }}
                    iconPosition="end"
                    icon={
                      // Use for tab counting
                      <Chip
                        label={count?.[loanTab.value.toString()]}
                        sx={{
                          color: "black",
                          backgroundColor:
                            loanTabValue == fixingTabValue(loanTab.value)
                              ? "primarylight.main"
                              : "#F2F2F2",
                          borderRadius: "5px",
                        }}
                      />
                    }
                  />))
                }

              </TabList>
            </Box>
          </TabContext>
        </Paper>
        <CustomReactMaterialTable
          enableTopToolbar={false}
          childFunc={tableref}
          enableToolbarInternalActions={false}
          enablePagination={false}
          refetch={reloadTable}
          params={{ status: loanTabValue }}
          endPoint={API.loans}
          columns={columns}
          columnVisibility={{
            mloName: props.user.data.role == ROLE_LIST.mlo ? false : true,
            mlpName: props.user.data.role == ROLE_LIST.mlp ? false : true
          }}
          enableRowActions={true}
          renderRowActions={({ cell, row, table }) => {
            return (
              <>
                {row?.original?.borrowerInfo.map((item, i) => (
                  <Box
                    display="flex"
                    direction="row"
                    justifyContent="start"
                    key={item.formId}
                  >
                    <ConfirmationModel
                      body={
                        <Tooltip title="Give edit privilege to borrower" arrow>
                          <IconButton
                            color={item?.isLocked ? "initial" : "primary"}
                          >
                            <KSEditUserSmIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      handleSubmit={() => {
                        handleEditPermission(item?.formId);
                      }}
                      message={
                        `Are you sure you want to give edit privilege to Borrower ${item?.name}?`
                      }
                      title={"Confirmation"}
                    />

                    <Tooltip title="Edit Loan" arrow>
                      <IconButton
                        onClick={() => {
                          goToLoanApplication(item?.formId);
                        }}
                      >
                        <KSPencilAltIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Chat with Borrower" arrow>
                      <IconButton
                        onClick={() => {
                          setChatData({
                            firstScreen: true,
                            externalChat: item?.externalChat,
                            internalChat: item?.internalChat,
                            appNo: row?.original?.appNo,
                            formId: item?.formId
                          });
                        }}


                      >
                        <KSChatSmIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Additional Loan Contacts" arrow>
                      <IconButton
                        onClick={() => {
                          goToAdditionalLoanContact(item?.formId);
                        }}
                      >
                        <KSUserGroupIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Broker Notes" arrow>
                      <IconButton
                        onClick={() => {
                          goToLoanNotes(item?.formId);
                        }}
                      >
                        <KSClipBoardListIcon />
                      </IconButton>
                    </Tooltip>
                    <Link to={`/${profileInfo?.role?.toLowerCase() === 'mlo' ? '_mlo' : profileInfo?.role?.toLowerCase() === 'mlo owner' ? 'broker' : profileInfo?.role?.toLowerCase()}/loan-application/loan-summary/${row._valuesCache.appNo}`}>
                      <Tooltip title="Pending Tasks" arrow>
                        <IconButton>
                          <KSClockIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </Box >
                ))}
              </>
            );
          }}
        />
      </Grid>
    </MainLoyOut >
  );
}
