
import React, { useState, useEffect } from 'react';
import { Button, Table, TableCell, TableRow, TextField } from '@mui/material';
import { useContext } from 'react';
import { BillContext } from '../BillContext';
import axios from 'axios';
import { API } from '../../../../services/api-config'
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";

const styles = {
  tableCell: {
    borderBottom: 'none',
  },
  submitCell: {
    borderBottom: 'none',
    height: '10vh',
  },
};

function PaymentContact({ setSsnSubmitted }) {
  const { ownerName, address, isNewUser, ssn, userCode } = useContext(BillContext);

  const [ssnNo, setSsnNo] = useState('');

  useEffect(() => {

    if (ssn !== null) {
      setSsnNo(ssn);
    }
  }, [ssn]);

  const postData = {
    userCode: userCode,
    ssn: ssnNo
  };

  const handleSSNSubmit = async () => {
    const BASE_URL = API.billUser;
    const SSN_ENDPOINT = "submitSSN";
    const SUBMIT_SSN_FOR_PAYMENT = `${BASE_URL}/${SSN_ENDPOINT}`;
    try {
      await postApiCallDynamicWithOutReturn({
        data: postData,
        path: SUBMIT_SSN_FOR_PAYMENT,
      });
      setSsnSubmitted(true);
    } catch (error) {
      console.error("Error During SSN Submit:", error);
    }
  };


  const handleSSNChange = (e) => {
    const formattedSSN = e.target.value
      .replace(/\D/g, '') // Remove non-digit characters
      .slice(0, 9) // Limit to 9 digits
      .replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3'); // Add hyphens after the first 3 and after two digits

    setSsnNo(formattedSSN);
  };

  if (isNewUser !== null && isNewUser !== 'No') {
    return (
      <>
        <Table style={styles.table}>
          <TableRow>
            <TableCell style={styles.tableCell}>Owner Name</TableCell>
            <TableCell style={styles.tableCell}>{ownerName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={styles.tableCell}>Address</TableCell>
            <TableCell style={styles.tableCell}>{address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={styles.tableCell}>Enter your SSN:</TableCell>
            <TableCell style={styles.tableCell}>
              <TextField
                type="text"
                value={ssnNo}
                onChange={handleSSNChange}

                pattern="\d{3}-?\d{2}-?\d{4}" // valid SSN format
                placeholder="AAA-GG-SSSS"
                disabled={ssn !== null} // Disable the input if SSN is present
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={styles.submitCell}></TableCell>
            <TableCell style={styles.submitCell}>
              <div>
                {/* <Button variant="contained" onClick={handleSSNSubmit}>
                  SUBMIT
                </Button> */}
                <Button
                  variant="contained"
                  onClick={handleSSNSubmit}
                  disabled={ssn != null}
                >
                  SUBMIT
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </Table>
      </>
    );
  } else {
    return null;
  }
}

export default PaymentContact;
