import { AttachMoney } from '@mui/icons-material'
import { Box, Button, Stack, Typography, Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import CustomInput from '../../../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../../helpers/radio-chip'
import { Controller, useForm } from 'react-hook-form'
import { OWNERSHIP, STATE_OF_COUNTRY, CONTACT_COUNTRY } from '../../../../../../../constants/constatnt-variable'
import { SelfEmployedValidation } from '../../../../../../../constants/validation-rules'
import { TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater'
import { useWatch } from 'react-hook-form'
import { formatNumericValue, parseNumericValue } from '../../../../../../../helpers/numeric-format'

export default function SelfEmployedForm({ onSubmit, editItem }) {



    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            ownerShip: editItem?.ownerShip,
            businessName: editItem?.businessName,
            positionOfTitle: editItem?.positionOfTitle,
            startDate: TimeStampToMonthDateYearForInput(editItem?.startDate),
            endDate: TimeStampToMonthDateYearForInput(editItem?.endDate),
            profession: editItem?.profession,
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry ? editItem?.stateOfCountry : null,
            country: editItem?.country ? editItem?.country : null,
            zip: editItem?.zip,
            averageMonthlyBonus: editItem?.sideIncome?.averageMonthlyBonus,
            averageMonthlyOvertime: editItem?.sideIncome?.averageMonthlyOvertime,
            militaryEntitlement: editItem?.sideIncome?.militaryEntitlement,
            commission: editItem?.sideIncome?.commission,
            prevGrossMonthlyIncome: editItem?.prevGrossMonthlyIncome,
            monthlyIncome: editItem?.monthlyIncome,
            isStillWorking: editItem?.isStillWorking == "Yes" ? true : false,
        }
    })



    const {
        isStillWorking,
    } = useWatch({ control })


    useEffect(() => {
        if (isStillWorking === true) {
            setValue('endDate', null);
        }
    }, [isStillWorking]);




    return (
        <form id='selfEmployed' onSubmit={handleSubmit(onSubmit)}>

            <Controller
                name='ownerShip'
                control={control}
                rules={SelfEmployedValidation.ownerShip}
                render={({ field }) => (
                    <CustomRadioChip
                        {...field}
                        field={field}
                        required
                        label={'Owernship'}
                        data={OWNERSHIP}
                        error={errors?.ownerShip?.message}
                    />
                )}
            />

            <Controller
                name='businessName'
                control={control}
                rules={SelfEmployedValidation.businessName}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Employer or Business Name'} required error={errors?.businessName?.message} />
                )}
            />

            <Controller
                name='positionOfTitle'
                control={control}
                rules={SelfEmployedValidation.positionOfTitle}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Position or Title'} required error={errors?.positionOfTitle?.message} />
                )}
            />



            {/* <Controller
                name='startDate'
                control={control}
                rules={SelfEmployedValidation.startDate}
                render={({ field }) => (
                    <CustomInput
                        style={{ display: 'block' }}
                        {...field}
                        type={'date'}
                        label={`Start Date`}
                        labelRightComponent={
                            <FormControlLabel
                                control={
                                    <Controller
                                        name='isStillWorking'
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox checked={isStillWorking}  {...field} />
                                        )}
                                    />
                                }
                                label=
                                {`Are you still working there?`}
                            />
                        }
                        required
                        error={errors?.startDate?.message}
                    />
                )}
            /> */}

            <Controller
                name='startDate'
                control={control}
                rules={SelfEmployedValidation.startDate}
                render={({ field }) => (
                    <>
                        <FormControlLabel
                            control={
                                <Controller
                                    name='isStillWorking'
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox checked={isStillWorking} {...field} />
                                    )}
                                />
                            }
                            label={`Are you still working there?`}
                        />
                        <CustomInput
                            style={{ display: 'block' }}
                            {...field}
                            type={'date'}
                            label={`Start Date`}
                            required
                            error={errors?.startDate?.message}
                        />
                    </>
                )}
            />


            {isStillWorking ? null : <Controller
                name='endDate'
                control={control}
                rules={SelfEmployedValidation.endDate}
                render={({ field }) => (
                    <CustomInput {...field} type={'date'} label={`End Date`} required error={errors?.endDate?.message} />
                )}
            />}

            <Controller
                name='profession'
                control={control}
                rules={SelfEmployedValidation.profession}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={'Years in this line of profession '} required error={errors?.profession?.message} />
                )}
            />
            <Controller
                name='monthlyIncome'
                control={control}
                rules={SelfEmployedValidation.monthlyIncome}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Monthly Income`} sicon={<AttachMoney />}
                        value={formatNumericValue(field.value)}
                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                        required error={errors?.monthlyIncome?.message} />
                )}
            />

            <Typography variant='h6'>{`Business Address`}</Typography>

            <Controller
                name='addressLine1'
                control={control}
                rules={SelfEmployedValidation.addressline1}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Address Line 1'} required error={errors?.addressLine1?.message} />
                )}
            />

            <Controller
                name='addressLine2'
                control={control}
                rules={SelfEmployedValidation.addressline2}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Address Line 2'} error={errors?.addressLine2?.message} />
                )}
            />

            <Controller
                name='unit'
                control={control}
                rules={SelfEmployedValidation.unit}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Unit'} error={errors?.unit?.message} />
                )}
            />

            <Controller
                name='city'
                control={control}
                rules={SelfEmployedValidation.city}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'City'} required error={errors?.city?.message} />
                )}
            />

            <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                <Box width={'100%'}>
                    <Controller
                        name='stateOfCountry'
                        control={control}
                        rules={SelfEmployedValidation.stateOfCountry}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'State'}
                                required
                                option={STATE_OF_COUNTRY}
                                error={errors?.stateOfCountry?.message}
                            />
                        )}
                    />



                </Box>
                <Box width={'100%'}>
                    <Controller
                        name='zip'
                        control={control}
                        rules={SelfEmployedValidation.zip}
                        render={({ field }) => (
                            <CustomInput {...field} type={'text'} label={'ZIP'} required error={errors?.zip?.message} />
                        )}
                    />
                </Box>
            </Stack>

            <Controller
                name="country"
                control={control}
                rules={SelfEmployedValidation.country}
                render={({ field }) => (
                    <CustomDropDown
                        {...field}
                        field={field}
                        label={'Country'}
                        required
                        // state={mailingCountry}
                        option={CONTACT_COUNTRY}
                        error={errors?.country?.message}
                    />
                )}
            />




            <Stack direction={'row-reverse'} spacing={2}>
                <Button form={'selfEmployed'} variant='contained' type='submit'>
                    {editItem.length != 0 ? 'Update' : 'Add'}
                </Button>
            </Stack>
            {/* </Stack> */}
        </form>
    )
}
