import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const KSTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2f80ed', // Custom background color
        color: 'white', // Text color
        boxShadow: theme.shadows[1], // Add subtle shadow
        fontSize: 15, // Adjust font size
        padding: '8px 12px', // More padding for better touch interaction
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2f80ed', // Color of the arrow
    },
}));