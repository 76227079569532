import { CheckCircleOutline } from '@mui/icons-material'
import { Box, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { KSCheckCircleIcon, KSCloudUploadIcon, KSDocumentSearchIcon, KSXCircleIcon } from '../../../../../icons/custome-icon'
import DocumentStatus from './document-status'
import { downloadReportFromServer } from '../../../../../helpers/file-upload-download'
import { API } from '../../../../../services/api-config'

export default function DocumentStatusCard({ control, status, title = null, fileName, info, docIc, uploadTime, fileUpload, isReview, ...props }) {
    const downloadDocument = () => {

        downloadReportFromServer(API.proxyDocumentDownload, {
            id: docIc,
            serviceType: 'loan'
        })
    }
    return (
        <Box my={2} p={{ xs: 1, md: 2, lg: 3 }} border={1} borderColor={'#11111115'} borderRadius={1}>
            <Grid container justifyContent={'space-between'} spacing={3}>

                {/* Documents title */}
                <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
                    <Typography variant='h6'>{title}</Typography>
                    <Typography variant='body1'>{info}</Typography>
                </Grid>


                <Grid container direction={'column'} justifyContent={'space-between'} item xs={12} md={4} textAlign={{ xs: 'center', md: 'right' }}>
                    <Grid item>
                        <Stack direction={'column'} gap={1}>
                            <Typography sx={{ cursor: 'pointer' }} onClick={downloadDocument} variant='body1' color={"A5.main"}>{fileName}</Typography>
                            <Stack display={isReview ? 'none' : null} direction={'row-reverse'} gap={1} alignSelf={{ xs: 'center', md: 'auto' }}>
                                <IconButton color='primary' component="label">
                                    <input id={docIc} onChange={fileUpload} hidden

                                        type="file" />
                                    <KSCloudUploadIcon />
                                </IconButton>
                                <IconButton color='primary'>
                                    <KSDocumentSearchIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Typography color={'A5.main'}>{`Uploaded on: ${uploadTime}`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    )
}
