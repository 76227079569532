import {useState} from "react";

const check = (data, status, initial) => {
  if (status) {
    return data;
  } else {
    return initial;
  }
};


// custom hook
//  return onChange, saveValue, and value
//  if status is true then data will be in value else initial param will take place
const useInput = ({ data = "", status = false, initial = "" }) => {
  const [value, setValue] = useState(check(data, status, initial));
  return [
    {
      value,
      onChange: (e) => setValue(e.target.value),
      saveValue: (val) => setValue(val),
      onWheel: (event) => event.currentTarget.blur(),
    },
    () => setValue(check(initialValue)),
  ];
};

export default useInput;
