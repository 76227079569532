import { Box, Button, FormHelperText, Grid, Link as MuLink, Typography, useMediaQuery, Tooltip, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import CustomInput from "../../helpers/custom-input";
import useInput from "../../helpers/use-input";
import {
  getApiCallDynamic,
  postApiCallDynamic,
} from "../../services/api-service";
import { useFormik } from "formik";
import { MAXLength, ValidationConstant } from "../../constants/validation";
import { API } from "../../services/api-config";
import { CountDown } from "../../helpers/time-counter";
import { useRecoilState } from "recoil";
import { GlobalAlertSeverity, profileState } from "../../recoil/profile-atom";
import { EditOutlined } from "@mui/icons-material";
import { SERVICE_TYPE } from "../../constants/constatnt-variable";
import { setRecoil } from "recoil-nexus";
import CustomReactMaterialTable from "../../helpers/table/react-material-table";
import Edit from '../../assets/edit.png'
import EditDisabled from '../../assets/edit-disabled.png'
import NmlsForm from "./nmls-form";
import { KSDocumentSearchIcon } from "../../icons/custome-icon";
import { blue } from "@mui/material/colors";
import { kstheme } from "../../theme/theme";
const grey = 'var(--Icon-idle-mode, rgba(34, 34, 34, 0.60))';

export default function IdentityChange({ identityValue, variant }) {
  let [identityChange, setIdentityChange] = useState("identity");
  let [profileData, setProfileData] = useRecoilState(profileState);
  const [showForm, setShowForm] = useState(false);
  let [emailOtpCount] = CountDown({ sec: 0 });
  let [phoneOtpCount] = CountDown({ sec: 0 });
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

  const getOwnInfo = async () => {
    setShowForm(false)
    let param = { userCode: profileData.userCode };
    await getApiCallDynamic({ path: API.ownInfo, param: param }).then((res) => {
      if (res?.status === 200) {
        setProfileData(res.data);
      }
    });
  }

  const getAddMoreEnabled = (type) => {
    if (!profileData?.stateLicenses?.length) {
      return type === 'input' ? false : true
    }
    let bool = false;
    let isValid = false;
    profileData?.stateLicenses?.forEach((item) => {
      if (item.status !== 'valid') {
        bool = true;
      } else {
        isValid = true;
      }
    })
    return (type === 'input' && isValid) ? true : (type === 'input' ? false : bool)
  }

  let [emailOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [phoneOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let componentData = {
    label: "",
    placeholder: "",
    description: "",
    nmlsStatus: "",
    edit: "",
    type: "",
    emailOtpLabel: ""
  }


  if (variant === "nmls") {
    componentData.title = "NMLS ID"
    componentData.label = "Enter your new NMLS ID"
    componentData.placeholder = "985647"
    componentData.nmlsStatus = profileData?.nmls?.status
    componentData.edit = profileData?.nmls?.id
    componentData.type = "number"
    componentData.emailOtpLabel = "Enter OTP sent on your email"

  } else {
    componentData.title = "Email"
    componentData.label = "Enter your new Email ID"
    componentData.placeholder = "thomas@cache.com"
    componentData.description = "Verify OTP to Update your email id"
    componentData.nmlsStatus = ""
    componentData.edit = profileData?.email
    componentData.type = "email"
    componentData.emailOtpLabel = "Enter OTP sent on your new email"


  }

  const identityUpdateRequest = (event) => {
    let data = {}
    switch (variant) {
      case 'nmls':
        data = {
          service: SERVICE_TYPE.changeNmlsId,
        }
        break;
      case 'companyNmls':
        data = {
          service: SERVICE_TYPE.changeCompanyNmlsId,
        }
        break;
      case 'email':
        data = {
          service: SERVICE_TYPE.emailChange,
        }
        break;
      default:
    }

    if (event.target.id == "newIdentityValue") {
      data = {
        ...data,
        changedValue: identityValue.value,
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.info
        }
      ).then((res) => {
        if (res?.status == 200) {
          setIdentityChange("verify");
          emailOtpCount.setCounter(res.data.emailOtpCount);
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      });
    }

    if (event.target.id === "verify") {
      data = {
        ...data,
        emailOTP: emailOTP.value,
        phoneOTP: phoneOTP.value,
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.securedOTP
        }
      ).then((res) => {
        if (res?.status === 200) {
          if (variant === "nmls") {
            setProfileData((prev) => {
              return {
                ...prev,
                nmlsId: identityValue.value,
              };
            });
          } else if (variant === "companyNmls") {
            setProfileData((prev) => {
              return {
                ...prev,
                companyNmlsId: identityValue.value,
              };
            });
          } else {
            setProfileData((prev) => {
              return {
                ...prev,
                email: identityValue.value,
              };
            });
          }
          // toastNotify("success", "Successfully Updated")
          setRecoil(GlobalAlertSeverity, {
            state: true,
            data: {
              severity: "success",
              msg: "Successfully Updated"
            }
          })
        }
      });
      identityChangeCancel();
    }
  };

  const handleValidation = (values) => {
    let errors = {};
    if (variant === 'nmls' || variant === 'companyNmls') {
      if (
        identityValue.value.length < MAXLength.nmlsIdMin ||
        identityValue.value.length > MAXLength.nmlsIdMax
      ) {
        errors.identityValue = "Please enter a valid nmlsId";
      }
    }

    if (variant === 'email') {
      if (ValidationConstant.emailRegx.test(String(identityValue.value)) === false) {
        errors.identityValue = "Please enter a valid Email";
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      identityValue: identityValue.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      identityUpdateRequest({ target: { id: "newIdentityValue" } });
    },
  });



  const handleValidationOTP = (values) => {
    let errors = {};

    if (emailOTP.value.trim() == "") {
      errors.emailOTP = "Please enter your email otp";
    }
    if (phoneOTP.value.trim() == "") {
      errors.phoneOTP = "Please enter your phone otp";
    }
    return errors;
  };

  const formikOTP = useFormik({
    initialValues: {
      emailOTP: emailOTP.value,
      phoneOTP: phoneOTP.value,
    },
    validate: (values) => handleValidationOTP(values),
    onSubmit: (values) => {
      identityUpdateRequest({ target: { id: "verify" } });
    },
  });

  const resetForm = () => {
    emailOTP.saveValue("");
    phoneOTP.saveValue("");
    identityValue.saveValue(componentData?.edit);
  };
  const identityChangeCancel = () => {
    resetForm();
    setIdentityChange("identity");
  };

  const resendOtp = (event) => {
    event.preventDefault();

    let param = {}
    switch (variant) {
      case 'nmls':
        param = {
          email: profileData.email,
          type: event.target.value,
          service: SERVICE_TYPE.changeNmlsId,
        }
        break;
      case 'companyNmls':
        param = {
          email: profileData.email,
          type: event.target.value,
          service: SERVICE_TYPE.changeCompanyNmlsId,
        }
        break;
      case 'email':
        param = {
          email: identityValue.value,
          type: event.target.value,
          service: SERVICE_TYPE.emailChange,
        }
        break;
      default:
    }

    getApiCallDynamic({ param: param, path: API.verifyOTP }).then((res) => {
      if (res?.status === 200) {
        if (event.target.value === "email") {
          emailOtpCount.setCounter(res.data.emailOtpCount);
        }
        if (event.target.value === "phone") {
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      }
    });
  };

  const newIdentity = (
    <Box display={identityChange == "identity" ? "" : "none"}>
      <Button
        startIcon={<EditOutlined />}
        variant="outlined"
        onClick={() => {
          setIdentityChange("newIdentityValue");
        }}
      >
        Edit
      </Button>
    </Box>
  )

  const columns = useMemo(
    () => [
      {
        accessorKey: "Company NMLS ID",
        minSize: 30,
        maxSize: 30,
        enableSorting: false,
        size: 30,
        header: "Company NMLS ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.companyNmlsId || '-'}
            </>
          );
        },
      },
      {
        accessorKey: "Company State License ID",
        minSize: 70,
        maxSize: 70,
        size: 70,
        enableSorting: false,
        header: "Company State License ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.companyStateLicense || '-'}
            </>
          );
        },
      },
      {
        accessorKey: "NMLS ID",
        minSize: 40,
        enableSorting: false,
        maxSize: 40,
        size: 40,
        header: "NMLS ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.userNmlsId || '-'}
            </>
          );
        },
      },

      {
        accessorKey: "State License ID",
        minSize: 30,
        enableSorting: false,
        maxSize: 30,
        size: 30,
        header: "State License ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.stateLicenseCode || '-'}
            </>
          );
        },
      },

      {
        accessorKey: "State",
        minSize: 40,
        maxSize: 40,
        size: 40,
        header: "State",
        enableSorting: false,
        padding: 0,
        style: { padding: 0 },
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.state || '-'}
            </>
          );
        },
      },
      {
        accessorKey: "Status",
        minSize: 40,
        enableSorting: false,
        maxSize: 40,
        size: 40,
        header: "Status",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <Typography variant="span" color={'#2F80ED'} fontWeight={700} fontSize={15}>
              {cell.row.original?.status || 'Not Validated'}
              {cell.row.original?.notes &&
                <Box display={'flex'} width={'100px'} color={'grey'}>


                  {/*<Typography variant="p" sx={{ '&:hover': { overflow: 'visible', whiteSpace: 'normal' }, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} fontWeight={700} ml={1} alignItems={'center'} >
                     */}
                  <Tooltip
                    title={cell.row.original?.notes}
                    placement="right"
                    arrow
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <iconButton>
                      <KSDocumentSearchIcon />
                    </iconButton>
                  </Tooltip>
                </Box>
              }
            </Typography >
          );
        },
      },
      {
        accessorKey: "Add more",
        minSize: 40,
        maxSize: 40,
        enableColumnActions: false,
        enableSorting: false,
        size: 40,
        header: (profileData.role === 'MLO' || profileData.role === 'MLO Owner') ? <></> : <Button
          disabled={getAddMoreEnabled()}
          variant="outlined"
          onClick={() => setShowForm(true)}
          style={{ borderWidth: '2px', border: `2px solid ${getAddMoreEnabled() ? grey : blue}`, color: getAddMoreEnabled() ? 'gray' : blue, letterSpacing: 0 }}
          sx={{ borderRadius: "24px", fontSize: '14px', borderWidth: '3px', color: getAddMoreEnabled() ? 'gray' : blue, fontWeight: 'bold', letterSpacing: 0 }}
        >
          Add more
        </Button>,
        Cell: ({ cell }) => {
          return (
            <Box textAlign={'center'}>
              {cell.row.original?.status === "valid" ?
                <img src={EditDisabled} /> :
                <img src={Edit} onClick={() => setShowForm(cell.row.original)} />
              }
            </Box>
          );
        },
      },
    ],
    []
  );
  const rowData = [
    {
      id: 0,
      state: '-',
      userNmlsId: profileData?.nmlsId,
      companyNmlsId: profileData?.companyNmlsId,
      companyStateLicense: '-',
      stateLicenseCode: '-',
      status: 'Not Validated'
    }
  ]
  //   return (
  //     <Grid container direction={isMobile ? 'column' : 'row'}>
  //       <Grid display={isMobile ? 'flex' : ''} justifyContent={isMobile ? 'space-between' : ''} direction={isMobile ? 'row' : 'column'} item xs={12} sm={12} md={6} my={1} style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}>
  //         <Box>
  //           <Typography variant="h3">{componentData.title}</Typography>
  //           <Typography variant="body1" my={1} color="text.secondary">
  //             {componentData.description}
  //           </Typography>
  //         </Box>
  //         {componentData.nmlsStatus === "" ? (
  //           <Box display={identityChange == "identity" ? "" : "none"}>
  //             <Button
  //               startIcon={<EditOutlined />}
  //               variant="outlined"
  //               onClick={() => {
  //                 setIdentityChange("newIdentityValue");
  //               }}
  //             >
  //               Edit
  //             </Button>
  //           </Box>
  //         ) :
  //           null}
  //       </Grid>
  //       <Grid item xs={12} sm={12} md={4} my={1} style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}>
  //         <Box my={2} display={identityChange === "identity" ? "" : "none"}>
  //           {!(variant === 'nmls') ?
  //             <Box style={!isMobile ? { marginLeft: '100px' } : {}}>
  //               <CustomInput
  //                 label={componentData.title}
  //                 size="small"
  //                 style={!isMobile ? { width: '50%' } : {}}
  //                 value={identityValue.value}
  //                 disabled
  //                 marginBottom={0}
  //               />
  //               <FormHelperText error={componentData?.nmlsStatus == 'valid' ? false : true} >
  //                 {`${componentData?.nmlsStatus}`}
  //               </FormHelperText>
  //             </Box>
  //             :
  //             <>
  //               <Grid ml={isMobile ? 0 : 12} >
  //                 <CustomReactMaterialTable
  //                   enableTopToolbar={false}
  //                   enablePagination={false}
  //                   rowData={profileData.stateLicenses.length ? profileData.stateLicenses : rowData}
  //                   columns={columns}
  //                   enableRowActions={false}
  //                   enableBottomToolbar={false}
  //                 />
  //               </Grid>

  //             </>
  //           }
  //         </Box>
  //       </Grid>
  //       <Grid container spacing={1}>
  //         <Grid item xs={12} sm={12} md={4} my={1} style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}>
  //           {showForm &&
  //             <NmlsForm
  //               getOwnInfo={getOwnInfo}
  //               isSingleForm={!profileData.stateLicenses.length}
  //               inputEnable={getAddMoreEnabled('input')}
  //               isMLO={profileData.role === "MLO"}
  //               formData={showForm}
  //               onCancelClick={() => setShowForm(false)}
  //               rowData={rowData[0]}
  //             // style={{ width: '100%' }}
  //             />
  //           }
  //         </Grid>
  //       </Grid>
  //       <Grid item xs={12} sm={12} md={4} my={1} style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}>
  //         <form id="newIdentityValue" onSubmit={formik.handleSubmit}>
  //           <Box my={2} display={identityChange === "newIdentityValue" ? "" : "none"} style={{ marginLeft: isMobile ? '' : '45px' }}>

  //             <CustomInput
  //               size="small"
  //               type={componentData.type}
  //               change={identityValue}
  //               style={{ width: isMobile ? '100%' : '50%' }}
  //               label={componentData.label}
  //               placeholder={componentData.placeholder}
  //               error={formik.errors.identityValue}
  //             />

  //             <Box my={2} display={"flex"} justifyContent={"start"}>
  //               <Button
  //                 size="large"
  //                 color="primary"
  //                 onClick={identityChangeCancel}
  //                 type="button"
  //                 variant="outlined"
  //               >
  //                 Cancel
  //               </Button>

  //               <Button
  //                 variant="contained"
  //                 size="large"
  //                 color="primary"
  //                 sx={{ borderRadius: "24px", fontWeight: "bold", marginLeft: "50px" }}
  //                 id="newIdentityValue"
  //                 type="submit"
  //               >
  //                 Update
  //               </Button>
  //             </Box>
  //           </Box>
  //         </form>
  //         {/* Use for OTP */}
  //         <form onSubmit={formikOTP.handleSubmit}>
  //           <Box display={identityChange === "verify" ? "" : "none"} style={{ marginLeft: '45px' }}>
  //             <Typography my={2}>
  //               We have sent OTP to your registered mobile number & new email
  //             </Typography>

  //             {/* Used for  making inline*/}
  //             <Grid container spacing={1} alignItems="center" mb={2} style={{ width: '50%' }}>
  //               <Grid item xs={12}>
  //                 <FormHelperText
  //                   sx={{
  //                     color: "blue",
  //                     width: "25%",
  //                     float: "right",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   {emailOtpCount.counter == 0 ? (
  //                     <MuLink
  //                       component="button"
  //                       id={"identityChange"}
  //                       value="email"
  //                       onClick={resendOtp}
  //                     >
  //                       {" "}
  //                       Resend
  //                     </MuLink>
  //                   ) : (
  //                     emailOtpCount.obj.m + " min " + emailOtpCount.obj.s + " sec"
  //                   )}
  //                 </FormHelperText>
  //                 <CustomInput
  //                   label={componentData.emailOtpLabel}
  //                   size="small"
  //                   error={formikOTP.errors.emailOTP}
  //                   change={emailOTP}
  //                   fullWidth
  //                   placeholder="Email OTP"
  //                   maxLength={MAXLength.otp}
  //                 />
  //               </Grid>
  //             </Grid>
  //             {/* Used for  making inline*/}
  //             <Grid container spacing={1} alignItems="center" mb={2} style={{ width: '50%' }}>
  //               <Grid item xs={12} >
  //                 <FormHelperText
  //                   sx={{
  //                     color: "blue",
  //                     width: "25%",
  //                     float: "right",
  //                     textAlign: "right",
  //                   }}
  //                 >
  //                   {phoneOtpCount.counter == 0 ? (
  //                     <MuLink
  //                       component="button"
  //                       id={"identityChange"}
  //                       value="phone"
  //                       onClick={resendOtp}
  //                     >
  //                       {" "}
  //                       Resend
  //                     </MuLink>
  //                   ) : (
  //                     phoneOtpCount.obj.m + " min " + phoneOtpCount.obj.s + " sec"
  //                   )}
  //                 </FormHelperText>
  //                 <CustomInput
  //                   label={"Enter OTP sent on your phone"}
  //                   size="small"
  //                   error={formikOTP.errors.phoneOTP}
  //                   change={phoneOTP}
  //                   fullWidth
  //                   placeholder="Phone OTP"
  //                   maxLength={MAXLength.otp}
  //                 />
  //               </Grid>
  //             </Grid>
  //             <Box display={"flex"} justifyContent={"start"}>
  //               <Button
  //                 variant="outlined"
  //                 size="large"
  //                 color="primary"
  //                 type="button"
  //                 onClick={identityChangeCancel}
  //               >
  //                 Cancel
  //               </Button>

  //               <Button
  //                 style={{ marginLeft: '50px' }}
  //                 variant="contained"
  //                 size="large"
  //                 color="primary"
  //                 id="verify"
  //                 type="submit"
  //               >
  //                 Verify
  //               </Button>
  //             </Box>
  //           </Box>
  //         </form>
  //       </Grid>
  //     </Grid >
  //   );
  // }
  return (
    <Grid container direction={isMobile ? 'column' : 'row'}>
      <Grid display={isMobile ? 'flex' : ''} justifyContent={isMobile ? 'space-between' : ''} direction={isMobile ? 'row' : 'column'} item xs={12} sm={3.6} md={3.6} my={1}
      // style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}
      >
        <Box>
          <Typography variant="h3">{componentData.title}</Typography>
          <Typography variant="body1" my={1} color="text.secondary">
            {componentData.description}
          </Typography>
        </Box>
        {componentData.nmlsStatus === "" ? (
          <Box display={identityChange == "identity" ? "" : "none"}>
            <Button
              startIcon={<EditOutlined />}
              variant="outlined"
              onClick={() => {
                setIdentityChange("newIdentityValue");
              }}
            >
              Edit
            </Button>
          </Box>
        ) :
          null}
      </Grid>
      <Grid item xs={12} sm={8} md={8} my={1}
      //style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}
      >
        <Box my={2} display={identityChange === "identity" ? "" : "none"}>
          {!(variant === 'nmls') ?
            <Box style={!isMobile ? { marginLeft: '45px' } : {}}>
              <CustomInput
                label={componentData.title}
                size="small"
                style={!isMobile ? { width: '50%' } : {}}
                value={identityValue.value}
                disabled
                marginBottom={0}
              />
              <FormHelperText error={componentData?.nmlsStatus == 'valid' ? false : true} >
                {`${componentData?.nmlsStatus}`}
              </FormHelperText>
            </Box>
            :
            <>
              <Grid container spacing={1} >
                <Grid item xs={5} sm={9} md={9} ml={isMobile ? 0 : 5} >
                  <CustomReactMaterialTable
                    enableTopToolbar={false}
                    enablePagination={false}
                    rowData={profileData.stateLicenses.length ? profileData.stateLicenses : rowData}
                    columns={columns}
                    enableRowActions={false}
                    enableBottomToolbar={false}
                  />
                </Grid>
              </Grid>
              {/* <Grid container spacing={1} > */}

              {showForm &&

                <NmlsForm
                  getOwnInfo={getOwnInfo}
                  isSingleForm={!profileData.stateLicenses.length}
                  inputEnable={getAddMoreEnabled('input')}
                  isMLO={profileData.role === "MLO"}
                  formData={showForm}
                  onCancelClick={() => setShowForm(false)}
                  rowData={rowData[0]}
                //style={{ width: '100%' }} 
                />
              }
              {/* </Grid> */}

            </>
          }
        </Box>
        <form id="newIdentityValue" onSubmit={formik.handleSubmit}>
          <Box my={2} display={identityChange === "newIdentityValue" ? "" : "none"} style={{ marginLeft: '45px' }}>
            <CustomInput
              size="small"
              type={componentData.type}
              change={identityValue}
              style={{ width: '50%' }}
              label={componentData.label}
              placeholder={componentData.placeholder}
              error={formik.errors.identityValue}
            />
            <Box my={2} display={"flex"} justifyContent={"start"}>
              <Button
                size="large"
                color="primary"
                onClick={identityChangeCancel}
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ borderRadius: "24px", fontWeight: "bold", marginLeft: "50px" }}
                id="newIdentityValue"
                type="submit"
              >
                Update
              </Button>
            </Box>
          </Box>
        </form>
        {/* Use for OTP */}
        <form onSubmit={formikOTP.handleSubmit}>
          <Box display={identityChange === "verify" ? "" : "none"} style={{ marginLeft: '45px' }}>
            <Typography my={2}>
              We have sent OTP to your registered mobile number & new email
            </Typography>

            {/* Used for  making inline*/}
            <Grid container spacing={1} alignItems="center" mb={2} style={{ width: '50%' }}>
              <Grid item xs={12}>
                <FormHelperText
                  sx={{
                    color: "blue",
                    width: "25%",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {emailOtpCount.counter == 0 ? (
                    <MuLink
                      component="button"
                      id={"identityChange"}
                      value="email"
                      onClick={resendOtp}
                    >
                      {" "}
                      Resend
                    </MuLink>
                  ) : (
                    emailOtpCount.obj.m + " min " + emailOtpCount.obj.s + " sec"
                  )}
                </FormHelperText>
                <CustomInput
                  label={componentData.emailOtpLabel}
                  size="small"
                  error={formikOTP.errors.emailOTP}
                  change={emailOTP}
                  fullWidth
                  placeholder="Email OTP"
                  maxLength={MAXLength.otp}
                />
              </Grid>
            </Grid>
            {/* Used for  making inline*/}
            <Grid container spacing={1} alignItems="center" mb={2} style={{ width: '50%' }}>
              <Grid item xs={12} >
                <FormHelperText
                  sx={{
                    color: "blue",
                    width: "25%",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {phoneOtpCount.counter == 0 ? (
                    <MuLink
                      component="button"
                      id={"identityChange"}
                      value="phone"
                      onClick={resendOtp}
                    >
                      {" "}
                      Resend
                    </MuLink>
                  ) : (
                    phoneOtpCount.obj.m + " min " + phoneOtpCount.obj.s + " sec"
                  )}
                </FormHelperText>
                <CustomInput
                  label={"Enter OTP sent on your phone"}
                  size="small"
                  error={formikOTP.errors.phoneOTP}
                  change={phoneOTP}
                  fullWidth
                  placeholder="Phone OTP"
                  maxLength={MAXLength.otp}
                />
              </Grid>
            </Grid>
            <Box display={"flex"} justifyContent={"start"}>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                type="button"
                onClick={identityChangeCancel}
              >
                Cancel
              </Button>

              <Button
                style={{ marginLeft: '50px' }}
                variant="contained"
                size="large"
                color="primary"
                id="verify"
                type="submit"
              >
                Verify
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
}
