import React, { useEffect, useState } from "react";
import {
  ArrowBackIos,
  ArrowForwardIos,
  AttachMoney,
  NavigateNext,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Link as MLink,
  Stack,
  Typography,
  Checkbox, FormControlLabel, useMediaQuery,
} from "@mui/material";
import {
  ATTACHMENT_TYPE,
  BUYING_AND_REFINACEING_LIST,
  HOME_HOA_MANAGEMENT,
  LOAN_PRODUCT_TYPE,
  LOAN_TERM_TYPE,
  LOAN_TERM_TYPE_ARM,
  LOAN_TERM_TYPE_ARM_OPTION,
  LOAN_TERM_TYPE_FIXED,
  LOAN_TERM_TYPE_FIXED_OPTION,
  OCCUPANCY_TYPE,
  PROPERTY_TYPE,
  REASON_OF_REFINANCE,
  SOLAR_PURCHASED_STATUS,
  STATE,
  STATUS_OF_NEW_LENDER,
  STATUS_OF_PURCHASE,
  STATUS_OF_REALTOR,
  STATUS_OF_REFINANCE,
  TYPES_OF_LOAN,
  TYPE_OF_CONTROL,
  TYPE_OF_REFINANCE,
  STATUS_MIXED_USE_PROPERTY,
  STATUS_MANUFACTURED_HOME,

} from "../../../../../constants/constatnt-variable";
import CustomInput from "../../../../../helpers/custom-input";
import CustomDropDown from "../../../../../helpers/dropdown/custom-dropdown";
import CustomLargeLabel from "../../../../../helpers/large-label";
import RadioButtonGroup from "../../../../../helpers/radio-button-group";
import CustomRadioChip from "../../../../../helpers/radio-chip";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { TimeStampToMonthDateYearForInput } from "../../../../../helpers/time-formater";
import { LoanDetailsValidation } from "../../../../../constants/validation-rules";
import GoogleAutocomplete from "../../../../../helpers/google-autocomplete";
import { currentDate } from "../../../../../helpers/common_fuctions/current-date";
import { parseInt, truncate } from "lodash";
import { kstheme } from "../../../../../theme/theme";
import { API } from '../../../../../services/api-config';
import { getApiCallDynamic } from "../../../../../services/api-service";
import { ToastContainer, toast } from 'react-toastify';
import { Auth } from "../../../../../auth/auth";
import LockUnlockConfirmationPopup from "./LockUnlockConfirmationPopup";
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'
import CustomRadioChipWithTooltip from "../../../../../helpers/radio-chip-with-tooltip";
import CustomTooltip from "../../../../../helpers/custom-tooltip";
import CustomRadioChipWithTooltipOnHover from "../../../../../helpers/radio-chip-with-tooltip-hover";


export default function LoanDetailsForm({ data, onSubmit, brokerCode, fetchedStates }) {
  const route = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

  const [modalOpenLock, setModalOpenLock] = useState(false);
  const [modalOpenUnlock, setModalOpenUnlock] = useState(false);
  const [hoveredValue, setHoveredValue] = useState(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      buyingOrRefinancing: data?.buyingOrRefinancing,
      statusOfPurchase: data?.statusOfPurchase,
      statusOfRealtor: data?.realtor?.name ? "Yes" : "No",
      name: data?.realtor?.name,
      companyName: data?.realtor?.companyName,
      phone: data?.realtor?.phone,
      email: data?.realtor?.email,
      solarPurchasedStatus: data?.solarPurchasedStatus,
      isRecentlyRefinanced: "NA",
      isMortgageStatementAvailable: data?.isMortgageStatementAvailable,
      typeOfRefinance: data?.typeOfRefinance,
      reasonOfRefinance: data?.reasonOfRefinance,
      cashoutAmount: data?.cashoutAmount,

      loanTermType: data?.loanTermType,

      loanOptions: data?.loanOptions,
      homeHOAManagement: data?.homeHOAManagement,
      propertyType: data?.propertyType,
      attachmentType: data?.attachmentType,
      occupancyType: data?.occupancyType,
      occupancyTypeFHA: data?.occupancyTypeFHA,

      rateLockStatus: data?.rateLockStatus,
      lockActivityDate: (data?.lockActivityDate),
      addressLine1: data?.addressLine1,
      addressLine2: data?.addressLine2 || '',
      unit: data?.unit || '',
      city: data?.city,
      county: data?.county,
      country: data?.country,
      state: data?.state ? data?.state : null,
      zip: data?.zip,
      closingDate: TimeStampToMonthDateYearForInput(data?.closingDate),
      datePropertyAcquired: TimeStampToMonthDateYearForInput(data?.datePropertyAcquired),
      lastFinancedDate: TimeStampToMonthDateYearForInput(data?.lastFinancedDate),
      propertyBuiltDate: TimeStampToMonthDateYearForInput(
        data?.propertyBuiltDate
      ),
      HOIAmount: data?.HOIAmount,
      HOADues: data?.HOADues,
      investmentMonthIncome: data?.investmentMonthIncome,
      purchasePrice: data?.purchasePrice,
      downPayment: data?.downPayment,

      loanAmount: data?.loanAmount,
      typeOfTitle: data?.typeOfTitle,
      solarleaseapyment: data?.solarleaseapyment,
      countryPropertyTax: data?.countryPropertyTax,
      mortgageInsurance: data?.mortgageInsurance,
      loanCategory: data?.loanCategory,
      estimatedPropertyValue: data?.estimatedPropertyValue,
      mixedUsePropertyStatus: data?.mixedUsePropertyStatus,
      manufacturedHomeStatus: data?.manufacturedHomeStatus,
      propertyInsurance: data?.propertyInsurance,

      rateMortgagePayment: data?.rateMortgagePayment,

    },
  });

  const {
    buyingOrRefinancing,
    solarPurchasedStatus,
    statusOfRealtor,
    statusOfPurchase,

    loanTermType = null,
    loanOptions = null,
    homeHOAManagement,
    attachmentType,
    occupancyType,
    occupancyTypeFHA,

    rateLockStatus,
    lockActivityDate,
    typeOfTitle,
    isRecentlyRefinanced,
    isMortgageStatementAvailable,
    typeOfRefinance,

    purchasePrice,
    downPayment,
    downPaymentAmount,
    loanAmount,
    loanCategory,
    estimatedPropertyValue,
    manufacturedHomeStatus,
    mixedUsePropertyStatus,
    rateMortgagePayment,
    investmentMonthIncome


  } = useWatch({ control });

  const [states, setStates] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [lastLockActivityDate, setLastLockActivityDate] = useState(null);


  const handleLockChecked = () => {
    setModalOpenLock(true);
  };

  const handleLockUnchecked = () => {
    setModalOpenUnlock(true);
  };

  const handleClose = () => {
    setModalOpenLock(false);
    setModalOpenUnlock(false);
  };



  const handleUnlockConfirmation = () => {
    setModalOpenUnlock(false);
    setValue('rateLockStatus', false);
  };

  const handleLockConfirmation = () => {
    setModalOpenLock(false);
    setValue('rateLockStatus', true);
  };




  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }



  useEffect(() => {

    if (typeOfRefinance != "Cash Out Refinance") {
      setValue('cashoutAmount', null);
      setValue('reasonOfRefinance', null);

    }


  }, [typeOfRefinance]);

  useEffect(() => {

    if (buyingOrRefinancing == "Refinancing")

      setValue('isMortgageStatementAvailable', "Yes");
    setValue('HOIAmount', 0.0)





  }, [buyingOrRefinancing]);





  useEffect(() => {
    if (solarPurchasedStatus === "No solar")

      setValue('solarleaseapyment', null);

  }, [solarPurchasedStatus]);

  useEffect(() => {
    if (homeHOAManagement === "No")

      setValue('HOADues', null);

  }, [homeHOAManagement]);

  useEffect(() => {
    if (occupancyType === "Primary" || occupancyType === "Secondary")

      setValue('investmentMonthIncome', null);

  }, [occupancyType]);




  const handleDownPaymentAmountBlur = () => {


    if (buyingOrRefinancing == "Purchasing") {

      let parsedDownPaymentAmount = parseFloat(downPaymentAmount);
      const parsedPurchasePrice = parseFloat(purchasePrice);
      const parsedEstimatedPropertyValue = parseFloat(estimatedPropertyValue);

      if (
        !isNaN(parsedPurchasePrice) &&
        !isNaN(parsedEstimatedPropertyValue)
      ) {
        let newLoanAmount = 0;

        if (buyingOrRefinancing == "Purchasing")
          newLoanAmount = (parsedPurchasePrice <= parsedEstimatedPropertyValue) ? parsedPurchasePrice : parsedEstimatedPropertyValue;
        if (buyingOrRefinancing == "Refinancing")
          newLoanAmount = parsedEstimatedPropertyValue;


        if (parsedDownPaymentAmount > newLoanAmount)
          parsedDownPaymentAmount = newLoanAmount;

        const loanAmountValue = newLoanAmount - parsedDownPaymentAmount;
        const downPaymentValue = parsedDownPaymentAmount * 100 / newLoanAmount;
        setValue('downPaymentAmount', loanAmountValue);
        setValue('downPayment', downPaymentValue.toFixed(2));

      }
    }

    if (buyingOrRefinancing == "Refinancing") {

      let parsedDownPaymentAmount = parseFloat(downPaymentAmount);
      const parsedEstimatedPropertyValue = parseFloat(estimatedPropertyValue);

      if (
        !isNaN(parsedEstimatedPropertyValue)
      ) {
        let newLoanAmount = 0;
        newLoanAmount = parsedEstimatedPropertyValue;


        if (parsedDownPaymentAmount > newLoanAmount)
          parsedDownPaymentAmount = newLoanAmount;

        const loanAmountValue = newLoanAmount - parsedDownPaymentAmount;
        const downPaymentValue = parsedDownPaymentAmount * 100 / newLoanAmount;
        setValue('downPaymentAmount', loanAmountValue);
        setValue('downPayment', downPaymentValue.toFixed(2));

      }
    }
  };

  const handleDownPaymentBlur = () => {
    const parsedDownPayment = parseFloat(downPayment);

    if (
      !isNaN(parsedDownPayment) &&
      parsedDownPayment >= 100
    ) {

      setValue('downPayment', 100);
    }
  };


  useEffect(() => {
    if (occupancyTypeFHA === null) {
      setValue('occupancyTypeFHA', false);
    }
  }, [occupancyTypeFHA]);


  useEffect(() => {

    if (rateLockStatus && lastLockActivityDate) {

      setValue('lockActivityDate', lastLockActivityDate);
    }
  }, [rateLockStatus, lastLockActivityDate]);


  useEffect(() => {

    setLastLockActivityDate(data?.lockActivityDate);

  }, []);

  useEffect(() => {
    if (rateLockStatus === null) {
      setValue('rateLockStatus', false);
    }

    const currentDateInMillis = Date.now();

    if (rateLockStatus === true)
      setValue('lockActivityDate', currentDateInMillis);
  }, [rateLockStatus]);



  useEffect(() => {
    const loanAmountValue = parseFloat(loanAmount);
    const rateMortgagePaymentValue = parseFloat(rateMortgagePayment);
    let loanTermValue = parseInt(loanTermType);

    if (
      !isNaN(loanAmountValue) &&
      !isNaN(rateMortgagePaymentValue) &&
      rateMortgagePaymentValue <= 100
    ) {

      const monthlyInterestRate = (rateMortgagePaymentValue / 100) / 12;
      const denominator = Math.pow(1 + monthlyInterestRate, loanTermValue * 12) - 1;
      const numerator = (loanAmountValue * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTermValue * 12));
      const firstPayment = numerator / denominator;
      if (!isNaN(firstPayment))
        setValue('firstMortgagePayment', firstPayment.toFixed(2));

    }
  }, [loanAmount, rateMortgagePayment, loanTermType]);



  useEffect(() => {


    if (buyingOrRefinancing == "Purchasing") {
      const parsedPurchasePrice = parseFloat(purchasePrice);
      const parsedDownPayment = parseFloat(downPayment);
      const parsedEstimatedPropertyValue = parseFloat(estimatedPropertyValue);

      if (
        !isNaN(parsedPurchasePrice) &&
        !isNaN(parsedDownPayment) &&
        !isNaN(parsedEstimatedPropertyValue) &&
        parsedDownPayment <= 100
      ) {
        if (parsedPurchasePrice <= parsedEstimatedPropertyValue) {
          const downPaymentAmountValue = Math.floor(((parsedPurchasePrice * parsedDownPayment) / 100) + 0.5);
          const loanAmountValue = Math.floor((parsedPurchasePrice - downPaymentAmountValue) + 0.5);
          setValue('downPaymentAmount', downPaymentAmountValue);
          setValue('loanAmount', loanAmountValue);
        } else {
          const downPaymentAmountValue = Math.floor(((parsedEstimatedPropertyValue * parsedDownPayment) / 100) + 0.5);
          const loanAmountValue = Math.floor((parsedEstimatedPropertyValue - downPaymentAmountValue) + 0.5);
          setValue('downPaymentAmount', downPaymentAmountValue);
          setValue('loanAmount', loanAmountValue);
        }
      }
    }
    if (buyingOrRefinancing == "Refinancing") {
      const parsedDownPayment = parseFloat(downPayment);
      const parsedEstimatedPropertyValue = parseFloat(estimatedPropertyValue);

      if (
        !isNaN(parsedDownPayment) &&
        !isNaN(parsedEstimatedPropertyValue) &&
        parsedDownPayment <= 100
      ) {
        const downPaymentAmountValue = Math.floor(((parsedEstimatedPropertyValue * parsedDownPayment) / 100) + 0.5);
        const loanAmountValue = Math.floor((parsedEstimatedPropertyValue - downPaymentAmountValue) + 0.5);
        setValue('downPaymentAmount', downPaymentAmountValue);
        setValue('loanAmount', loanAmountValue);
      }
    }

  }, [purchasePrice, estimatedPropertyValue, downPayment]);

  const handleLoanAmountBlur = () => {
    let parsedLoanAmountValue = parseFloat(loanAmount);
    const parsedPurchasePrice = parseFloat(purchasePrice);
    const parsedEstimatedPropertyValue = parseFloat(estimatedPropertyValue);

    if (
      !isNaN(parsedPurchasePrice) &&
      !isNaN(parsedEstimatedPropertyValue)
    ) {

      let newLoanAmount = 0;
      if (parsedPurchasePrice <= parsedEstimatedPropertyValue)
        newLoanAmount = parsedPurchasePrice;
      else
        newLoanAmount = parsedEstimatedPropertyValue;

      if (parsedLoanAmountValue > newLoanAmount)
        parsedLoanAmountValue = newLoanAmount;

      const downPaymentAmountValue = newLoanAmount - parsedLoanAmountValue;
      const downPaymentValue = downPaymentAmountValue * 100 / newLoanAmount;
      setValue('downPaymentAmount', downPaymentAmountValue);
      setValue('downPayment', downPaymentValue.toFixed(2));



    }
  };

  const [stateFetched, setStateFetched] = useState(null);



  const handleStateComparison = (selectedState) => {
    setStateFetched(selectedState)
  };


  useEffect(() => {
    if (stateFetched != null) {



      const match = fetchedStates.some((state) => {


        return state.value === stateFetched;
      });

      if (match) {

        setValue('state', stateFetched);
        setErrorMessage('');
      } else {

        setErrorMessage('The MLO/Broker has not licensed in the state for which you are selecting your subject property address.');

        setValue('addressLine1', '');
        setValue('addressLine2', '');
        setValue('unit', '');
        setValue('city', '');
        setValue('county', '');
        setValue('zip', '');

      }
    }
  }, [stateFetched])


  const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(data?.realtor?.phone));
  const [unformatWorkPhone, setUnformatWorkPhone] = useState(data?.realtor?.phone || '');


  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  }

  function handleWorkPhoneChange(event) {
    let unformattedValue = event.target.value.replace(/\D/g, '');

    if (unformattedValue.length > 10) {
      unformattedValue = unformattedValue.slice(0, 10);
    }

    setUnformatWorkPhone(unformattedValue);
    setFormatWorkPhone(formatPhoneNumber(unformattedValue));
  }

  function handleWorkPhoneChangeDB() {
    setValue('phone', unformatWorkPhone)
  }





  const getTooltipTitle = (chipValue) => {
    switch (chipValue) {
      case 'Conventional':
        return "Conventional: Select this for a standard mortgage that is not insured or guaranteed by the government.";
      case 'FHA':
        return "FHA (Federal Housing Administration): Opt for an FHA-insured loan, which may offer more lenient qualification criteria.";
      case 'VA':
        return "VA (Veterans Affairs): Available for eligible veterans, active-duty service members, and surviving spouses.";
      case 'USDA':
        return "USDA (United States Department of Agriculture): Ideal for rural homebuyers who meet specific income and property eligibility requirements.";
      case 'Rate/Term':
        return "Choose a rate / term refinance to adjust your interest rate or loan term, or opt for a cash - out refinance to extract equity as cash during the loan process."

      case 'Cash Out Refinance':
        return "Choose a rate / term refinance to adjust your interest rate or loan term, or opt for a cash - out refinance to extract equity as cash during the loan process."



      default:
        return "";
    }
  };







  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        py={2}
        px={4}
        height={"inherit"}
        overflow={"auto"}
      >
        <Box>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
          >
            <MLink fontWeight={700} underline="hover" color="inherit" href="#">
              Loan Details
            </MLink>
            <Typography color="primary.main" fontWeight={700}>Property Details</Typography>
          </Breadcrumbs>

          <Box maxWidth={{ xs: "100%", md: "50%" }}>
            <Controller
              name="buyingOrRefinancing"
              control={control}
              rules={LoanDetailsValidation.buyingOrRefinancing}
              render={({ field }) => (

                <CustomRadioChip
                  labelComponent={
                    <CustomLargeLabel
                      required
                      value=
                      "Are you buying a new home or refinancing the existing one?"
                    />
                  }
                  field={field}
                  {...field}
                  data={BUYING_AND_REFINACEING_LIST}
                  direction="row"
                  labelColor={true}
                  error={errors?.buyingOrRefinancing?.message}
                />
              )}
            />




            {buyingOrRefinancing == "Purchasing" || buyingOrRefinancing == "Refinancing" ? (
              <>
                {/* <Controller
                  name="loanCategory"
                  control={control}
                  rules={LoanDetailsValidation.loanCategory}
                  render={({ field }) => (
                    <div>
                      <CustomRadioChipWithTooltip

                        labelComponent={
                          <CustomLargeLabel
                            required
                            value={"What type of loan do you want?"}

                          />
                        }
                        {...field}
                        field={field}
                        state={loanCategory}
                        data={TYPES_OF_LOAN}
                        error={errors?.loanCategory?.message}


                        tooltipTitle={getTooltipTitle(loanCategory)}
                        chipValue={loanCategory}
                      />
                    </div>
                  )}
                /> */}
                <Controller
                  name="loanCategory"
                  control={control}
                  rules={LoanDetailsValidation.loanCategory}
                  render={({ field }) => (
                    <div>
                      <CustomRadioChipWithTooltipOnHover
                        labelComponent={
                          <CustomLargeLabel
                            required
                            value={"What type of loan do you want?"}
                          />
                        }
                        {...field}
                        field={field}
                        state={loanCategory}
                        data={TYPES_OF_LOAN}
                        error={errors?.loanCategory?.message}
                        tooltipTitle={getTooltipTitle(hoveredValue)}
                        chipValue={field.value}
                        onOptionHover={(value) => setHoveredValue(value)}
                      />
                    </div>
                  )}
                />


                <Controller
                  name="loanOptions"
                  control={control}
                  rules={LoanDetailsValidation.loanOptions}
                  render={({ field }) => (
                    <CustomDropDown
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Loan Product Type"}
                        />
                      }
                      {...field}
                      field={field}
                      state={loanOptions}
                      option={LOAN_TERM_TYPE_ARM_OPTION}
                      error={errors?.loanOptions?.message}
                    />
                  )}
                />


              </>
            ) : null}
            {buyingOrRefinancing == "Purchasing" ? (
              <>

                <Controller
                  name="statusOfPurchase"
                  control={control}
                  rules={LoanDetailsValidation.statusOfPurchase}
                  render={({ field }) => (
                    <CustomTooltip
                      title={<React.Fragment>
                        <div>TBD (To Be Determined): Choose this option if the purchase details are yet to be determined.</div>
                        <div>Offer Accepted: Indicate this when an offer for the property has been accepted.</div>
                        <div>Under Construction: Select this if the property is currently under construction.</div>
                      </React.Fragment>}
                    >
                      <div>
                        <CustomDropDown
                          labelComponent={
                            <CustomLargeLabel
                              required
                              value={"Status of Purchase"}
                            />
                          }
                          {...field}
                          field={field}
                          state={statusOfPurchase}
                          rowData={statusOfPurchase}
                          value={statusOfPurchase}
                          option={STATUS_OF_PURCHASE}
                          error={errors?.statusOfPurchase?.message}
                        />
                      </div>
                    </CustomTooltip>
                  )}
                />
                <Controller
                  name="statusOfRealtor"
                  control={control}
                  rules={LoanDetailsValidation.statusOfRealtor}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Are you working with Realtor?"}
                        />
                      }
                      {...field}
                      field={field}
                      state={statusOfRealtor}
                      data={STATUS_OF_REALTOR}
                      error={errors?.statusOfRealtor?.message}
                    />
                  )}
                />

                {statusOfRealtor == "Yes" ? (
                  <>
                    <Controller
                      name="name"
                      control={control}
                      rules={LoanDetailsValidation.name}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Name"
                          required
                          error={errors?.name?.message}
                        />
                      )}
                    />

                    <Controller
                      name="companyName"
                      control={control}
                      rules={LoanDetailsValidation.companyName}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Company Name"
                          required
                          error={errors?.companyName?.message}
                        />
                      )}
                    />

                    <Controller
                      name="phone"
                      control={control}
                      rules={LoanDetailsValidation.phone}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Phone Number"
                          required
                          type="text"
                          error={errors?.phone?.message}
                          value={formatWorkPhone}
                          onChange={handleWorkPhoneChange}
                          onBlur={handleWorkPhoneChangeDB}
                        />
                      )}
                    />

                    <Controller
                      name="email"
                      control={control}
                      rules={LoanDetailsValidation.email}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          label="Email"
                          required
                          error={errors?.email?.message}
                        />
                      )}
                    />
                  </>
                ) : null}
              </>) : null}



            {buyingOrRefinancing == "Refinancing" ? (
              <>

                <>
                  <Controller
                    name="typeOfRefinance"
                    control={control}
                    rules={LoanDetailsValidation.typeOfRefinance}
                    render={({ field }) => (
                      <CustomTooltip
                        title="Choose a rate / term refinance to adjust your interest rate or loan term, or opt for a cash - out refinance to extract equity as cash during the loan process.">
                        <div> <CustomRadioChip
                          labelComponent={
                            <CustomLargeLabel
                              required
                              value={"Refinance Type"}
                            />
                          }
                          {...field}
                          field={field}
                          state={typeOfRefinance}
                          data={TYPE_OF_REFINANCE}
                          error={errors?.typeOfRefinance?.message}
                        />
                        </div>
                      </CustomTooltip>
                    )}
                  />

                  {typeOfRefinance == "Cash Out Refinance" ? (
                    <>
                      <Controller
                        name="reasonOfRefinance"
                        control={control}
                        rules={LoanDetailsValidation.reasonOfRefinance}
                        render={({ field }) => (
                          <CustomDropDown
                            labelComponent={
                              <CustomLargeLabel
                                required
                                value={"Refinance Reason"}
                              />
                            }
                            {...field}
                            field={field}
                            option={REASON_OF_REFINANCE}
                            error={errors?.reasonOfRefinance?.message}
                          />
                        )}
                      />
                      <Controller
                        name="cashoutAmount"
                        control={control}
                        rules={LoanDetailsValidation.cashoutAmount}
                        render={({ field }) => (
                          <CustomInput
                            {...field}
                            type={"text"}
                            label={"Cashout Amount"}
                            sicon={<AttachMoney />}
                            required
                            value={formatNumericValue(field.value)}
                            onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            error={errors?.cashoutAmount?.message}
                          />
                        )}
                      />
                    </>) : null}

                </>
              </>
            ) : null}

            {buyingOrRefinancing == "Refinancing" ||
              buyingOrRefinancing == "Purchasing" ? (
              <>



                <Controller
                  name="solarPurchasedStatus"
                  control={control}
                  rules={LoanDetailsValidation.solarPurchasedStatus}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Is solar purchased ?"}
                        />
                      }
                      {...field}
                      field={field}
                      state={solarPurchasedStatus}
                      data={SOLAR_PURCHASED_STATUS}
                      error={errors?.solarPurchasedStatus?.message}
                    />
                  )}
                />




                <Controller
                  name="homeHOAManagement"
                  control={control}
                  rules={LoanDetailsValidation.homeHOAManagement}
                  render={({ field }) => (

                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Do the home under HOA management?"}
                        />
                      }
                      {...field}
                      field={field}
                      state={homeHOAManagement}
                      data={HOME_HOA_MANAGEMENT}
                      error={errors?.homeHOAManagement?.message}
                    />

                  )}
                />

                <Controller
                  name="propertyType"
                  control={control}
                  rules={LoanDetailsValidation.propertyType}
                  render={({ field }) => (
                    <CustomDropDown
                      labelComponent={
                        <CustomLargeLabel required value={"Property Type"} />
                      }
                      {...field}
                      field={field}
                      option={PROPERTY_TYPE}
                      error={errors?.propertyType?.message}
                    />
                  )}
                />

                <Controller
                  name="attachmentType"
                  control={control}
                  rules={LoanDetailsValidation.attachmentType}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel required value={"Attachment Type"} />
                      }
                      {...field}
                      field={field}
                      state={attachmentType}
                      data={ATTACHMENT_TYPE}
                      error={errors?.attachmentType?.message}
                    />
                  )}
                />




                <Typography variant="h4" mt={2} mb={1}>

                  Subject Property Address
                </Typography>
                {errorMessage && (
                  <Typography variant="body2" color="error">
                    {errorMessage}
                  </Typography>
                )}
                <Controller
                  name="addressLine1"
                  control={control}
                  rules={LoanDetailsValidation.addressLine1}
                  render={({ field }) => (
                    <GoogleAutocomplete
                      {...field}
                      required
                      name="addressLine1"
                      setValue={setValue}
                      label={'Address Line 1'}
                      error={errors?.addressLine1?.message}
                      onStateSelected={handleStateComparison}
                    />
                  )}
                />


                <Controller
                  name="addressLine2"
                  control={control}
                  rules={LoanDetailsValidation.addressLine2}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      name="addressLine2"
                      setValue={setValue}
                      label={'Address Line 2'}
                      error={errors?.addressLine2?.message}
                    />
                  )}
                />

                <Controller
                  name="unit"
                  control={control}
                  rules={LoanDetailsValidation.unit}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="Unit"
                      type="text"
                      error={errors?.unit?.message}
                    />
                  )}
                />

                <Controller
                  name="city"
                  control={control}
                  rules={LoanDetailsValidation.city}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="City"
                      required
                      type="text"
                      error={errors?.city?.message}
                    />
                  )}
                />

                <Controller
                  name="county"
                  control={control}
                  rules={LoanDetailsValidation.county}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      label="County"
                      required
                      type="text"
                      error={errors?.county?.message}
                    />
                  )}
                />


                <Stack
                  direction={{ xs: "column", md: "row" }}
                  gap={{ xs: 0, md: 2 }}
                >
                  <Box width={"100%"}>

                    <Controller
                      name="state"
                      control={control}
                      rules={LoanDetailsValidation.state}
                      render={({ field }) => (
                        <CustomDropDown
                          {...field}
                          field={field}
                          label="State"

                          defaultValue={control._defaultValues.state}
                          option={fetchedStates}
                          required
                          error={errors?.state?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Controller
                      name="zip"
                      control={control}
                      rules={LoanDetailsValidation.zip}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          field={field}
                          type={"number"}
                          label={"ZIP"}
                          required
                          error={errors?.zip?.message}
                        />
                      )}
                    />
                  </Box>
                </Stack>



                <Controller
                  name="occupancyType"
                  control={control}
                  rules={LoanDetailsValidation.occupancyType}
                  render={({ field }) => (
                    <div>
                      <CustomRadioChip
                        labelComponent={
                          <CustomLargeLabel required value={"Occupancy Type"} />
                        }
                        {...field}
                        field={field}
                        state={occupancyType}
                        data={OCCUPANCY_TYPE}
                        error={errors?.occupancyType?.message}
                      />
                      {buyingOrRefinancing == "Purchasing" || buyingOrRefinancing == "Refinancing" ?
                        <CustomTooltip
                          title="Select this option if the property is intended to be a secondary residence under FHA guidelines, as this detail affects the type of loan and its terms."
                        >
                          <div>
                            <Controller
                              name="occupancyTypeFHA"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      checked={field.value}
                                    />
                                  }

                                  label={<CustomLargeLabel marginTop={1} value={"FHA Secondary Residence"} />}

                                  error={errors?.occupancyType?.message}
                                />
                              )}
                            /></div></CustomTooltip> : null}
                    </div>
                  )}
                />







                <Controller
                  name="mixedUsePropertyStatus"
                  control={control}
                  rules={LoanDetailsValidation.mixedUsePropertyStatus}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Mixed-Use Property: If you will occupy the property, will you set aside space within the property to operate your own business? (e.g., daycare facility, medical office, beauty/barber shop)"}
                        />
                      }
                      {...field}
                      field={field}
                      state={mixedUsePropertyStatus}
                      data={STATUS_MIXED_USE_PROPERTY}
                      error={errors?.mixedUsePropertyStatus?.message}
                    />
                  )}
                />

                <Controller
                  name="manufacturedHomeStatus"
                  control={control}
                  rules={LoanDetailsValidation.manufacturedHomeStatus}
                  render={({ field }) => (
                    <CustomRadioChip
                      labelComponent={
                        <CustomLargeLabel
                          required
                          value={"Is the property a manufactured home? ( e.g., a factory built dwelling built on a permanent chassis) "}
                        />
                      }
                      {...field}
                      field={field}
                      state={manufacturedHomeStatus}
                      data={STATUS_MANUFACTURED_HOME}
                      error={errors?.manufacturedHomeStatus?.message}
                    />
                  )}
                />

                {buyingOrRefinancing == "Purchasing" || buyingOrRefinancing == "Refinancing" ?
                  <Controller
                    name="closingDate"
                    control={control}
                    rules={LoanDetailsValidation.closingDate}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        minDate={new Date().toISOString().split('T')[0]}
                        field={field}
                        type={"date"}
                        // maxDate={currentDate()}
                        label={"Estimated Closing Date"}
                        required
                        error={errors?.closingDate?.message}
                      />
                    )}
                  />
                  : null}

                <Controller
                  name="propertyBuiltDate"
                  control={control}
                  rules={LoanDetailsValidation.propertyBuiltDate}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      sx={{
                        svg: { color: '#fff' },
                      }}
                      field={field}
                      type={"date"}
                      maxDate={currentDate()}
                      label={"Date property built"}
                      required
                      error={errors?.propertyBuiltDate?.message}
                    />
                  )}
                />

                {buyingOrRefinancing == "Refinancing" ?
                  <>
                    <Controller
                      name="datePropertyAcquired"
                      control={control}
                      rules={LoanDetailsValidation.datePropertyAcquired}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          field={field}
                          type={"date"}
                          maxDate={currentDate()}
                          label={"Date Property Acquired"}
                          required
                          error={errors?.datePropertyAcquired?.message}
                        />
                      )}
                    />
                    <Controller
                      name="lastFinancedDate"
                      control={control}
                      rules={LoanDetailsValidation.lastFinancedDate}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          field={field}
                          type={"date"}
                          label={"Last Financed Date"}
                          required
                          error={errors?.lastFinancedDate?.message}
                        />
                      )}
                    />
                  </>
                  : null}
                {buyingOrRefinancing == "Purchasing" ?
                  <Controller
                    name="purchasePrice"
                    control={control}
                    rules={LoanDetailsValidation.purchasePrice}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        type={"text"}
                        // step="0.01"
                        label={"Purchase Price"}
                        sicon={<AttachMoney />}
                        required
                        value={formatNumericValue(field.value)}
                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                        error={errors?.purchasePrice?.message}
                      />
                    )}
                  />
                  : null}


                <Controller
                  name="estimatedPropertyValue"
                  control={control}
                  rules={LoanDetailsValidation.estimatedPropertyValue}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      //  step="0.01"
                      label={"Estimated Property Value"}
                      sicon={<AttachMoney />}
                      required
                      value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                      error={errors?.estimatedPropertyValue?.message}
                    />
                  )}
                />



                <Stack
                  direction={{ xs: "column", md: "row" }}
                  gap={{ xs: 0, md: 2 }}
                >
                  <Box width={isMobile ? '100%' : "35%"}>

                    <Controller
                      name="downPayment"
                      control={control}
                      rules={LoanDetailsValidation.downPayment}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          type={"number"}
                          step="0.01"
                          label={"Down Payment (%)"}
                          required
                          error={errors?.downPayment?.message}

                          onBlur={handleDownPaymentBlur}
                        />
                      )}
                    />
                  </Box>

                  <Box width={isMobile ? '100%' : "65%"}>
                    {<Controller
                      name="downPaymentAmount"
                      control={control}
                      rules={LoanDetailsValidation.downPaymentAmount}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          type={"text"}

                          label={"Down Payment Amount"}
                          sicon={<AttachMoney />}
                          required
                          error={errors?.downPaymentAmount?.message}
                          value={formatNumericValue(field.value)}
                          onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                          onBlur={handleDownPaymentAmountBlur}

                        />
                      )}
                    />}
                  </Box>
                </Stack>



                <Controller
                  name="loanAmount"
                  control={control}
                  rules={LoanDetailsValidation.loanAmount}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      //  step="0.01"
                      label={"Loan Amount"}
                      sicon={<AttachMoney />}
                      required
                      error={errors?.loanAmount?.message}
                      value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                      onBlur={handleLoanAmountBlur}
                    />
                  )}
                />

                {((buyingOrRefinancing == "Purchasing" || buyingOrRefinancing == "Refinancing")
                  &&
                  (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']))) ?
                  <>

                    <Controller
                      name="lockActivityDate"
                      control={control}
                      render={({ field }) => (
                        <>

                          <input type="hidden" {...field} />
                        </>
                      )}
                    />

                    <Controller
                      name="rateLockStatus"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleLockChecked();
                                } else {
                                  handleLockUnchecked();
                                }

                              }}
                            />
                          }
                          label={
                            rateLockStatus
                              ? <CustomLargeLabel
                                marginTop={1}
                                value={`Locked (On ${formatDate(lockActivityDate)})`}
                              />
                              :

                              <CustomLargeLabel
                                marginTop={1}
                                value={`Lock`}
                              />

                          }
                        />
                      )}
                    />


                  </> : null}


                <Stack
                  direction={{ xs: "column", md: "row" }}
                  gap={{ xs: 0, md: 2 }}
                >




                  <Box width={isMobile ? '100%' : "35%"}>


                    <Controller
                      name="rateMortgagePayment"
                      control={control}
                      rules={LoanDetailsValidation.rateMortgagePayment}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          type={"number"}
                          step="0.01"
                          label={"Interest Rate (%)"}
                          required
                          error={errors?.rateMortgagePayment?.message}
                          disabled={rateLockStatus === true ? true : false}
                        />
                      )}
                    />
                  </Box>
                  <Box width={isMobile ? '100%' : "65%"}>
                    <Controller
                      name="loanTermType"
                      control={control}
                      rules={LoanDetailsValidation.loanTerm}
                      render={({ field }) => (
                        <CustomDropDown
                          label={"Loan Term"
                          }
                          {...field}
                          field={field}
                          state={loanTermType}
                          option={LOAN_TERM_TYPE}
                          required
                          error={errors?.loanTermType?.message}
                        />
                      )}
                    />
                  </Box>
                </Stack>
                <Controller
                  name="firstMortgagePayment"
                  control={control}

                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      label={"First Mortgage Payment"}
                      sicon={<AttachMoney />}

                      value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                      disabled={true}
                    />
                  )}
                />



                <CustomLargeLabel value={"Monthly Mortgage Payment"} required />

                <Controller
                  name="propertyInsurance"
                  control={control}
                  rules={LoanDetailsValidation.propertyInsurance}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      label={"Monthly Property Insurance"}
                      sicon={<AttachMoney />}
                      required
                      error={errors?.propertyInsurance?.message}
                      value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />
                  )}
                />
                {(solarPurchasedStatus === "Lease" || solarPurchasedStatus === "Purchased") && (
                  <Controller
                    name="solarleaseapyment"
                    control={control}
                    rules={LoanDetailsValidation.solarleaseapyment}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        type={"text"}
                        label={"Solar Monthly Payment"}
                        sicon={<AttachMoney />}
                        required
                        error={errors?.solarleaseapyment?.message}
                        value={formatNumericValue(field.value)}
                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                      />
                    )}
                  />)}

                <Controller
                  name="countryPropertyTax"
                  control={control}
                  rules={LoanDetailsValidation.countryPropertyTax}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      label={"Monthly Property Tax"}
                      sicon={<AttachMoney />}
                      required
                      error={errors?.countryPropertyTax?.message}
                      value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />
                  )}
                />

                <Controller
                  name="mortgageInsurance"
                  control={control}
                  rules={LoanDetailsValidation.mortgageInsurance}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      label={"Monthly Mortgage Insurance"}
                      sicon={<AttachMoney />}
                      value={formatNumericValue(field.value)}
                      onChange={(e) => field.onChange(parseNumericValue(e.target.value))}

                    />
                  )}
                />

                {homeHOAManagement === "Yes" && (
                  <Controller
                    name="HOADues"
                    control={control}
                    rules={LoanDetailsValidation.HOADues}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        type={"text"}
                        label="Monthly HOA Dues"
                        sicon={<AttachMoney />}
                        required
                        error={errors?.HOADues?.message}
                        value={formatNumericValue(field.value)}
                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                      />
                    )}
                  />
                )}

                {occupancyType === "Investment" && (
                  <Controller
                    name="investmentMonthIncome"
                    control={control}
                    rules={LoanDetailsValidation.investmentMonthIncome}
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        type={"text"}
                        label="Expected Monthly Rental Income"
                        sicon={<AttachMoney />}
                        required
                        error={errors?.investmentMonthIncome?.message}
                        value={formatNumericValue(field.value)}
                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                      />
                    )}
                  />
                )}






                {buyingOrRefinancing == "Refinancing" ?
                  <>



                  </> : null}






              </>
            ) : null}


            <Controller
              name="typeOfTitle"
              control={control}
              rules={LoanDetailsValidation.typeOfTitle}
              render={({ field }) => (
                <CustomRadioChip
                  {...field}
                  field={field}
                  labelStyle={{ fontWeight: 'bold', color: '#000', opacity: 0.8 }}
                  state={typeOfTitle}
                  required
                  label={"Type of the title"}
                  data={TYPE_OF_CONTROL}
                  error={errors?.typeOfTitle?.message}
                />
              )}
            />





          </Box>
        </Box>

        <Box maxWidth={{ xs: "100%", md: "50%" }} mt={2}>
          <Stack direction={"row"} justifyContent={"space-between"}>

            <Button
              startIcon={<ArrowBackIos />}
              onClick={() => {
                route(location.pathname.replace(/[^/]*$/, "getting-started"));
              }}
            >
              Previous
            </Button>

            <Button type="submit" endIcon={<ArrowForwardIos />}>
              Save and Next
            </Button>
          </Stack>
        </Box>
      </Stack>

      <DialogCommonHooks
        show={modalOpenLock}
        handleClose={handleClose}
        title={`Lock Interest Rate`}
        body={
          <>
            <Box width={'100%'} my={2} >

              <Typography>
                {`Are you sure you want to lock the Interest Rate?`}
              </Typography>
              <Stack direction={'row-reverse'} gap={2}>
                <Button variant='text' onClick={handleLockConfirmation}>Yes</Button>
                <Button variant='text' onClick={handleClose}>No</Button>
              </Stack>
            </Box>
          </>
        }
      />

      <DialogCommonHooks
        show={modalOpenUnlock}
        handleClose={handleClose}
        title={`Unlock Interest Rate`}
        body={
          <>
            <Box width={'100%'} my={2} >

              <Typography>
                {`Are you sure you want to unlock the Interest Rate?`}
              </Typography>
              <Stack direction={'row-reverse'} gap={2}>
                <Button variant='text' onClick={handleUnlockConfirmation}>Yes</Button>
                <Button variant='text' onClick={handleClose}>No</Button>


              </Stack>
            </Box>
          </>
        }
      />



    </form >
  );
}
