import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { formState } from '../../../../../recoil/profile-atom'
import { useRecoilState } from 'recoil'
import { UPDATE } from '../../../../../constants/constatnt-variable'
import { API } from '../../../../../services/api-config'
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal'
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon'
import { AssetCreditForm } from './assets-credit-form'


export default function LoanAssetsCredit({ setValue, assetCreditList, setAssetCreditList }) {

    let [formValue, setFormValue] = useRecoilState(formState)

    useEffect(() => {
        setValue('assetCredit', assetCreditList)
    }, [assetCreditList])


    const [editState, setEditState] = useState(() => {
        return null
    })

    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(assetCreditList)
            let editItem = list.find((item, i) => i == editState)
            setassetCreditEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])

    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            assetCreditList.splice(deleteState, 1)
            setAssetCreditList([...assetCreditList])
            setDeleteState(null)
        }

    }, [deleteState])

    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "accountType",
                header: "Account type",
                enableColumnActions: false,
            },
            {
                accessorKey: "accountNo",
                header: "Account number",
                enableColumnActions: false,
            },
            {
                accessorKey: "ownedBy",
                header: "Asset owned by",
                enableColumnActions: false,
                Cell: ({ row }) => {

                    return row.original.ownedBy === "Partner" ? "Co-Borrower" : row.original.ownedBy;
                },
            },
        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [assetCreditEdit, setassetCreditEdit] = useState();
    const handleModalOpen = () => {

        setassetCreditEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }

    const assetCreditSubmit = async data => {
        let list = _.cloneDeep(assetCreditList)
        data = Object.assign(data, {

        })

        if (editState != null) {
            list[editState] = {
                ...list[editState],
                ...data
            }


            setEditState(null)
        } else {
            list.push(data)

        }

        setAssetCreditList(list)
        handleClose();

    }











    return (
        <>

            <Box >
                <Typography variant='h3' mb={3}>{`Assets & Credits`}</Typography>
                <Typography variant='h3' mb={2} >{`Mention all the financial assets you hold`}</Typography>
                <Typography variant='h5' mb={2} >{`Bank Accounts, Retirement, and Other Accounts`}</Typography>

                <CustomReactMaterialTable
                    staticLoad={true}
                    staticData={assetCreditList}
                    columns={tableColumn}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableSorting={false}
                    enableToolbarInternalActions={false}
                    renderTopToolbarCustomActions={
                        () => (
                            <Button onClick={handleModalOpen} variant='outlined' >Add</Button>
                        )
                    }
                    enableRowActions={true}
                    renderRowActions={
                        ({ row }) => {

                            return (
                                <Stack direction={'row'}>
                                    <IconButton onClick={() => { setEditState(row.index) }}>
                                        <KSPencilAltIcon />
                                    </IconButton>


                                    <ConfirmationModel
                                        body={<IconButton
                                            id={row.index} onClick={() => {
                                                // setDeleteState(row.index)
                                            }}>
                                            <KSTrashIcon />
                                        </IconButton>}
                                        message={`Are you sure want remove`}
                                        title={'Asset Credit'}
                                        handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                    />

                                </Stack>
                            )
                        }
                    }
                />

            </Box>




            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Financial assets`}
                body={
                    <AssetCreditForm
                        onSubmit={assetCreditSubmit}
                        editItem={assetCreditEdit}
                        hasPartner={formValue?.hasPartner}
                    />
                }
            />


        </>
    )
}
