export const ValidationConstant = {
  usPhoneNumber: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  regNumber: /[0-9]+/,
  regCapitalAlpha: /[A-Z]+/,
  regSmallAlpha: /[a-z]+/,
  regSpecialChar: /[*@$!#%&()^~{}]+/,
  passwordLength: 8,
  nmlsIdRegx: /[0-9]+/,
  emailRegx:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const MAXLength = {
  password: 25,
  nmlsIdMin: 4,
  nmlsIdMax: 12,
  email: 60,
  name: 50,
  otp: 20,
  phone: 10,
  notes: 100,
  entityName: 100,
  invitationMessage: 201,
  address: 80,
  state: 30,
  city: 30,
  zip: 5,
  bio: 220,
  propertyMin: 2,
  propertyMax: 8,
  minstayPeriod: 0,
  maxstayPeriod: 2,
  minPeriodMonth: 0,
  maxPeriodMonth: 11,
  minPeriod: 0,
  minRentAmount: 2,
  maxRentAmount: 12,
  minDependent: 1,
  maxDependent: 100,
  minbusinessName: 5,
  maxbusinessName: 100,
  minpositionOfTitle: 2,
  maxpositionOfTitle: 50,
  minInsitutionName: 5,
  maxInsitutionName: 100,
  minaccountNo: 1,
  maxCompanyName: 50,
  maxaccountNo: 30,
  minmarketValue: 2,
  maxmarketValue: 12,
  maxUnpaidBalance: 12,
  maxMonthlyPay: 12,
  maxMonthsLeft: 3,
  // minBirthday: 31556926 * 18
  // minBirthday: (getTimestampInSeconds() - (31556926 * 18)),
  ssn: 11,
  minFirstName: 2,
  maxName: 50,
  AvatarMAXLength: 500000,
  addressMin: 3,
  addressMax: 100,
  unitMax: 9,
  cityMax: 20,
  maxNameSuffix: 3,

};


// Us phone Number Formatter
export const usPhoneNumberFormatDefaultValue = (phoneValue) => {
  let cleaned = ('' + phoneValue).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const usNumber = '(' + match[1] + ') ' + match[2] + '-' + match[3];
    return usNumber
  } else {
    return null
  }
}

export const usPhoneNumberDigitSubmit = (phoneSubmit) => {
  if (phoneSubmit == "" || phoneSubmit == null) {
    return phoneSubmit
  }
  return phoneSubmit.replace(/\D/g, '');
}

export const ssnDataFormat = (ssnValue) => {
  let cleaned = ('' + ssnValue).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);

  if (match) {
    const formattedSSN = match[1] + '-' + match[2] + '-' + match[3];
    return formattedSSN;
  } else {
    return null;
  }
};
