import { AttachMoney } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {
    HOME_HOA_MANAGEMENT,
    INTENDED_OCCUPANCY,
    CURRENT_OCCUPANCY,
    MORTAGE_NEW_LENDER,
    MORTAGE_PROPERTY,
    PROPERTY_STATUS,
    PROPERTY_TYPE,
    REAL_STATE_OWNED_BY,
    REAL_STATE_OWNED_BY_WITHOUT_PARTNER,
    REAL_STATE_STATUS,
    SOLAR_STATUS,
    STATUS_OF_NEW_LENDER
} from '../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { RealEstateValidation } from '../../../../../constants/validation-rules'
import { PropertyAddress } from '../../../../../components/loan-application/property-address'

import {
    TYPES_OF_LOAN,
} from "../../../../../constants/constatnt-variable";
import { LoanDetailsValidation } from "../../../../../constants/validation-rules";
import { useEffect } from 'react'
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'


export function RealStateForm({ onSubmit, editItem, hasPartner, isSubjectProperty }) {



    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: {

            solarStatus: editItem?.solarStatus,
            isHoaManaged: editItem?.isHoaManaged,

            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry ? editItem?.stateOfCountry : null,
            zip: editItem?.zip,
            country: editItem?.country ? editItem?.country : null,
            propertyType: editItem?.propertyType ? editItem?.propertyType : null,
            marketValue: editItem?.marketValue,
            realEstateOwnedBy: editItem?.realEstateOwnedBy ? editItem?.realEstateOwnedBy : null,
            propertyStatus: editItem?.propertyStatus,
            propertyMortgage: editItem?.propertyMortgage,
            intendedOccupancy: editItem?.intendedOccupancy,
            propertyInsurance: editItem?.propertyInsurance,
            propertyTaxes: editItem?.propertyTaxes,
            solarLeasePayment: editItem?.solarLeasePayment,
            hoaPayment: editItem?.hoaPayment,
            monthlyIncome: editItem?.monthlyIncome,

            currentOccupancy: editItem?.currentOccupancy,
            realEstateDerivedFrom: editItem?.realEstateDerivedFrom,
        }
    })

    const { isHoaManaged, solarStatus, newLeaderStatus, newLeaderStatement, stateOfCountry = null, country = null,
        propertyType = null, realEstateOwnedBy = null, propertyStatus, propertyMortgage, intendedOccupancy, currentOccupancy,
        creditLimit, realEstateLoanType, realestateStatus
    } = useWatch({ control })



    useEffect(() => {
        if (solarStatus === "No solar")

            setValue('solarLeasePayment', null);

    }, [solarStatus]);


    useEffect(() => {
        if (isHoaManaged === "No")

            setValue('hoaPayment', null);

    }, [isHoaManaged]);


    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Box width={'100%'} my={2} >
                <Box>




                    <Controller
                        name='solarStatus'
                        control={control}
                        rules={RealEstateValidation.solarStatus}
                        render={({ field }) => (
                            <>
                                {editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY" ? (
                                    <CustomRadioChip
                                        labelComponent={<CustomLargeLabel required value={'Is solar purchased?'} />}
                                        {...field}
                                        field={field}
                                        state={solarStatus}
                                        data={
                                            editItem.solarStatus === "Lease"
                                                ? [{ id: 'Lease', value: "Lease", label: "Lease" }]
                                                : editItem.solarStatus === "Purchased"
                                                    ? [{ id: 'Purchased', value: "Purchased", label: "Purchased" }]
                                                    : [{ id: 'No solar', value: "No solar", label: "No solar" }]
                                        }
                                        error={errors?.solarStatus?.message}

                                    />
                                ) : (
                                    <div>
                                        <CustomRadioChip
                                            labelComponent={<CustomLargeLabel required value={'Is solar purchased?'} />}
                                            {...field}
                                            field={field}
                                            state={solarStatus}
                                            data={SOLAR_STATUS}
                                            error={errors?.solarStatus?.message}

                                        />
                                    </div>
                                )}
                            </>
                        )}
                    />


                    <Controller
                        name='isHoaManaged'
                        control={control}
                        rules={RealEstateValidation.isHoaManaged}
                        render={({ field }) => (
                            <>
                                {editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY" ? (
                                    <CustomRadioChip
                                        labelComponent={<CustomLargeLabel required value={'Do the home under HOA management?'} />}
                                        {...field}
                                        field={field}
                                        state={isHoaManaged}
                                        data={
                                            editItem.isHoaManaged === "Yes"
                                                ? [
                                                    { id: 'yes', value: "Yes", label: "Yes" },

                                                ]

                                                : [

                                                    { id: 'no', value: "No", label: "No" },
                                                ]
                                        }
                                        error={errors?.isHoaManaged?.message}

                                    />
                                ) : (
                                    <div>
                                        <CustomRadioChip
                                            labelComponent={<CustomLargeLabel required value={'Do the home under HOA management?'} />}
                                            {...field}
                                            field={field}
                                            state={isHoaManaged}
                                            data={HOME_HOA_MANAGEMENT}
                                            error={errors?.isHoaManaged?.message}

                                        />



                                    </div>
                                )}
                            </>
                        )}
                    />

                    <>
                        <Typography variant='inherit' mt={2}>Address of the Property</Typography>



                        <PropertyAddress control={control} Controller={Controller} stateOfCountry={stateOfCountry} country={country} errors={errors} isSubject={editItem?.realEstateDerivedFrom} />

                        <Controller
                            name='marketValue'
                            control={control}
                            rules={RealEstateValidation.marketValue}
                            render={({ field }) => (
                                <CustomInput {...field} type={'text'} label={'Market Value'} required sicon={<AttachMoney />}
                                    error={errors?.marketValue?.message}
                                    value={formatNumericValue(field.value)}
                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                    disabled={editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY"} />

                            )}
                        />
                        <Controller
                            name='propertyType'
                            control={control}
                            rules={RealEstateValidation.propertyType}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    label={'Property Type'}
                                    required
                                    state={propertyType}
                                    option={PROPERTY_TYPE}
                                    error={errors?.propertyType?.message}
                                    disabled={editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY"}
                                />
                            )}
                        />



                        <Controller
                            name='realEstateOwnedBy'
                            control={control}
                            rules={RealEstateValidation.realEstateOwnedBy}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    label={'Real estate Owned by'}
                                    required
                                    state={realEstateOwnedBy}
                                    option={hasPartner ? REAL_STATE_OWNED_BY : REAL_STATE_OWNED_BY_WITHOUT_PARTNER}
                                    error={errors?.realEstateOwnedBy?.message}
                                />

                            )}
                        />

                        <Controller
                            name='propertyStatus'
                            control={control}
                            rules={RealEstateValidation.propertyStatus}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    required
                                    label={'Property Status'}
                                    state={propertyStatus}
                                    data={PROPERTY_STATUS}
                                    error={errors?.propertyStatus?.message}

                                />
                            )}
                        />

                        <Controller
                            name='propertyMortgage'
                            control={control}
                            rules={RealEstateValidation.propertyMortgage}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    required
                                    label={'Do you have mortgage on this property?'}
                                    state={propertyMortgage}
                                    data={MORTAGE_PROPERTY}
                                    error={errors?.propertyMortgage?.message}
                                />
                            )}
                        />

                        <Controller
                            name='currentOccupancy'
                            control={control}
                            rules={RealEstateValidation.currentOccupancy}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    required
                                    label={'Current Occupancy'}
                                    state={currentOccupancy}
                                    data={CURRENT_OCCUPANCY}
                                    error={errors?.currentOccupancy?.message}
                                    disabled={isSubjectProperty}
                                />
                            )}

                        /> <Controller
                            name='intendedOccupancy'
                            control={control}
                            rules={RealEstateValidation.intendedOccupancy}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    required
                                    label={'Intended Occupancy'}
                                    state={intendedOccupancy}
                                    data={INTENDED_OCCUPANCY}
                                    error={errors?.intendedOccupancy?.message}
                                />
                            )}
                        />

                        <Controller
                            name='propertyInsurance'
                            control={control}
                            rules={RealEstateValidation.propertyInsurance}
                            render={({ field }) => (
                                <CustomInput labelSize="16px" {...field} type={'text'} label={'Monthly Property Insurance'}
                                    required sicon={<AttachMoney />} error={errors?.propertyInsurance?.message}
                                    value={formatNumericValue(field.value)}
                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                    disabled={editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY"}
                                />
                            )}
                        />

                        <Controller
                            name='propertyTaxes'
                            control={control}
                            rules={RealEstateValidation.propertyTaxes}
                            render={({ field }) => (
                                <CustomInput labelSize="16px" {...field} type={'text'} label={'Monthly Property Taxes'}
                                    required sicon={<AttachMoney />} error={errors?.propertyTaxes?.message}
                                    value={formatNumericValue(field.value)}
                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                    disabled={editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY"} />
                            )}
                        />
                        {solarStatus === 'Purchased' || solarStatus === 'Lease' ? (
                            <Controller
                                name='solarLeasePayment'
                                control={control}
                                rules={RealEstateValidation.solarLeasePayment}
                                render={({ field }) => (
                                    <CustomInput labelSize="16px" {...field} type={'text'} label={'Monthly Solar Payment'} required sicon={<AttachMoney />} error={errors?.solarLeasePayment?.message}
                                        value={formatNumericValue(field.value)}
                                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                        disabled={editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY"} />

                                )}
                            />) : null}
                        {isHoaManaged === 'Yes' ? (
                            <Controller
                                name='hoaPayment'
                                control={control}
                                rules={RealEstateValidation.hoaPayment}
                                render={({ field }) => (
                                    <CustomInput labelSize="16px" {...field} type={'text'} label={'Monthly HOA dues'} required sicon={<AttachMoney />}
                                        value={formatNumericValue(field.value)}
                                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                        disabled={editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY"} error={errors?.hoaPayment?.message} />
                                )}
                            />) : null}

                        <Controller
                            name='monthlyIncome'
                            control={control}
                            rules={RealEstateValidation.monthlyIncome}
                            render={({ field }) => (
                                <CustomInput labelSize="16px" {...field} type={'text'} label={'Monthly Rental Income'} sicon={<AttachMoney />} error={errors?.monthlyIncome?.message}
                                    value={formatNumericValue(field.value)}
                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                    disabled={editItem?.realEstateDerivedFrom === "SUBJECT_PROPERTY"}
                                />
                            )}
                        />



                    </>

                </Box>
                <Box textAlign={'right'}>
                    <Button type='submit' variant='contained' >
                        {editItem.length != 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form >
    )
}