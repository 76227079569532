import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { usPhoneNumberDigitSubmit } from '../../../constants/validation'
import CustomReactMaterialTable from '../../../helpers/table/react-material-table'
import { DialogCommonHooks } from '../../../helpers/modal/modal'
import AdditionalAddressForm from './additional-address-form'
import { formState } from '../../../recoil/profile-atom'
import { useRecoilState } from 'recoil'
import { postApiCallDynamicWithOutReturn } from '../../../services/api-service'
import { API } from '../../../services/api-config'
import { UPDATE } from '../../../constants/constatnt-variable'
import { KSPencilAltIcon, KSTrashIcon } from '../../../icons/custome-icon'
import ConfirmationModel from '../../../helpers/modal/confirm-modal'

export default function LoanAdditionalAddress({ setPage, additionalAddresses, contact }) {

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]


    let [formValue, setFormValue] = useRecoilState(formState)
    // let { borrowerInfo: { page: { contact: { subPage } } } } = formValue


    // const { state } = useWatch({ control })
    // const [additionalAddress, setAdditionalAddress] = useState(addresses)


    const [additionalAddress, setAdditionalAddress] = useState(() =>
        additionalAddresses == null ? [] : _.cloneDeep(additionalAddresses)
    )

    const [modalOpen, setModalOpen] = useState(false);
    const [secondAddress, setSecondAddress] = useState()

    const [editState, setEditState] = useState(() => {
        return null
    })

    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(additionalAddress)
            let editItem = list.find((item, i) => i == editState)
            setSecondAddress(editItem)
            setModalOpen(true);
        }

    }, [editState])

    const [deleteState, setDeleteState] = useState(() => {
        return null;
    });

    //Delete 
    useEffect(() => {
        if (deleteState != null) {
            additionalAddress.splice(deleteState, 1);
            setAdditionalAddress([...additionalAddress]);
            setDeleteState(null);
        }
    }, [deleteState])

    const tableYear = (row) => (
        <Typography>
            {`${row.original["year"]} Years ${row.original["month"]} Months`}
        </Typography>
    )

    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "addressLine1",
                header: "Address",
                enableColumnActions: false,
            },
            {
                accessorKey: "city",
                header: "City",
                enableColumnActions: false,
            },
            {
                accessorKey: "zip",
                header: "ZIP",
                enableColumnActions: false,
            },
            {
                accessorKey: "year",
                header: "Period of stay",
                enableColumnActions: false,
                Cell: ({ row }) => tableYear(row),
            },

        ],
        []
    );


    const handleClickOpen = () => {

        setSecondAddress([])
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };


    const handleCloseDelete = (event, row) => {
        if (event.target.value == 'yes') {
            setDeleteState(row.index)
        }
    }


    const additionalAddressSubmit = (data) => {

        if (editState != null) {
            additionalAddress[editState] = {
                ...additionalAddress[editState],
                ...data
            }
            setEditState(null)
        } else {
            additionalAddress.push(data)
        }
        setAdditionalAddress([...additionalAddress])
        handleClose();
    }

    useEffect(() => {
        setAdditionalAddress([...additionalAddress]);
    }, [additionalAddress.length])

    let [customError, setCustomerror] = useState({})



    function getSum(array, column) {
        if (array.length == 0) {
            return 0
        }
        let values = array.map((item) => parseInt(item[column]) || 0)
        return values?.reduce((a, b) => a + b)
    }

    const handleSubmit = async () => {

        let year = getSum(additionalAddress, "year") + parseInt(contact?.year);
        let yearOfMonth = year * 12
        let monthCount = getSum(additionalAddress, "month") + parseInt(contact?.month) + yearOfMonth


        if (monthCount >= 24) {

            let path, updateState
            const apiCallData = {
                page: {
                    subPage: {
                        additionalAddresses: additionalAddress,
                        currentAddress: {
                            addressLine1: contact?.addressLine1,
                            addressLine2: contact?.addressLine2,
                            city: contact?.city,
                            stateOfCountry: contact?.stateOfCountry,
                            country: contact?.country,//
                            unit: contact?.unit,
                            year: contact?.year,
                            zip: contact?.zip,
                            month: contact?.month,


                        },
                        mailingAddressStatus: contact.mailingAddressStatus,
                        ...(
                            contact.mailingAddressStatus == 'Yes' ?
                                {
                                    mailingAddress: {
                                        addressLine1: contact?.addressLine1,
                                        addressLine2: contact?.addressLine2,
                                        city: contact?.city,
                                        stateOfCountry: contact?.stateOfCountry,
                                        country: contact?.country,//
                                        unit: contact?.unit,
                                        year: contact?.year,
                                        zip: contact?.zip,
                                        month: contact?.month

                                    }
                                }
                                :
                                {
                                    mailingAddress: {
                                        addressLine1: contact?.mailingAddressline1,
                                        addressLine2: contact?.mailingAddressline2,
                                        city: contact?.mailingCity,
                                        stateOfCountry: contact?.mailingStateofCountry,
                                        country: contact?.country,//
                                        unit: contact?.mailingUnit,
                                        year: 0,
                                        zip: contact?.mailingZip,
                                        month: 0
                                    },
                                }
                        ),
                        cellPhone: usPhoneNumberDigitSubmit(contact?.cellPhone),
                        email: contact?.email,
                        homePhone: usPhoneNumberDigitSubmit(contact?.homePhone),
                        workPhone: usPhoneNumberDigitSubmit(contact?.workPhone),
                        rentAmount: contact?.rentAmount,
                        ownerShipType: contact?.ownerShipType,

                    },
                    isComplete: true

                },
                formId: formValue.id,
                isComplete: true

            }

            if (partnerInfoPath == "partner-info") {
                path = API.partnerInfoContact
                updateState = {
                    ...formValue,
                    partnerInfo: {
                        ...formValue.partnerInfo,
                        page: {
                            ...formValue.partnerInfo.page,
                            contact: {
                                ...formValue.partnerInfo.page.contact,
                                subPage: apiCallData?.page?.subPage,
                                isComplete: true
                            }
                        }
                    }
                }
            } else {
                path = API.borrowerInfoContact
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            contact: {
                                ...formValue.borrowerInfo.page.contact,
                                subPage: apiCallData?.page?.subPage,
                                isComplete: true
                            }
                        }
                    }
                }
            }

            const contactInfoUpdateInRecoil = () => {
                setFormValue(updateState)
                route(location.pathname.replace(/[^/]*$/, 'demographics'));
            }

            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: path,
                    header: UPDATE,
                    refresh: contactInfoUpdateInRecoil
                }


            )


        } else {
            //     customError = {
            //         ...customError,
            //         additionalAddress: {
            //             'message': "We need minimum of 2 years of living history, Please enter your previous address."
            //         },
            //     }
            //     setCustomerror([...customError])
            // }
            setCustomerror({
                ...customError,
                additionalAddress: {
                    'message': "We need a minimum of 2 years of living history, Please enter your previous address."
                },
            });

        }

    }


    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Typography variant='h3' >{`Additional Addresses`}</Typography>
                    <Box my={2}>
                        <CustomReactMaterialTable
                            staticLoad={true}
                            staticData={additionalAddress}
                            columns={tableColumn}
                            enablePagination={false}
                            enableBottomToolbar={false}
                            enableSorting={false}
                            enableToolbarInternalActions={false}
                            renderTopToolbarCustomActions={
                                () => (
                                    <Button variant='outlined' onClick={handleClickOpen}>Add</Button>
                                )
                            }
                            enableRowActions={true}
                            renderRowActions={
                                ({ row }) => {
                                    return (
                                        <Stack direction={'row'}>
                                            <IconButton onClick={() => { setEditState(row.index) }}>
                                                <KSPencilAltIcon />
                                            </IconButton>
                                            <ConfirmationModel
                                                body={
                                                    <IconButton onClick={() => {
                                                        // setDeleteState(row.index)
                                                        // setRowDelete(true)
                                                    }}>
                                                        <KSTrashIcon />
                                                    </IconButton>}
                                                message={`Are you sure want remove the ${row?.original?.addressLine1}.....?`}
                                                title={`Delete Additional Address`}
                                                handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                            />
                                        </Stack >
                                    )
                                }
                            }
                        />
                    </Box >
                    {customError?.additionalAddress && <FormHelperText error>{customError.additionalAddress.message}</FormHelperText>}

                </Box >

                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Button onClick={() => { setPage("contact") }} startIcon={<ArrowBackIos />}>
                            Previous
                        </Button>
                        <Button endIcon={<ArrowForwardIos />} onClick={handleSubmit}>
                            Save and Next
                        </Button>
                    </Stack>
                </Box>

            </Stack >

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Additional Address`}
                body={
                    <AdditionalAddressForm
                        onSubmit={additionalAddressSubmit}
                        editItem={secondAddress}
                        handleClose={handleClose}
                    />
                }
            />

            {/* <DialogCommonHooks
                show={rowDelete}
                handleClose={handleCloseDelete}
                title={`Delete Additional Address`}
                body={
                    <>
                        <Box width={'100%'} my={2} >
                            <Typography mb={2}>{`Are you sure want remove the address 12, Hercules street.....?`}</Typography>
                            <Stack direction={'row-reverse'} gap={2} >
                                <Button variant='contained' value="yes" onClick={handleCloseDelete}>Yes</Button>
                                <Button variant='text' onClick={handleClose}>Cancel</Button>
                            </Stack>
                        </Box>
                    </>
                }
            /> */}
        </>
    )
}
