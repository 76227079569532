import React from "react";
import LoanEmptyIcon from "../../assets/loan_empty.png";
import { Box, Grid, Typography } from "@mui/material";

function EmptyPage() {
  return (
    <Grid container my={3}>
      <Grid item xs={12} sm={12} md={4} margin={"auto"}>
        <Box
          textAlign={"center"}
          my={2}
          width={{ xs: "100%", sm: "60%", md: "60%" }}
          mx={"auto"}
        >
          <img src={LoanEmptyIcon} width={"100%"} />
        </Box>
        <Box textAlign={"center"} my={2}>
          <Typography variant="h2">No loans Yet!</Typography>
          <Typography variant="body">
            {`No loans found? Let's start fresh. Begin a new loan application today and`} <br />
            unlock your path to homeownership!
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default EmptyPage;
