import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../../../../services/api-service";
import { API } from "../../../../../services/api-config";
import CoBorrowerStatus from "./co-borrower";
import PartnerCoBorrowerSet from "./partner-co-borrower-set";
import { useRecoilState } from "recoil";
import { formState, profileState } from "../../../../../recoil/profile-atom";
import Partner from "./partner";
import { UPDATE } from "../../../../../constants/constatnt-variable";
import { DialogCommonHooks } from "../../../../../helpers/modal/modal";
import { ThatsGreat } from "./thats-great";
import { LoanProvider } from './LoanContext';

export default function GettingStarted() {
  let [formValue, setFormValue] = useRecoilState(formState);


  let borrowerType = formValue?.borrowerType;


  let primaryBorrower = {
    firstName: formValue?.getStarted?.page?.primaryBorrower?.firstName,
    lastName: `${formValue?.getStarted?.page?.primaryBorrower?.lastName} (PB)`,
    phone: formValue?.getStarted?.page?.primaryBorrower?.phone,
    email: formValue?.getStarted?.page?.primaryBorrower?.email,
  };

  let primaryBorrowerPartner = {
    firstName: formValue?.getStarted?.page?.primaryBorrowerPartner?.firstName,
    lastName: `${formValue?.getStarted?.page?.primaryBorrowerPartner?.lastName} (PB-CB)`,
    phone: formValue?.getStarted?.page?.primaryBorrowerPartner?.phone,
    email: formValue?.getStarted?.page?.primaryBorrowerPartner?.email,
  };



  useEffect(() => {

    if (formValue.isLocked) {

      route(location.pathname.replace(/[^/]*$/, "review"));
    }

  }, []);


  const { getStarted, id } = formValue;
  const [partner, setPartner] = useState(formValue?.hasPartner);

  const route = useNavigate();
  const location = useLocation();

  const parnerOnSubmit = async (data) => {
    setFormValue({
      ...formValue,
      getStarted: {
        ...formValue.getStarted,
        page: {
          ...formValue.getStarted.page,
          partner: data,

          hasPartner: data?.loanPartnerStatus,
        },
      },

    });
    if (borrowerType === 'Primary') {
      setPage("co-borrower");
      setPartner(data?.loanPartnerStatus == "Yes");
    }
    else {
      setPage("merge");
      setPartner(data?.loanPartnerStatus);
    }
  };

  const coborrowerOnSubmit = (data) => {

    if (borrowerType === 'Primary') {
      if (data.length != 0) {
        setFormValue({
          ...formValue,
          getStarted: {
            ...formValue.getStarted,
            page: {
              ...formValue.getStarted.page,

              coBorrower: data,
            },
          },
        });
        //
        setPage("merge");
      } else {

        handleSubmit({ coBorr: data });
      }
    }
    else
      handleSubmit({ coBorrAdd: "no" })
  };

  const handleSubmit = ({ coBorr, coBorrInv, coBorrAdd }) => {


    if (coBorrAdd == "no") {
      coBorr = []
    }
    const apiCallData = {
      page: {
        partner:
          getStarted?.page?.partner?.email == undefined
            ? null
            : getStarted?.page?.partner,
        coBorrower: coBorr?.length == 0 ? [] : getStarted?.page?.coBorrower,
        primaryBorrower: primaryBorrower,
        primaryBorrowerPartner: getStarted?.page?.primaryBorrowerPartner,
        coborrowersPartner: getStarted?.page?.coborrowersPartner,

        hasPartner: getStarted?.page?.hasPartner,

        shouldMail: coBorrInv == "co-bor" ? true : false
      },
      formId: id,
      isComplete: true,
    };

    const updateState = () => {
      setFormValue({
        ...formValue,
        getStarted: {
          ...formValue.getStarted,

          page:
          {
            ...formValue.getStarted.page,
            //     coBorrower: getStarted?.page?.coBorrower,
            //     partner: getStarted?.page?.partner,
            //     hasPartner: getStarted?.page?.hasPartner,
            brokerCode: formValue?.getStarted?.page?.brokerCode,
            //coBorrower: coBorr?.length == 0 ? [] : getStarted?.page?.coBorrower,// added
          },
          isComplete: true,
        },
        hasPartner: partner,
      });
      route(location.pathname.replace(/[^/]*$/, "loan-details"));

    };

    postApiCallDynamicWithOutReturn({
      data: apiCallData,
      path: API.getStarted,
      header: UPDATE,
      refresh: updateState,
    });


  };



  const [page, setPage] = useState("partner");

  const [partnerData, setPartnerData] = useState(null);

  const handlePartnerDataChange = (newData) => {
    setPartnerData(newData?.partner);
  };

  const GenerateHTML = () => {
    if (page === "partner") {
      return (
        <Partner
          setPage={setPage}
          data={getStarted?.page}
          onSubmit={parnerOnSubmit}
          onDataChange={handlePartnerDataChange}
        />
      );
    } else if (page === "co-borrower") {
      return (

        <CoBorrowerStatus
          setPage={setPage}
          onSubmit={coborrowerOnSubmit}
          data={getStarted?.page?.coBorrower}
        />

      );
    } else if (page === "merge") {
      return (
        <PartnerCoBorrowerSet
          setPage={setPage}
          handleSubmit={handleSubmit}
          data={getStarted?.page?.coBorrower}
          coborrowersPartner={getStarted?.page?.coborrowersPartner}
          partnerData={getStarted?.page?.partner}
          hasPartner={getStarted?.page?.hasPartner}
          primaryBorrower={primaryBorrower}
          primaryBorrowerPartnerOrigi={primaryBorrowerPartner}
          primaryBorrowerPartner={partnerData}
          borrowerType={borrowerType}
        />
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(!getStarted?.isComplete);
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <LoanProvider>
        <GenerateHTML />
        <DialogCommonHooks
          show={modalOpen}
          handleClose={handleClose}
          body={<ThatsGreat handleClose={handleClose} />}
        />
      </LoanProvider>
    </>
  );
}
