
import { CloudUpload, Lock } from '@mui/icons-material'
import { Box, Checkbox, Chip, FormControlLabel, FormHelperText, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { CreditReportValidation, GuidedDocuments } from '../constants/validation-rules';
import { truncate } from 'lodash';

export default function CustomCheckboxFileUpload({
  control,
  status,
  title = null,
  docIc,
  checkData,
  id,
  fileUpload,
  changeCheck,
  isReview,
  isSkiped = false,
  errors,
  info,
  data,
  skipSegment,
  skipSegmentCliked,
  skipSegmentRev,
  skipSegmentAll, skipSegmentAllClicked,
  ...props
}) {
  const [checked, setChecked] = useState(() => {
    return checkData ? checkData : "";
  });

  const handleChange = (event) => {

    changeCheck(id, true, event.target.checked)
    setChecked(event.target.checked);

    // callBackForSwitch({ state: event.target.checked })
  };


  useEffect(() => {

    data.forEach((item, index) => {
      if (item.fileName === null)

        changeCheck(index)
      setChecked(skipSegment);



    });

  }, [skipSegment]);







  return (
    <>
      <Stack direction={'row'} gap={2} alignItems={'start'}>

        {/* Skip Checkbox */}
        {
          isReview || isSkiped ? null :
            <Box>
              <FormControlLabel
                control={
                  <Controller
                    name='checkbox'
                    control={control}
                    rules={GuidedDocuments.checkbox}
                    render={
                      ({ field }) => (
                        <Checkbox
                          {...field}
                          defaultChecked={checked}
                          checked={(checked === false && skipSegment === false) ? false : true}
                          onChange={handleChange}
                          color='error'
                          disabled={checked === true && skipSegment ? true : false}
                        />
                      )
                    }
                  />
                }
                label="Skip"
                labelPlacement="start"
                style={{ alignSelf: 'center', marginLeft: 0 }}
              />
              {!(checked === true) && (
                <FormHelperText style={{ alignSelf: 'center', textAlign: 'center', marginTop: 0 }} sx={{ fontSize: 14 }} error>{"Required"}</FormHelperText>
              )}
            </Box>
        }


        <Grid container my={1} border={1} p={1} borderRadius={'5px'} borderColor={'#11111115'}>

          {/* Document title */}
          <Grid item xs={12} md={6} >
            <Typography my={.5} variant={checked === true ? 'h5' : 'h6'}>{title}</Typography>
            {info && info.map((item) =>
              <Typography color={"#222222BF"} key={item.label} variant='body1'>{item.label}: {item.value}</Typography>
            )}

          </Grid>

          {/* Skip status label */}
          <Grid item xs={12} md={3} textAlign={'center'}>
            {checked === true ?
              <Chip
                icon={<Lock color='error' />}
                label="Skipped"
                variant="filled"
                size='small'
                sx={{
                  textTransform: 'uppercase',
                  marginY: .5,
                  backgroundColor: '#FF454520',
                  color: 'error.main'
                }}
              />
              :
              null}
          </Grid>

          {/* File upload inputfield */}
          <Grid display={isReview ? 'none' : null} item xs={12} md={3} textAlign={{ xs: 'center', md: 'right' }}>
            <IconButton
              disabled={checked === true ? true : false}
              color="primary"
              component="label"
              sx={{
                borderRadius: '4%',
                padding: '0px'
              }}
            >
              <input id={docIc} onChange={fileUpload} hidden
                //accept="image/*" 
                type="file" />
              <CloudUpload />
              <Typography variant='body1' ml={.5} my={.5}>Upload</Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Stack>

    </>
  )
}

