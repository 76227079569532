import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react'

export default function SingleSelectDynamicValueDropdown({ label, required, marginBottom = 3, marginTop = 1, options, value, setValue, field, error = null, ...props }) {

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Box mb={marginBottom} mt={marginTop}>
            <InputLabel>
                {label}
                {/* {required ? (
                    <Typography variant="small" color="error">
                        *
                    </Typography>
                ) : null} */}
                {required ?
                    <Typography component={'span'} color={'error.main'} >*</Typography>
                    : null
                }
            </InputLabel>
            <FormControl fullWidth>
                <Select
                    displayEmpty
                    value={value ? value : null}
                    {...(field ? field : { onchange: handleChange })}
                >
                    <MenuItem color="Primary" style={{ display: "none" }} name={null} value={null} disabled>
                        <InputLabel htmlFor='Select'>
                            Select Option
                        </InputLabel>
                    </MenuItem>
                    {options.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {/* {(error && <FormHelperText error>{error}</FormHelperText>)} */}
                {(error && (
                    <FormHelperText sx={{ fontSize: 14 }} error>
                        {error}
                    </FormHelperText>
                ))}
            </FormControl>
        </Box>
    )
}
