import React, {useEffect, useState} from "react";
import {Tab} from "@mui/material";
import Box from "@mui/material/Box";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useLocation, useNavigate} from "react-router-dom";
import {API} from "../../../services/api-config";
import {useRecoilState} from "recoil";
import {globalSpinner, webInfoState,} from "../../../recoil/profile-atom";
import {getApiCallDynamic} from "../../../services/api-service";
import {WEBSITE_TABS} from "../../../constants/constatnt-variable";
import Bio from "./bio";
import SocialLink from "./socialLink";
import Publish from "./publish";
import Rates from "./rates/rates";
import Review from "./review/review";

// Tab code

function a11yProps(index, clickEvent) {
  return {
    // id: `simple-tab-${index}`,
    // "aria-controls": `simple-tabpanel-${index}`,
    // href: `#${index}`,
    onClick: clickEvent,
    name: `${index}`,
    value: index,
  };
}

function Website(props) {
  const router = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(() => {
    return "";
  });

  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  let [webData, setWebData] = useRecoilState(webInfoState);

  useEffect(() => {
    if (location.hash == "") {
      router(WEBSITE_TABS.Bio);
    } else {
      setValue(location.hash);
    }
  }, [location]);

  useEffect(() => {
    setSpinner(true);
    let param = { userCode: props.user.data.userCode };
    getApiCallDynamic({ param: param, path: API.webInfo }).then((res) => {
      if (res?.status === 200) {
        setWebData(res.data);
        setSpinner(false);
      }
      setSpinner(false);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRouting = (event) => {
    router(`${event.target?.name}`);
  };
  return (
    <>
      <Box>
        {/* Tab List */}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab
                  label="Bio"
                  {...a11yProps(WEBSITE_TABS.Bio, handleRouting)}
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Social Media Links"
                  {...a11yProps(WEBSITE_TABS.SocialMediaLink, handleRouting)}
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Reviews"
                  {...a11yProps(WEBSITE_TABS.Reviews, handleRouting)}
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Rates"
                  {...a11yProps(WEBSITE_TABS.Rates, handleRouting)}
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Publish"
                  {...a11yProps(WEBSITE_TABS.Publish, handleRouting)}
                  sx={{ textTransform: "capitalize" }}
                />
              </TabList>
            </Box>
            <TabPanel value={WEBSITE_TABS.Bio}>
              <Bio />
            </TabPanel>

            {/* Social Media Links Tab Value */}
            <TabPanel value={WEBSITE_TABS.SocialMediaLink} sx={{ px: { xs: 0, md: 3 } }}>
              <SocialLink />
            </TabPanel>
            {/* Reviews Tab Value */}
            <TabPanel value={WEBSITE_TABS.Reviews} sx={{ px: { xs: 0, md: 3 } }}>
              <Review />
            </TabPanel>
            {/* Rates Tab Value */}
            <TabPanel value={WEBSITE_TABS.Rates} sx={{ px: { xs: 0, md: 3 } }}>
              <Rates />
            </TabPanel>
            {/* Publish Tab Value */}
            <TabPanel value={WEBSITE_TABS.Publish} sx={{ px: { xs: 0, md: 3 } }}>
              <Publish />
            </TabPanel>
          </TabContext>
        </Box>

        {/* Bio Tab Value */}
      </Box>
    </>
  );
}

export default Website;
