import React, { useState, useEffect } from 'react';
import {
    Paper,
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { NoCoupon } from '../../../icons/custome-icon';
import { API } from '../../../services/api-config'




const Coupon = () => {

    const [windowSize, setWindowSize] = useState(getSize());
    function getSize() {
        const width = window.innerWidth;
        if (width <= 600) return 'xs';
        else if (width <= 960) return 'sm';
        else if (width <= 1280) return 'md';
        else if (width <= 1920) return 'lg';
        else return 'xl';
    }

    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [userCode, setUserCode] = useState('');
    useEffect(() => {

        const fetchUserCode = () => {
            const localStorageData = localStorage.getItem('cliqloan-user-app-session');
            if (localStorageData) {
                const parsedData = JSON.parse(localStorageData);
                const code = parsedData?.data?.userCode;
                setUserCode(code);
            }
        };

        fetchUserCode();


    }, []);

    const BASE_URL_ADD_COUPON = API.billCoupon
    const GET_COUPON_URL = "getCoupon?userCode=";

    const CouponAvailableApi = `${BASE_URL_ADD_COUPON}/${GET_COUPON_URL}${userCode}`;

    const [coupons, setCoupons] = useState([]);



    useEffect(() => {
        fetchCouponsData();
    }, [CouponAvailableApi]);

    const fetchCouponsData = () => {
        axios
            .get(CouponAvailableApi)
            .then((response) => setCoupons(response.data))
            .catch((error) => console.error('Error fetching data:', error));
    };


    return (
        // <>
        //     <Grid container>
        //         <Grid item sm={12} md={5} my={1}>
        //             <Typography variant="h3" mb={1} ml={2}>Coupon</Typography>
        //             <Typography variant="p2" color="rgba(34, 34, 34, 0.75)">
        //                 {/* Your coupon details here */}
        //             </Typography>
        //         </Grid>
        //         {coupons.length === 0 ? (
        //             <>
        //                 {/* // <Grid item sm={12} md={5} my={30}>
        //             //     <NoCoupon />
        //             //     <Typography variant="h3" my={5} mb={5} ml={-6}>Sorry! No coupon is available </Typography>


        //             // </Grid>
        //             // <Grid container justifyContent="center" alignItems="center"> */}
        //                 <Grid item xs={12} sm={12} md={7} lg={7} xl={7} my={30} overflow="auto">
        //                     <Box ml={4}>
        //                         {windowSize === 'xs' && <NoCoupon size="xs" />}
        //                         {windowSize === 'sm' && <NoCoupon size="sm" />}
        //                         {windowSize === 'md' && <NoCoupon size="md" />}
        //                         {windowSize === 'lg' && <NoCoupon size="lg" />}
        //                         {windowSize === 'xl' && <NoCoupon size="xl" />}
        //                     </Box>
        //                     <Typography variant="h3" my={5}>Sorry! No coupon is available</Typography>
        //                 </Grid>
        //                 <Grid item xs={12} sm={12} md={7} lg={7} xl={7} my={5} overflow="auto">

        //                 </Grid>

        //             </>


        //         ) : (
        //             <Grid item sm={12} md={7} my={1} overflow="auto">
        //                 <TableContainer component={Paper}>
        //                     <Table>
        //                         <TableHead>
        //                             <TableRow>
        //                                 <TableCell>
        //                                     <Typography variant="subtitle1">
        //                                         <strong>Coupon ID</strong>
        //                                     </Typography>
        //                                 </TableCell>
        //                                 <TableCell>
        //                                     <Typography variant="subtitle1">
        //                                         <strong>Created Date</strong>
        //                                     </Typography>
        //                                 </TableCell>
        //                                 <TableCell>
        //                                     <Typography variant="subtitle1">
        //                                         <strong>Type</strong>
        //                                     </Typography>
        //                                 </TableCell>
        //                                 <TableCell>
        //                                     <Typography variant="subtitle1">
        //                                         <strong>Status</strong>
        //                                     </Typography>
        //                                 </TableCell>
        //                             </TableRow>
        //                         </TableHead>
        //                         <TableBody>
        //                             {coupons.map((coupon) => (
        //                                 <TableRow key={coupon.couponId}>
        //                                     <TableCell>
        //                                         <Typography variant="subtitle1">
        //                                             <strong>{coupon.couponId}</strong>
        //                                         </Typography>
        //                                     </TableCell>
        //                                     <TableCell>{coupon.createDate}</TableCell>
        //                                     <TableCell>{coupon.couponType}</TableCell>
        //                                     <TableCell>{coupon.couponStatus}</TableCell>
        //                                 </TableRow>
        //                             ))}
        //                         </TableBody>
        //                     </Table>
        //                 </TableContainer>
        //             </Grid>
        //         )}
        //     </Grid>
        // </>
        <>
            <Grid container>
                <Grid item sm={12} md={5} my={1}>
                    <Typography variant="h3" mb={1} ml={2}>Coupon</Typography>
                    <Typography variant="body2" color="rgba(34, 34, 34, 0.75)">
                        {/* Your coupon details here */}
                    </Typography>
                </Grid>
                {coupons.length === 0 ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} my={5}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                {windowSize === 'xs' && <NoCoupon size="xs" />}
                                {windowSize === 'sm' && <NoCoupon size="sm" />}
                                {windowSize === 'md' && <NoCoupon size="md" />}
                                {windowSize === 'lg' && <NoCoupon size="lg" />}
                                {windowSize === 'xl' && <NoCoupon size="xl" />}
                                <Typography variant="h3" my={5}>Sorry! No coupon is available</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item sm={12} md={7} my={1} overflow="auto">
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle1">
                                                <strong>Coupon ID</strong>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">
                                                <strong>Created Date</strong>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">
                                                <strong>Type</strong>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">
                                                <strong>Status</strong>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {coupons.map((coupon) => (
                                        <TableRow key={coupon.couponId}>
                                            <TableCell>
                                                <Typography variant="subtitle1">
                                                    <strong>{coupon.couponId}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{coupon.createDate}</TableCell>
                                            <TableCell>{coupon.couponType}</TableCell>
                                            <TableCell>{coupon.couponStatus}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
        </>

    );
}

export default Coupon;
