import { Checkbox, FormControlLabel, InputAdornment, InputLabel, Stack, TextField, Typography, } from "@mui/material";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import React from "react";
import ErrorIcon from "@mui/icons-material/Error";

const styles = {
  root: {
    "& div.Mui-disabled": {
      background: "#dddddd70",
    },
    "& input:disabled": {
      "-webkit-text-fill-color": "black",
      cursor: "not-allowed",
    },
  },
};
function CustomInputSideCheckbox({
  label,
  sicon, // prefix Icon
  eicon, // postfixs Icon
  change,
  placeholder,
  size = "small",
  type = "text",
  pattern,
  helperText,
  error = null,
  disabled = false,
  readOnly = false,
  maxLength = 200,
  boxProps,
  required,
  checkLabel,
  marginBottom = 3,
  marginTop = 0,
  ...props
}) {
  return (
    <Box {...boxProps} mb={marginBottom} mt={marginTop}>
      <Stack direction={{ xs: 'row', md: 'row' }} justifyContent={'space-between'} alignItems={'center'} >
        <InputLabel>
          {label}
          {required ? (
            <Typography variant="small" color="error">
              *
            </Typography>
          ) : null}
        </InputLabel>
        <FormControlLabel control={<Checkbox size="small" sx={{ padding: .5 }} />} label={checkLabel} />
      </Stack>
      <TextField
        {...props}
        disabled={disabled}
        type={type}
        size={size}
        fullWidth
        placeholder={placeholder}
        {...change}
        Box
        InputProps={{
          inputProps: { maxLength: maxLength, pattern: pattern },
          readOnly: readOnly,
          endAdornment:
            error && props?.id !== "password" && (
              <ErrorIcon color="error" />
            ) || eicon && (
              <InputAdornment position="end">{eicon}</InputAdornment>
            ),
          startAdornment: sicon ? (
            <InputAdornment position="start">{sicon}</InputAdornment>
          ) : null,
        }}
        // {...(error && { error: true, helperText: error })}
        {...(error && {
          error: true,
          //   helperText: error 
          helperText: (
            <Typography variant="body2" color="error" style={{ fontSize: 14 }}>
              {error}
            </Typography>
          )

        })}
      />
    </Box>
  );
}
export default withStyles(styles)(CustomInputSideCheckbox);
