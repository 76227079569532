import { Box, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import { KSCloudUploadIcon, KSDocumentSearchIcon } from '../../../../../icons/custome-icon'
import React, { useState } from 'react'
import DocumentStatus from './document-status'
import { downloadReportFromServer, documentPreview } from '../../../../../helpers/file-upload-download'
import { API } from '../../../../../services/api-config'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../../../../recoil/profile-atom'
import { ROLE_LIST, UPDATE } from '../../../../../constants/constatnt-variable'
import OverriddeReasonForm from './override-document/Overridde-reason-form'
import { DialogCommonHooks, PreviewPDFModal } from '../../../../../helpers/modal/modal'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import FilePreview from '../../../../broker/leads/file-preview'
import CustomTooltip from '../../../../../helpers/custom-tooltip'
import { kstheme } from "../../../../../theme/theme"
export default function DocumentStatusCard(
    { control,
        status,
        title = null,
        fileName,
        info,
        docIc,
        uploadTime,
        fileUpload,
        isReview,
        isOverridden,
        validStatus,
        overrideReason,
        setUpdateState,
        remark,
        isManualReview,
        skipSegment,
        ...props }
) {


    const downloadDocument = () => {
        downloadReportFromServer(API.proxyDocumentDownload, {
            id: docIc,
            serviceType: 'guided'
        })
    }

    const previewDocument = () => {



        const fileUrl = documentPreview(API.proxyDocumentDownload, {
            id: docIc,
            serviceType: 'guided'
        }).then((response) => {



            < FilePreview

                fileUrl={fileUrl}
                downloadPdf={downloadDocument}
            />

        })

    }
    let profileInfo = useRecoilValue(profileState);
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const overrideReasonOnSubmit = async (data) => {
        let apiCallData = {
            id: docIc,
            overrideReason: data.overrideReason
        }

        await postApiCallDynamicWithOutReturn({
            data: apiCallData,
            path: API.guidedDocumentOverride,

        });
        setUpdateState((prev) => !prev)
        handleClose();

    };

    const overrideValidInvalidStatus = async (status) => {
        let apiCallData = {
            id: docIc,
            validStatus: status
        }

        await postApiCallDynamicWithOutReturn({
            data: apiCallData,
            path: API.guidedDocumentOverride,
            header: UPDATE,
        });
        setUpdateState((prev) => !prev)
        handleClose();

    }

    return (
        <>
            <Box my={2} p={{ xs: 1, md: 2, lg: 3 }} border={1} borderColor={'#11111115'} borderRadius={1}>
                <Grid container justifyContent={'space-between'} spacing={3}>


                    <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography my={0.5} variant='h6'>{title}</Typography>
                        {info && info.map((item) => {

                            return (
                                <Typography color={"#222222BF"} key={item.label} variant='body1'>
                                    {item.label}: {item.value}
                                </Typography>
                            );
                        })}
                    </Grid>



                    <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
                        <DocumentStatus matchStatus={status} tremarks={remark} />
                        {
                            isOverridden ?
                                (
                                    <Stack direction={'row'} gap={1} alignSelf={'center'} justifyContent={'center'}>
                                        <KSDocumentSearchIcon />
                                        <Typography color={'error.main'}>{`Overridden`}</Typography>
                                    </Stack>

                                )
                                :
                                !(status == "Matched") ? < Stack direction={'row'} gap={1} alignSelf={'center'} justifyContent={'center'}>
                                    <KSDocumentSearchIcon />
                                    <CustomTooltip title="Are you certain that the document you uploaded is correct? Please add your comment here." arrow>
                                        <Typography
                                            sx={{ cursor: 'pointer' }}
                                            onClick={isReview ? "" : handleModalOpen}
                                            color={'error.main'}
                                        >
                                            {status === null && isManualReview ? `Optional note` : `Override`}
                                        </Typography>
                                    </CustomTooltip>
                                </Stack> : null
                        }
                        <DocumentStatus matchStatus={validStatus} tremarks={remark} />
                        <Typography mt={3}
                            textAlign={'center'}
                            display={(status == "Matched" && validStatus == "Valid") ? "none" : ""}
                            sx={{ cursor: profileInfo.role == ROLE_LIST.borrower ? `` : `pointer` }}
                            color={'A5.main'}
                            onClick={isReview ? null : profileInfo.role !== ROLE_LIST.borrower ? handleModalOpen : null}
                        >
                            {profileInfo.role == ROLE_LIST.borrower ? `` : `Manual Review`}
                        </Typography>
                    </Grid>

                    {/* Documents file status */}
                    <Grid container direction={'column'} justifyContent={'space-between'} item xs={12} md={4} textAlign={{ xs: 'center', md: 'right' }}>
                        <Grid item>
                            <Stack direction={'column'} gap={1}>
                                <CustomTooltip title="If you want to download the document please clicks on document name.">
                                    <Typography sx={{ cursor: 'pointer' }} onClick={downloadDocument} variant='body1' color={"A5.main"}>
                                        {isMobile ? (fileName.length > 30 ? `${fileName.slice(0, 20)}...` : fileName) :
                                            (fileName.length > 50 ? `${fileName.slice(0, 60)}...` : fileName)}

                                    </Typography>
                                </CustomTooltip>
                                <Stack display={isReview ? 'none' : null} direction={'row-reverse'} gap={1} alignSelf={{ xs: 'center', md: 'auto' }}>
                                    <IconButton color='primary' component="label">
                                        <input id={docIc} onChange={fileUpload} hidden
                                            // accept="image/*"
                                            type="file" />
                                        <KSCloudUploadIcon />
                                    </IconButton>
                                    <IconButton color='primary' onClick={previewDocument}>
                                        <KSDocumentSearchIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Typography color={'A5.main'}>{`Uploaded on: ${uploadTime}`}</Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Box >
            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={status === null && isManualReview ? `Optional note` : `Override`}
                body={
                    <OverriddeReasonForm
                        overrideReasonOnSubmit={overrideReasonOnSubmit}
                        overrideValidInvalidStatus={overrideValidInvalidStatus}
                        overrideReason={overrideReason}
                        handleClose={handleClose}
                        role={profileInfo.role}
                        fileName={fileName}
                    />
                }
            />
        </>


    )
}
