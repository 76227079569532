import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

const ConfirmationDialogMsg = ({ open, onClose, title, message, width, height }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth={false} maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ width: width, height: height }}>
                <DialogContentText marginTop={3}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialogMsg;


