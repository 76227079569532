import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CustomLargeLabel from '../../../../../helpers/large-label'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { CONSENT_STATUS, LOAN_CO_BORROWER_STATUS, UPDATE } from '../../../../../constants/constatnt-variable'
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon'
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal'
import { RealStateForm } from './real-estate-form'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import useInput from '../../../../../helpers/use-input'
import { getApiCallDynamic } from "../../../../../services/api-service";

export default function LoanRealEstate() {
    const location = useLocation();
    const route = useNavigate();
    const [value, setValue] = useState(0);

    let [formValue, setFormValue] = useRecoilState(formState)






    const [hasNullFields, setHasNullFields] = useState(false);
    const [nullFields, setNullFields] = useState([]);

    let isSubjectProperty;

    let [realEstateList, setRealEstateList] = useState([])




    useEffect(() => {
        getApiCallDynamic({
            path: API.formData,
            param: { formId: formValue.id },
        }).then((res) => {
            if (res?.status === 200) {

                setRealEstateList(res.data?.realEstate?.page)
            } else {
                // navigatetoFromPage("/");
            }
            // setSpinner(false);
        });

    }, []);



    const [realEstateStatus] = useInput({
        data: realEstateList?.length ? 'yes' : 'no',
        status: true,
        initial: ""
    });



    useEffect(() => {
        if (realEstateList.length <= 0) {
            if (realEstateStatus.value == 'yes') {
                handleClickOpen()
            }
        }

    }, [realEstateStatus.value])

    const [editState, setEditState] = useState(() => {
        return null
    })


    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(realEstateList)
            let editItem = list.find((item, i) => i == editState)
            setSecondRealState(editItem)
            setModalOpen(true)
        }
    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null;
    })



    useEffect(() => {
        if (deleteState != null) {
            realEstateList.splice(deleteState, 1)
            setRealEstateList([...realEstateList])
            setDeleteState(null)
        }
    }, [deleteState])



    const realEstateColumn = useMemo(
        () => [
            {
                accessorKey: "addressLine1",
                header: "Address",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {row.original["addressLine1"]}
                        </Typography><br />

                        <Typography color={'#F38F19'} variant='body2'>
                            {row.original["addressLine1"] === formValue.loanDetails.page.addressLine1 &&

                                row.original.realEstateDerivedFrom === "SUBJECT_PROPERTY" &&
                                formValue?.loanDetails?.page?.buyingOrRefinancing === 'Refinancing' &&
                                (
                                    <Typography>
                                        {`Subject Property `}
                                    </Typography>
                                )}
                        </Typography>
                    </>
                ),
            },

            {
                accessorKey: "propertyType",
                header: "Property type",
                enableColumnActions: false,
            },
            {
                accessorKey: "city",
                header: "City",
                enableColumnActions: false,
            },
        ],
        []
    );
    //
    const [modalOpen, setModalOpen] = useState(false);
    const [secondRealState, setSecondRealState] = useState();

    const handleClickOpen = () => {
        setSecondRealState([])
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
    };

    const handleCloseDelete = (event, row) => {
        if (event.target.value == 'yes') {
            setDeleteState(row.index)
        }

    }

    const realEstateSubmit = (data) => {

        if (editState != null) {
            realEstateList[editState] = {
                ...realEstateList[editState],
                ...data
            }
            setEditState(null)
        } else {
            realEstateList.push(data);
        }

        setRealEstateList([...realEstateList])
        handleClose()
    }

    useEffect(() => {
        setRealEstateList(realEstateList);
    }, [realEstateList.length])


    const getFieldsWithNullValues = (list, fieldsToCheck) => {
        const fieldsWithNullValues = [];

        for (const [index, item] of list.entries()) {
            for (const field of fieldsToCheck) {
                if (item[field] === null || item[field] === undefined) {
                    fieldsWithNullValues.push({ index, field });
                }
            }
        }

        return fieldsWithNullValues;
    };

    const handleSubmit = async () => {

        const fieldsToCheck = ['isHoaManaged', 'solarStatus', 'marketValue', 'propertyType', 'propertyStatus', 'propertyMortgage'
            , 'currentOccupancy', 'intendedOccupancy', 'propertyTaxes'];
        const nullFields = getFieldsWithNullValues(realEstateList, fieldsToCheck);
        if (nullFields.length > 0) {
            setHasNullFields(true);
            setNullFields(nullFields);
            return;
        }


        const apiCallData = {
            page: realEstateList,
            formId: formValue.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                realEstate: {
                    ...formValue.realEstate,
                    page: realEstateList,
                    isComplete: true,
                }
            })
            route(location.pathname.replace(/[^/]*$/, 'credit-report'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.realEstate,
                header: UPDATE,
                refresh: updateState
            }
        )

    }



    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>

                    {realEstateList.length !== 0 ?
                        <Typography variant='h3' mb={2} mt={2}>{'Real Estate'}</Typography>
                        :
                        <CustomRadioChip
                            labelComponent={<CustomLargeLabel required value={'Do you own any real estate?'} />}
                            state={realEstateStatus}
                            data={CONSENT_STATUS}

                        />}
                    {hasNullFields && (
                        <div>
                            <Typography color="error" variant="subtitle1">
                                Please edit and fill all required fields.
                            </Typography>

                        </div>
                    )}
                    {realEstateStatus.value == 'yes' || realEstateList.length > 0 ?
                        <CustomReactMaterialTable
                            staticLoad={true}
                            staticData={realEstateList}
                            columns={realEstateColumn}
                            enablePagination={false}
                            enableBottomToolbar={false}
                            enableSorting={false}
                            enableToolbarInternalActions={false}
                            renderTopToolbarCustomActions={
                                () => (
                                    <Button variant='outlined' onClick={handleClickOpen}>Add</Button>
                                )
                            }
                            enableRowActions={true}
                            renderRowActions={
                                ({ row }) => {

                                    const rowMode = row.original.realEstateDerivedFrom
                                    return (
                                        <Stack direction={'row'}>
                                            <IconButton onClick={() => { setEditState(row.index) }}>
                                                <KSPencilAltIcon />
                                            </IconButton>
                                            {rowMode !== 'SUBJECT_PROPERTY' ?
                                                <ConfirmationModel
                                                    body={<IconButton
                                                        id={row.index} onClick={() => {

                                                        }}>
                                                        <KSTrashIcon />
                                                    </IconButton>}
                                                    message={`Are you sure want remove the real estate ${row?.original?.addressLine1}...?`}
                                                    title={`Delete Additional Address`}
                                                    handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                                />
                                                : null}
                                        </Stack>
                                    )
                                }
                            }
                        /> :
                        null
                    }


                </Box>
                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../gift-grants'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>

                        <Link onClick={handleSubmit}>
                            <Button endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Link>

                    </Stack>
                </Box>
            </Stack>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Real estate`}
                body={
                    <RealStateForm
                        onSubmit={realEstateSubmit}
                        editItem={secondRealState}
                        handleClose={handleClose}
                        hasPartner={formValue.hasPartner}
                        isSubjectProperty={isSubjectProperty}
                    />
                }
            />
        </>
    )
}