import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Grid,
    Divider
} from "@mui/material";
import Logo from "../../../icons/cliqloanblack.png"

const Invoice = ({ invoiceDetails }) => {
    return (
        <Box
            width={'625px'}
            borderTop={'7px solid #2F80ED'}
        // sx={{
        //     border: '1px solid #DBDBDB',
        //     backgroundColor: '#FAFAFA',
        //     marginBottom: '25px',
        //     padding: 2,
        // }}
        >
            {/* First box - Left side */}


            {/* Invoice No */}

            {/* Separator line */}
            {/* <Divider
                sx={{
                    marginY: 2,
                }}
            /> */}

            {/* Second box */}
            {/* Left side */}
            <TableContainer style={{ backgroundColor: '#F8FBFC' }} component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ verticalAlign: 'top' }}>
                                {/* Replace 'Logo' with your actual logo */}
                                <a href="/" style={{ textDecoration: 'none' }}>
                                    <img src={Logo} className="imglogo" alt="Logo" height={19} width={111} />
                                </a>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <span style={{ fontWeight: '700' }}>Invoice No:</span> {invoiceDetails.invoiceNumber} <br />
                                <span style={{ fontWeight: '700' }}>Invoice Date:</span> {invoiceDetails.invoiceDate}<br />
                                <span style={{ fontWeight: '700' }}>Billing Period:</span> {`${invoiceDetails.billPeriodFrom} to ${invoiceDetails.billPeriodTo}`}
                            </TableCell>
                        </TableRow>


                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                To <br />
                                {invoiceDetails.toName} <br />
                                {invoiceDetails.toAddress}

                            </TableCell>

                            <TableCell style={{ textAlign: 'right' }}>
                                From <br />

                                {invoiceDetails.fromName} <br />
                                {invoiceDetails.fromAddress} <br />
                            </TableCell>


                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }} style={{ paddingLeft: '50px', backgroundColor: 'white' }}>Bill name</TableCell>
                            <TableCell style={{ backgroundColor: 'white' }}>{invoiceDetails.toName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Type</TableCell>
                            <TableCell style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>One Time</TableCell>
                        </TableRow>
                        <TableRow style={{ backgroundColor: 'white' }}>
                            <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px', margin: 0 }}>Active users</TableCell>
                            <TableCell style={{ backgroundColor: 'white' }}></TableCell>
                        </TableRow>
                        {invoiceDetails.invoiceItem.some((item) => item.itemType === 'Billing Determinant') && (
                            <>
                                <TableRow ml={3} style={{ backgroundColor: 'white', paddingLeft: '59px', margin: 0, paddingTop: '10px' }}>
                                    <TableCell>
                                        {invoiceDetails.invoiceItem &&
                                            invoiceDetails.invoiceItem
                                                .filter((item) => item.itemType === 'Billing Determinant')
                                                .map((item) => (
                                                    <div key={item.index} style={{ height: '35px', width: '100%', borderBottom: 0, padding: '5px', backgroundColor: 'white', fontWeight: 'bold' }} >
                                                        <li style={{ paddingLeft: '50px' }}> {item.itemLabel}</li> <br />
                                                    </div>
                                                ))}
                                    </TableCell>
                                    <TableCell>
                                        {invoiceDetails.invoiceItem &&
                                            invoiceDetails.invoiceItem
                                                .filter((item) => item.itemType === 'Billing Determinant')
                                                .map((item) => (
                                                    <div key={item.index} style={{ height: '35px', display: 'flex', flexDirection: 'row', textAlign: 'right', justifyContent: 'end', paddingRight: '50px' }}>

                                                        <div style={{ height: '35px', borderBottom: 0, padding: '5px', backgroundColor: 'white' }}>
                                                            {item.count} * {item.rate}<br />

                                                        </div>
                                                        <div style={{ height: '35px', borderBottom: 0, padding: '5px', backgroundColor: 'white' }}>
                                                            =<br />

                                                        </div>
                                                        <div style={{ height: '35px', borderBottom: 0, padding: '5px', backgroundColor: 'white' }}>
                                                            {item.count * item.rate}<br />

                                                        </div>
                                                    </div>
                                                ))}
                                    </TableCell>
                                </TableRow>

                            </>
                        )}
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Gross Amount</TableCell>
                            <TableCell style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>{invoiceDetails.grossTotal}</TableCell>
                        </TableRow>
                        {invoiceDetails.invoiceItem.some((item) => item.itemType === 'Coupan') && (
                            <>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Coupons</TableCell>
                                </TableRow>

                                <TableRow>
                                    <Box ml={3}>
                                        {invoiceDetails.invoiceItem &&
                                            invoiceDetails.invoiceItem
                                                .filter((item) => item.itemType === 'Coupan')
                                                .map((item) => (
                                                    <TableRow key={item.invoiceItemId}>

                                                        <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white' }}>
                                                            {item.itemLabel} <br />

                                                        </TableCell>

                                                        <TableCell style={{ backgroundColor: 'white' }}>
                                                            {item.count} * {item.rate}<br />

                                                        </TableCell>
                                                        <TableCell style={{ backgroundColor: 'white' }}>
                                                            =<br />

                                                        </TableCell>
                                                        <TableCell style={{ backgroundColor: 'white' }}>
                                                            {item.count * item.rate}<br />

                                                        </TableCell>

                                                    </TableRow>
                                                ))}

                                    </Box>
                                </TableRow>
                            </>
                        )}
                        {invoiceDetails.invoiceItem.some((item) => item.itemType === 'Discount') && (
                            <>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Discounts</TableCell>
                                    <TableCell style={{ backgroundColor: 'white' }}></TableCell>
                                </TableRow>

                                <TableRow ml={3} style={{ backgroundColor: 'white', paddingLeft: '59px', margin: 0 }}>
                                    <TableCell>
                                        {invoiceDetails.invoiceItem &&
                                            invoiceDetails.invoiceItem
                                                .filter((item) => item.itemType === 'Discount')
                                                .map((item) => (
                                                    <div key={item.invoiceItemId}>
                                                        <div style={{ backgroundColor: 'white', paddingLeft: '50px' }} > {item.itemLabel}</div>

                                                    </div>

                                                ))}
                                    </TableCell>
                                    <TableCell>
                                        {invoiceDetails.invoiceItem &&
                                            invoiceDetails.invoiceItem
                                                .filter((item) => item.itemType === 'Discount')
                                                .map((item) => (
                                                    <div key={item.invoiceItemId}>
                                                        <div style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }} >  {item.amount}</div>
                                                    </div>
                                                ))}
                                    </TableCell>
                                </TableRow>

                            </>
                        )}
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Tax</TableCell>
                            <TableCell style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>{invoiceDetails.tax}</TableCell>
                        </TableRow>

                        {/* <Box sx={{ border: '1px solid #DBDBDB', backgroundColor: '#FAFAFA', marginBottom: '20px' }} >
                        </Box> */}

                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', paddingLeft: '50px' }}>Total payable amount</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }} style={{ backgroundColor: 'white', textAlign: 'right', paddingRight: '50px' }}>$ {invoiceDetails.netTotal}</TableCell>
                        </TableRow>


                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>

                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Total payable amount</TableCell>
                                <TableCell>$ {invoiceDetails.netTotal}</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box> */}
        </Box>
    );
};

export default Invoice;
