import { API } from "../../services/api-config";
import { getApiCallDynamic, getApiCallDynamicWithHeader } from "../../services/api-service";

export const fetchBorrowerDetails = async (taskCode) => {




    try {
        const res = await getApiCallDynamic({
            path: API.getThirdPartyTask,
            param: { thirdPartyTaskCode: taskCode },
            //  token: accessCode
        });

        if (res?.status === 200) {
            console.error('Ftech data :', res);
            //setThirdPartyTaskDetails(res.data);
        } else {
            console.error('Unexpected response status:', res);
        }

        return res?.data;
    } catch (error) {
        console.error('Error fetching third-party details:', error);
    }
};



export const getTaskStatusLabel = (status) => {
    switch (status) {
        case "COMPLETED":
            return "Completed";
        case "RESOLVED":
            return "Resolved";
        case "CREATED":
        case "INCOMPLETE":
        case "PARTIALCOMPLETE":
        case "RESUMELATER":
            return "Active";
        default:
            return status;
    }
};


export const calculateTaskCounts = (data) => {

    let allTasksCount = data.length;
    let activeTasksCount = 0;
    let completedTasksCount = 0;
    let resolvedTaskCount = 0;
    let highPriorityCount = 0;
    let lowPriorityCount = 0;


    data.forEach(task => {

        if (task.taskStatus === 'COMPLETED') {
            completedTasksCount++;
        } else if (task.taskStatus === 'RESOLVED') {
            resolvedTaskCount++;
        } else {
            activeTasksCount++;
        }


        if (task.priority === 'High') {
            highPriorityCount++;
        } else if (task.priority === 'Low') {
            lowPriorityCount++;
        }
    });


    return [
        { name: "All", count: allTasksCount },
        { name: "Active", count: activeTasksCount },
        { name: "Completed", count: completedTasksCount },
        { name: "High", count: highPriorityCount },
        { name: "Low", count: lowPriorityCount },
        { name: 'Resolved', count: resolvedTaskCount }
    ];
};



