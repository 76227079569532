
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Box,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';

const MultiSelectNotAllDropDown = ({ field, options, errors, label, required, labelRightComponent, labelComponent }) => {
    return (
        <Box mb={3} mt={1}>
            {labelComponent ? labelComponent :
                <Stack direction={'row'} justifyContent={'space-between'} >
                    <InputLabel>
                        {label}
                        {required ?
                            <Typography component={'span'} color={'error.main'} >*</Typography>
                            : null
                        }
                    </InputLabel>
                    <InputLabel>
                        {labelRightComponent}
                    </InputLabel>
                </Stack>
            }
            <FormControl fullWidth error={!!errors}>
                {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
                <Select
                    {...field}
                    multiple
                    displayEmpty
                    // input={<OutlinedInput label={label} />}
                    renderValue={(selected) => {
                        // Check if nothing is selected
                        if (!selected || selected.length === 0) {
                            return <em>Select Option</em>;
                        }
                        return selected.join(', '); // Show selected options
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 224,
                                width: 250,
                            },
                        },
                    }}
                    onChange={(event) => {
                        const value = event.target.value;
                        field.onChange(value); // Handle the change in selected value
                    }}
                    value={field.value || []} // Ensure value is always an array
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default MultiSelectNotAllDropDown;
