import { ContentCutOutlined } from "@mui/icons-material";
import { API } from "../services/api-config";
import { docPreview, documentPreview, downloadReportFromServer } from "./file-upload-download";

export const previewDocument = (row) => {




    const storageKey = row?.storageKey;
    const fileName = row?.fileName;
    // const apiUrl = API.downloadDoc
    let apiUrl

    if (row?.docType === 'LocalUpload') {


        // not working
        documentPreview(API.proxyDocumentDownload, {
            id: row?.docId || row?.storageDocId,
            serviceType: "Loan",
        });



    } else {

        apiUrl = API.downloadDoc
        if (storageKey && fileName) {


            docPreview(apiUrl, { storageKey, fileName })
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        }

    }


};

export const previewDocumentResolved = (row) => {




    const storageKey = row?.resolvedDocStorageKey;
    const fileName = row?.resolvedDocFileName;
    const id = row?.resolvedDocId
    const apiUrl = API.downloadDoc

    if (storageKey && fileName) {

        docPreview(apiUrl, { storageKey, fileName })
            .then((url) => {
                console.log('Preview URL:', url);
            })
            .catch((error) => {
                console.error('Error previewing the file:', error);
            });
    }


};


export const previewLocalTaskDocument = (row) => {


    const storageKey = row?.storageKey;
    const fileName = row?.fileName;
    const apiUrl = API.downloadDoc

    if (storageKey && fileName) {

        docPreview(apiUrl, { storageKey, fileName })
            .then((url) => {
                console.log('Preview URL:', url);
            })
            .catch((error) => {
                console.error('Error previewing the file:', error);
            });
    }
};