import React from 'react'
import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, Link as MLink, Stack, Typography } from '@mui/material'
import CustomRadioChip from '../../../../../../helpers/radio-chip'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { MILITARY_SERVICE } from '../../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../../helpers/custom-input'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomDropDown from '../../../../../../helpers/dropdown/custom-dropdown'
import { MilitaryServiceValidation } from '../../../../../../constants/validation-rules'

export const MilitaryServiceForm = ({ data, onSubmit, partnerInfoPath }) => {

    const route = useNavigate()
    const location = useLocation();



    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            statusOfMiliteryService: data?.statusOfMiliteryService,
            militaryService: data?.militaryService === 'Select' ? null : data?.militaryService,
            expireDate: data?.expireDate,
        }
    })


    const { statusOfMiliteryService, militaryService = null } = useWatch({ control })
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} minHeight={'calc(100vh - 200px)'} overflow={'auto'}>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb"
                            separator={<NavigateNext fontSize="small" />}
                        >

                            <MLink fontWeight={700} underline="hover" color="inherit" href="#">
                                {`${partnerInfoPath == "partner-info" ? "Co-Borrower" : "Borrower"} Info`}
                            </MLink>
                            <Typography color="primary.main" fontWeight={700}>Military services</Typography>
                        </Breadcrumbs>
                        <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                            <Typography variant='h3' display={'contents'}>Have you or your spouse ever been part of United State Armed Force?</Typography>
                            <Typography component={"span"} color={"error.main"} ml={1}>
                                *
                            </Typography>
                            <Controller
                                name='statusOfMiliteryService'
                                control={control}
                                rules={MilitaryServiceValidation.statusOfMiliteryService}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        state={statusOfMiliteryService}
                                        data={[
                                            { id: 'yes', value: "Yes", label: "Yes" },
                                            { id: 'no', value: "No", label: "No" },
                                        ]}
                                        error={errors?.statusOfMiliteryService?.message}
                                    />
                                )}
                            />
                            {statusOfMiliteryService == 'Yes' ?
                                <>
                                    {console.log(control, militaryService)}
                                    <Controller
                                        name='militaryService'
                                        control={control}
                                        rules={MilitaryServiceValidation.serviceStatus}
                                        render={({ field }) => (
                                            <CustomDropDown
                                                field={field}
                                                required
                                                label={`Tell us about your Service status`}
                                                defaultValue={control._defaultValues.militaryService}
                                                option={MILITARY_SERVICE}
                                                error={errors?.militaryService?.message}
                                            />
                                        )}
                                    />

                                    {militaryService == 'Currently serving as active duty' ?
                                        <Controller
                                            name='expireDate'
                                            control={control}
                                            rules={MilitaryServiceValidation.expireDate}
                                            render={({ field }) => (
                                                <CustomInput {...field} type={'date'} label={'Expiration Date'} required error={errors?.expireDate?.message} />
                                            )}
                                        />
                                        : null

                                    }

                                </>
                                : null
                            }
                        </Box>
                    </Box>

                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button startIcon={<ArrowBackIos />} onClick={() => { route(location.pathname.replace(/[^/]*$/, 'additional-income')) }}>
                                Previous
                            </Button>
                            <Button type='submit' endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </form>
        </>
    )
}