export const parseNumericValue = (value) => {

    const parsedValue = value.replace(/[^\d.]/g, '');


    const decimalCount = (parsedValue.match(/\./g) || []).length;
    let result = parsedValue;
    if (decimalCount > 1) {
        const decimalIndex = parsedValue.lastIndexOf('.');
        result = parsedValue.slice(0, decimalIndex) + parsedValue.slice(decimalIndex + 1);
    }


    const decimalIndex = result.indexOf('.');
    if (decimalIndex !== -1) {
        const integerPart = result.slice(0, decimalIndex);
        const fractionalPart = result.slice(decimalIndex + 1, decimalIndex + 3); // Two digits after decimal
        result = `${integerPart}.${fractionalPart}`;
    }

    return result;
}

// Function to format a numeric value
export const formatNumericValue = (value) => {
    if (value == null) {
        return '';
    }

    const strValue = value.toString();
    const decimalIndex = strValue.indexOf('.');
    if (decimalIndex !== -1) {
        const integerPart = strValue.slice(0, decimalIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}${strValue.slice(decimalIndex)}`;
    } else {
        return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}


// export const validateNumericValue = (value) => {
//     if (!value || isNaN(parseFloat(value))) {
//         return ERROR_MESSAGE.required;
//     }
//     return true;
// }

// Function to parse a numeric value
// export const parseNumericValue = (value) => {

//     const parsedValue = value.replace(/[^\d.]/g, '');

//     const decimalCount = (parsedValue.match(/\./g) || []).length;
//     if (decimalCount > 1) {
//         const decimalIndex = parsedValue.lastIndexOf('.');
//         return parsedValue.slice(0, decimalIndex) + parsedValue.slice(decimalIndex + 1);
//     }
//     return parsedValue;
// }
// // Function to format a numeric value

// export const formatNumericValue = (value) => {

//     if (value == null) {
//         return '';
//     }


//     const strValue = value.toString();


//     const decimalIndex = strValue.indexOf('.');
//     if (decimalIndex !== -1) {

//         const integerPart = strValue.slice(0, decimalIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//         return `${integerPart}${strValue.slice(decimalIndex)}`;
//     } else {

//         return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     }
// }





// Function to format a numeric value with thousand separators
// export const formatNumericValue = (value) => {
//     if (value === 0) return '0';
//     if (!value) return '';
//     return parseFloat(value).toLocaleString('en-US');
// }

// // Function to parse a numeric value
// export const parseNumericValue = (value) => {
//     const parsedValue = value.replace(/[^\d.]/g, '');
//     return parseFloat(parsedValue);
// }


// Function to format a numeric value with thousand separators and up to three decimal points
export const formatNumericValueDecimal = (value) => {
    if (!value) return '';
    const formattedValue = parseFloat(value).toFixed(3);
    return formattedValue.toLocaleString('en-US');
}

// Function to parse a numeric value with up to three decimal points
export const parseNumericValueDecimal = (value) => {
    const parsedValue = value.replace(/[^\d.]/g, '');
    return parseFloat(parsedValue).toFixed(3);
}

