// import { TabContext, TabList, TabPanel } from "@mui/lab";
// import {
//   Box,
//   Grid,
//   Stack,
//   Tab,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useContext, useEffect, useState } from "react";
// import LoanSummaryDetailsLayout from "./loan-summary-details/loan-summary-details-realtors";
// import LoanSummaryLoanStatistics from "./loan-summary-statistices/loan-summary-loan-statistics";
// import LoanSummaryTaskBody from "./loan-summary-tasks/loan-summary-task-body";
// import { getApiCallDynamic } from "../../../services/api-service";
// import { API } from "../../../services/api-config";
// import { useParams } from "react-router-dom";
// import MainLoyOut from "../../main-layout";
// import LoanSummaryMiles from "./loan-summary-miles/loan-summary-miles";
// import { InfoOutlined } from "@mui/icons-material";
// //import { LoanSummaryParentData } from "./loan-summary-visiblity";

// import ThirdPartyTaskBody from "./loan-summary-tasks/third-part-task-body";
// import BorrowerTaskBody from "./loan-summary-tasks/borrower-task-body";
// import { TaskContext } from "../taskContext/TaskContext";
// import TaskProvider from "../taskContext/TaskProvider";
// import { profileState } from "../../../recoil/profile-atom";
// import { useRecoilValue } from "recoil";
// import RealtorDocumentView from "../../../pages/realtors/realtor-doc-view";
// import LoanSummaryMilesRealtors from "./loan-summary-miles/loan-summary-milesrealtors";

// export default function LoanSummaryLayoutRealtors({ realtorDetails, realtorDocuments,
//   realtorTaskCount,
//   accessCode,
//   taskCode


// }) {

//   //let profileInfo = useRecoilValue(profileState);




//   // Variables and functions
//   const { id } = useParams();
//   //const { visiblity } = useContext(LoanSummaryParentData);
//   // Use State Start
//   // Data Set Start
//   const [mainAPIData, setMainAPIData] = useState([]);
//   const [borrowerListsData, setBorrowerListsData] = useState([]);

//   // Data Set End

//   const [borrowerFormID, setborrowerFormID] = useState(null);
//   const [borrowerTabValue, setBorrowerTabValue] = useState(null);
//   const [realtorData, setRealtorData] = useState(realtorDocuments)

//   const [typeMloMlp, setTypeMloMlp] = useState(null);

//   const [taskTabCount, setTaskTabCount] = useState([]);
//   const [taskTabValue, setTaskTabValue] = useState(null);
//   const [tasksLists, setTasksLists] = useState({});

//   const [thirdPartyTasksLists, setThirdPartyTasksLists] = useState({});
//   const [borrowerTasksLists, setBorrowerTasksLists] = useState({});

//   // Use State End



//   const [info, setInfo] = useState({})

//   // Main API Start
//   // API
//   const mainAPICall = () => {



//     setMainAPIData(realtorDetails);
//     setBorrowerListsData(realtorDetails.formInfos);
//     setborrowerFormID(realtorDetails.formInfos[0].formId);
//     setBorrowerTabValue(realtorDetails.formInfos[0].formId);

//     setRealtorData(realtorDocuments)

//     const { mloInfo, borrowerInfos, loanSummary } = realtorDetails || {};
//     setInfo({
//       mloInfo,
//       borrowerInfos,
//       loanSummary
//     });



//   };



//   // Reloader
//   const mainAPIReloader = () => {
//     // mainAPICall();
//   };
//   // Main API End

//   // Borrower tab function Start
//   const borrowerTabHandleChange = (event, newValue) => {
//     setBorrowerTabValue(newValue);
//     setborrowerFormID(newValue);
//   };

//   // Conditions Start
//   let borrowerTypeShort = (e) => {
//     switch (e) {
//       case "Primary":
//         return "(PB)";

//       case "Co-borrower":
//         return "(CB)";

//       default:
//         return "";
//     }
//   };
//   // Conditions End
//   // Borrower tab function End

//   // Task Related Code Start
//   // Tab value
//   const taskTabHandleChange = (event, newValue) => {
//     setTaskTabValue(newValue);
//   };

//   // API Start
//   // Task Start
//   const taskTabCounter = (formId) => {
//     getApiCallDynamic({
//       path: API.taskTabCount,
//       param: { formId: formId },
//     }).then((res) => {
//       if (res?.status === 200) {
//         setTaskTabCount(res?.data);
//         setTaskTabValue(res?.data[0]?.name);
//       }
//     });
//   };

//   // Tasks Lists Body
//   const taskListsData = (formId, status) => {
//     getApiCallDynamic({
//       path: API.taskLists,
//       param: { formId: formId, status: status },
//     }).then((res) => {
//       if (res?.status === 200) {
//         setTasksLists(res?.data);
//       }
//     });
//   };
//   // Task End


//   // Third party Task Lists


//   // Borrower Task Lists




//   const getLoanStateDate = () => {
//     let date = ''
//     mainAPIData.miles.forEach((item) => {
//       if (item.mileName === mainAPIData.loanState) {
//         date = item.mileDate
//       }
//     })
//     return date
//   }




//   // Task Related Code End

//   // MLO MLP API


//   // API End

//   // Use Effect Start
//   // 1st load
//   useEffect(() => {
//     mainAPICall();
//   }, []);

//   // Borrower Tab
//   useEffect(() => {
//     if (borrowerTabValue !== null) {
//       // taskListsData(borrowerFormID, taskTabValue);
//       //  taskTabCounter(borrowerTabValue);
//       // thirdPartyTaskListsData(borrowerFormID, taskTabValue)
//       //  borrowerTaskListsData(borrowerFormID, taskTabValue)
//     }
//   }, [borrowerTabValue]);

//   // Task
//   useEffect(() => {
//     if (taskTabValue !== null) {
//       taskListsData(borrowerFormID, taskTabValue);
//       //thirdPartyTaskListsData(borrowerFormID, taskTabValue)
//       // borrowerTaskListsData(borrowerFormID, taskTabValue)
//     }
//   }, [taskTabValue]);

//   // Change MLO MLP

//   // Use Effect End

//   // State to manage the currently selected tab
//   const [selectedTab, setSelectedTab] = useState(0);

//   // Function to handle tab changes
//   const handleChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };
//   //const [selectedTab, setSelectedTab] = React.useState('1');

//   const handleTabChange = (event, newTab) => {
//     setSelectedTab(newTab);
//   };
//   return (
//     <MainLoyOut>
//       {/* Main container and box for padding on this page */}
//       {/* Tab of loan users start */}
//       <TabContext value={borrowerTabValue}>
//         {/* Tab navigation */}
//         <Box bgcolor={"#F2F2F7"} sx={{ borderBottom: 1, borderColor: "divider", display: 'block' }}>
//           <Box padding={2}>



//             <TabList
//               onChange={borrowerTabHandleChange}
//               variant="scrollable"
//               allowScrollButtonsMobile
//               aria-label="Loan Summary Task Tab"
//             >
//               <Box>
//                 {borrowerListsData
//                   // Checking Borrower Type
//                   .sort((a) => (a.borrowerType == "Primary" ? -1 : 1))
//                   .map((borrowerData) => (
//                     <Tab
//                       key={borrowerData.formId}
//                       label={
//                         borrowerData.firstName +
//                         " " +
//                         borrowerData.lastName +
//                         " " +
//                         borrowerTypeShort(borrowerData.borrowerType)
//                       }
//                       value={borrowerData.formId}
//                     />
//                   ))}

//                 <Box ml={"auto"} my={"auto"} px={2}>
//                   <Tooltip
//                     arrow
//                     placement="left"
//                     color="A8"
//                     title={
//                       <>
//                         <Typography
//                           component={"span"}
//                           variant="body2"
//                           color={"primary.main"}
//                         >
//                           {`Note : `}
//                         </Typography>
//                         <Typography component={"span"} variant="body2">
//                           {`Here we have use few abbreviation`}
//                         </Typography>
//                         <Typography component={"span"} variant="body2">
//                           {`ie. PB (Primary Borrower) | CB (Co-Borrower here)`}
//                         </Typography>
//                       </>
//                     }
//                   >
//                     <InfoOutlined />
//                   </Tooltip>
//                 </Box>
//               </Box>
//             </TabList>
//           </Box>
//         </Box>
//         {/* Tab Content */}
//         <Box bgcolor={"#F2F2F7"}>



//           {borrowerListsData.map((borrowerData) => (

//             <TabPanel
//               key={borrowerData.formId}
//               value={borrowerData.formId}
//               sx={{ marginTop: 0, padding: 0 }}
//             >
//               <Box sx={{ paddingX: 3, paddingTop: 3, }}>
//                 <LoanSummaryDetailsLayout
//                   loanState={mainAPIData.loanState}
//                   loanStateDate={getLoanStateDate()}
//                   lockStatus={mainAPIData.loanSummary.lockStatus}
//                   lockExpirationDate={mainAPIData.loanSummary.lockExpirationDate}
//                   borrowerData={borrowerData}
//                   mloData={mainAPIData.mloInfo}
//                   mlpData={mainAPIData.mlpInfo}
//                   subjectProperty={mainAPIData.subjectPropertyAddress}

//                   loanCode={id}
//                   reload={mainAPIReloader}
//                 />

//               </Box>


//               <Box margin={3} paddingBottom={2}>

//                 <Grid container rowSpacing={1} columnSpacing={2}>
//                   <Grid item xs={12} md={5}>
//                     <Stack direction={"column"} gap={2}>
//                       <LoanSummaryLoanStatistics
//                         loanSummaryLoanStatisticsData={mainAPIData.loanSummary}
//                         statisticsReloader={mainAPIReloader}
//                       />
//                       <LoanSummaryMilesRealtors
//                         loanCode={id}
//                         loanState={mainAPIData.loanState}
//                         milesData={mainAPIData.miles}
//                         milesReloader={mainAPIReloader}
//                       />
//                     </Stack>
//                   </Grid>
//                   {/* <Grid item xs={12} md={7}>
//                   <LoanSummaryTaskBody
//                     formId={borrowerFormID}
//                     borrowerName={
//                       borrowerData.firstName + " " + borrowerData.lastName
//                     }
//                     taskTabCount={taskTabCount}
//                     tasksLists={tasksLists}
//                     taskReload={taskReload}
//                     taskTabValue={taskTabValue}
//                     taskTabHandleChange={taskTabHandleChange}
//                   />
//                 </Grid> */}

//                   {<Grid item xs={12} md={7}>
//                     <RealtorDocumentView
//                       // formId={borrowerFormID}
//                       // borrowerName={
//                       //   borrowerData.firstName + " " + borrowerData.lastName
//                       // }
//                       // taskTabCount={taskTabCount}
//                       // tasksLists={tasksLists}
//                       // taskReload={taskReload}
//                       // taskTabValue={taskTabValue}
//                       // taskTabHandleChange={taskTabHandleChange}
//                       realtorDocuments={realtorData}
//                       realtorTaskCount={realtorTaskCount}
//                       accessCode={accessCode}
//                       taskCode={taskCode}
//                     />
//                   </Grid>}



//                   <Grid item xs={12} md={7}>
//                     {/*    <TabContext value={selectedTab}>
//                     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                       <TabList onChange={handleTabChange} aria-label="custom tabs example">
//                         <Tab label="Borrower Tasks" value="1" />
//                         {(profileInfo.role === 'MLO' || profileInfo.role === 'MLO Owner'
//                           || profileInfo.role === 'Broker' || profileInfo.role === 'MLP'
//                         ) && (
//                             <Tab label="Third Party Tasks" value="2" />)}

//                       </TabList>
//                     </Box>
//                     <TabPanel value="1">

//                       <BorrowerTaskBody
//                         formId={borrowerFormID}
//                         borrowerName={
//                           borrowerData.firstName + " " + borrowerData.lastName
//                         }
//                         taskTabCount={taskTabCount}
//                         tasksLists={borrowerTasksLists}  // card lists
//                         taskReload={taskReload}
//                         taskTabValue={taskTabValue}
//                         info={info}
//                         taskTabHandleChange={taskTabHandleChange}
//                       />

//                     </TabPanel>
//                     {(profileInfo.role === 'MLO' || profileInfo.role === 'MLO Owner'
//                       || profileInfo.role === 'Broker' || profileInfo.role === 'MLP'
//                     ) && (
//                         <TabPanel value="2">
//                           <ThirdPartyTaskBody
//                             formId={borrowerFormID}
//                             borrowerName={
//                               borrowerData.firstName + " " + borrowerData.lastName
//                             }
//                             taskTabCount={taskTabCount}
//                             tasksLists={thirdPartyTasksLists} // card lists
//                             taskReload={taskReload}
//                             taskTabValue={taskTabValue}
//                             info={info}
//                             taskTabHandleChange={taskTabHandleChange}


//                           />
//                         </TabPanel>
//                       )}

//                   </TabContext> */}



//                   </Grid>
//                 </Grid>
//               </Box>
//             </TabPanel>
//           ))}
//         </Box>
//       </TabContext>

//       {/* Tab of loan users end */}
//     </MainLoyOut>
//   );
// }
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Grid,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoanSummaryDetailsLayout from "./loan-summary-details/loan-summary-details-realtors";
import LoanSummaryLoanStatistics from "./loan-summary-statistices/loan-summary-loan-statistics";
import LoanSummaryTaskBody from "./loan-summary-tasks/loan-summary-task-body";
import { getApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import { useParams } from "react-router-dom";
import MainLoyOut from "../../main-layout";
import LoanSummaryMiles from "./loan-summary-miles/loan-summary-miles";
import { InfoOutlined } from "@mui/icons-material";
//import { LoanSummaryParentData } from "./loan-summary-visiblity";
import LoanSummaryMilesRealtors from "./loan-summary-miles/loan-summary-milesrealtors";

import ThirdPartyTaskBody from "./loan-summary-tasks/third-part-task-body";
import BorrowerTaskBody from "./loan-summary-tasks/borrower-task-body";
import { TaskContext } from "../taskContext/TaskContext";
import TaskProvider from "../taskContext/TaskProvider";
import { profileState } from "../../../recoil/profile-atom";
import { useRecoilValue } from "recoil";
import RealtorDocumentView from "../../../pages/realtors/realtor-doc-view";

export default function LoanSummaryLayoutRealtors({ realtorDetails, realtorDocuments,
  realtorTaskCount,
  accessCode,
  taskCode


}) {

  //let profileInfo = useRecoilValue(profileState);




  // Variables and functions
  const { id } = useParams();
  //const { visiblity } = useContext(LoanSummaryParentData);
  // Use State Start
  // Data Set Start
  const [mainAPIData, setMainAPIData] = useState([]);
  const [borrowerListsData, setBorrowerListsData] = useState([]);

  // Data Set End

  const [borrowerFormID, setborrowerFormID] = useState(null);
  const [borrowerTabValue, setBorrowerTabValue] = useState(null);
  const [realtorData, setRealtorData] = useState(realtorDocuments)

  const [typeMloMlp, setTypeMloMlp] = useState(null);

  const [taskTabCount, setTaskTabCount] = useState([]);
  const [taskTabValue, setTaskTabValue] = useState(null);
  const [tasksLists, setTasksLists] = useState({});

  const [thirdPartyTasksLists, setThirdPartyTasksLists] = useState({});
  const [borrowerTasksLists, setBorrowerTasksLists] = useState({});

  // Use State End



  const [info, setInfo] = useState({})

  // Main API Start
  // API
  const mainAPICall = () => {



    setMainAPIData(realtorDetails);
    setBorrowerListsData(realtorDetails.formInfos);
    setborrowerFormID(realtorDetails.formInfos[0].formId);
    setBorrowerTabValue(realtorDetails.formInfos[0].formId);

    setRealtorData(realtorDocuments)

    const { mloInfo, borrowerInfos, loanSummary } = realtorDetails || {};
    setInfo({
      mloInfo,
      borrowerInfos,
      loanSummary
    });



  };



  // Reloader
  const mainAPIReloader = () => {
    // mainAPICall();
  };
  // Main API End

  // Borrower tab function Start
  const borrowerTabHandleChange = (event, newValue) => {
    setBorrowerTabValue(newValue);
    setborrowerFormID(newValue);
  };

  // Conditions Start
  let borrowerTypeShort = (e) => {
    switch (e) {
      case "Primary":
        return "(PB)";

      case "Co-borrower":
        return "(CB)";

      default:
        return "";
    }
  };
  // Conditions End
  // Borrower tab function End

  // Task Related Code Start
  // Tab value
  const taskTabHandleChange = (event, newValue) => {
    setTaskTabValue(newValue);
  };

  // API Start
  // Task Start
  const taskTabCounter = (formId) => {
    getApiCallDynamic({
      path: API.taskTabCount,
      param: { formId: formId },
    }).then((res) => {
      if (res?.status === 200) {
        setTaskTabCount(res?.data);
        setTaskTabValue(res?.data[0]?.name);
      }
    });
  };

  // Tasks Lists Body
  const taskListsData = (formId, status) => {
    getApiCallDynamic({
      path: API.taskLists,
      param: { formId: formId, status: status },
    }).then((res) => {
      if (res?.status === 200) {
        setTasksLists(res?.data);
      }
    });
  };
  // Task End


  // Third party Task Lists


  // Borrower Task Lists




  const getLoanStateDate = () => {
    let date = ''
    mainAPIData.miles.forEach((item) => {
      if (item.mileName === mainAPIData.loanState) {
        date = item.mileDate
      }
    })
    return date
  }




  // Task Related Code End

  // MLO MLP API


  // API End

  // Use Effect Start
  // 1st load
  useEffect(() => {
    mainAPICall();
  }, []);

  // Borrower Tab
  useEffect(() => {
    if (borrowerTabValue !== null) {
      // taskListsData(borrowerFormID, taskTabValue);
      //  taskTabCounter(borrowerTabValue);
      // thirdPartyTaskListsData(borrowerFormID, taskTabValue)
      //  borrowerTaskListsData(borrowerFormID, taskTabValue)
    }
  }, [borrowerTabValue]);

  // Task
  useEffect(() => {
    if (taskTabValue !== null) {
      taskListsData(borrowerFormID, taskTabValue);
      //thirdPartyTaskListsData(borrowerFormID, taskTabValue)
      // borrowerTaskListsData(borrowerFormID, taskTabValue)
    }
  }, [taskTabValue]);

  // Change MLO MLP

  // Use Effect End

  // State to manage the currently selected tab
  const [selectedTab, setSelectedTab] = useState(0);

  // Function to handle tab changes
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  //const [selectedTab, setSelectedTab] = React.useState('1');

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };
  return (
    <MainLoyOut>
      {/* Main container and box for padding on this page */}
      {/* Tab of loan users start */}
      <TabContext value={borrowerTabValue}>
        {/* Tab navigation */}
        <Box bgcolor={"#F2F2F7"} sx={{ borderBottom: 1, borderColor: "divider", display: 'block' }}>
          <Box padding={0}>



            <TabList
              onChange={borrowerTabHandleChange}
              variant="scrollable"
              allowScrollButtonsMobile
              aria-label="Loan Summary Task Tab"
            >
              {borrowerListsData
                // Checking Borrower Type
                .sort((a) => (a.borrowerType == "Primary" ? -1 : 1))
                .map((borrowerData) => (
                  <Tab
                    key={borrowerData.formId}
                    label={
                      borrowerData.firstName +
                      " " +
                      borrowerData.lastName +
                      " " +
                      borrowerTypeShort(borrowerData.borrowerType)
                    }
                    value={borrowerData.formId}
                  />
                ))}

              <Box ml={"auto"} my={"auto"} px={2}>
                <Tooltip
                  arrow
                  placement="left"
                  color="A8"
                  title={
                    <>
                      <Typography
                        component={"span"}
                        variant="body2"
                        color={"primary.main"}
                      >
                        {`Note : `}
                      </Typography>
                      <Typography component={"span"} variant="body2">
                        {`Here we have use few abbreviation`}
                      </Typography>
                      <Typography component={"span"} variant="body2">
                        {`ie. PB (Primary Borrower) | CB (Co-Borrower here)`}
                      </Typography>
                    </>
                  }
                >
                  <InfoOutlined />
                </Tooltip>
              </Box>
            </TabList>
          </Box>
        </Box>
        <Box mb={2}>

        </Box>
        {/* Tab Content */}
        <Box bgcolor={"#F2F2F7"}>



          {borrowerListsData.map((borrowerData) => (

            <TabPanel
              key={borrowerData.formId}
              value={borrowerData.formId}
              sx={{ marginTop: 0, padding: 0 }}
            >
              <Box sx={{ paddingX: 3, paddingTop: 3, }}>
                <LoanSummaryDetailsLayout
                  loanState={mainAPIData.loanState}
                  loanStateDate={getLoanStateDate()}
                  lockStatus={mainAPIData.loanSummary.lockStatus}
                  lockExpirationDate={mainAPIData.loanSummary.lockExpirationDate}
                  borrowerData={borrowerData}
                  mloData={mainAPIData.mloInfo}
                  mlpData={mainAPIData.mlpInfo}
                  subjectProperty={mainAPIData.subjectPropertyAddress}

                  loanCode={id}
                  reload={mainAPIReloader}
                />

              </Box>


              <Box margin={3} paddingBottom={2}>

                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} md={5}>
                    <Stack direction={"column"} gap={2}>
                      <LoanSummaryLoanStatistics
                        loanSummaryLoanStatisticsData={mainAPIData.loanSummary}
                        statisticsReloader={mainAPIReloader}
                      />
                      <LoanSummaryMilesRealtors
                        loanCode={id}
                        loanState={mainAPIData.loanState}
                        milesData={mainAPIData.miles}
                        milesReloader={mainAPIReloader}
                      />
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={12} md={7}>
                  <LoanSummaryTaskBody
                    formId={borrowerFormID}
                    borrowerName={
                      borrowerData.firstName + " " + borrowerData.lastName
                    }
                    taskTabCount={taskTabCount}
                    tasksLists={tasksLists}
                    taskReload={taskReload}
                    taskTabValue={taskTabValue}
                    taskTabHandleChange={taskTabHandleChange}
                  />
                </Grid> */}

                  {<Grid item xs={12} md={7}>
                    <RealtorDocumentView
                      // formId={borrowerFormID}
                      // borrowerName={
                      //   borrowerData.firstName + " " + borrowerData.lastName
                      // }
                      // taskTabCount={taskTabCount}
                      // tasksLists={tasksLists}
                      // taskReload={taskReload}
                      // taskTabValue={taskTabValue}
                      // taskTabHandleChange={taskTabHandleChange}
                      realtorDocuments={realtorData}
                      realtorTaskCount={realtorTaskCount}
                      accessCode={accessCode}
                      taskCode={taskCode}
                    />
                  </Grid>}



                  <Grid item xs={12} md={7}>
                    {/*    <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="custom tabs example">
                        <Tab label="Borrower Tasks" value="1" />
                        {(profileInfo.role === 'MLO' || profileInfo.role === 'MLO Owner'
                          || profileInfo.role === 'Broker' || profileInfo.role === 'MLP'
                        ) && (
                            <Tab label="Third Party Tasks" value="2" />)}

                      </TabList>
                    </Box>
                    <TabPanel value="1">

                      <BorrowerTaskBody
                        formId={borrowerFormID}
                        borrowerName={
                          borrowerData.firstName + " " + borrowerData.lastName
                        }
                        taskTabCount={taskTabCount}
                        tasksLists={borrowerTasksLists}  // card lists
                        taskReload={taskReload}
                        taskTabValue={taskTabValue}
                        info={info}
                        taskTabHandleChange={taskTabHandleChange}
                      />

                    </TabPanel>
                    {(profileInfo.role === 'MLO' || profileInfo.role === 'MLO Owner'
                      || profileInfo.role === 'Broker' || profileInfo.role === 'MLP'
                    ) && (
                        <TabPanel value="2">
                          <ThirdPartyTaskBody
                            formId={borrowerFormID}
                            borrowerName={
                              borrowerData.firstName + " " + borrowerData.lastName
                            }
                            taskTabCount={taskTabCount}
                            tasksLists={thirdPartyTasksLists} // card lists
                            taskReload={taskReload}
                            taskTabValue={taskTabValue}
                            info={info}
                            taskTabHandleChange={taskTabHandleChange}


                          />
                        </TabPanel>
                      )}

                  </TabContext> */}



                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          ))}
        </Box>
      </TabContext>

      {/* Tab of loan users end */}
    </MainLoyOut>
  );
}
