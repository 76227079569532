import { Box } from '@mui/material'
import React from 'react'

const AnalyticsContainer = () => {
    return (
        <>

            <Box
            // sx={{ border: '1px solid red' }}
            >
                <div>Analytics Report</div>

            </Box>

        </>

    )
}

export default AnalyticsContainer