
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { formState } from '../../../recoil/profile-atom'
import { Box, Breadcrumbs, FormHelperText, Button, Link as MLink, Stack, Typography, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import CustomReactMaterialTable from '../../../helpers/table/react-material-table'



export default function LoanAdditional() {

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]

    let [formValue, setFormValue] = useRecoilState(formState)

    const [subPage, setSubPage] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue.partnerInfo?.page?.contact?.subPage)
            :
            _.cloneDeep(formValue.borrowerInfo?.page?.contact?.subPage)

    )


    let [additionalAddresses, setAdditionalAddresses] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue.partnerInfo?.page?.contact?.subPage?.additionalAddresses)
            :
            _.cloneDeep(formValue.borrowerInfo?.page?.contact?.subPage.additionalAddresses)

    )
    const tableYear = (row) => (
        <Typography>
            {`${row.original["year"]} Years ${row.original["month"]} Months`}
        </Typography>
    )

    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "addressLine1",
                header: "Address",
                enableColumnActions: false,
            },
            {
                accessorKey: "city",
                header: "City",
                enableColumnActions: false,
            },
            {
                accessorKey: "zip",
                header: "ZIP",
                enableColumnActions: false,
            },
            {
                accessorKey: "year",
                header: "Period of stay",
                enableColumnActions: false,
                Cell: ({ row }) => tableYear(row),
            },

        ],
        []
    );


    function renderTable(additionalAddress, tableColumn) {
        return (
            <>

                <CustomReactMaterialTable
                    staticLoad={true}
                    staticData={additionalAddress}
                    columns={tableColumn}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableSorting={false}
                    enableToolbarInternalActions={false}
                    renderTopToolbarCustomActions={() => (
                        <Typography variant='h3'>Additional Addresses</Typography>
                    )}

                    enableRowActions={false}
                />
            </>
        );
    }

    return (
        <>{subPage == null ? <></>
            :
            <>

                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>

                    </Stack>
                </Box>

                <Box my={2}>{renderTable(additionalAddresses, tableColumn)}</Box>


            </>
        }
        </>
    )
}
