import { AccessTime, HighlightOff } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react'

export default function UserStatus({ status }) {


    let color, iconStyle, userStatus

    switch (status) {
        case 'Pending Invitation':
            color = '#F38F19';
            iconStyle = < AccessTime />;
            userStatus = 'Pending Invitation';
            break;

        case 'Declined':
            color = 'error.main';
            iconStyle = <HighlightOff />;
            userStatus = 'Declined';
            break;

        default:
            break;
    }

    return (
        <Stack direction={'row'} spacing={1} color={color}>
            {iconStyle}
            <Typography variant='body1'>{userStatus}</Typography>
        </Stack>
    )
}
