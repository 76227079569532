import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth/auth-context-provide";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "@mui/material/styles";
import "./font/Inter-Bold.ttf";
import "./font/Inter-SemiBold.ttf";
import "./font/Inter-Medium.ttf";
import "./font/Inter-Regular.ttf";
import "./font/DMSans-Medium.ttf";
import "./font/DMSans-Regular.ttf";
import "./font/DMSans-Bold.ttf";
import "./font/DMSans-Italic.ttf";
import { kstheme } from "./theme/theme";
import { CssBaseline } from "@mui/material";
import RecoilNexus from "recoil-nexus";

const handleWheel = (event) => {
  const tagName = event.target.tagName.toLowerCase();
  if (tagName === "input" && event.target.type === "number") {
    event.preventDefault();
  }
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <RecoilNexus />
        <AuthProvider>
          <ThemeProvider theme={kstheme}>
            <CssBaseline>
              <App />
            </CssBaseline>
          </ThemeProvider>
        </AuthProvider>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

document.addEventListener("wheel", handleWheel, { passive: false });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
