import React, { useState, useEffect } from 'react'
import { ArrowBackIos, ArrowForwardIos, AttachMoney } from '@mui/icons-material';
import { Box, Button, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import { LOAN_DECLARATIONS } from '../../../../../../constants/constatnt-variable';
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal';
import CustomRadioChip from '../../../../../../helpers/radio-chip';
import LoanDeclarationsCheck from '../../../../../../components/loan-application/loan-declaration-check';
import { Controller, useForm } from 'react-hook-form'
import useInput from '../../../../../../helpers/use-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoanDeclarationValidation } from "../../../../../../constants/validation-rules";
import { Breadcrumbs, Link as MLink } from '@mui/material';
import { NavigateNext } from '@mui/icons-material'
import { formatNumericValue, parseNumericValue } from '../../../../../../helpers/numeric-format'

import CustomDropDown from "../../../../../../helpers/dropdown/custom-dropdown";
import {
    DECLARATION_TYPE_OF_PROPERTY,
    DECLARATION_TITLE_OF_PROPERTY,
    DECLARATION_TYPE_OF_BANKRUPTCY,
} from "../../../../../../constants/constatnt-variable"
import { useWatch } from 'react-hook-form'
import CustomInput from "../../../../../../helpers/custom-input";
import { kstheme } from '../../../../../../theme/theme';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../../../recoil/profile-atom';
import CustomTooltip from '../../../../../../helpers/custom-tooltip';
import CustomRadioChipWithTooltip from '../../../../../../helpers/radio-chip-with-tooltip';



export const DeclarationForm = ({ data, onCheckSubmit, partnerInfoPath }) => {
    const route = useNavigate()
    const location = useLocation()
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
    let [formValue, setFormValue] = useRecoilState(formState)

    const [modalOpen, setModalOpen] = useState(false);

    const onSubmit = async data => {
        handleClickOpen()
    }

    const handleClickOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };



    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: {
            checkbox: data?.checkbox,

            primaryResident: data?.primaryResident,
            ownershipProperty: data?.ownershipProperty,
            affiliation: data?.affiliation,
            moneyFromAnotherParty: data?.moneyFromAnotherParty,
            loanOnAnotherProperty: data?.loanOnAnotherProperty,
            creditOnBeforeLoan: data?.creditOnBeforeLoan,
            lien: data?.lien,
            coSignerOrGuarantor: data?.coSignerOrGuarantor,
            outstandingJudgement: data?.outstandingJudgement,
            federalDept: data?.federalDept,
            partyLawsuit: data?.partyLawsuit,
            convertTitle: data?.convertTitle,
            preForeclosure: data?.preForeclosure,
            propertyForeclosed: data?.propertyForeclosed,
            text: data?.text,
            isDeclared: true,
            declarationTypeOfProperty: data?.declarationTypeOfProperty,
            declarationTitleOfProperty: data?.declarationTitleOfProperty,
            amountFromAnotherParty: data?.amountFromAnotherParty,
            declaredBankruptcy: data?.declaredBankruptcy,
            typesOfBankruptcy: data?.typesOfBankruptcy,

        }
    })

    const { declarationTitleOfProperty = null,
        declarationTypeOfProperty = null,
        primaryResident,
        amountFromAnotherParty,
        moneyFromAnotherParty,
        declaredBankruptcy,
        typesOfBankruptcy = null,
        ownershipProperty,

    } = useWatch({ control })

    const [loanOnAnotherProperty] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [creditOnBeforeLoan] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [lien] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [coSignerOrGuarantor] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [outstandingJudgement] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [federalDept] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [partyLawsuit] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [convertTitle] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [preForeclosure] = useInput({
        data: null,
        status: false,
        initial: null
    });

    const [propertyForeclosed] = useInput({
        data: null,
        status: false,
        initial: null
    });


    useEffect(() => {
        if (ownershipProperty === 'no') {
            setValue('declarationTypeOfProperty', null);
            setValue('declarationTitleOfProperty', null);
        }
    }, [ownershipProperty]);


    useEffect(() => {
        if (moneyFromAnotherParty === 'no') {
            setValue('amountFromAnotherParty', null);
        }
    }, [moneyFromAnotherParty]);

    useEffect(() => {
        if (declaredBankruptcy === 'no') {
            setValue('typesOfBankruptcy', null);
        }
    }, [declaredBankruptcy]);

    return (
        <Box px={4} py={2}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Breadcrumbs aria-label="breadcrumb"
                    separator={<NavigateNext fontSize="small" />}
                >
                    <MLink fontWeight={700} underline="hover" color="inherit" href="#">
                        {`${partnerInfoPath == "partner-info" ? "Co-Borrower" : "Borrower"} Info`}
                    </MLink>
                    <Typography color="primary.main">Declarations</Typography>
                </Breadcrumbs>
                <Stack direction={'column'} justifyContent={'space-between'} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Box maxWidth={{ xs: '100%', md: '75%' }} mt={2}>
                            <Typography variant='h3'>{`Hello ${formValue[partnerInfoPath == "partner-info" ? 'partnerInfo' : 'borrowerInfo']?.page?.personal?.subPage?.firstName} ${" "} ${formValue[partnerInfoPath == "partner-info" ? 'partnerInfo' : 'borrowerInfo']?.page?.personal?.subPage?.lastName}, Government wants us to collect this info`}</Typography>

                            <Controller
                                name='primaryResident'
                                control={control}
                                rules={LoanDeclarationValidation.primaryResident}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={'Will you occupy the property as your primary residence?'}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.primaryResident?.message}
                                    />
                                )}
                            />

                            {primaryResident == "yes" ? (
                                <Box ml={3}>
                                    <Controller
                                        name='ownershipProperty'
                                        control={control}

                                        rules={LoanDeclarationValidation.ownershipProperty}
                                        render={({ field }) => (
                                            <CustomRadioChip
                                                {...field}
                                                field={field}

                                                label={'Have you had an ownership interest in another property in the last three years?'}
                                                required
                                                data={LOAN_DECLARATIONS}
                                                error={errors?.ownershipProperty?.message}

                                            />

                                        )}
                                    />
                                </Box>) : null}



                            {primaryResident == "yes" && ownershipProperty == "yes" ? (
                                <>
                                    <Box maxWidth={{ xs: "100%" }} width={{ xs: '100%' }} ml={7}>

                                        <>
                                            <Controller
                                                name="declarationTypeOfProperty"
                                                control={control}
                                                rules={LoanDeclarationValidation.declarationTypeOfProperty}
                                                render={({ field }) => (
                                                    <CustomDropDown
                                                        {...field}
                                                        field={field}
                                                        required
                                                        limitWidth={!isMobile}
                                                        rowData={declarationTypeOfProperty}
                                                        value={declarationTypeOfProperty}
                                                        label={'What type of property did you own ?'}
                                                        state={declarationTypeOfProperty}
                                                        option={DECLARATION_TYPE_OF_PROPERTY}
                                                        error={errors?.declarationTypeOfProperty?.message}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="declarationTitleOfProperty"
                                                control={control}
                                                rules={LoanDeclarationValidation.declarationTitleOfProperty}
                                                render={({ field }) => (
                                                    <CustomDropDown
                                                        {...field}
                                                        field={field}
                                                        required
                                                        limitWidth={!isMobile}
                                                        rowData={declarationTitleOfProperty}
                                                        value={declarationTitleOfProperty}
                                                        label={'How did you hold title to the property ?'}
                                                        state={declarationTitleOfProperty}
                                                        option={DECLARATION_TITLE_OF_PROPERTY}
                                                        error={errors?.declarationTitleOfProperty?.message}
                                                    />
                                                )}
                                            />
                                        </>

                                    </Box>
                                </>
                            ) : null}





                            <Controller
                                name='affiliation'
                                control={control}
                                rules={LoanDeclarationValidation.affiliation}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`If this is a purchase transaction, do you have a family relationship 
                                    or business affiliation with the seller of the property?`}

                                        data={LOAN_DECLARATIONS}
                                        error={errors?.affiliation?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='moneyFromAnotherParty'
                                control={control}
                                rules={LoanDeclarationValidation.moneyFromAnotherParty}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Are you borrowing any money for this real estate transaction 
                                ( e.g. money for your closing cost or down payment) 
                                for obtaining any money from another party, such as the seller or realtor, 
                                that you have not disclosed on this loan application.`}

                                        data={LOAN_DECLARATIONS}
                                        error={errors?.moneyFromAnotherParty?.message}
                                    />
                                )}
                            />


                            <Box maxWidth={{ xs: '100%' }} ml={3}>
                                {moneyFromAnotherParty === "yes" && (
                                    <Controller
                                        name="amountFromAnotherParty"
                                        control={control}
                                        rules={LoanDeclarationValidation.amountFromAnotherParty}
                                        render={({ field }) => (
                                            <CustomInput
                                                {...field}
                                                type={"text"}
                                                label="What is the amount of this money?"
                                                required
                                                value={formatNumericValue(field.value)}
                                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                                error={errors?.amountFromAnotherParty?.message}
                                                sicon={<AttachMoney />}
                                            />
                                        )}
                                    />
                                )}
                            </Box>





                            <Controller
                                name='loanOnAnotherProperty'
                                control={control}
                                rules={LoanDeclarationValidation.loanOnAnotherProperty}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Have you or will you be applying for a Mortgage Loan on another 
                                property ( Not the property securing this loan) on or 
                                before closing this transaction that is not disclosed on this loan application.`}
                                        state={loanOnAnotherProperty}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.loanOnAnotherProperty?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='creditOnBeforeLoan'
                                control={control}
                                rules={LoanDeclarationValidation.creditOnBeforeLoan}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Have you or will you be applying for any new credit ( e.g.  installment loan
                                , credit card, etc.) on or before closing this loan
                                that is not disclosed in this application.`}
                                        state={creditOnBeforeLoan}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.creditOnBeforeLoan?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='lien'
                                control={control}
                                rules={LoanDeclarationValidation.lien}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Will this property be subject to a lien that could take priority or the first 
                                mortgage lien, such as a Clean Energy lien paid
                                through your property taxes ( e g. the property assessed Clean Energy program) ?`}
                                        state={lien}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.lien?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='coSignerOrGuarantor'
                                control={control}
                                rules={LoanDeclarationValidation.coSignerOrGuarantor}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Are you a co-signer or guarantor on any Dept or loan that is not disclosed on this application?`}
                                        state={coSignerOrGuarantor}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.coSignerOrGuarantor?.message}
                                        chipValue='yes'
                                    />
                                )}
                            />


                            <Controller
                                name='outstandingJudgement'
                                control={control}
                                rules={LoanDeclarationValidation.outstandingJudgement}
                                render={({ field }) => (
                                    <CustomRadioChipWithTooltip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Are there any outstanding judgement against you?`}
                                        state={outstandingJudgement}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.outstandingJudgement?.message}
                                        tooltipTitle="Select yes If you have any unpaid court orders against you otherwise select No."
                                        chipValue='yes'
                                    />
                                )}
                            />



                            <Controller
                                name='federalDept'
                                control={control}
                                rules={LoanDeclarationValidation.federalDept}
                                render={({ field }) => (
                                    <CustomRadioChipWithTooltip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Are you currently delinquent or under fault on a federal Dept?`}
                                        state={federalDept}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.federalDept?.message}
                                        tooltipTitle="Select Yes if you are currently late on payments or in trouble with a federal department otherwise select NO"
                                        chipValue='yes'
                                    />
                                )}
                            />

                            <Controller
                                name='partyLawsuit'
                                control={control}
                                rules={LoanDeclarationValidation.partyLawsuit}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Are you party lawsuit in which you potentially have any personal financial liability?`}
                                        state={partyLawsuit}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.partyLawsuit?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='convertTitle'
                                control={control}
                                rules={LoanDeclarationValidation.convertTitle}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Have you conveyed title to any property in lieu of foreclosure in the past seven years?`}
                                        state={convertTitle}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.convertTitle?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='preForeclosure'
                                control={control}
                                rules={LoanDeclarationValidation.preForeclosure}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Within the past seven years, Have you completed a pre-
                                foreclosure sale or a short sale, whereby the property was 
                                sold to a third party and the lender agreed to accept less 
                                than the outstanding mortgage balance due.`}
                                        state={preForeclosure}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.preForeclosure?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='propertyForeclosed'
                                control={control}
                                rules={LoanDeclarationValidation.propertyForeclosed}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Have you had property foreclosed upon in the last seven years?`}
                                        state={propertyForeclosed}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.propertyForeclosed?.message}
                                    />
                                )}
                            />

                            <Controller
                                name='declaredBankruptcy'
                                control={control}
                                rules={LoanDeclarationValidation.declaredBankruptcy}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        required
                                        label={`Have you declared bankruptcy within the past 7 years?`}
                                        state={declaredBankruptcy}
                                        data={LOAN_DECLARATIONS}
                                        error={errors?.declaredBankruptcy?.message}
                                    />
                                )}
                            />

                            {declaredBankruptcy == "yes" ? (
                                <>
                                    <Box maxWidth={{ xs: "100%" }} ml={3}>

                                        <Controller
                                            name="typesOfBankruptcy"
                                            control={control}
                                            rules={LoanDeclarationValidation.typesOfBankruptcy}
                                            render={({ field }) => (
                                                <CustomDropDown
                                                    {...field}
                                                    field={field}
                                                    required
                                                    style={{ width: '50%' }}
                                                    rowData={typesOfBankruptcy}
                                                    value={typesOfBankruptcy}
                                                    label={'Identify the type(s) of bankruptcy ?'}
                                                    state={typesOfBankruptcy}
                                                    option={DECLARATION_TYPE_OF_BANKRUPTCY}

                                                    error={errors?.typesOfBankruptcy?.message}
                                                />
                                            )}
                                        />



                                    </Box>
                                </>
                            ) : null}






                            <Typography variant='p' fontSize={'14px'} color={'grey'}>{`Add a note`}</Typography>
                            <Box maxWidth={{ xs: '100%', md: '50%' }}>
                                <Controller
                                    name='text'
                                    control={control}


                                    render={({ field }) => (
                                        <TextField {...field}

                                            type={'text'} multiline minRows={4} fullWidth />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>


                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button startIcon={<ArrowBackIos />} onClick={() => { route(location.pathname.replace(/[^/]*$/, 'demographics')) }}>
                                Previous
                            </Button>
                            <Button type='submit' endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </form>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`We need your consent to proceed.`}
                body={
                    <LoanDeclarationsCheck
                        onSumbit={onCheckSubmit}
                        handleSubmit={handleSubmit}
                        Controller={Controller}
                        control={control}
                        checkbox={data?.checkbox}
                        errors={errors}
                    />
                }
            />
        </Box>
    )
}