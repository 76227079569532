import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import LandingAppBar from "../../components/landing/landing-app-bar";
import Disclaimer from "../../components/landing/disclaimer";
import TermsOfUse from "../../components/landing/terms-of-use";
import PrivacyPolicy from "../../components/landing/privacy-policy";
import Security from "../../components/landing/security";
import Footer from "../../components/landing/footer";
import LandingMainBody from "../../components/landing/landing-main-body";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertSeverity } from "../../helpers/alert-msg";
import CaliforniaPolicy from "../../components/landing/california-policy";


const customStyle = makeStyles({
  bodyColor: {
    backgroundColor: "#fcfcfc",
  },
});

export default function Landing(props) {
  const classes = customStyle();

  const router = useNavigate();
  // scroll state
  const location = useLocation();
  useEffect(() => {
    const href = window.location.href;
    if (href.includes("#")) {
      const id = `${href.substring(href.indexOf("#") + 1)}`;
      const anchor = document.getElementById(id);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);


  const [page, setPage] = useState('landing')

  const LandingBody = () => {
    if (page === 'landing') {
      return <LandingMainBody />
    }
    else if (page === 'disclaimer') {
      return <Disclaimer />
    }
    else if (page === 'termsOfUse') {
      return <TermsOfUse />
    }
    else if (page === 'privacyPolicy') {
      return <PrivacyPolicy />
    }
    else if (page === 'californiaPolicy') {
      return <CaliforniaPolicy setPage={setPage} />

    }
    else if (page === 'security') {
      return <Security />
    }
  };

  return (

    <Box className={classes.bodyColor} position={'relative'}>
      <AlertSeverity />
      {/* Appbar area start */}
      <LandingAppBar setPage={setPage} />
      {/* Landing Body Generate  */}
      <LandingBody />
      <Footer setPage={setPage} />
    </Box>
  );
}
