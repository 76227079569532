


import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button, Grid, Box, Stack, Typography, Container } from '@mui/material';
import Footer from '../../components/landing/footer';
import LandingAppBarThirdParty from '../../components/landing/landing-app-bar-third-party';
import { DocumentDialogCommonHooks } from '../../helpers/modal/modal';
import ThirdPartyPreviewTask from './third-party-preview';
import { useParams } from 'react-router-dom';
import { getApiCallDynamic, getApiCallDynamicWithHeader, postApiCallDynamicWithResponse, postApiCallDynamicWithResponseWithToken } from '../../services/api-service';
import { API } from '../../services/api-config';
import { useForm } from 'react-hook-form';

import { PUT, UPDATE } from '../../constants/constatnt-variable';

import ThirdPartyResolveConfirm from './third-party-resolve-confirm';

const ThirdPartyMain = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues
    } = useForm({
        defaultValues: {

            phoneOtp: '',
            emailOtp: ''

        },
    });

    const { token } = useParams();

    const [emailOtp, setEmailOtp] = useState('');
    const [phoneOtp, setPhoneOtp] = useState('');
    const [otpError, setOtpError] = useState({ email: '', phone: '' });
    const [generalError, setGeneralError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [taksModal, setTaksModal] = useState(false);
    const [accessCode, setAccessCode] = useState(null)

    const [uploadFile, setUploadFile] = useState(null)
    const [finalData, setFinalData] = useState(null)
    const [isSubmit, setIsSubmit] = useState(localStorage.getItem('isSecondary'));
    const [otpValidateResponse, setOtpValidateResponse] = useState(null);
    const [thirdPartyTaskDetails, setThirdPartyTaskDetails] = useState(null);



    function updateDocument(prevData, uploadId, storageKey, fileName, docId) {



        const finalDataUpdate = JSON.parse(JSON.stringify(prevData));


        const documentToUpdate = finalDataUpdate?.requiredDocuments.find(doc => doc.id === uploadId);

        if (documentToUpdate) {

            documentToUpdate.resolvedDocStorageKey = storageKey;
            documentToUpdate.resolvedDocFileName = fileName;
            documentToUpdate.resolvedDocId = docId;
        }


        return finalDataUpdate;
    }

    useEffect(() => {
        if (uploadFile) {
            setFinalData(prevFinalData => {

                const dataToUpdate = prevFinalData || thirdPartyTaskDetails;


                return updateDocument(
                    dataToUpdate,
                    uploadFile?.uploadId,
                    uploadFile?.storageKey,
                    uploadFile?.fileName,
                    uploadFile?.docId
                );
            });
        }
    }, [uploadFile]);







    const [otpDetails, setOtpDetails] = useState(null);


    useEffect(() => {
        if (token) {
            const fetchOtp = async () => {
                try {
                    const res = await getApiCallDynamic({
                        path: API.getThirdPartyOtp,
                        param: { t: token },
                    });
                    if (res?.status === 200) {
                        setOtpDetails(res.data);
                    } else {
                        console.error("Failed to fetch OTP:", res);
                    }
                } catch (error) {
                    console.error('Error fetching OTP:', error);
                }
            };

            fetchOtp();
        }
    }, [token]);


    useEffect(() => {
        const fetchThirdPartyDetails = async () => {
            if (accessCode) {
                try {
                    const res = await getApiCallDynamicWithHeader({
                        path: API.getThirdPartyTaskDetails,
                        param: { thirdPartyTaskCode: token },
                        token: accessCode
                    });

                    if (res?.status === 200) {

                        setThirdPartyTaskDetails(res.data);
                    } else {
                        console.error('Unexpected response status:', res.status);
                    }
                } catch (error) {
                    console.error('Error fetching third-party details:', error);
                }
            }
        };


        fetchThirdPartyDetails();

    }, [accessCode]);



    const validateOtp = async () => {
        try {
            const data = {
                "taskCode": null,
                "email": otpDetails?.email,
                "emailOtp": emailOtp,
                "phoneNumber": otpDetails?.phoneNumber,
                "phoneNumberOtp": phoneOtp,
                "accessCode": otpDetails?.accessCode,
                "tempToken": otpDetails?.tempToken
            };

            const res = await postApiCallDynamicWithResponse({
                path: API.validateThirdPartyOtp,
                data: data
            });


            return res;
        } catch (error) {
            console.error("Error during OTP validation:", error);
            throw error;
        }
    };


    const handleTaskModalClose = () => {
        setTaksModal(false);
    };

    const fetchThirdPartyDetails = async (accessCode) => {
        try {
            const res = await getApiCallDynamicWithHeader({
                path: API.getThirdPartyTaskDetails,
                param: { thirdPartyTaskCode: token },
                token: accessCode
            });

            if (res?.status === 200) {

                setThirdPartyTaskDetails(res.data);
            } else {
                console.error('Unexpected response status:', res.status);
            }

            return res;
        } catch (error) {
            console.error('Error fetching third-party details:', error);
        }
    };



    const handleVerify = async () => {
        let valid = true;
        let errors = { email: '', phone: '' };

        setOtpError({ email: '', phone: '' });
        setGeneralError('');



        if (!emailOtp) {
            valid = false;
            errors.email = 'Email OTP is required.';
        }

        if (!phoneOtp) {
            valid = false;
            errors.phone = 'Phone OTP is required.';
        }

        setOtpError(errors);







        if (valid) {
            try {
                const res = await validateOtp();
                if (res) {

                    // setEmailOtp('');
                    // setPhoneOtp('');

                    const accessCode = res?.accessCode;

                    if (accessCode) {

                        const response = await fetchThirdPartyDetails(accessCode);
                        if (response?.data) {


                            const updatedTaskDetails = {
                                ...response.data,
                                accessCode
                            };
                            setThirdPartyTaskDetails(updatedTaskDetails);
                            setAccessCode(accessCode)
                            localStorage.setItem('accessCode', accessCode);
                            localStorage.setItem('thirdPartyTaskDetails', JSON.stringify(updatedTaskDetails))

                            setModalOpen(true);
                        } else {
                            setGeneralError("Failed to fetch third-party details.");
                        }
                    } else {
                        setGeneralError("Access code missing in OTP validation response.");
                    }
                } else {
                    setGeneralError(res.data?.message || "OTP validation failed. Please check your OTP.");
                }
            } catch (error) {
                console.error("Error during OTP validation:", error);
                setGeneralError("OTP are not valid");
            }
        } else {
            setOtpError(errors);
        }
    };


    const handleSend = () => {
        handleSubmit(formOnSubmit)();
    };

    const handleClose = () => {
        //  setIsPreview(false);
        setModalOpen(false);
    };

    const handleCancelPreview = () => {
        // setIsPreview(false);
        setModalOpen(false);
        setEmailOtp('');
        setPhoneOtp('');
    };

    const formOnSubmit = async (data) => {



        handleResolvekModalOpen()





    };

    const handleFormSubmit = (formData) => {
        event.preventDefault();

    };
    useEffect(() => {
        if (accessCode) {
            setModalOpen(true)
        }
    }, [accessCode]);



    const [resolveModal, setResolveModal] = useState(false);

    const handleResolvekModalOpen = () => {
        setResolveModal(true);
    };

    const handleResolveModalClose = () => {
        setResolveModal(false);
    };

    const handleResolveConfirm = async (selectedOption) => {

        let data;


        if (finalData === null) {


            data = {
                ...thirdPartyTaskDetails,
                taskStatus: selectedOption,
            };
        }

        else {

            data = {
                ...finalData,
                taskStatus: selectedOption,
            };

        }
        try {


            const res = await postApiCallDynamicWithResponseWithToken({
                path: API.finalThirdPartyResolve,
                data: data,
                token: accessCode,
                header: UPDATE
            });


            if (res) {


                localStorage.setItem('isSecondary', true)
                setIsSubmit(true)
                setModalOpen(false);

            }
        } catch (error) {
            console.error("Error during OTP validation:", error);
            throw error;
        }








    }

    const handleResolve = () => {
        setIsSubmit(false)
        localStorage.setItem('isSecondary', false)
        localStorage.clear('thirdPartyTaskDetails')
        localStorage.clear('accessCode')
        setEmailOtp('');
        setPhoneOtp('');
    };
    return (
        <>
            <LandingAppBarThirdParty />

            {!isSubmit &&
                <Box sx={{ maxWidth: 600, margin: 'auto', padding: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography paragraph>
                                We have sent OTP to your registered mobile number & new email
                            </Typography>
                            <Typography paragraph>
                                Enter OTP sent on your Email ({otpDetails?.email})
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={emailOtp}
                                onChange={(e) => setEmailOtp(e.target.value)}
                                error={!!otpError.email}
                            // helperText={otpError.email}
                            />
                            {
                                otpError.email && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {otpError.email}
                                    </Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography paragraph>
                                Enter OTP sent on your phone ({otpDetails?.phoneNumber})
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={phoneOtp}
                                onChange={(e) => setPhoneOtp(e.target.value)}
                                error={!!otpError.phone}
                            //helperText={otpError.phone}
                            />
                            {
                                otpError.phone && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {otpError.phone}
                                    </Typography>
                                )
                            }
                        </Grid>
                        {generalError && (
                            <Grid item xs={12}>
                                <Typography color="error">{generalError}</Typography> {/* Display general error */}
                            </Grid>
                        )}
                        <Grid item xs={12} mt={4}>
                            {/* <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" gap={2}>
                                <Button variant="text" onClick={handleClose}>Cancel</Button>
                                <Button variant="contained" onClick={handleVerify}>Verify</Button>
                            </Stack> */}
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                justifyContent={{ xs: "center", md: "flex-end" }}
                                alignItems="center"
                                gap={2}
                            >


                                <Button variant="contained" onClick={handleVerify}>
                                    Verify
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            }

            {isSubmit &&

                <Container maxWidth={'lg'} >

                    <Box sx={{ minHeight: '50vh' }} mt={3}>
                        <Typography variant="h3" gutterBottom>
                            Task Update
                        </Typography>
                        <Typography >
                            Your task has been updated. You can resume or view it by clicking the &#39;Resolve&#39; button.
                        </Typography>
                        <Button variant="contained" onClick={handleResolve} sx={{ mt: 2 }}>
                            Resolve
                        </Button>
                    </Box>

                </Container>

            }

            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={handleCancelPreview}

                size={'lg'}
                body={
                    <ThirdPartyPreviewTask
                        // isPreview={true}
                        handleSend={handleSend}
                        handleCancelPreview={handleCancelPreview}
                        formData={thirdPartyTaskDetails}
                        accessCode={accessCode}
                        setUploadFile={setUploadFile}
                        taskCode={token}
                    />
                }
            />





            <Footer />
            <DocumentDialogCommonHooks
                show={resolveModal}
                handleClose={handleResolveModalClose}
                body={
                    <ThirdPartyResolveConfirm
                        handleClose={handleResolveModalClose}
                        onConfirm={handleResolveConfirm}
                        isResolve={true}
                    />
                }
            />
        </>
    );
};

export default ThirdPartyMain;
