import { Box, Button } from '@mui/material'
import { React, useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from '../../helpers/custom-input'
import { GettingStartedCoBorrowerValidation } from '../../constants/validation-rules'

export default function PartnerDetailsForm({ onSubmit, editItem }) {

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            firstName: editItem?.firstName,
            lastName: editItem?.lastName,
            email: editItem?.email,
            phone: editItem?.phone,
            // loanCoBorrowerStatus: '',
        },
    })
    const [formatHomePhone, setFormatHomePhone] = useState(formatPhoneNumber(editItem?.phone));
    const [unformatHomePhone, setUnformatHomePhone] = useState(editItem?.phone || '');
    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }


    function handleHomePhoneChange(event) {
        let unformattedValue = event.target.value.replace(/\D/g, '');

        if (unformattedValue.length > 10) {
            unformattedValue = unformattedValue.slice(0, 10);
        }
        setUnformatHomePhone(unformattedValue);
        setFormatHomePhone(formatPhoneNumber(unformattedValue));
    }

    function handleHomePhoneChangeDB() {
        setValue('phone', unformatHomePhone)
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Box width={'100%'} my={2} >
                    <Controller
                        name="firstName"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.firstName}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="First Name"
                                required
                                error={errors?.firstName?.message}
                            />
                        )}
                    />

                    <Controller
                        name="lastName"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.lastName}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="Last Name"
                                required
                                error={errors?.lastName?.message}
                            />
                        )}
                    />

                    <Controller
                        name="phone"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.phone}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="Phone Number"
                                required
                                type="text"
                                error={errors?.phone?.message}
                                value={formatHomePhone}
                                onChange={handleHomePhoneChange}
                                onBlur={handleHomePhoneChangeDB}
                            />
                        )}
                    />

                    <Controller
                        name="email"
                        control={control}
                        rules={GettingStartedCoBorrowerValidation.email}
                        disable={editItem}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                label="Email"
                                required
                                error={errors?.email?.message}
                            />
                        )}
                    />

                    <Box textAlign={'right'}>
                        <Button type='submit' variant='contained' >{editItem?.firstName ? 'Update' : 'Submit'}</Button>
                    </Box>
                </Box>

            </form>
        </>
    )
}
