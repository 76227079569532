import { ArrowBackIos, ArrowForwardIos, AttachMoney, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, Checkbox, Link as MLink, Stack, Typography } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
    CONTACT_STATE,
    CONTACT_COUNTRY,
    MAILING_ADDRESS_STATUS,
    OWNERSHIP_STATUS
} from '../../../constants/constatnt-variable'
import { usPhoneNumberFormatDefaultValue } from '../../../constants/validation'
import CustomInput from '../../../helpers/custom-input'
import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../helpers/large-label'
import CustomRadioChip from '../../../helpers/radio-chip'
import LoanAdditional from './loan-additional'
import { KSMailIcon, KSPhoneIcon } from '../../../icons/custome-icon'
import { PropertyAddress } from '../property-address'
import { ContactValidation } from '../../../constants/validation-rules'
import { formatNumericValue, parseNumericValue } from '../../../helpers/numeric-format'

export default function ContactInfo({ onSubmit, data, partnerInfoPath, primaryBorrowerCurrentAddress }) {

    // // State for managing current address checkbox
    // const [stateVal, setStateVal] = useState(primaryBorrowerCurrentAddress?.stateOfCountry);
    // const [countryVal, setCountryVal] = useState(primaryBorrowerCurrentAddress?.country);

    const [isCheck, setIsCheck] = useState(false);

    // // Effect to update state when primaryBorrowerCurrentAddress changes
    // useEffect(() => {
    //     setStateVal(primaryBorrowerCurrentAddress?.stateOfCountry);
    //     setCountryVal(primaryBorrowerCurrentAddress?.country);
    // }, [primaryBorrowerCurrentAddress]);


    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm({

        defaultValues: {
            homePhone: usPhoneNumberFormatDefaultValue(data?.homePhone),
            cellPhone: usPhoneNumberFormatDefaultValue(data?.cellPhone),
            workPhone: usPhoneNumberFormatDefaultValue(data?.workPhone),
            email: data?.email,
            addressLine1: data?.currentAddress?.addressLine1,
            addressLine2: data?.currentAddress?.addressLine2,
            unit: data?.currentAddress?.unit,
            city: data?.currentAddress?.city,
            // stateOfCountry: data?.currentAddress?.stateOfCountry,
            stateOfCountry: isCheck ? primaryBorrowerCurrentAddress?.stateOfCountry : data?.currentAddress?.stateOfCountry,
            country: data?.currentAddress?.country,
            zip: data?.currentAddress?.zip,
            mailingAddressStatus: data?.mailingAddressStatus,
            // currentAddress: {
            mailingAddressline1: data?.mailingAddress?.addressLine1,
            mailingAddressline2: data?.mailingAddress?.addressLine2,
            mailingUnit: data?.mailingAddress?.unit,
            mailingCity: data?.mailingAddress?.city,
            mailingStateofCountry: data?.mailingAddress?.stateOfCountry,
            mailingCountry: data?.mailingAddress?.country,
            mailingZip: data?.mailingAddress?.zip,
            // },
            ownerShipType: data?.ownerShipType,
            year: data?.currentAddress?.year,
            month: data?.currentAddress?.month,
            rentAmount: data?.rentAmount,
        }

    })

    const isCurrentAddressSame = watch('isCurrentAddressSame');





    const handleCurrentAddressCheckboxChange = (event) => {
        const isChecked = (event.target.checked);
        setIsCheck(event.target.checked)

        if (isChecked === true) {
            setValue('addressLine1', primaryBorrowerCurrentAddress?.addressLine1);
            setValue('addressLine2', primaryBorrowerCurrentAddress?.addressLine2);
            setValue('city', primaryBorrowerCurrentAddress?.city);
            setValue('unit', primaryBorrowerCurrentAddress?.unit);
            setValue('stateOfCountry', primaryBorrowerCurrentAddress?.stateOfCountry);
            setValue('country', primaryBorrowerCurrentAddress?.country);
            setValue('zip', primaryBorrowerCurrentAddress?.zip);
        } else if (isChecked === false) {
            if (data?.currentAddress != null) {
                setValue('addressLine1', data?.currentAddress?.addressLine1);
                setValue('addressLine2', data?.currentAddress?.addressLine2);
                setValue('city', data?.currentAddress?.city);
                setValue('unit', data?.currentAddress?.unit);
                setValue('stateOfCountry', data?.currentAddress?.stateOfCountry);
                setValue('country', data?.currentAddress?.country);
                setValue('zip', data?.currentAddress?.zip);
            }
            else {
                setValue('addressLine1', '');
                setValue('addressLine2', '');
                setValue('city', '');
                setValue('unit', '');
                setValue('stateOfCountry', '');
                setValue('country', '');
                setValue('zip', '');
            }
        }

    };




    // const { stateOfCountry = null, country = null, } = useWatch({ control })

    const { stateOfCountry = null, country = null, mailingAddressStatus, ownerShipType } = useWatch({ control });


    const [formatHomePhone, setFormatHomePhone] = useState(formatPhoneNumber(data?.homePhone));
    const [unformatHomePhone, setUnformatHomePhone] = useState(data?.homePhone || '');
    const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(data?.workPhone));
    const [unformatWorkPhone, setUnformatWorkPhone] = useState(data?.workPhone || '');


    const shouldHideAddressLine2 = true
    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }


    function handleHomePhoneChange(event) {
        let unformattedValue = event.target.value.replace(/\D/g, '');

        if (unformattedValue.length > 10) {
            unformattedValue = unformattedValue.slice(0, 10);
        }
        setUnformatHomePhone(unformattedValue);
        setFormatHomePhone(formatPhoneNumber(unformattedValue));
    }

    function handleHomePhoneChangeDB() {
        setValue('homePhone', unformatHomePhone)
    }

    function handleWorkPhoneChange(event) {
        let unformattedValue = event.target.value.replace(/\D/g, '');

        if (unformattedValue.length > 10) {
            unformattedValue = unformattedValue.slice(0, 10);
        }

        setUnformatWorkPhone(unformattedValue);
        setFormatWorkPhone(formatPhoneNumber(unformattedValue));
    }

    function handleWorkPhoneChangeDB() {
        setValue('workPhone', unformatWorkPhone)
    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>



                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb"
                            separator={<NavigateNext fontSize="small" />}
                        >
                            <MLink fontWeight={700} underline="hover" color="inherit" href="#">
                                {`${partnerInfoPath == "partner-info" ? "Co-Borrower" : "Borrower"} Info`}
                            </MLink>
                            <Typography color="primary.main" fontWeight={700}>Contact</Typography>
                        </Breadcrumbs>
                        <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                            <CustomLargeLabel value={'How can we reach you?'} />
                            <Controller
                                name="homePhone"
                                control={control}
                                rules={ContactValidation.homePhone}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        type={'text'}
                                        label={'Home Phone'}
                                        required
                                        sicon={<KSPhoneIcon />}
                                        error={errors?.homePhone?.message}
                                        value={formatHomePhone}
                                        onChange={handleHomePhoneChange}
                                        onBlur={handleHomePhoneChangeDB}

                                    />
                                )}
                            />

                            <Controller
                                name="cellPhone"
                                control={control}
                                rules={ContactValidation.phone}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        type={'text'}
                                        label={'Cell Phone (To change please contact administrator)'}
                                        required
                                        sicon={<KSPhoneIcon />}
                                        error={errors?.cellPhone?.message}
                                        disabled={true}
                                    />
                                )}
                            />

                            <Controller
                                name="workPhone"
                                control={control}
                                rules={ContactValidation.workPhone}
                                render={({ field }) => (
                                    <CustomInput {...field}
                                        type={'text'}
                                        label={'Work Phone'}
                                        sicon={<KSPhoneIcon />}
                                        error={errors?.workPhone?.message}
                                        value={formatWorkPhone}
                                        onChange={handleWorkPhoneChange}
                                        onBlur={handleWorkPhoneChangeDB}
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                rules={ContactValidation.email}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        type={'email'}
                                        label={'Email ID'}
                                        required
                                        sicon={<KSMailIcon />}
                                        error={errors?.email?.message}
                                    />
                                )}
                            />

                            {partnerInfoPath === 'partner-info' && (
                                <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                                    <Box display="flex" alignItems="center" width={'100%'}>
                                        <Controller
                                            name="isCurrentAddressSame"
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox

                                                    {...field}
                                                    onChange={handleCurrentAddressCheckboxChange}
                                                    color="primary"
                                                    size="medium" // Adjust the size as needed
                                                />
                                            )}
                                        />
                                        <Typography>Current Address is same as Primary Borrower</Typography>
                                    </Box>
                                </Stack>
                            )}

                            <Typography variant='body1' mt={3}>Current Address</Typography>
                            <PropertyAddress Controller={Controller} control={control}
                                stateOfCountry={stateOfCountry} country={country}
                                setValue={setValue}
                                errors={errors} shouldHideAddressLine2={shouldHideAddressLine2} />


                            <Controller
                                name="mailingAddressStatus"
                                control={control}
                                rules={ContactValidation.mailingAddressStatus}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        labelComponent={<CustomLargeLabel required value={'Is mailing address same as the current address?'} />}
                                        {...field}
                                        field={field}
                                        state={mailingAddressStatus}
                                        data={MAILING_ADDRESS_STATUS}
                                        error={errors?.mailingAddressStatus?.message}
                                    />
                                )}
                            />

                            {mailingAddressStatus == 'No' ?
                                <>
                                    <Typography variant='body1' mt={3}>{`Mailing Address`}</Typography>
                                    {/* <PropertyAddress control={control} Controller={Controller} stateOfCountry={mailingStateofCountry} errors={errors} /> */}
                                    <Controller
                                        name="mailingAddressline1"
                                        control={control}
                                        rules={ContactValidation.mailingAddressline1}
                                        render={({ field }) => (
                                            <CustomInput
                                                {...field}
                                                type={'text'}
                                                label={'Address Line 1'}
                                                required
                                                error={errors?.mailingAddressline1?.message}
                                            />
                                        )}
                                    />
                                    {!shouldHideAddressLine2 && (
                                        <Controller
                                            name="mailingAddressline2"
                                            control={control}
                                            rules={ContactValidation.mailingAddressline2}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    type={'text'}
                                                    label={'Address Line 2'}
                                                    error={errors?.mailingAddressline2?.message}
                                                />
                                            )}
                                        />)}

                                    <Controller
                                        name="mailingUnit"
                                        control={control}
                                        rules={ContactValidation.mailingUnit}
                                        render={({ field }) => (
                                            <CustomInput
                                                {...field}
                                                type={'text'}
                                                label={'Unit'}
                                                error={errors?.mailingUnit?.message}
                                            />

                                        )}
                                    />

                                    <Controller
                                        name="mailingCity"
                                        control={control}
                                        rules={ContactValidation.mailingCity}
                                        render={({ field }) => (
                                            <CustomInput
                                                {...field}
                                                type={'text'}
                                                label={'City'}
                                                required
                                                error={errors?.mailingCity?.message}
                                            />
                                        )}
                                    />


                                    <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                                        <Box width={'100%'}>
                                            <Controller
                                                name="mailingStateofCountry"
                                                control={control}
                                                rules={ContactValidation.mailingStateofCountry}
                                                render={({ field }) => (
                                                    <CustomDropDown
                                                        {...field}
                                                        field={field}
                                                        label={'State'}
                                                        required
                                                        // state={mailingStateofCountry}
                                                        option={CONTACT_STATE}
                                                        error={errors?.mailingStateofCountry?.message}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box width={'100%'}>
                                            <Controller
                                                name="mailingZip"
                                                control={control}
                                                rules={ContactValidation.mailingZip}
                                                render={({ field }) => (
                                                    <CustomInput
                                                        {...field}
                                                        type={'number'}
                                                        label={'ZIP'}
                                                        required
                                                        error={errors?.mailingZip?.message}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Stack>
                                    <Controller
                                        name="mailingCountry"
                                        control={control}
                                        rules={ContactValidation.mailingCountry}
                                        render={({ field }) => (
                                            <CustomDropDown
                                                {...field}
                                                field={field}
                                                label={'Country'}
                                                required
                                                // state={mailingCountry}
                                                option={CONTACT_COUNTRY}
                                                error={errors?.mailingCountry?.message}
                                            />
                                        )}
                                    />
                                </>
                                : null
                            }
                            <Controller
                                name='ownerShipType'
                                control={control}
                                rules={ContactValidation.ownerShipType}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        field={field}
                                        state={ownerShipType}
                                        required
                                        label={'Ownership Type'}
                                        data={OWNERSHIP_STATUS}
                                        error={errors?.ownerShipType?.message}
                                    />
                                )}
                            />
                            <Typography variant='body1' mt={3}>{`Period of Stay`}</Typography>
                            <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                                <Box width={'100%'}>
                                    <Controller
                                        name="year"
                                        control={control}
                                        rules={ContactValidation.year}
                                        render={({ field }) => (
                                            <CustomInput {...field} type={'number'} label={'Year'} required error={errors?.year?.message} />
                                        )}
                                    />
                                </Box>
                                <Box width={'100%'}>
                                    <Controller
                                        name="month"
                                        control={control}
                                        rules={ContactValidation.month}
                                        render={({ field }) => (
                                            <CustomInput {...field} type={'number'} label={'Month'} required error={errors?.month?.message} />
                                        )}
                                    />
                                </Box>
                            </Stack>
                            {ownerShipType == "Rent" ?
                                <Controller
                                    name="rentAmount"
                                    control={control}
                                    rules={ContactValidation.rentAmount}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'text'} label={'Rent Amount'} required
                                            sicon={<AttachMoney />}
                                            value={formatNumericValue(field.value)}
                                            onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                            error={errors?.rentAmount?.message} />
                                    )}
                                />
                                : null}
                        </Box>
                        <LoanAdditional />
                    </Box>



                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Link to={'../personal-information'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>
                            <Button type='submit' endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </form>

        </>
    )
}
