import React, { createContext, useContext, useState } from 'react';

const LoanContext = createContext();

export const LoanProvider = ({ children }) => {
    const [additionalBorrowerInfo, setAdditionalBorrowerInfo] = useState('');

    return (
        <LoanContext.Provider value={{ additionalBorrowerInfo, setAdditionalBorrowerInfo }}>
            {children}
        </LoanContext.Provider>
    );
};

export const useLoanContext = () => {
    return useContext(LoanContext);
};
