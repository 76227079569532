import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import loanPageSideImage from '../../../../../assets/loanPageSideImage.png'
import { LOAN_CO_BORROWER_STATUS } from '../../../../../constants/constatnt-variable';
import CustomLargeLabel from '../../../../../helpers/large-label';
import CustomRadioChip from '../../../../../helpers/radio-chip';
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table';
import { DialogCommonHooks } from '../../../../../helpers/modal/modal';
import PartnerDetailsForm from '../../../../../components/loan-application/partner-details-form';
import useInput from '../../../../../helpers/use-input';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../../recoil/profile-atom';
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal';
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon';
import { useLoanContext } from './LoanContext';
import { kstheme } from "../../../../../theme/theme";
import CustomTooltip from '../../../../../helpers/custom-tooltip';
import CustomRadioChipWithTooltip from '../../../../../helpers/radio-chip-with-tooltip';

export default function CoBorrowerStatus({ data, setPage, onSubmit }) {
    const { additionalBorrowerInfo, setAdditionalBorrowerInfo } = useLoanContext();

    let [formValue, setFormValue] = useRecoilState(formState)



    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));



    let initialLoanCoBorrowerStatus = data?.length ? 'yes' : 'no';

    const [loanCoBorrowerStatus] = useInput({
        data: initialLoanCoBorrowerStatus,
        status: true,
        initial: initialLoanCoBorrowerStatus
    });

    let [loanCoBorrowerList, setLoanCoBorrowerList] = useState(
        () => data ? _.cloneDeep(data) : []
    )

    useEffect(() => {
        if (loanCoBorrowerList.length <= 0) {
            if (loanCoBorrowerStatus.value == 'yes') {
                handleClickOpen()
            }
        }

    }, [loanCoBorrowerStatus.value])

    initialLoanCoBorrowerStatus = useMemo(() => {
        if (additionalBorrowerInfo === 'No') {
            loanCoBorrowerStatus.value = 'no'
        }
        return 'no';


    }, [additionalBorrowerInfo]);




    const [editState, setEditState] = useState(() => {
        return null
    })


    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(loanCoBorrowerList)
            let editItem = list.find((item, i) => i == editState)
            setCoBorrowerEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])




    const coborrowerSubmit = async (data) => {


        let list = _.cloneDeep(loanCoBorrowerList)

        if (editState != null) {
            list[editState] = {
                ...list[editState],
                ...data
            }


            setEditState(null)
        } else {
            list.push(data)


        }
        setLoanCoBorrowerList(
            [...list]
        )

        formValue = {
            ...formValue,
            getStarted: {
                ...formValue.getStarted,
                page: {
                    ...formValue.getStarted.page,
                    coBorrower: list
                }
            }
        }
        setFormValue(formValue)
        handleClose();

    }




    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "firstName",
                header: "Name",
                enableColumnActions: false,
            },
            {
                accessorKey: "phone",
                header: "Mobile Number",
                enableColumnActions: false,
            },
            {
                accessorKey: "email",
                header: "Email ID",
                enableColumnActions: false,
            },

        ],
        []
    );

    const [modalOpen, setModalOpen] = React.useState(false);
    const [coBorrowerEdit, setCoBorrowerEdit] = useState();
    const handleClickOpen = () => {

        setCoBorrowerEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
    };


    const handleCloseDelete = (index) => {
        let list = _.cloneDeep(loanCoBorrowerList)
        list.splice(index, 1)
        setLoanCoBorrowerList(list)
    };



    const submit = () => {
        onSubmit(loanCoBorrowerStatus.value == "yes" ? loanCoBorrowerList : [])
    }



    return (
        <>

            <>
                <Grid container height={'100%'} px={{ xs: 1, md: 4 }}>
                    <Grid item xs={12} md={8}>
                        <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>


                            {formValue?.borrowerType === "Co-borrower" ?
                                <Box>

                                    <Box>
                                        {<CustomLargeLabel value={'Additional Borrower is not applicable.'} />}

                                    </Box>


                                </Box> :

                                <Box ml={3}>

                                    <Box >

                                        <CustomRadioChipWithTooltip
                                            labelComponent={<CustomLargeLabel limitWidth={isMobile} required value={'Do you have additional borrower for this loan ?'} />}
                                            state={loanCoBorrowerStatus}
                                            data={LOAN_CO_BORROWER_STATUS}
                                            tooltipTitle="This additional borrower will become a co-applicant for your loan using a separate login."
                                            chipValue='yes'
                                        />

                                    </Box>
                                    {loanCoBorrowerStatus.value == 'yes' ?
                                        <Box my={2}>
                                            <CustomReactMaterialTable
                                                staticLoad={true}
                                                staticData={loanCoBorrowerList}
                                                columns={tableColumn}
                                                enablePagination={false}
                                                enableBottomToolbar={false}
                                                enableSorting={false}
                                                enableToolbarInternalActions={false}
                                                renderTopToolbarCustomActions={
                                                    () => (
                                                        <CustomTooltip title="This additional Borrower will become a co-applicant for your loan using a separate login."
                                                            arrow
                                                        // placement="top-end"
                                                        >
                                                            <div>
                                                                <Button variant='outlined' disabled={data.length} onClick={handleClickOpen}>Add</Button>
                                                            </div>
                                                        </CustomTooltip>)
                                                }
                                                enableRowActions={true}
                                                renderRowActions={
                                                    ({ row }) => {
                                                        return (
                                                            <Stack direction={'row'}>
                                                                <IconButton onClick={() => { setEditState(row.index) }}>
                                                                    <KSPencilAltIcon />
                                                                </IconButton>

                                                                <ConfirmationModel
                                                                    body={
                                                                        <IconButton>
                                                                            <KSTrashIcon />
                                                                        </IconButton>
                                                                    }
                                                                    message={`Are you sure want remove  ${row?.original?.firstName} ?`}
                                                                    title={'Delete Additional Borrower'}
                                                                    handleSubmit={(event) => { handleCloseDelete(row.index) }}
                                                                />
                                                            </Stack>
                                                        )
                                                    }
                                                }
                                            />
                                        </Box>
                                        :
                                        null
                                    }
                                </Box>

                            }

                            <Box my={2} ml={2} width={{ xs: '100%', md: '50%' }}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Button type='button' startIcon={<ArrowBackIos />} onClick={() => {

                                        setPage("partner")
                                    }}>
                                        Previous
                                    </Button>
                                    <Button type='button' disabled={loanCoBorrowerStatus.value == "yes" && loanCoBorrowerList.length == 0} onClick={() => {
                                        submit()
                                    }} endIcon={<ArrowForwardIos />}>
                                        Save and Next
                                    </Button>
                                </Stack>
                            </Box>


                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4} display={'flex'} height={'100%'}>
                        <Box display={{ xs: 'none', md: 'block' }} pl={3} my={'auto'}>
                            <img src={loanPageSideImage} width={"100%"} style={{ transform: 'rotate(90deg)' }} />
                        </Box>
                    </Grid>
                </Grid>

                <DialogCommonHooks
                    show={modalOpen}
                    handleClose={handleClose}
                    title={`${coBorrowerEdit?.firstName ? 'Update' : 'Add'} Additional Borrower`}
                    body={
                        <>
                            <PartnerDetailsForm handleClose={handleClose} onSubmit={coborrowerSubmit} editItem={coBorrowerEdit} />

                        </>
                    }
                />

            </>

        </>
    )
}