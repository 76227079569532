import React from "react";
import EmptyPage from "../../components/loan/empty-page";
import MainLoyOut from "../../components/main-layout";
import LoanData from "../../components/loan/loan-data";

export default function LoansBorrower() {
  let loanTable = true;
  return (
    <MainLoyOut>
      {loanTable ? <LoanData /> : <EmptyPage />}

      {/* <LoanData /> */}
    </MainLoyOut>
  );
}
