import {
  CheckCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  TripOrigin,
  WatchLater,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Menu,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Paper
} from "@mui/material";
import React, { useContext, useState } from "react";
import { TimeStampToMonthDateYear } from "../../../../helpers/time-formater";
import { TimeStampToMonthDateYearForInput } from '../../../../helpers/time-formater'

import LoanSummaryLoanStatusForm from "../loan-summary-forms/loan-summary-loan-status-form";
import { LoanSummaryParentData } from "../loan-summary-visiblity";

export default function LoanSummaryMiles({
  loanCode,
  loanState,
  milesData,
  milesReloader,
}) {
  const { visiblity } = useContext(LoanSummaryParentData);
  // const { visiblity } = true;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const loanStatusHandleClose = () => {
    setAnchorEl(null);
  };

  const stepIconChange = (value) => {
    switch (value) {
      case "Completed":
        return CheckCircle;

      case "Working":
        return WatchLater;

      case "Incomplete":
        return TripOrigin;

      default:
        return TripOrigin;
    }
  };

  // const loanMileSequences = (a, b) => {

  //   // Use for sorting backend data based on loan steps
  //   if (a.mileDate) {
  //     if (b.mileDate) {
  //       // if (a.mileTotal > b.mileTotal) {
  //       if (a.mileDate >= b.mileDate) {
  //         return 1;
  //       } else {
  //         return -1;
  //       }
  //     } else {
  //       return -1;
  //     }
  //   } else {
  //     return 1;
  //   }
  // };

  const loanMileSequences = (a, b) => {

    if (a.mileDate !== null && b.mileDate !== null) {
      if (a.mileDate >= b.mileDate) {
        return 1;
      } else {
        return -1;
      }
    } else if (a.mileDate === null && b.mileDate === null) {

      return 0;
    } else if (a.mileDate === null) {

      return 1;
    } else {

      return -1;
    }
  };


  return (
    <Box bgcolor={"white"} borderRadius={3} p={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        mb={2}
        alignItems={"center"}
      >
        <Typography variant="h3">{`Loan States`}</Typography>
        {visiblity ? (

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            size="small"
            endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            {`Update Loan State`}
          </Button>
        ) : null}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={loanStatusHandleClose}
        >
          <Paper
            elevation={0}
            style={{
              maxHeight: 700,
              width: 350,
              paddingLeft: 5
            }}
          >
            <LoanSummaryLoanStatusForm
              loanCode={loanCode}
              loanState={loanState}
              handleClose={loanStatusHandleClose}
              reloader={milesReloader}
            />

          </Paper>
        </Menu>


      </Stack>

      <Divider />

      {/* Stepper Start */}
      <Box mt={2}>
        <Stepper orientation="vertical">
          {milesData
            .sort((a, b) => loanMileSequences(a, b))
            .map((step) => (
              <Step
                key={step.mileName}
                active={
                  step.mileState == "Working" || step.mileState == "Completed"
                }
                completed={step.mileState == "Completed"}
              >
                <StepLabel StepIconComponent={stepIconChange(step.mileState)}>
                  <Typography
                    variant="h6"
                    color={step.mileState == "Working" ? "primary.main" : null}
                  >
                    {step.mileName}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    justifyContent={"space-between"}
                    gap={2}
                  >
                    <Typography variant="body2">{`Took: ${step.mileTook} days`}</Typography>
                    <Typography variant="body2">{`Total: ${step.mileTotal} days`}</Typography>
                    <Typography variant="body2">{`On ${TimeStampToMonthDateYear(
                      step.mileDate
                    )}`}</Typography>
                  </Stack>
                </StepContent>
              </Step>
            ))}
        </Stepper>
      </Box>
      {/* Stepper End */}
    </Box>
  );
}
