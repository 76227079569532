import { FileUploadOutlined } from "@mui/icons-material";
import { Box, Button, FormHelperText, Grid, InputLabel, Typography, } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { MODAL_ACTION, UPDATE } from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import useInput from "../../../../helpers/use-input";
import { webInfoState } from "../../../../recoil/profile-atom";
import { API } from "../../../../services/api-config";
import { postApiCallDynamicWithOutReturn, uploadFile } from "../../../../services/api-service";
import { ServerSuccessMsg } from "../../../../helpers/alert-msg";
import { MAXLength } from "../../../../constants/validation";

export default function ReviewForm({
  data,
  handleClose,
  setTableReload,
  ...props
}) {
  let webInfo = useRecoilValue(webInfoState);
  let [customError, setCustomError] = useState({})

  let [firstName] = useInput({
    data: data.firstName,
    status: props.action == UPDATE,
    initial: "",
  });
  let [lastName] = useInput({
    data: data.lastName,
    status: props.action == UPDATE,
    initial: "",
  });
  let [description] = useInput({
    data: data.description,
    status: props.action == UPDATE,
    initial: "",
  });
  let [avatar] = useInput({
    data: "",
    status: "",
    initial: "",
  });

  let [avatarDocId] = useInput({
    data: props?.data?.avatarDocId,
    status: true,
    initial: "",
  });

  let [avatarDocFileName] = useInput({
    data: props?.data?.avatarDocFileName,
    status: true,
    initial: "",
  });

  const handleSubmit = async (event) => {
    const apiData = {
      websiteId: webInfo.id,
      id: data.id,
      firstName: firstName.value,
      lastName: lastName.value,
      avatar: avatar.value,
      description: description.value,
    };

    await postApiCallDynamicWithOutReturn(
      {
        data: apiData,
        refresh: () => {
          setTableReload((prev) => !prev);
          handleClose();
        },
        path: API.reviews,
        header: event.target.id
      }
    );
  };

  const handleValidation = (values) => {
    let errors = {};
    if (firstName.value === "") {
      errors.firstName = "Enter first Name";
    }
    if (lastName.value === "") {
      errors.lastName = "Enter last Name";
    }

    if (description.value === "") {
      errors.description = "Enter Description";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: firstName.value,
      lastName: lastName.value,
      description: description.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit({ target: { id: props.action } });
    },
  });

  const handleUpload = (event, id, type) => {
    let file = event.target.files[0]

    if (event.target.files) {

      if (event.target.files[0].size < MAXLength.AvatarMAXLength) {
        if (type == "avatar") {
          setCustomError({
            avatarSizeError: false
          })
        } else {
          setCustomError({
            eSignSizeError: false
          })
        }
        uploadFile({ docId: id, file: file, path: API.proxyUserUpload }).then((res) => {
          if (res.status === 201) {
            ServerSuccessMsg(res);
            if (type == "avatar") {
              avatarDocId.saveValue(res.data.docId);
              avatarDocFileName.saveValue(res.data.fileName);
            }
          }
        })
      } else {
        if (type == "avatar") {
          setCustomError({
            avatarSizeError: true
          })
        }
      }

    } else {
      console.log("not found");
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"First Name"}
          placeholder={"Enter your name.."}
          change={firstName}
          error={formik.errors.firstName}
        />
      </Box>
      <Box>
        <CustomInput
          size="small"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Last Name"}
          placeholder={"Enter your name.."}
          change={lastName}
          error={formik.errors.lastName}
        />
      </Box>
      {/* <Box>
        <InputLabel sx={{ pb: "5px", fontSize: "15px" }}>
          Upload Avatar
        </InputLabel>

      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <input id="customUpload" type="file" hidden onChange={event => handleUpload(event, avatarDocId.value, "avatar")} />
          <label htmlFor={"customUpload"}>
            <Box
              sx={{ background: "#F3F8FF" }}
              border="2px dashed #999999"
              borderRadius={3}
            >
              <Grid container pt={1} pl={2}>
                <Grid item sx={{ verticalAlign: "center" }}>
                  <FileUploadOutlined />
                </Grid>
                <Grid item px={1} sx={{ verticalAlign: "center" }}>
                  <Typography
                    variant="body"
                    sx={{ textTransform: "uppercase" }}
                  >
                    Drop PHOTO
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </label>
          <FormHelperText>
            The maximum file size allowed is 500KB.
          </FormHelperText>
        </Grid>
      </Grid>

      <Button
        variant="text"
        color="error"
        sx={{ textTransform: "capitalize" }}
      >
        Remove photo
      </Button>
    </Box>  */}
      <Box Box my={2} >
        <CustomInput
          size="large"
          fullWidth
          boxProps={{ my: 2 }}
          label={"Description"}
          placeholder={"Review"}
          change={description}
          multiline
          rows={5}
          rowsMax={7}
          error={formik.errors.description}
        />
      </Box >
      <Box my={2} display="flex" flexDirection={"row"} justifyContent={"end"}>
        <Button type="submit" id={props.action} variant="contained">
          {MODAL_ACTION[props.action]}
        </Button>
      </Box>
    </form >
  );
}
