import {Button, Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {Link, useNavigate} from "react-router-dom";
import {Path} from "../../routes/route-config";
import CustomInput from "../../helpers/custom-input";
import useInput from "../../helpers/use-input";
import {API} from "../../services/api-config";
import {postApiCallDynamic} from "../../services/api-service";
import {useFormik} from "formik";
import {MAXLength, ValidationConstant} from "../../constants/validation";
import AuthSideUi from "../../components/auth-side-ui";
import {SERVICE_TYPE} from "../../constants/constatnt-variable";

export default function ForgotPassword(props) {
  const router = useNavigate();

  let [email] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  const handleSubmit = (event) => {
    event?.preventDefault();
    let data = {
      email: email.value,
    };

    postApiCallDynamic(
      {
        data: data,
        refresh: (res) => {
          return res;
        },
        path: API.forget
      }
    ).then((res) => {
      if (
        res &&
        (res.status === 200 || res.status === 201 || res.status === 202)
      ) {
        router(Path.otp, {
          state: { ...res.data, ...res.requestData, from: SERVICE_TYPE.forget },
        });
      }
    });
  };

  const handleValidation = (values) => {
    let errors = {};
    if (ValidationConstant.emailRegx.test(String(email.value.trim()).toLowerCase()) === false) {
      errors.email = "Please enter your registered Email Id";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: email.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  return (
    <>
      <Grid container>
        {/* side background */}
        <AuthSideUi />
        {/* forget form */}
        <Grid item xs={12} sm={6} sx={{ height: "100vh", overflow: "auto" }}>
          <Box p={2}>
            <Grid container md={12} justifyContent="space-between">
              <Grid item>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    onClick={() => {
                      router(-1);
                    }}
                  >
                    <ArrowBackOutlinedIcon />
                  </Button>
                </Stack>
              </Grid>
              <Grid item>
                <Typography>
                  New here? &nbsp;
                  <Link to={Path.signup} style={{ color: "#2f80ed" }}>
                    Sign Up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box
              px={{ xs: 2, md: 20 }}
              pb={{ xs: 2, md: 3 }}
              minHeight="85vh"
              justifyContent="center"
              flexDirection="column"
              display="flex"
            >
              <Typography variant="h4" fontWeight="bold" mb={2}>
                Forgot Password
              </Typography>

              {/* For Every Input Field */}
              {/* Kindly follow the 1st component for all options */}
              <Box my={2}>
                <CustomInput
                  label={"Email ID"}
                  placeholder={"Enter Registered Email ID"}
                  change={email}
                  error={formik.errors.email}
                  maxLength={MAXLength.email}
                />
              </Box>

              <Box my={2}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ borderRadius: "24px", fontWeight: "bold" }}
                  type="submit"
                >
                  Reset Password
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
