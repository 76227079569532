import { LocalPhone } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import { useFormik } from 'formik';
import { React, useState } from 'react'
import { ValidationConstant } from '../../constants/validation';
import CustomInput from '../../helpers/custom-input'
import useInput from '../../helpers/use-input';
import { API } from '../../services/api-config';
import { postApiCallDynamicWithOutReturn } from '../../services/api-service';
import { invalid } from 'moment';

export default function DemoScheduleForm({ handleScheduleModalClose, handleDemoSuccessModalOpen }) {

    let [firstName] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [lastName] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [email] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [phone] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [message] = useInput({
        data: "",
        status: false,
        initial: "",
    });
    const [invitationMessage, setInvitationMessage] = useState('');
    const handleValidation = (values) => {
        let errors = {};
        if (firstName.value === "" || firstName.value === null) {
            errors.firstName = "Please enter First Name";
        }

        if (lastName.value === "" || lastName.value === null) {
            errors.lastName = "Please enter Last Name";
        }

        if (ValidationConstant.emailRegx.test(String(email.value)) === false) {
            errors.email = "Please enter a valid Email";
        }

        if (ValidationConstant.usPhoneNumber.test(String(phone.value)) === false) {
            errors.phone = "Please enter valid Phone";
        }
        /* if (message.value === "" || message.value === null) {
             errors.message = "Please enter message";
         }*/
        if (invitationMessage === "" || invitationMessage === null) {
            errors.message = "Please enter message";
        }
        return errors;
    };

    const handleDemoSchedule = () => {
        let apiCallData = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            // phone: phone.value,
            phone: unformatPhoneNumber(phone.value),
            //message: message.value,
            message: invitationMessage,
        }
        postApiCallDynamicWithOutReturn({ data: apiCallData, path: API.demoSchedule })
        handleScheduleModalClose();
        handleDemoSuccessModalOpen();
    }

    const formik = useFormik({
        initialValues: {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            phone: phone.value,
            //message: message.value,
            message: invitationMessage,
        },
        validate: (values) => handleValidation(values),
        onSubmit: (values) => {
            handleDemoSchedule()
        },
    });

    const handleInvitationMessageChange = (event) => {
        const inputMessage = event.target.value;
        setInvitationMessage(inputMessage);
    }

    const remainingChars = 500 - invitationMessage.length;

    const unformatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/\D/g, '');
    };

    const formatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    };
    const handlePhoneChange = (event) => {
        let inputPhone = event.target.value.replace(/\D/g, '');
        inputPhone = inputPhone.substring(0, 10);
        const formattedPhone = formatPhoneNumber(inputPhone);

        phone.onChange({ target: { value: formattedPhone } });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <CustomInput
                    size="small"
                    fullWidth
                    boxProps={{ my: 2 }}
                    label={"First Name"}
                    required={true}
                    placeholder={"Jacob"}
                    error={formik.errors.firstName}
                    change={firstName}
                />
            </Box>
            <Box>
                <CustomInput
                    size="small"
                    fullWidth
                    boxProps={{ my: 2 }}
                    label={"Last Name"}
                    required={true}
                    placeholder={"Lee"}
                    error={formik.errors.lastName}
                    change={lastName}
                />
            </Box>
            <Box>
                <CustomInput
                    size="small"
                    fullWidth
                    boxProps={{ my: 2 }}
                    label={"Email id"}
                    required={true}
                    placeholder={"Jacob@gmail.com"}
                    error={formik.errors.email}
                    change={email}
                />
            </Box>
            <Box>
                <CustomInput
                    size="small"
                    fullWidth
                    boxProps={{ my: 2 }}
                    label={"Phone Number"}
                    required={true}
                    placeholder={"(466)788-9807"}
                    sicon={<LocalPhone />}
                    error={formik.errors.phone}
                    type={"text"}
                    //change={phone}


                    value={formatPhoneNumber(phone.value)}
                    onChange={handlePhoneChange}
                />
            </Box>
            <Box>
                <div>
                    <CustomInput
                        size="small"
                        required={true}
                        fullWidth
                        error={formik.errors.message}
                        label={"Message"}
                        placeholder={"Enter Description"}
                        multiline
                        // rows={5}
                        // rowsMax={7}
                        // change={message}
                        value={invitationMessage}
                        onChange={handleInvitationMessageChange}
                    />
                    <span>{remainingChars} characters remaining</span>
                </div>
            </Box>
            <Stack
                my={2}
                direction={"row"}
                spacing={2}
                justifyContent={"flex-end"}
            >
                <Button variant="outlined" onClick={handleScheduleModalClose}>
                    Cancel
                </Button>
                <Button type="submit" variant="contained">
                    Send
                </Button>
            </Stack>
        </form>
    )
}
