import {
    Box,
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLoyOut from "../../components/main-layout";
import AnalyticsEmpty from "./AnalyticsEmpty";
import AnalyticsContainer from "./AnalyticsContainer";
import { getApiCallDynamic } from "../../services/api-service";
import { API } from "../../services/api-config";
import useInput from "../../helpers/use-input";
import { Search } from "@mui/icons-material";
import { kstheme } from "../../theme/theme";



export default function AnalyticsMain() {
    const [value, setValue] = useState(null);
    const [searchHide, setSearchHide] = useState(false)

    const [searchOptions, setSearchOptions] = useState([])
    const [userInfo, setUserInfo] = useState(null)

    const isTab = useMediaQuery(kstheme.breakpoints.down("md"));
    const [hideResult, setHideResult] = useState(true)
    const [searchItem] = useInput({
        data: null,
        status: false,
        initial: null,
    });

    useEffect(() => {
        if (searchItem.value) userList(searchItem.value)
        if (hideResult && searchItem.value) setHideResult(false)
    }, [searchItem.value]);


    const userList = (v) => {
        let params = { "globalFilter": v, "start": 0, "size": 50 }
        // getApiCallDynamic({ param: params, path: API.userList }).then((res) => {
        //     if (res?.status === 200) {
        //         setSearchOptions(res?.data?.data);
        //         setSearchHide(false)
        //     }
        // });
    }

    const getUserInfo = async (param) => {
        await getApiCallDynamic({ param: param, path: API.userInfo }).then((res) => {
            if (res?.status === 200) {
                setUserInfo(res?.data);
            }
        });
    }

    useEffect(() => {
        if (value != null) {
            let param = { userCode: value };
            setUserInfo(null)
            getUserInfo(param)
        }

    }, [value])

    const keyDownHandler = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setHideResult(true)
        }
    }
    return (
        <>

            <MainLoyOut>
                <Box py={5} px={{ xs: 1.5, md: 15 }}>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <Typography variant="h2">
                                Loan performance analysis dashboard
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box pb={4} width={"100%"} sx={{ display: "flex", flexDirection: "row" }}>
                                <TextField
                                    autoComplete="off"
                                    fullWidth
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px 0px 0px 8px",
                                            height: "50px",
                                        },
                                    }}
                                    onKeyDown={keyDownHandler}
                                    placeholder={isTab ? "Search" : "Search for loan performances"}
                                    {...searchItem}
                                />
                                <Button
                                    variant="contained"
                                    size="large"

                                    sx={{
                                        height: "50px",
                                        borderRadius: "0px 8px 8px 0px",
                                    }}
                                >
                                    <Search />
                                </Button>
                            </Box>

                            {/* {(!hideResult && !(!searchItem.value && userInfo)) ? (
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                {searchOptions.map((i) => (
                                                    <TableRow
                                                        onClick={() => { if (i.userCode !== 'angeoqui1a_1691073189182') { setValue(i.userCode); setSearchHide(true); setHideResult(true) } }}
                                                        key={i.userCode}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {i.firstName + " " + i.lastName}
                                                        </TableCell>
                                                        <TableCell align="right">{i.phone}</TableCell>
                                                        <TableCell align="right">{i.email}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : null} */}
                        </Grid>
                    </Grid>
                    {console.log(searchOptions, 'searchOptions.length == 0')}
                    {/* {!userInfo ?
                        searchOptions.length == 0 && <AnalyticsEmpty />
                        :
                        (<>
                            <AnalyticsContainer
                            // userInfo={userInfo} getUserInfo={getUserInfo}
                            />
                        </>
                        )} */}
                    {
                        searchOptions.length === 0 ? (
                            <AnalyticsEmpty />
                        ) : (
                            <AnalyticsContainer
                            // userInfo={userInfo} getUserInfo={getUserInfo}
                            />
                        )
                    }

                </Box>
            </MainLoyOut>

        </>
    );
}
