// // import {
// //     InputAdornment,
// //     InputLabel,
// //     Stack,
// //     TextField,
// //     Typography,
// // } from "@mui/material";
// // import { withStyles } from "@mui/styles";
// // import Box from "@mui/material/Box";
// // import React from "react";
// // import ErrorIcon from "@mui/icons-material/Error";

// // const styles = {
// //     root: {
// //         "& div.Mui-disabled": {
// //             background: "#dddddd70",
// //         },
// //         "& input:disabled": {
// //             "-webkit-text-fill-color": "black",
// //             cursor: "not-allowed",
// //         },
// //     },
// // };

// // function CustomInputMultiLine({
// //     label,
// //     labelRightComponent,
// //     sicon, // prefix Icon
// //     eicon, // postfixs Icon
// //     description = null,
// //     change,
// //     placeholder,
// //     size = "small",
// //     type = "text",
// //     pattern,
// //     labelSize,
// //     maxDate,
// //     minDate,
// //     helperText,
// //     error = null,
// //     disabled = false,
// //     readOnly = false,
// //     maxLength = 200,
// //     boxProps,
// //     required,
// //     marginBottom = 3,
// //     marginTop = 1,
// //     onNextLine,
// //     ...props
// // }) {
// //     return (
// //         <Box {...boxProps} mb={marginBottom} mt={marginTop}>
// //             <Stack
// //                 direction={onNextLine ? "column" : "row"}
// //                 justifyContent={"space-between"}
// //                 alignItems={'center'}
// //                 sx={{ width: '100%' }}
// //             >
// //                 <InputLabel style={{ fontSize: labelSize }} sx={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'pre-line', lineHeight: '1.1' }}>
// //                     {label}
// //                     {required ? (
// //                         <Typography variant="small" color="error">
// //                             *
// //                         </Typography>
// //                     ) : null}
// //                 </InputLabel>
// //                 <InputLabel>{labelRightComponent}</InputLabel>
// //             </Stack>
// //             {description ? (
// //                 <Typography>{description}</Typography>
// //             ) : (
// //                 <TextField
// //                     {...props}
// //                     disabled={disabled}
// //                     type={type}
// //                     size={size}
// //                     fullWidth
// //                     placeholder={placeholder}
// //                     {...change}
// //                     InputProps={{
// //                         inputProps: {
// //                             maxLength: maxLength,
// //                             pattern: pattern,
// //                             max: maxDate,
// //                             min: minDate,
// //                             step: "any",
// //                         },
// //                         readOnly: readOnly,
// //                         endAdornment:
// //                             error && props?.id !== "password" && (
// //                                 <ErrorIcon color="error" />
// //                             ) || eicon && (
// //                                 <InputAdornment position="end" >{eicon}</InputAdornment>
// //                             ),
// //                         startAdornment: sicon ? (
// //                             <InputAdornment position="start">{sicon}</InputAdornment>
// //                         ) : null,
// //                     }}
// //                     {...(error && { error: true, helperText: error })}
// //                 />
// //             )}
// //         </Box>
// //     );
// // }
// // export default withStyles(styles)(CustomInputMultiLine);

// import {
//     InputAdornment,
//     InputLabel,
//     Stack,
//     TextField,
//     Typography,
// } from "@mui/material";
// import { withStyles } from "@mui/styles";
// import Box from "@mui/material/Box";
// import React from "react";
// import ErrorIcon from "@mui/icons-material/Error";

// const styles = {
//     root: {
//         "& div.Mui-disabled": {
//             background: "#dddddd70",
//         },
//         "& input:disabled": {
//             "-webkit-text-fill-color": "black",
//             cursor: "not-allowed",
//         },
//     },
// };

// function CustomInputMultiLine({
//     label1,
//     label2,
//     labelRightComponent,
//     sicon, // prefix Icon
//     eicon, // postfixs Icon
//     description = null,
//     change,
//     placeholder,
//     size = "small",
//     type = "text",
//     pattern,
//     labelSize,
//     maxDate,
//     minDate,
//     helperText,
//     error = null,
//     disabled = false,
//     readOnly = false,
//     maxLength = 200,
//     boxProps,
//     required,
//     marginBottom = 3,
//     marginTop = 1,
//     onNextLine,
//     ...props
// }) {
//     return (
//         <Box {...boxProps} mb={marginBottom} mt={marginTop}>
//             <Stack direction="column" alignItems="flex-start">
//                 <InputLabel style={{ fontSize: labelSize }}>
//                     {label1}
//                     {required && (
//                         <Typography variant="small" color="error">
//                             *
//                         </Typography>
//                     )}
//                 </InputLabel>
//                 {label2 && (
//                     <InputLabel style={{ marginTop: '-4px' }}>{label2}</InputLabel>
//                 )}
//                 {/* <InputLabel>{labelRightComponent}</InputLabel> */}
//             </Stack>
//             {/* {label2 && <InputLabel>{label2}</InputLabel>} */}
//             {description ? (
//                 <Typography>{description}</Typography>
//             ) : (
//                 <TextField
//                     {...props}
//                     disabled={disabled}
//                     type={type}
//                     size={size}
//                     fullWidth
//                     placeholder={placeholder}
//                     {...change}
//                     InputProps={{
//                         inputProps: {
//                             maxLength: maxLength,
//                             pattern: pattern,
//                             max: maxDate,
//                             min: minDate,
//                             step: "any",
//                         },
//                         readOnly: readOnly,
//                         endAdornment:
//                             error && props?.id !== "password" && (
//                                 <ErrorIcon color="error" />
//                             ) || eicon && (
//                                 <InputAdornment position="end" >{eicon}</InputAdornment>
//                             ),
//                         startAdornment: sicon ? (
//                             <InputAdornment position="start">{sicon}</InputAdornment>
//                         ) : null,
//                     }}
//                     {...(error && { error: true, helperText: error })}
//                 />
//             )}
//         </Box>
//     );
// }
// export default withStyles(styles)(CustomInputMultiLine);


import React, { useState } from "react";
import {
    InputAdornment,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";

function CustomInputMultiLine({
    label,
    label1,
    label2,
    labelSize,
    sicon,
    eicon,
    placeholder,
    size = "small",
    type = "text",
    error = null,
    maxLength = 500,
    required,
    multiline = false,
    rows = 4,
    value,
    onChange,
    onBlur,
    ...props
}) {
    const [charCount, setCharCount] = useState(value?.length || 0);

    const handleChange = (e) => {
        setCharCount(e.target.value.length);
        onChange(e);  // Ensure react-hook-form's onChange is triggered
    };

    return (
        <Box mb={3} mt={1}>
            {/* <Stack direction="row" justifyContent="space-between">
                <InputLabel>
                    {label} {required && <Typography color="error">*</Typography>}
                </InputLabel>
            </Stack> */}
            {/* <Stack direction="row" justifyContent="space-between">
                <InputLabel>
                    {label} {required && <Typography color="error">*</Typography>}
                </InputLabel>
            </Stack> */}
            <Stack direction="column" alignItems="flex-start">
                <InputLabel style={{ fontSize: labelSize }}>
                    {label1 || label}
                    {required && (
                        <Typography variant="small" color="error">
                            *
                        </Typography>
                    )}
                </InputLabel>
                {label2 && (
                    <InputLabel style={{ marginTop: '-4px' }}>{label2}</InputLabel>
                )}
                {/* <InputLabel>{labelRightComponent}</InputLabel> */}
            </Stack>
            <TextField
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                type={type}
                size={size}
                fullWidth
                placeholder={placeholder}
                multiline={multiline}
                rows={multiline ? rows : undefined}
                // InputProps={{
                //     inputProps: { maxLength },
                //     startAdornment: sicon && (
                //         <InputAdornment position="start">{sicon}</InputAdornment>
                //     ),
                //     endAdornment: error ? (
                //         <ErrorIcon color="error" />
                //     ) : eicon && <InputAdornment position="end">{eicon}</InputAdornment>,
                // }}
                error={!!error}
                helperText={
                    error && (
                        <Typography variant="caption" color="error" sx={{ margin: '0 !important' }}>
                            {error}
                        </Typography>
                    )
                }
            />

            {maxLength && charCount > 0 && (
                <Box ml={0}>
                    <Typography variant="caption" color="error">
                        {`${maxLength - charCount} characters remaining`}
                    </Typography>
                </Box>
            )}

        </Box>
    );
}
export default CustomInputMultiLine;