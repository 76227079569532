import React from "react";
import { Box, Container, Divider, Grid, IconButton, Stack, Typography, } from "@mui/material";
import FooterIconFb from "../../assets/FooterIconFB.png";
import FooterIconTwitter from "../../assets/FooterIconTwitter.png";
import { makeStyles } from "@mui/styles";
import Logo from "../../assets/Logo White.svg";
import FooterIconLinkdin from "../../assets/FooterIconLinkdin.png";
import { Link } from "react-router-dom";
import { KSFacebookIcon, KSLinkedInIcon, KSTwitterIcon } from "../../icons/custome-icon";


const customStyle = makeStyles({
    footerBG: {
        backgroundColor: "#3d065f",
        color: "white",
        backgroundImage: "url('/images/footerBg.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        backgroundPosition: "left 15% bottom",
    },
    curPointer: {
        cursor: "pointer"
    }
});


function FooterThirdParty({ setPage }) {

    const blogUrl = process.env.REACT_APP_BLOG_URL;

    const disclaimerUrl = process.env.REACT_APP_PRODUCT;
    const TermsOfUseUrl = process.env.REACT_APP_DISCLAIMER;
    const PrivacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY;
    const CaliforniaPolicyUrl = process.env.REACT_APP_CALIFORNIA_POLICY;
    const SecurityUrl = process.env.REACT_APP_SECURITY;



    const classes = customStyle();

    return (
        <>
            <Box className={classes.footerBG}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid
                            item
                            container
                            xs={12}
                            md={12}
                            px={{ xs: 2 }}
                            my={{ xs: 5, md: 15 }}
                        >
                            <Grid item md={8} xs={12}>
                                <Box
                                    display={"flex"}
                                    flexGrow={0}
                                    component={Link}
                                    to="/"
                                    alignItems={"center"}
                                    sx={{ textDecoration: "none" }}
                                >
                                    <img src={Logo} width={"150px"} alt="logo" />

                                </Box>
                            </Grid>

                            <Grid item md={2} sm={12} xs={12} mt={{ xs: 3, md: 0 }}>
                                <Typography
                                    variant="body2"
                                    fontWeight={"bold"}
                                    fontFamily={"inter"}
                                    component={Link}
                                    to={`#about-us`}
                                    onClick={() => { setPage("landing"); }}
                                    sx={{
                                        color: 'white',
                                        textDecoration: "none",
                                    }}
                                >
                                    About us
                                </Typography>
                                <Box marginTop={2}>
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                        fontFamily="Inter"
                                        component={Link}
                                        to="#about-us"
                                        onClick={() => {
                                            window.open(blogUrl, "_blank");
                                        }}
                                        sx={{
                                            color: 'white',
                                            textDecoration: "none",
                                        }}
                                    >
                                        Blogs
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={2} sm={12} xs={12} mt={{ xs: 3, md: 0 }}>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        fontWeight={"bold"}
                                        fontFamily={"inter"}
                                    >
                                        Connect with us
                                    </Typography>
                                    <Stack direction={"row"} mt={1}>
                                        <IconButton sx={{ paddingLeft: 0 }} aria-label="Linkedin.com" onClick={() => window.open('https://www.linkedin.com/company/kramasoft-mortgagespecialist/about/?viewAsMember=true')}>
                                            <KSLinkedInIcon sx={{ fontSize: '35px' }} />
                                        </IconButton>
                                        <IconButton aria-label="Facebook.com" onClick={() => window.open('https://www.facebook.com/profile.php?id=100089106098684')}>
                                            <KSFacebookIcon sx={{ fontSize: '35px' }} />
                                        </IconButton>
                                        <IconButton aria-label="Twitter.com" onClick={() => window.open('https://www.twitter.com')}>
                                            <KSTwitterIcon sx={{ fontSize: '35px' }} />
                                        </IconButton>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Divider sx={{ backgroundColor: "white" }} />
                        </Grid>

                        <Grid item container xs={12} md={12} my={5}>
                            <Grid item md={2} sm={2} xs={6} my={{ xs: 1, md: "none" }}>
                                <Typography
                                    onClick={() => window.open(disclaimerUrl)}
                                    // onClick={() => { setPage("disclaimer"); window.location.replace("#disclaimer") }} 
                                    variant="body2" className={classes.curPointer}>Disclaimers
                                </Typography>
                            </Grid>
                            <Grid item md={2} sm={2} xs={6} my={{ xs: 1, md: "none" }}>
                                <Typography
                                    onClick={() => window.open(TermsOfUseUrl)}
                                    // onClick={() => { setPage("termsOfUse"); window.location.replace("#termsOfUse") }} 
                                    variant="body2" className={classes.curPointer}>Terms of Use

                                </Typography>
                            </Grid>
                            <Grid item md={2} sm={2} xs={6} my={{ xs: 1, md: "none" }}>
                                <Typography
                                    onClick={() => window.open(PrivacyPolicyUrl)}
                                    // onClick={() => { setPage("privacyPolicy"); window.location.replace("#privacyPolicy") }} 
                                    variant="body2" className={classes.curPointer}>Privacy Policy

                                </Typography>
                            </Grid>
                            <Grid item md={2} sm={2} xs={6} my={{ xs: 1, md: "none" }}>
                                <Typography
                                    onClick={() => window.open(CaliforniaPolicyUrl)}
                                    // onClick={() => { setPage("californiaPolicy"); window.location.replace("#californiaPolicy") }} 
                                    variant="body2" className={classes.curPointer}>California Policy

                                </Typography>
                            </Grid>
                            <Grid item md={1} sm={2} xs={6} my={{ xs: 1, md: "none" }}>
                                <Typography
                                    onClick={() => window.open(SecurityUrl)}
                                    //  onClick={() => { setPage("security"); window.location.replace("#security") }} 
                                    variant="body2" className={classes.curPointer}>Security</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default FooterThirdParty;
