import { Box, Button, Grid, IconButton, Typography, } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import MainLoyOut from "../../../components/main-layout";
import NoteForm from "./note-form";
import { CREATE, UPDATE } from "../../../constants/constatnt-variable";
import { DialogCommonHooks } from "../../../helpers/modal/modal";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import { API } from "../../../services/api-config";
import { deleteApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../../services/api-service";
import { TimeStampToMonthDateYear } from "../../../helpers/time-formater";
import { KSPencilAltIcon, KSTrashIcon } from "../../../icons/custome-icon";
import ConfirmationModel from "../../../helpers/modal/confirm-modal";

export default function Notes() {

  const [tableReload, setTableReload] = useState(() => false);

  const location = useLocation();


  const demoRequestId =
    location.pathname.split("/")[location.pathname.split("/").length - 2];

  const [openModal, setOpenModal] = useState(false);

  const [action, setAction] = useState(CREATE);

  const [data, setData] = useState("");

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setAction(CREATE);
    setData("");
  };

  const handleEdit = (cell, row) => {
    handleOpen();
    setData(row?.original);
    setAction(UPDATE);
  };

  const deleteNote = (row) => {
    let apiData = {
      id: row.original.id,
    };

    deleteApiCallDynamic(
      {
        refresh: () => {
          setTableReload((prev) => !prev);
        },
        path: API.note,
        data: apiData
      }
    ).then(r => {

    })

  };

  const tableNote = (row) => {
    return (
      <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>{`${row.original.note}`}</Typography>
    )
  }

  const tableNoteActions = (cell, row) => {
    return (
      <Box display={"flex"}>
        <IconButton >
          <KSPencilAltIcon onClick={() => {
            handleEdit(cell, row);
          }} />
        </IconButton>

        <ConfirmationModel
          body={
            <IconButton>
              <KSTrashIcon />
            </IconButton>
          }
          handleSubmit={() => {
            deleteNote(row);
          }}
          message={`Are you sure you want to delete the note?`}
          title={"Confirmation"}
        />
      </Box>
    );
  }


  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date.",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return (
            TimeStampToMonthDateYear(cell.getValue())
          );
        },
      },
      //column definitions...
      {
        accessorKey: "note",
        header: "Note",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => tableNote(row),
      },
      {
        accessorKey: "note",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,

        Cell: ({ cell, row }) => tableNoteActions(cell, row),
      },
      //end
    ],
    []
  );



  const handleSubmit = async data => {
    let apiCallData = Object.assign(data, {
      demoRequestId: demoRequestId,
    });
    await postApiCallDynamicWithOutReturn({
      data: apiCallData,
      path: API.note,
      refresh: handleClose,
      header: action == UPDATE ? UPDATE : null
    })
  };




  return (
    <MainLoyOut>
      <Box mx={{ md: 15 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <CustomReactMaterialTable
              endPoint={API.note}
              params={{ demoRequestId: demoRequestId }}
              refetch={tableReload}
              columns={columns}
              enablePagination={false}
              enableToolbarInternalActions={false}
              renderTopToolbarCustomActions={(table) => (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    onClick={handleOpen}
                  >
                    Add More
                  </Button>
                </Box>
              )}
            />
          </Grid>
          <DialogCommonHooks
            title="Add New Note"
            show={openModal}
            handleClose={handleClose}
            body={
              <NoteForm
                data={data}
                action={action}
                onSubmit={handleSubmit}
              />
            }
          />
        </Grid>
      </Box>
    </MainLoyOut>
  );
}
