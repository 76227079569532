import { Box, Button, Container, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KSUploadIcon, KSUploadIconDoc, LinkedDocumentDisabledIcon, LinkedDocumentEnabledIcon } from "../../icons/custome-icon"
import { API } from "../../services/api-config";
import { toastNotify } from "../../helpers/alert-msg";
import { postApiCallDynamic, postApiCallDynamicWithOutReturn, postApiCallDynamicWithResponse, uploadFile, uploadFileWithToken } from "../../services/api-service";
import ThirdPartyUploadCard from "./third-party-upload-card";
import { KSCloudDownloadIcon, KSDocumentDownloadIcon, KSDocumentSearchIcon } from '../../icons/custome-icon'
import { docDownload, docDownloadWithToken, docPreview, docPreviewResolve, docPreviewWithToken, handleViewAfterResolve } from "../../helpers/file-upload-download";
import { previewDocument, previewDocumentResolved, previewLocalTaskDocument } from "../../helpers/preview-document";
import ThirdPartyChatDisplay from "./thirdparty-chat-display";
import ThirdPartyResolveConfirm from "./third-party-resolve-confirm";
import { DocumentDialogCommonHooks } from "../../helpers/modal/modal";
import { CREATE, ROLE_LIST, UPDATE } from "../../constants/constatnt-variable";
import { ReactComponent as LinkIcon } from "../../icons/LinkIcon.svg"
import LinkDocumentConfirm from "./link-document-confirm";
import { profileState } from "../../recoil/profile-atom";
import { useRecoilState } from "recoil";
import { downloadDocumentAfterResolve, handleFileDownloadRequiredDoc, handleFileDownloadSupportingDoc, handleFileDownloadView, previewDocumentAfterResolve } from "./third-party-file-operations";
import { toast } from "react-toastify";
import { KSTooltip } from "../../helpers/ks-tooltip";
import { kstheme } from "../../theme/theme";


function ThirdPartyResolvePreviewTask({
    formData, handleCancelPreview,
    thirdPartyTaskDetails,
    accessCode,
    taskCode,
    setUploadFile,
    taskReload,
    formId
}) {

    let [profileData, setProfileData] = useRecoilState(profileState);
    const [listDocumentTypes, setListDocumentTypes] = useState([])
    const [listSupportingDocs, setListSupportingDocs] = useState([])
    const isSmallScreen = useMediaQuery(kstheme.breakpoints.down("sm"));

    useEffect(() => {


        setListDocumentTypes(formData?.requiredDocuments)

        setListSupportingDocs(formData?.supportingSampleDocuments)

    }, [formData]);



    function capitalize(name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }






    const [localFileDetails, setLocalFileDetails] = useState(null);
    let [localData, setLocalData] = useState(null)




    const fileUpload = async (event, item) => {
        event.preventDefault(); // Prevents page reload


        let file = event.target.files[0];
        if (file) {
            const res = await uploadFileWithToken({
                formId: formData?.formId,
                // docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
                file: file,
                serviceType: "task",
                path: API.proxyDocumentThirdPartyOthersUpload,
                token: accessCode
            });

            if (res.status === 201) {
                toastNotify("success", res.data.message);
                setLocalFileDetails(res.data);


            } else {
                toastNotify("error", res.data.message);
            }
        } else {
            console.log("not found");
        }
    };




    const handleSupportView = (row) => {

        if (row?.docType === 'LocalUpload') {


            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.proxyDocumentDownload
            const token = accessCode
            const docId = row?.docId

            const data = {

                storageKey: storageKey,
                //fileName: fileName,
                id: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {

                docPreviewWithToken(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            }

        } else {



            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.downloadDoc
            const token = accessCode
            //const docId = row?.docId

            const data = {

                storageKey: storageKey,
                fileName: fileName,
                // fileName: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {

                docPreview(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            }
        }
    };












    const [resolveModal, setResolveModal] = useState(false);

    const handleResolvekModalOpen = () => {
        setResolveModal(true);
    };

    const handleResolveModalClose = () => {
        setResolveModal(false);
    };

    const [linkDocumentDetails, setLnkDocumentDetails] = useState(null)
    const [linkModal, setLinkModal] = useState(false);
    const handleLinkModalOpen = (item) => {

        setLnkDocumentDetails(item)
        setLinkModal(true);
    };

    const handleLinkModalClose = () => {
        setLinkModal(false);
    };



    const handleSend = () => {
        handleResolvekModalOpen()
    };
    const handleResolveConfirm = async (selectedOption) => {

        let data;





        data = {
            ...formData,
            taskStatus: selectedOption,
        };

        try {


            const res = await postApiCallDynamicWithResponse({
                path: API.createThirdPartyTask,
                data: data,
                // token: accessCode,
                header: UPDATE
            });


            if (res) {


                handleCancelPreview()
                taskReload()

            }
        } catch (error) {
            console.error("Error during OTP validation:", error);
            throw error;
        }








    }

    const [linkDetails, setLinkDetails] = useState(null)

    const updateState = (response) => {




        if (response.status === 200) {

            toast.success("Successfully Linked!", {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
            //taskReload()

        }
        else {
            toast.error("An error occurred while Linking Document", {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
        }




    }


    const handleLinkConfirm = async (data) => {


        setLinkDetails(data);

        const borrowerVisibilityStatus = data?.visibility.includes("Borrower");
        const realtorsVisibilityStatus = data?.visibility.includes("Realtors");

        const apiCallData = {
            "loanCode": formData?.loanCode,
            "documentName": linkDocumentDetails?.documentType,
            "fileName": linkDocumentDetails?.resolvedDocFileName,
            "storageKey": linkDocumentDetails?.resolvedDocStorageKey,
            "docType": "LocalUpload", //earlier local
            "storageDocId": linkDocumentDetails?.resolvedDocId,
            "uploadType": "LinkedDocument",
            //"description": "sdfdes",
            "linkingFromTask": true,
            "formId": formData?.formId,
            "userDefinedDocType": true,    //hardcode
            "borrowerOnlyDocument": data?.borrowerOnlyDocument,
            "borrowerVisibility": borrowerVisibilityStatus,
            "realtorsVisibility": realtorsVisibilityStatus


        }



        if (linkDocumentDetails?.resolvedDocFileName && linkDocumentDetails?.resolvedDocStorageKey &&
            linkDocumentDetails?.resolvedDocId
        ) {
            try {
                await postApiCallDynamic(
                    {
                        data: apiCallData,
                        path: API.createLoanDocument,
                        header: CREATE,
                        refresh: updateState
                    }
                )



            } catch (error) {

                toast.error("An error occurred while Linking Document", {
                    autoClose: 3000,
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        //  handleClose()


        handleLinkModalClose()




    }



















    return (<>
        <Container maxWidth={'lg'}>




            <Box >

                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="left">
                    <Box mr={2}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                <KSUploadIconDoc width={42} height={42} />
                            </Typography>

                        </Stack>

                    </Box>

                    <Box style={{ display: 'flex', flexDirection: 'column', padding: 0, }}>

                        <Box>

                            <Typography variant="h6"> </Typography>

                            <Grid item xs={12} md={6} mb={3}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                        {formData?.taskType?.taskTypeName}
                                    </Typography> */}
                                    <KSTooltip title={formData?.taskType?.taskTypeDescription} arrow>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            {formData?.taskType?.taskTypeName}
                                        </Typography>
                                    </KSTooltip>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 400, color: '#2F80ED' }}>
                                        {formData?.taskPriority}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid container spacing={0} sx={{ flexGrow: 1, alignItems: "center" }}>
                                <Grid item xs={12} md={6} mb={isSmallScreen ? 2 : 0}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>

                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Borrower Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.borrowerFirstName && capitalize(formData.borrowerFirstName)}{' '}
                                            {formData?.borrowerLastName && capitalize(formData.borrowerLastName)}
                                        </Typography>

                                    </Stack>

                                </Grid>
                                <Grid item xs={12} md={6} mb={isSmallScreen ? 2 : 0}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            MLO Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.mloFirstName && capitalize(formData?.mloFirstName)}{' '}
                                            {formData?.mlolastName && capitalize(formData?.mlolastName)}

                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mt={2} mb={2}>

                                    <Stack
                                        // direction="row"
                                        // alignItems="flex-start"
                                        // justifyContent="space-between"
                                        // spacing={1}
                                        // sx={{ width: '80%' }}
                                        direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'flex-start'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}
                                    >
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Property:
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{ textAlign: 'right' }}
                                        >
                                            <Typography variant="body1" sx={{ textAlign: isSmallScreen ? 'left' : 'right' }}>
                                                {[
                                                    formData?.propertyAddress?.addressLineText,
                                                    formData?.propertyAddress?.addressAdditionalLineText,
                                                    formData?.propertyAddress?.cityName,
                                                    formData?.propertyAddress?.addressUnitIdentifier,
                                                    formData?.propertyAddress?.county,
                                                    formData?.propertyAddress?.postalCode,
                                                    formData?.propertyAddress?.stateCode,
                                                    formData?.propertyAddress?.countryCode,
                                                ]
                                                    .filter(Boolean)
                                                    .join(', ')
                                                }
                                            </Typography>


                                        </Typography>
                                    </Stack>


                                </Grid>
                                <Grid item xs={12} md={6} mb={isSmallScreen ? 2 : 0}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>

                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Loan Amount:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.loanAmount
                                                ? `$ ${new Intl.NumberFormat('en-US').format(formData?.loanAmount)}`
                                                : ''}
                                        </Typography>

                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mb={isSmallScreen ? 2 : 0}>

                                </Grid>
                                <Grid item xs={12} md={6} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Due Date:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.dueDate}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mt={2} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Loan Type:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.loanType}
                                        </Typography>
                                    </Stack>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Is lender Condition ?
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.lenderCondition === true ? 'Yes' : 'No'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid sx={12} md={12} mt={2} mb={4}>
                                    <Typography>{formData?.taskDescription}</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} mt={4} mb={2}>
                                {(listSupportingDocs && listSupportingDocs.length > 0) ? (
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Supporting Documents
                                        </Typography>
                                    </Stack>
                                ) : null}
                            </Grid>




                            <List>
                                <Grid container spacing={2}>
                                    {listSupportingDocs?.map((item, index) => (
                                        <Grid item xs={12} sm={6} key={index}>





                                            <Box
                                                sx={{
                                                    padding: '10px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '5px',
                                                    boxSizing: 'border-box',
                                                    width: '100%',
                                                }}
                                            >
                                                <ListItem
                                                    secondaryAction={
                                                        <>
                                                            {/* IconButton for view */}
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="view"
                                                                color="primary"
                                                                onClick={() => handleSupportView(item)}
                                                            >
                                                                <KSDocumentSearchIcon />
                                                            </IconButton>

                                                            {/* IconButton for download */}
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="download"
                                                                color="primary"
                                                                onClick={() => handleFileDownloadSupportingDoc(item, accessCode, taskCode)}
                                                            >
                                                                <KSCloudDownloadIcon />
                                                            </IconButton>
                                                        </>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" fontWeight="bold">
                                                                {`${index + 1}. ${item?.documentName}`}
                                                            </Typography>
                                                        }
                                                        secondary={item?.fileName}
                                                    />
                                                </ListItem>
                                            </Box>

                                        </Grid>
                                    ))}
                                </Grid>
                            </List>


                            <Grid item xs={12} md={12} mt={4} mb={2}>
                                {(listDocumentTypes && listDocumentTypes.length > 0) ? (
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Required Documents
                                        </Typography>
                                    </Stack>
                                ) : null}
                            </Grid>




                            <List>
                                {listDocumentTypes?.map((item, index) => (
                                    <Box key={index} mb={2}
                                        sx={{
                                            padding: '10px',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            boxSizing: 'border-box',
                                            width: '100%',
                                        }}
                                    >
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <>
                                                    {/* <IconButton
                                                    edge="end"
                                                    aria-label="view"
                                                    color="primary"
                                                    onClick={() => handleLinkModalOpen(item)}
                                                >
                                                    <LinkedDocumentEnabledIcon />
                                                </IconButton> */}

                                                    {formData?.taskStatus === 'RESOLVED' &&
                                                        // <IconButton
                                                        //     edge="end"
                                                        //     aria-label="view"
                                                        //     color="primary"
                                                        //     onClick={() => handleLinkModalOpen(item)}
                                                        //     disabled={!(profileData.role === ROLE_LIST.mlo
                                                        //         && (!item.linkedToLoanDoc)
                                                        //     )}
                                                        // >
                                                        //     {(profileData.role === ROLE_LIST.mlo
                                                        //         && (!item.linkedToLoanDoc)
                                                        //     ) ? (
                                                        //         <LinkedDocumentEnabledIcon />
                                                        //     ) : (
                                                        //         <LinkedDocumentDisabledIcon />
                                                        //     )}
                                                        // </IconButton>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="view"
                                                            color="primary"
                                                            onClick={() => handleLinkModalOpen(item)}
                                                            disabled={!((profileData.role === ROLE_LIST.mlo
                                                                || profileData.role === ROLE_LIST.mlp
                                                                || profileData.role === ROLE_LIST.broker
                                                                || profileData.role === ROLE_LIST.mloOwner)
                                                                && (!item.linkedToLoanDoc)
                                                            )}
                                                        >
                                                            {((profileData.role === ROLE_LIST.mlo
                                                                || profileData.role === ROLE_LIST.mlp
                                                                || profileData.role === ROLE_LIST.broker
                                                                || profileData.role === ROLE_LIST.mloOwner)
                                                                && (!item.linkedToLoanDoc)
                                                            ) ? (
                                                                <LinkedDocumentEnabledIcon />
                                                            ) : (
                                                                <LinkedDocumentDisabledIcon />
                                                            )}
                                                        </IconButton>

                                                    }

                                                    {/* IconButton for view */}
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="view"
                                                        color="primary"
                                                        onClick={() => previewDocumentAfterResolve(item)}
                                                    >
                                                        <KSDocumentSearchIcon />
                                                    </IconButton>

                                                    {/* IconButton for download */}
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="download"
                                                        color="primary"
                                                        // onClick={() => handleFileDownloadRequiredDoc(item, accessCode, taskCode)}
                                                        onClick={() => downloadDocumentAfterResolve(item)}
                                                    >
                                                        <KSCloudDownloadIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <KSTooltip title={item?.documentNote || 'No description available'}>
                                                <ListItemText primary={`${index + 1}. ${item?.documentType}`}
                                                    // secondary={`${item?.resolvedDocFileName}`}
                                                    secondary={item?.resolvedDocFileName ? `${item.resolvedDocFileName}` : ''}

                                                />
                                            </KSTooltip >


                                        </ListItem>
                                    </Box>
                                ))}

                            </List>

                            <ThirdPartyChatDisplay taskCode={taskCode} />

                            <Grid container spacing={2} mt={3} mb={3}>
                                <Grid item xs={12} md={6}>

                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" gap={2} justifyContent="flex-end">

                                        <Button variant="text" onClick={handleCancelPreview}>
                                            Cancel
                                        </Button>
                                        {/* <Button variant="contained" onClick={handleSend}>
                                            Submit
                                        </Button> */}
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                if (formData.taskStatus === 'RESOLVED') {
                                                    handleCancelPreview();
                                                } else {
                                                    handleSend();
                                                }
                                            }}
                                        >
                                            {formData.taskStatus === 'RESOLVED' ? 'OK' : 'Submit'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>


                        </Box >
                    </Box>
                </Box>
            </Box>
        </Container>
        <DocumentDialogCommonHooks
            show={resolveModal}
            handleClose={handleResolveModalClose}
            body={
                <ThirdPartyResolveConfirm
                    handleClose={handleResolveModalClose}
                    onConfirm={handleResolveConfirm}
                />
            }
        />
        {/* <DocumentDialogCommonHooks
            show={linkModal}
            handleClose={handleLinkModalClose}
            body={
                <LinkDocumentConfirm
                    handleClose={handleLinkModalClose}
                    onSubmit={handleLinkConfirm}
                    editItem={linkDetails}
                />
            }
        /> */}
        <DocumentDialogCommonHooks
            show={linkModal}
            handleClose={handleLinkModalClose}
            title={'Link Document'}
            body={
                <LinkDocumentConfirm
                    handleClose={handleLinkModalClose}
                    onSubmit={handleLinkConfirm}
                    editItem={linkDetails}
                    linkDocumentDetails={linkDocumentDetails}

                />
            }
        />
    </>
    );
}

export default ThirdPartyResolvePreviewTask;
