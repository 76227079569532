import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { DeleteIcon } from "../../../icons/custome-icon";
import ConfirmationDialogCustom from '../../../helpers/dialog-boxes/ConfirmationDialogAction';
import ConfirmationDialogMsg from '../../../helpers/dialog-boxes/ConfirmationDialogMsg';

import { API } from '../../../services/api-config'
import { getApiCallDynamic, deleteApiCallDynamic, deleteApiCallDynamicWithStatus } from '../../../services/api-service';





const CouponAvailable = ({ CouponAvailableApi, renderKey, userCode }) => {
  const [coupons, setCoupons] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState("");
  const [couponIdToDelete, setCouponIdToDelete] = useState(null);


  useEffect(() => {
    fetchCouponsData();
  }, [CouponAvailableApi, renderKey]);

  const fetchCouponsData = () => {
    getApiCallDynamic({ path: CouponAvailableApi, param: { userCode: userCode } }).then(
      (res) => {
        if (res?.status === 200) {
          setCoupons(res?.data);
        }

      }
    );
  };

  const handleDeleteIconClick = (couponId) => {
    setCouponIdToDelete(couponId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCouponIdToDelete(null);
    setOpenDialog(false);
  };

  const handleDeleteConfirm = () => {
    if (couponIdToDelete) {

      getApiCallDynamic({ path: API.deleteCoupon, param: { couponId: couponIdToDelete } }).then(
        (res) => {
          if (res?.status === 200) {
            fetchCouponsData();
            toast.error("Coupon successfully deleted");
          }
          else {
            toast.error("Error occurred during deletion" + res?.status);
          }
        }
      );

    }

    handleCloseDialog();
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">
                  <strong>Coupon ID</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  <strong>Created Date</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  <strong>Type</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  <strong>Status</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  <strong>Action</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon) => (
              <TableRow key={coupon.couponId}>
                <TableCell>
                  <Typography variant="subtitle1">
                    <strong> {coupon.couponId}</strong>
                  </Typography>
                </TableCell>
                <TableCell>{coupon.createDate}</TableCell>
                <TableCell>{coupon.couponType}</TableCell>
                <TableCell>{coupon.couponStatus}</TableCell>
                <TableCell>
                  {coupon.couponStatus === 'Active' && (
                    <Button
                      onClick={() => handleDeleteIconClick(coupon.couponId)}
                      style={{ cursor: 'pointer' }}
                      startIcon={<DeleteIcon />}
                    >

                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationDialogCustom
        open={openDialog}
        onClose={handleCloseDialog}
        title="Confirm Delete"
        message="Are you sure you want to delete this coupon?"
        onConfirm={handleDeleteConfirm}
        actionName="Delete"
      />

      <ConfirmationDialogMsg
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        title="Delete Coupon"
        message={confirmationDialogMessage}
        width={400}
        height={200}
      />
    </div>
  );
};

export default CouponAvailable;


