import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import { usPhoneNumberDigitSubmit } from '../../../../../constants/validation';
import ContactInfo from '../../../../../components/loan-application/contact/contact-info'
import LoanAdditionalAddress from '../../../../../components/loan-application/contact/loan-additional-address'
import ContactConfirmation from '../../../../../components/loan-application/contact/contact-confirmation'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { API } from '../../../../../services/api-config'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { UPDATE } from '../../../../../constants/constatnt-variable'


export default function LoanContactInfo() {

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]

    let [formValue, setFormValue] = useRecoilState(formState)


    let [subPage, setSubPage] = useState(null)

    let [primaryBorrowerCurrentAddress, setPrimaryBorrowerCurrentAddress] = useState(null)
    useEffect(() => {
        if (partnerInfoPath == "partner-info") {
            setSubPage(formValue.partnerInfo?.page?.contact?.subPage)
        } else {
            setSubPage(formValue.borrowerInfo?.page?.contact?.subPage)

        }


    }, [formValue.partnerInfo?.page?.contact?.subPage, formValue.borrowerInfo?.page?.contact?.subPage])



    useEffect(() => {

        setPrimaryBorrowerCurrentAddress(formValue.borrowerInfo?.page?.contact?.subPage?.currentAddress)

    }, [formValue.borrowerInfo?.page?.contact?.subPage?.currentAddress])


    const [modalOpen, setModalOpen] = useState(false);
    let [contact, setContact] = useState({})



    const handleModalOpen = () => {
        setModalOpen(true)
    };

    const handleClose = () => {
        setModalOpen(false);
    };


    const contactInfoOnSubmit = (data) => {

        if (data.year < 2) {
            setContact(data)
            handleModalOpen()
        } else {
            handleSubmit(data)
        }
    }

    const onSubmit = async (data) => {
        setContact({
            ...contact,
            ...data
        })
    }

    const [page, setPage] = useState('contact')
    let html;
    if (page === 'contact') {

        html = <ContactInfo
            partnerInfoPath={partnerInfoPath}
            data={subPage}
            onSubmit={contactInfoOnSubmit}
            primaryBorrowerCurrentAddress={primaryBorrowerCurrentAddress}
        />
    }
    else if (page === 'additional-contact') {
        html = <LoanAdditionalAddress
            setPage={setPage}
            additionalAddresses={subPage?.additionalAddresses}
            onSubmit={onSubmit}
            contact={contact} />
    }


    const handleSubmit = async (contact) => {
        let path, updateState
        const apiCallData = {
            page: {
                subPage: {
                    additionalAddresses: [],
                    currentAddress: {
                        addressLine1: contact?.addressLine1,
                        addressLine2: contact?.addressLine2,
                        city: contact?.city,
                        stateOfCountry: contact?.stateOfCountry,
                        country: contact?.country,
                        unit: contact?.unit,
                        year: contact?.year,
                        zip: contact?.zip,
                        month: contact?.month

                    },
                    mailingAddressStatus: contact.mailingAddressStatus,
                    ...(
                        contact.mailingAddressStatus == 'Yes' ?
                            {
                                mailingAddress: {
                                    addressLine1: contact?.addressLine1,
                                    addressLine2: contact?.addressLine2,
                                    city: contact?.city,
                                    stateOfCountry: contact?.stateOfCountry,
                                    country: contact?.country,
                                    unit: contact?.unit,
                                    year: contact?.year,
                                    zip: contact?.zip,
                                    month: contact?.month

                                }
                            }
                            :
                            {
                                mailingAddress: {
                                    addressLine1: contact?.mailingAddressline1,
                                    addressLine2: contact?.mailingAddressline2,
                                    city: contact?.mailingCity,
                                    stateOfCountry: contact?.mailingStateofCountry,
                                    country: contact?.mailingCountry,
                                    unit: contact?.mailingUnit,
                                    year: 0,
                                    zip: contact?.mailingZip,
                                    month: 0
                                },
                            }
                    ),
                    cellPhone: usPhoneNumberDigitSubmit(contact?.cellPhone),
                    email: contact?.email,
                    homePhone: usPhoneNumberDigitSubmit(contact?.homePhone),
                    workPhone: usPhoneNumberDigitSubmit(contact?.workPhone),
                    rentAmount: contact?.ownerShipType == "Rent" ? contact?.rentAmount : "",
                    ownerShipType: contact?.ownerShipType,
                },
                isComplete: true
            },
            formId: formValue.id,
            isComplete: true
        }

        if (partnerInfoPath == "partner-info") {
            path = API.partnerInfoContact
            updateState = {
                ...formValue,
                partnerInfo: {
                    ...formValue.partnerInfo,
                    page: {
                        ...formValue.partnerInfo.page,
                        contact: {
                            ...formValue.partnerInfo.page.contact,
                            subPage: apiCallData?.page?.subPage,
                            isComplete: true
                        }
                    }
                }
            }
        } else {
            path = API.borrowerInfoContact
            updateState = {
                ...formValue,
                borrowerInfo: {
                    ...formValue.borrowerInfo,
                    page: {
                        ...formValue.borrowerInfo.page,
                        contact: {
                            ...formValue.borrowerInfo.page.contact,
                            subPage: apiCallData?.page?.subPage,
                            isComplete: true
                        }
                    }
                }
            }
        }

        const contactInfoUpdateInRecoil = () => {
            setFormValue(updateState)
            route(location.pathname.replace(/[^/]*$/, 'demographics'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: path,
                header: UPDATE,
                refresh: contactInfoUpdateInRecoil
            }

        )


    }


    return (
        <>{subPage == null ? <></>
            :
            <>
                {html}

                < DialogCommonHooks
                    show={modalOpen}
                    handleClose={handleClose}
                    title={`Additional Addresses`}
                    body={
                        <ContactConfirmation handleClose={handleClose} setPage={setPage} />
                    }
                />
            </>
        }
        </>
    )
}
