import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";
import { Container, InputLabel, Stack, TextField } from "@mui/material";
import { DialogCommonHooks } from "../../helpers/modal/modal";
import CustomInput from "../../helpers/custom-input";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SuccessImage from "../../assets/sucessImage.png";
import SucessDemoImage from "../../assets/sucess.png";
import { makeStyles } from "@mui/styles";
import DemoScheduleForm from "./demo-schedule-form";
import Logo from "../../assets/Logo Black.svg";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const customStyle = makeStyles({
    logoAll: {
        alignItems: "center",
        textDecoration: "none",
    },
    menuIcon: {
        color: "#001A40",
    },
    menuColor: {
        color: "#222222",
    },
    activeLinkAdd: {
        color: "#2F80ED",
        textDecoration: "underline"
    },
    activeLinkRemove: {
        color: "#2F80ED",
        textDecoration: "underline"
    }
});

export default function LandingAppBarThirdParty({ setPage }) {
    const classes = customStyle();
    const productUrl = process.env.REACT_APP_PRODUCT;
    const aboutUsUrl = process.env.REACT_APP_ABOUT_US;

    // Contact us modal
    const [openContactModal, setContactModalOpen] = useState(false);

    const handleContactModalOpen = () => {
        setContactModalOpen(true);
    };

    const handleContactModalClose = () => {
        setContactModalOpen(false);
    };

    // sucess modal
    const [openSuccessModal, setSuccessModalOpen] = useState(false);

    const handleSuccessModalOpen = () => {
        setSuccessModalOpen(true);
        setContactModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    // Schedule demo state

    const [openScheduleModal, setScheduleModalOpen] = useState(false);

    const handleScheduleModalOpen = () => {
        setScheduleModalOpen(true);
    };

    const handleScheduleModalClose = () => {
        setScheduleModalOpen(false);
    };

    // Demo sucess modal
    const [openDemoSuccessModal, setDemoSuccessModalOpen] = useState(false);

    const handleDemoSuccessModalOpen = () => {
        setDemoSuccessModalOpen(true);
    };

    const handleDemoSuccessModalClose = () => {
        setDemoSuccessModalOpen(false);
    };

    // Menu state

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const router = useNavigate();

    const handleNavigation = (e) => {

        router(e.target.id);
        handleCloseNavMenu();
    };

    return (
        <>
            <AppBar position="fixed" sx={{ bgcolor: "white" }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Box
                            display={"flex"}
                            flexGrow={0}
                            component={"a"}
                            href="/"
                            className={classes.logoAll}
                        >
                            <img src={Logo} width={"50%"} />
                        </Box>

                        {/* menu for mobile */}
                        <Box
                            justifyContent={"flex-center"}
                            sx={{ flexGrow: 1, display: { xs: "flex", md: "none", } }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon className={classes.menuIcon} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                }}
                            >
                                {/* <MenuItem
                                    onClick={() => {
                                        handleContactModalOpen();
                                        handleCloseNavMenu();
                                    }}
                                >
                                    <Typography textAlign={"center"}>Contact us</Typography>
                                </MenuItem> */}
                                {/* <MenuItem
                                    component={Link}
                                    to={`#product`}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign={"center"}>Product</Typography>
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to={`#about-us`}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign={"center"}>About us</Typography>
                                </MenuItem> */}
                                {/* <MenuItem
                                    component={Link}
                                    to={`/signin`}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign={"center"}>Sign in</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleScheduleModalOpen();
                                        handleCloseNavMenu();
                                    }}
                                >
                                    <Typography textAlign={"center"}>Schedule a demo</Typography>
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to={`/signup`}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign={"center"}>Sign up</Typography>
                                </MenuItem> */}
                                <MenuItem component={Link} to={`#product`}>
                                    <Typography
                                        //  onClick={() => { setPage("landing"); }} 
                                        className={classes.menuColor} >Product</Typography>

                                </MenuItem>
                                <MenuItem component={Link} to={`#about-us`}>
                                    <Typography
                                        // onClick={() => { setPage("landing"); }}
                                        className={classes.menuColor} >About Us</Typography>

                                </MenuItem>
                            </Menu>
                        </Box>

                        {/* menu web */}

                        {/* <MenuItem component={Link}
                        // to={`/signin`}
                        >
                            <Typography className={classes.menuColor}>Product</Typography>
                            <KeyboardArrowDownIcon className={classes.menuColor} sx={{ marginLeft: 1 }} /> 
                    </MenuItem>
                    <MenuItem component={Link}
                    // to={`/signin`}
                    >
                        <Typography className={classes.menuColor}>About Us</Typography>
                        <KeyboardArrowDownIcon className={classes.menuColor} sx={{ marginLeft: 1 }} /> 
                    </MenuItem> */}

                        {/* <Box
                            justifyContent={"flex-end"}
                            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                        >
                            <Button></Button>
                            <MenuItem onClick={handleContactModalOpen}>
                                <Typography className={classes.menuColor}>
                                    Contact Us
                                </Typography>
                            </MenuItem>
                            <MenuItem component={Link} to={`#product`}>
                                <Typography onClick={() => { setPage("landing"); }} className={classes.menuColor} >Product</Typography>
                            </MenuItem>
                            <MenuItem component={Link} to={`#about-us`}>
                                <Typography onClick={() => { setPage("landing"); }} className={classes.menuColor} >About Us</Typography>
                            </MenuItem>
                            <MenuItem component={Link} to={`/signin`}>
                                <Typography className={classes.menuColor}>Sign In</Typography>
                            </MenuItem>
                            <Stack direction={"row"} spacing={2} ml={1}>
                                <Button onClick={handleScheduleModalOpen} variant="outlined">
                                    Schedule a demo
                                </Button>
                                <Button
                                    variant="contained"
                                    id={Path.signup}
                                    onClick={handleNavigation}
                                >
                                    Sign Up
                                </Button>
                            </Stack>
                        </Box> */}
                        <Box
                            justifyContent={"flex-centre"} marginLeft={'10%'}
                            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                        >

                            <MenuItem component={Link} to={`#product`}>
                                <Typography
                                    //  onClick={() => { setPage("landing"); }} 
                                    onClick={() => window.open(productUrl)}
                                    className={classes.menuColor} >Product</Typography>
                                {/* <KeyboardArrowDownIcon className={classes.menuColor} sx={{ marginLeft: 1 }} />  */}
                            </MenuItem>
                            <MenuItem component={Link} to={`#about-us`}>
                                <Typography
                                    // onClick={() => { setPage("landing"); }}
                                    onClick={() => window.open(aboutUsUrl)}
                                    className={classes.menuColor} >About Us</Typography>
                                {/* <KeyboardArrowDownIcon className={classes.menuColor} sx={{ marginLeft: 1 }} />  */}
                            </MenuItem>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar >
            <Toolbar />

            {/* Contact Modal */}
            <DialogCommonHooks
                title="Contact us"
                show={openContactModal}
                handleClose={handleContactModalClose}
                body={
                    <>
                        <Box>
                            <CustomInput
                                size="small"
                                fullWidth
                                boxProps={{ my: 2 }}
                                label={"First Name"}
                                required={true}
                                placeholder={"Sylvia"}
                            />
                        </Box>
                        <Box>
                            <CustomInput
                                size="small"
                                fullWidth
                                boxProps={{ my: 2 }}
                                label={"Last Name"}
                                required={true}
                                placeholder={"June"}
                            />
                        </Box>
                        <Box>
                            <CustomInput
                                size="small"
                                fullWidth
                                boxProps={{ my: 2 }}
                                label={"Email id"}
                                required={true}
                                placeholder="sylvia@gmail.com"
                            />
                        </Box>
                        <Box>
                            <CustomInput
                                size="small"
                                fullWidth
                                boxProps={{ my: 2 }}
                                label={"Phone Number"}
                                required={true}
                                placeholder="(466)788-9807"
                                sicon={<LocalPhoneIcon />}
                            />
                        </Box>
                        <Box>
                            <InputLabel>What can we help you with ?</InputLabel>
                            <TextField
                                size="small"
                                fullWidth
                                placeholder="Enter Description"
                                multiline
                                rows={5}
                                rowsMax={7}
                            />
                        </Box>
                        <Stack
                            my={2}
                            direction={"row"}
                            spacing={2}
                            justifyContent={"flex-end"}
                        >
                            <Button variant="outlined" onClick={handleContactModalClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleSuccessModalOpen}>
                                Send
                            </Button>
                        </Stack>
                    </>
                }
            />

            {/* Success Modal */}

            <DialogCommonHooks
                title={"Successfull"}
                show={openSuccessModal}
                handleClose={handleSuccessModalClose}
                body={
                    <>
                        <Box textAlign={"center"} overflow={'hidden'}>
                            <img src={SuccessImage} width={'50%'} />
                        </Box>
                        <Box textAlign={"center"} margin={"auto"}>
                            <Typography variant="h4">
                                Thank you! <br />
                                Your request has been submitted, <br />
                                our team will contact you soon.
                            </Typography>
                        </Box>
                        <Stack my={3} direction={"row"} justifyContent={"center"}>
                            <Button variant="contained" onClick={handleSuccessModalClose}>
                                Done
                            </Button>
                        </Stack>
                    </>
                }
            />

            {/* Schedule demo modal */}

            <DialogCommonHooks
                title={"Schedule a demo"}
                show={openScheduleModal}
                handleClose={handleScheduleModalClose}
                body={
                    <DemoScheduleForm
                        handleScheduleModalClose={handleScheduleModalClose}
                        handleDemoSuccessModalOpen={handleDemoSuccessModalOpen}
                    />
                }
            />

            {/* Demo Sucess Modal */}
            <DialogCommonHooks
                title={"Successfull"}
                show={openDemoSuccessModal}
                handleClose={handleDemoSuccessModalClose}
                body={
                    <>
                        <Box textAlign={"center"} my={3}>
                            <img src={SucessDemoImage} width={"50%"} />
                        </Box>
                        <Box textAlign={"center"} margin={"auto"} p={3}>
                            <Typography variant="h3">
                                Thank you! <br />
                                Your request for a demo has been sent.
                            </Typography>
                        </Box>
                        <Stack my={3} direction={"row"} justifyContent={"center"}>
                            <Button variant="contained" onClick={handleDemoSuccessModalClose}>
                                Done
                            </Button>
                        </Stack>
                    </>
                }
            />
        </>
    );
}
