import React from "react";
import InvitationEmptyIcon from "../../assets/no_Invitation.png";
import { Box, Grid, Typography } from "@mui/material";

function InvitationEmptyPage() {
  return (
    <Grid container my={3}>
      <Grid item xs={12} sm={12} md={4} margin={"auto"}>
        <Box
          textAlign={"center"}
          my={5}
          width={{ xs: "100%", sm: "80%", md: "80%" }}
          mx={"auto"}
        >
          <img src={InvitationEmptyIcon} width={"100%"} />
        </Box>
        <Box textAlign={"center"} my={2}>
          <Typography variant="h2" mb={1}>No Invitations Yet!</Typography>
          <Typography variant="body">
            {`Currently, there are no invitations available. Stay tuned for updates and notifications regarding your current application status.`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default InvitationEmptyPage;
