import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CouponAvailable from './CouponAvailable';
import ConfirmationDialogMsg from '../../../helpers/dialog-boxes/ConfirmationDialogMsg';
import { API } from '../../../services/api-config'
import { toast } from 'react-toastify';
import { getApiCallDynamic, postApiCallDynamicWithStatusReturnOnly, postApiCallDynamic } from '../../../services/api-service';


const Coupon = ({ userCode }) => {


  const BASE_URL_ADD_COUPON = API.billCoupon
  const BASE_URL_USER_COUPON = API.billUser


  const [activeUsersForCoupon, setActiveUsersForCoupon] = useState([]);


  useEffect(() => {

    getApiCallDynamic({ path: API.activeUsers, param: { userCode: userCode } }).then(
      (res) => {
        if (res?.status === 200) {
          setActiveUsersForCoupon(res?.data);
        }

      }
    );
  }, []);



  const [couponType, setCouponType] = useState('individual');
  const [selectedDropdown, setSelectedDropdown] = useState(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState('');

  const [renderKey, setRenderKey] = useState(0);

  const handleCouponTypeChange = (event) => {
    setCouponType(event.target.value);
  };

  const handleDropdownChange = (event) => {
    setSelectedDropdown(event.target.value);
  };



  const handleAddCoupon = () => {
    const ADD_COUPON_URL = 'Coupon';
    const COUPOUN_ADD_URL = `${BASE_URL_ADD_COUPON}/${ADD_COUPON_URL}`;

    let couponTypValue = '';
    let couponForUserIdValue = null; // Initialize couponForUserIdValue

    if (couponType === 'User') {
      couponTypValue = 'User';
      couponForUserIdValue = selectedDropdown; // Set couponForUserIdValue for 'individual' type
    } else if (couponType === 'company') {
      couponTypValue = 'Company';
    } else if (couponType === 'freeLoan') {
      couponTypValue = 'FreeLoan';
    }

    const requestData = {
      userCode: userCode,
      couponTyp: couponTypValue,
      couponQuantity: 1,
      couponForUserId: couponForUserIdValue,

    };


    if (requestData.couponTyp.trim() === '' || requestData.couponTyp === null || (requestData.couponTyp === 'User' && requestData.couponForUserId === null)) {
      toast.error("Please select Coupon Type / User Type ");
      return;
    } else {

      postApiCallDynamicWithStatusReturnOnly(
        {
          data: requestData,
          path: API.addCoupon,

        }).then((res) => {
          if (res?.status === 200) {
            // setConfirmationDialogMessage('Coupon successfully added');
            // setOpenConfirmationDialog(true);
            toast.success("Coupon Successfully Added", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            setRenderKey(renderKey + 1);
          }
          else {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
        });


      const handleCloseDialog = () => {
        setOpenDialog(false);
      };
    }
  };




  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ paddingLeft: 0, paddingBottom: 3 }}>
          Add a coupon
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom sx={{ paddingLeft: 0 }}>
          Select coupon type
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup name="couponType" value={couponType} onChange={handleCouponTypeChange}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <FormControlLabel
                  value="User"
                  control={<Radio />}
                  label="Individual - 1 month free"
                  sx={{ marginLeft: 0 }}
                />
                {couponType === 'User' && activeUsersForCoupon && Array.isArray(activeUsersForCoupon) && (
                  <FormControl>
                    <Select
                      sx={{ marginLeft: 0, width: 200, height: 30 }}
                      value={selectedDropdown}
                      onChange={handleDropdownChange}
                      displayEmpty
                    >
                      {activeUsersForCoupon.map((item) => (
                        <MenuItem key={item.userCode} value={item.userCode}>
                          {`${item.fname} ${item.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                value="company"
                control={<Radio />}
                label="Company level - 1 month free"
                sx={{ marginLeft: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                value="freeLoan"
                control={<Radio />}
                label="Free funded loan"
                sx={{ marginLeft: 0 }}
              />
            </Grid>
          </RadioGroup>
        </FormControl>

        <Grid item xs={12} marginTop={3} marginBottom={2}>
          <Button sx={{ marginLeft: 2 }} variant="contained" color="primary" onClick={handleAddCoupon}>
            Add Coupon
          </Button>
          <ConfirmationDialogMsg
            open={openConfirmationDialog}
            onClose={() => setOpenConfirmationDialog(false)}
            title="Coupon"
            message={confirmationDialogMessage} // Use the confirmation dialog message
            width={400}
            height={200}
          />
        </Grid>
        <Grid item xs={12} marginTop={3} marginBottom={2}>
          <CouponAvailable CouponAvailableApi={API.couponDetails} userCode={userCode} renderKey={renderKey} />
        </Grid>
      </Grid>
    </Grid>

  );
};
export default Coupon;
