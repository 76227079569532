import React, { useEffect, useState } from "react";
import MainLoyOut from "../../../../components/main-layout";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiCallDynamic } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import PreapprovalForm from "./preapproval-form";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../../../recoil/profile-atom";
import { profileState } from "../../../../recoil/profile-atom";


export default function Preapproval() {

    const route = useNavigate();
    const location = useLocation();
    const leadId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    let [profileData, setProfileData] = useRecoilState(profileState);

    let [leadData, setleadData] = useState({});
    let [, setSpinner] = useRecoilState(globalSpinner);

    const getLeadData = () => {
        setSpinner(true)
        getApiCallDynamic({ path: API.lead, param: { leadId: leadId } }).then((res) => {
            setSpinner(false)
            if (res?.status === 200) {
                setleadData(res.data)


            } else {
                route("/broker/leads")
            }

        })

    }

    useEffect(() => {
        if (leadId) {
            getLeadData()
        }

    }, [leadId])

    if (_.isEmpty(leadData)) {
        return <></>
    }




    return (
        <MainLoyOut>
            <PreapprovalForm leadData={leadData} profileData={profileData} />
        </MainLoyOut>
    );
}
