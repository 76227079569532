import { Box, Button, DialogActions, Grid, Typography } from "@mui/material";
import React from "react";

export default function DialogSuccesMsg(props) {
  return (
    <>
      <Grid item md={12} sm={12} xs={12} sx={{ textAlign: "center" }} >
        <Box display={'flex'} overflow={'hidden'} justifyContent={'center'}>
          <img src="/images/sucess.png" width="300" height="200" alt={"Sucess"} />
        </Box>
        <Typography style={{ fontSize: '1.25rem', lineHeight: 1.334 }} variant="h5" fontWeight={400} lineHeight={'150%'} fontSize={props?.descSize ? props?.descSize : '32px'}>{props.description}</Typography>
        {props.subDesc &&
          <Typography style={{ fontSize: '1.25rem', lineHeight: 1.334 }} px={2} variant="h5" fontWeight={400} lineHeight={'150%'} fontSize={props?.descSize ? props?.descSize : '32px'}>{props.subDesc}</Typography>
        }
      </Grid >
      <DialogActions sx={{ pt: 3, pb: 3 }}>
        <Grid container item md={12} sx={{ justifyContent: "center" }}>
          <Button onClick={props.handleClose} variant={"contained"}>
            Done
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
}
