import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import { CONTACT_STATE, CONTACT_COUNTRY, STATE_OF_COUNTRY, STATE } from '../../constants/constatnt-variable';
import CustomInput from '../../helpers/custom-input'
import CustomDropDown from '../../helpers/dropdown/custom-dropdown'
import { PropertyAddressValidation } from '../../constants/validation-rules';
import { useWatch } from 'react-hook-form'

export function PropertyAddress({ Controller, control, stateOfCountry, country, errors, isSubject, setValue, shouldHideAddressLine2 }) {

    const [selectedStateOfCountry, setSelectedStateOfCountry] = useState(stateOfCountry);

    useEffect(() => {
        if (stateOfCountry != null)
            setSelectedStateOfCountry(stateOfCountry);

    }, [stateOfCountry]);



    return (
        <>
            <Controller
                name='addressLine1'
                control={control}
                rules={PropertyAddressValidation.addressline1}
                render={({ field }) => (
                    <CustomInput marginBottom={0}  {...field} type={'text'} label={'Address Line 1'} required
                        error={errors.addressLine1?.message}
                        disabled={isSubject === 'SUBJECT_PROPERTY'} />
                )}
            />

            <Typography style={{ fontSize: '12px', marginBottom: '24px' }}>Note:- Add only 2 letters for the street type: <br />
                e.g., “123 Main St”, “2378 Alimony Dr”, “7923 Monarch Av”</Typography>
            {!shouldHideAddressLine2 && (
                <Controller
                    name='addressLine2'
                    control={control}
                    rules={PropertyAddressValidation.addressline2}
                    render={({ field }) => (
                        <CustomInput {...field} type={'text'} label={'Address Line 2'} error={errors?.addressLine2?.message} />
                    )}
                />)}

            <Controller
                name='unit'
                control={control}
                rules={PropertyAddressValidation.unit}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Unit'} error={errors?.unit?.message} />
                )}
            />

            <Controller
                name='city'
                control={control}
                rules={PropertyAddressValidation.city}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'City'} required error={errors?.city?.message}
                        disabled={isSubject === 'SUBJECT_PROPERTY'} />
                )}
            />



            <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                <Box width={'100%'}>
                    {/* <Controller
                        name='stateOfCountry'
                        control={control}
                        rules={PropertyAddressValidation.stateofCountry}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'State'}
                                required
                                state={stateOfCountry}
                                option={STATE_OF_COUNTRY}
                                error={errors?.stateOfCountry?.message}
                                disabled={isSubject === 'SUBJECT_PROPERTY'}
                            />
                        )}
                    /> */}

                    {/* <Controller
                        name="stateOfCountry"
                        control={control}
                        rules={PropertyAddressValidation.stateofCountry}
                        defaultValue={stateOfCountry}
                        render={({ field }) => (
                            <CustomDropDown
                                label={'State'}
                                {...field}
                                field={field}
                                value={selectedStateOfCountry}
                                onChange={setSelectedStateOfCountry}
                                option={STATE_OF_COUNTRY}
                                error={errors?.propertyType?.message}
                            />
                        )}
                    /> */}

                    <Controller
                        name="stateOfCountry"
                        control={control}
                        rules={PropertyAddressValidation.stateofCountry}
                        defaultValue={stateOfCountry}
                        render={({ field }) => (
                            <CustomDropDown
                                label={'State'}
                                {...field}
                                field={field}
                                state={stateOfCountry}
                                option={STATE_OF_COUNTRY}
                                error={errors?.stateOfCountry?.message}
                            />
                        )}
                    />

                </Box>
                <Box width={'100%'}>
                    <Controller
                        name='zip'
                        control={control}
                        rules={PropertyAddressValidation.zip}
                        render={({ field }) => (
                            <CustomInput {...field} type={'text'} label={'ZIP'} required error={errors?.zip?.message}
                                disabled={isSubject === 'SUBJECT_PROPERTY'} />
                        )}

                    />
                </Box>
            </Stack>

            <Controller
                name="country"
                control={control}
                rules={PropertyAddressValidation.country}
                defaultValue={country}
                render={({ field }) => (
                    <CustomDropDown
                        {...field}
                        field={field}
                        label={'Country'}
                        required
                        state={country}
                        option={CONTACT_COUNTRY}
                        error={errors?.country?.message}
                    />
                )}
            />
        </>
    )

}

