import React from 'react'
import { EditOutlined, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { profileState } from '../../../../../recoil/profile-atom';
import { NEW_KEYS, REMOVE_KEYS, ROLE_LIST } from '../../../../../constants/constatnt-variable';
import { TimeStampToMonthDateYear } from '../../../../../helpers/time-formater';
import { removeKeys } from '../../../../../helpers/common_fuctions/remove-keys';

export default function ReviewSection({
    sectionInfo,
    sectionName,
    chidSectionName,
    hasPartner,
    editUrl,
    isLocked,
}) {


    const location = useLocation();
    const route = useNavigate();
    let profileInfo = useRecoilValue(profileState);

    const goToSelectPage = () => {
        route(location.pathname.replace(/[^/]*$/, editUrl));
    };

    const subSectionEditUrls = {
        Personal: 'personal-information',
        Contact: 'contact-information',
        'Mailing': 'contact-information',
        Demographics: 'demographics',
        Declarations: 'declarations',
        Dependent: 'dependent',
        Employed: 'employment',
        Self: 'employment',
        Retired: 'employment',
        Secondary: 'employment',
        'Additional': 'additional-income',
        'Military': 'military-service',
    };


    return (
        <Box mb={1}>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h3' fontSize={'20px'}>{sectionName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h5' color='primary' mb={2}>{hasPartner}</Typography>
                    {
                        sectionInfo.map((data, j) => {
                            return (
                                <>
                                    {data.chidSectionName === "Co-Borrower Info" && hasPartner ? null :
                                        <Box key={j}>
                                            <Stack direction={'row'} justifyContent={'space-between'} mb={1}>
                                                <Typography variant='h5' color='primary'>{chidSectionName ? chidSectionName : data['chidSectionName']}</Typography>
                                                {/* <Button variant='outlined' 
                                    disabled={profileInfo.role == ROLE_LIST.borrower ? isLocked : false} 
                                    onClick={goToSelectPage} startIcon={<EditOutlined />}>{`Edit`}</Button> */}
                                                {/* <Button
                                                    variant='outlined'
                                                    disabled={profileInfo.role === ROLE_LIST.borrower ? isLocked : false}
                                                    onClick={() => {

                                                        const baseSectionName = chidSectionName || data['chidSectionName'];
                                                        const sectionKey = baseSectionName.split(" ")[0]; // before the first space
                                                        const fullEditUrl = editUrl || `borrower-info/${subSectionEditUrls[sectionKey]}`;
                                                        route(location.pathname.replace(/[^/]*$/, fullEditUrl));
                                                    }}
                                                    startIcon={<EditOutlined />}
                                                    style={{ fontSize: '15px' }}
                                                >
                                                    {`Edit`}
                                                </Button> */}
                                                {
                                                    data &&
                                                        data['chidSectionName']
                                                        ? (
                                                            <Button
                                                                variant='outlined'
                                                                disabled={profileInfo.role === ROLE_LIST.borrower ? isLocked : false}
                                                                onClick={() => {
                                                                    const baseSectionName = chidSectionName || data['chidSectionName'];
                                                                    const sectionKey = baseSectionName.split(" ")[0];
                                                                    const fullEditUrl = editUrl || `borrower-info/${subSectionEditUrls[sectionKey]}`;
                                                                    route(location.pathname.replace(/[^/]*$/, fullEditUrl));
                                                                }}
                                                                startIcon={<EditOutlined />}
                                                                style={{ fontSize: '15px' }}
                                                            >
                                                                {`Edit`}
                                                            </Button>
                                                        ) : null
                                                }


                                            </Stack>
                                            <Grid container>

                                                {
                                                    Object.keys(removeKeys(data)).map(
                                                        (key, i) => {
                                                            return (
                                                                //  data[key] &&
                                                                data[key] !== undefined && data[key] !== null && data[key] !== false &&
                                                                <Grid key={i} item xs={12} sm={6} md={4} xl={3} p={2}>
                                                                    <Typography variant='body1' color={'accent.A5'}>
                                                                        {NEW_KEYS[key] || key}
                                                                        {/* {NEW_KEYS[key] || key} */}
                                                                    </Typography>
                                                                    <Typography variant='body1'>
                                                                        {
                                                                            NEW_KEYS[key] &&
                                                                                NEW_KEYS[key].split(" ").includes("Date") ?
                                                                                TimeStampToMonthDateYear(data[key]) : data[key]
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            );
                                                        }
                                                    )

                                                }
                                            </Grid>
                                        </Box>}
                                </>)
                        }
                        )
                    }
                </AccordionDetails>
            </Accordion>
        </Box>

    )
}
