
import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import { API } from '../../services/api-config';
import { profileState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { fetchBorrowerDetails } from './third-party-util';
import { postApiCallDynamicWithResponse } from '../../services/api-service';
import { CREATE } from '../../constants/constatnt-variable';
import { KSSendIcon } from '../../icons/custome-icon';

const BorrowerChatDisplay = ({ taskCode }) => {
    const [profileData, setProfileData] = useRecoilState(profileState);
    const [taskDetails, setTaskDetails] = useState(null);
    const [comments, setComments] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messageEndRef = useRef(null);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const borrowerDetails = await fetchBorrowerDetails(taskCode);
                if (borrowerDetails) {
                    setTaskDetails(borrowerDetails);

                    setComments(borrowerDetails?.comments || []);
                } else {
                    console.error("Failed to fetch borrower task details");
                }
            } catch (error) {
                console.error("Error while fetching borrower details:", error);
            }
        };

        fetchDetails();
    }, [taskCode]);


    const sortedComments = comments?.sort((a, b) => {
        const timeA = new Date(a.commentTime);
        const timeB = new Date(b.commentTime);

        return timeA - timeB;
    });

    const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [comments]);



    const handleSendMessage = async () => {

        const trimmedMessage = newMessage.trim();


        if (trimmedMessage) {


            const formattedSenderName = `${capitalize(profileData?.firstName || '')} ${capitalize(profileData?.lastName || '')}`.trim();

            const data = {
                thirdPartyTaskCode: taskCode,
                message: trimmedMessage,
                senderName: formattedSenderName,
                senderRole: profileData?.role
            };

            try {
                const res = await postApiCallDynamicWithResponse({
                    path: API.chatMessage,
                    data: data,
                    header: CREATE
                });




                const borrowerDetails = await fetchBorrowerDetails(taskCode);
                if (borrowerDetails) {
                    setComments(borrowerDetails?.comments || []);
                }
                setNewMessage('');

            } catch (error) {
                console.error("Error during message submission:", error);
            }
        }
    };

    return (

        <Box mt={2}>
            <div style={{
                display: 'flex', flexDirection: 'column', height: '400px', border: '1px solid #ccc',

            }}>
                <div style={{
                    height: '7px', backgroundColor: '#2F80ED',

                }}></div>


                <div style={{ flex: '1', overflowY: 'auto', padding: '10px' }}>
                    {sortedComments?.map((comment) => (
                        <div key={comment.id} style={{ margin: '10px 0', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>

                            <div style={{ textAlign: 'left' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#D9D9D9',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginRight: '10px',
                                        }}
                                    >

                                    </div>
                                    <p style={{ margin: '0', fontWeight: 'bold' }}>
                                        {(comment.senderName)}
                                    </p>

                                </div>



                                <div style={{ marginLeft: '50px' }}>
                                    <p style={{ margin: '0', fontSize: '0.9rem', color: '#555', fontStyle: 'italic' }}>
                                        {comment.message}
                                    </p>
                                </div>

                            </div>

                            <div style={{ textAlign: 'right', fontSize: '0.8rem', color: '#888' }}>
                                {comment.commentTime ?
                                    new Date(comment.commentTime).toLocaleString('en-US', {
                                        month: 'short',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true
                                    }).replace(',', '') : ''
                                }
                            </div>



                        </div>
                    ))}
                    <div ref={messageEndRef} />


                    <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '10px' }}>
                        <TextField
                            variant="outlined"
                            placeholder="Type your message"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            multiline
                            rows={3}
                            fullWidth
                            InputProps={{
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        {/* <IconButton onClick={handleSendMessage} color="primary" style={{ marginLeft: '10px', alignSelf: 'flex-end' }}>
                            <KSSendIcon fontSize='40px' />
                        </IconButton> */}
                    </div>

                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Box>
                        <IconButton onClick={handleSendMessage} color="primary" style={{ marginLeft: '10px' }}>
                            <KSSendIcon fontSize='40px' />
                        </IconButton>
                    </Box>
                </div>
            </div>
        </Box>
    );
}

export default BorrowerChatDisplay;
