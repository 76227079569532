import React from 'react'
import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, Link as MLink, Stack, Typography, Grid } from '@mui/material'
import CustomLargeLabel from '../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../helpers/radio-chip'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { LOAN_DEPENDENT_STATUS } from '../../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../../helpers/custom-input'
import { useLocation, useNavigate } from 'react-router-dom'
import { DependentValidation } from '../../../../../../constants/validation-rules'

export const DependentForm = ({ onSubmit, data, partnerInfoPath }) => {

    const route = useNavigate()
    const location = useLocation();



    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            loanDependentStatus: data?.loanDependentStatus,
            dependent: data?.dependent,
            ageDependent1: data?.ageDependent1,
            ageDependent2: data?.ageDependent2,
            ageDependent3: data?.ageDependent3,
            ageDependent4: data?.ageDependent4,
        }
    })

    const { loanDependentStatus } = useWatch({ control })

    const numberDependentsWatch = parseInt(watch('dependent'))

    let numberDependentsWatchArray = []

    for (let i = 1; i <= numberDependentsWatch && numberDependentsWatch <= 4; i++) {
        let name = `ageDependent${i}`
        let label = `Age of Dependent ${i}`

        numberDependentsWatchArray.push(<Controller
            key={i}
            name={name}
            control={control}
            rules={DependentValidation.ageDependent1}
            i={i}

            render={({ field }) => (
                <>
                    <CustomInput {...field} type={'number'} label={label} required error={errors?.[name]?.message} />
                </>
            )}
        />)
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} minHeight={'calc(100vh - 200px)'} overflow={'auto'}>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb"
                            separator={<NavigateNext fontSize="small" />}
                        >
                            <MLink fontWeight={700} underline="hover" color="inherit" href="#">
                                {`${partnerInfoPath == "partner-info" ? "Co-Borrower" : "Borrower"} Info`}
                            </MLink>
                            <Typography color="primary.main" fontWeight={700}>Dependent</Typography>
                        </Breadcrumbs>
                        <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>

                            <Controller
                                name='loanDependentStatus'
                                control={control}
                                rules={DependentValidation.loanDependentStatus}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        labelComponent={<CustomLargeLabel value={`Do you have dependents ?`} />}
                                        {...field}
                                        field={field}
                                        state={loanDependentStatus}
                                        data={LOAN_DEPENDENT_STATUS}
                                        error={errors?.loanDependentStatus?.message}
                                    />
                                )}
                            />

                            {loanDependentStatus == 'yes' ?
                                <>
                                    <Controller
                                        name='dependent'
                                        control={control}
                                        rules={DependentValidation.numberDependents}
                                        render={({ field }) => (
                                            <CustomInput {...field} type={'number'} label={`Number Dependents`} required error={errors?.dependent?.message} />
                                        )}
                                    />

                                    {numberDependentsWatchArray}
                                </>
                                : null
                            }
                        </Box>
                    </Box>

                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}

                    >
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button onClick={() => { route(location.pathname.replace(/[^/]*$/, 'declarations')) }} startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                            <Button type='submit' endIcon={<ArrowForwardIos />}>
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </form>
        </>
    )
}

