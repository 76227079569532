import React, {useEffect, useState} from "react";


//  Time counter
//  take sec as parameter and count down
export const CountDown = ({ sec }) => {
  const [counter, setCounter] = useState(sec);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  let obj={}
  const secondsToTime = () => {
    let hours = Math.floor(counter / (60 * 60));

    let divisor_for_minutes = counter % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    return {
        h: hours,
        m: minutes,
        s: seconds,
    };
  };
   obj = secondsToTime();

  return [{ counter, setCounter, obj }];
};
