import React, { useState, useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
//import CurrentInvoice from './current-invoice';
import { makeStyles } from "@mui/styles";
import Coupon from './Coupon';
import PastInvoice from './past-invoice';

import CurrentInvoice from "../../settings/billing/current-invoice";
//import PastInvoice from "../../settings/billing/past-invoice";
import { BillContext } from '../../settings/billing/BillContext';

const AdminInvoiceTabPanel = ({ userInfo }) => {

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const useStyles = makeStyles((theme) => ({
  //   tabLabel: {
  //     justifyContent: "flex-end",
  //   },
  // }));
  //const classes = useStyles();
  if (userInfo.role === 'Broker' || userInfo.role === 'MLO Owner') {
    return (


      <TabContext value={value}>

        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          variant="scrollable"
          allowScrollButtonsMobile
        >
          <Tab label="Current Invoice" value={0} />
          <Tab label="Past Invoice" value={1} />
          <Tab label="Coupon" value={2} />
        </TabList>
        <TabPanel value={0} sx={{ px: { xs: 0, md: 3 } }}>
          <CurrentInvoice userCode={userInfo.userCode} />
        </TabPanel>
        <TabPanel value={1} sx={{ px: { xs: 0, md: 3 } }}>
          <PastInvoice userCode={userInfo.userCode} />
        </TabPanel>
        <TabPanel value={2} sx={{ px: { xs: 0, md: 3 } }}>
          <Coupon userCode={userInfo.userCode} />
        </TabPanel>

      </TabContext>

    );
  } else
    return null;
};


export default AdminInvoiceTabPanel;
