import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
  useMediaQuery,
  Paper
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../helpers/custom-input";
import { EditOutlined, FileUploadOutlined } from "@mui/icons-material";
import useInput from "../../helpers/use-input";
import {
  getApiCallDynamic,
  postApiCallDynamic,
  postApiCallDynamicWithOutReturn,
  uploadFile,
} from "../../services/api-service";
import { API } from "../../services/api-config";
import { MAXLength, usPhoneNumberFormatDefaultValue } from "../../constants/validation";
import { useRecoilState } from "recoil";
import { globalSpinner, profileState } from "../../recoil/profile-atom";
import { useFormik } from "formik";
import {
  DEMO_IMAGE,
  ROLE_LIST,
  roleOwner,
  UPDATE,
} from "../../constants/constatnt-variable";
import IdentityChange from "../../components/setting/identity-change";
import {
  AccessableRoleForAvatarAndSignature,
  AccessableRoleForProfile,
  AccessableRoleForProfileEntityName,
} from "../../constants/access-control";
import { KSPhoneIcon } from "../../icons/custome-icon";
import { ServerSuccessMsg } from "../../helpers/alert-msg";
import { getImageUrl } from "../../helpers/file-upload-download";
import { kstheme } from "../../theme/theme";
import CustomDropDown from "../../helpers/dropdown/custom-dropdown";

export default function Profile(props) {
  let [profileData, setProfileData] = useRecoilState(profileState);

  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  const [states, setStates] = useState([])
  const [rowValue, setRowValue] = useState(props?.data?.state)
  const [userData, setUserData] = useState({
    firstAddress: props.data.firstAddress,
    secondAddress: props.data.secondAddress,
    city: props.data.city,
    zip: props.data.zip
  })
  const profileCall = () => {
    setCustomError('')
    let param = { userCode: props.data.userCode };
    getApiCallDynamic({ param: param, path: API.ownInfo })
      .then((res) => {
        if (res?.status === 200) {
          setProfileData(res.data);
          setSpinner(false);
        }
        setBasicInfo((prev) => !prev);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
      });
  };
  const [altPhoneChange, setaltPhoneChange] = useState(true);

  let [customError, setCustomError] = useState({})

  const handleUpload = (event, id, type) => {
    let file = event.target.files[0]

    if (event.target.files) {
      if (event.target.files[0].size < MAXLength.AvatarMAXLength) {
        if (type == "avatar") {
          setCustomError({
            avatarSizeError: false
          })
        } else {
          setCustomError({
            eSignSizeError: false
          })
        }
        uploadFile({ docId: id, file: file, path: API.proxyUserUpload }).then((res) => {
          if (res.status === 201) {
            ServerSuccessMsg(res);
            if (type == "avatar") {
              avatarDocId.saveValue(res.data.docId);
              avatarDocFileName.saveValue(res.data.fileName);

            } else {
              esignDocId.saveValue(res.data.docId)
              esignDocFileName.saveValue(res.data.fileName)
            }
          }
        })
      } else {
        if (type == "avatar") {
          setCustomError({
            avatarSizeError: true
          })
        } else {
          setCustomError({
            eSignSizeError: true
          })
        }
      }

    } else {
      console.log("not found");
    }
  }

  const handleRemove = (type) => {
    let apiCallData
    if (type == 'avatar') {
      apiCallData = {
        docId: profileData.avatarDocId
      }
    } else {
      apiCallData = {
        docId: profileData.esignDocId
      }
    }
    postApiCallDynamic({
      data: apiCallData,
      refresh: (res) => {
        ServerSuccessMsg(res)
        if (type == 'avatar') {
          avatarDocId.saveValue("");
          avatarDocFileName.saveValue("")
        } else {
          esignDocId.saveValue("");
          esignDocFileName.saveValue("")
        }
      },
      path: API.proxyUserRemove,
      header: "update",
    })
  }


  const [basicInfo, setBasicInfo] = useState(false);
  let [email] = useInput({
    data: props?.data?.email,
    status: true,
    initial: "",
  });

  let [firstName] = useInput({
    data: props?.data?.firstName,
    status: true,
    initial: "",
  });

  let [lastName] = useInput({
    data: props?.data?.lastName,
    status: true,
    initial: "",
  });

  let [entityName] = useInput({
    data: props?.data?.entityName,
    status: true,
    initial: "",
  });

  let [accountNo] = useInput({
    data: props?.data?.accountNo,
    status: true,
    initial: "",
  });

  let [nmlsId] = useInput({
    data: props?.data?.nmlsId,
    status: true,
    initial: "",
  });

  let [avatarDocId] = useInput({
    data: props?.data?.avatarDocId,
    status: true,
    initial: "",
  });

  let [avatarDocFileName] = useInput({
    data: props?.data?.avatarDocFileName,
    status: true,
    initial: "",
  });

  let [esignDocId] = useInput({
    data: props?.data?.esignDocId,
    status: true,
    initial: "",
  });

  let [esignDocFileName] = useInput({
    data: props?.data?.esignDocFileName,
    status: true,
    initial: "",
  });

  let [compNmlsId] = useInput({
    data: props?.data?.companyNmlsId,
    status: true,
    initial: "",
  });

  let [firstAddress] = useInput({
    data: props?.data?.firstAddress,
    status: true,
    initial: "",
  });

  let [secondAddress] = useInput({
    data: props?.data?.secondAddress,
    status: true,
    initial: "",
  });

  let [city] = useInput({
    data: props?.data?.city,
    status: true,
    initial: "",
  });

  let [zip] = useInput({
    data: props?.data?.zip,
    status: true,
    initial: "",
  });
  let [phone] = useInput({
    data: props?.data?.phone,
    status: true,
    initial: "",
  });

  let [altPhone] = useInput({
    data: props?.data?.altPhone,
    status: true,
    initial: "",
  });

  useEffect(() => {
    getApiCallDynamic({ path: API.getStates }).then((res) => {
      if (res?.status === 200) {
        setStates(res.data);
        if (props?.data?.state) {
          res.data.forEach((item) => {
            if (item.stateCode === props?.data.state) {
              setRowValue(item.stateCode)
            }
          })
        }
      }
    });
  }, [props.data.state]);

  useEffect(() => {
    firstName.saveValue(props?.data?.firstName);
    lastName.saveValue(props?.data?.lastName);
    email.saveValue(props?.data?.email);
    accountNo.saveValue(props?.data?.accountNo);
    avatarDocId.saveValue(props?.data?.avatarDocId);
    esignDocId.saveValue(props?.data?.esignDocId);
    avatarDocFileName.saveValue(props?.data?.avatarDocFileName)
    esignDocFileName.saveValue(props?.data?.esignDocFileName)
    // avatar.saveValue(DEMO_IMAGE);
    // esignature.saveValue(DEMO_IMAGE);

    firstAddress.saveValue(props?.data?.firstAddress);
    secondAddress.saveValue(props?.data?.secondAddress);
    city.saveValue(props?.data?.city);
    zip.saveValue(props?.data?.zip);
    phone.saveValue(props?.data?.phone);
    altPhone.saveValue(props?.data?.altPhone);
    entityName.saveValue(props?.data?.entityName);
    nmlsId.saveValue(props?.data?.nmlsId);
    compNmlsId.saveValue(props?.data?.companyNmlsId);
    if (props.data.firstAddress && !userData.firstAddress) {
      setUserData({
        firstAddress: props.data.firstAddress,
        secondAddress: props.data.secondAddress,
        city: props.data.city,
        zip: props.data.zip
      })
    }
  }, [props.data, basicInfo, rowValue]);

  const handleValidation = (values) => {
    let errors = {};
    if (firstName.value === "" || firstName.value === null) {
      errors.firstName = "Please enter First Name";
    } else if (firstName.value.toString().length < MAXLength.minFirstName) {
      errors.firstName = "Minimum 3 alphabet";
    } else if (firstName.value.toString().length > MAXLength.maxName) {
      errors.firstName = "Maximum 10 alphabet";
    }

    if (lastName.value === "" || lastName.value === null) {
      errors.lastName = "Please enter Last Name";
    } else if (lastName.value.toString().length > MAXLength.maxName) {
      errors.lastName = "Maximum 10 alphabet";
    }
    if (roleOwner(props?.data?.role)) {
      if (entityName.value === "" || entityName.value === null) {


        console.log('issue in entity');
        errors.entityName = "Please enter Entity Name";
      }
    }
    if (roleOwner(props?.data?.role)) {
      if (accountNo.value === "" || accountNo.value === null) {
        errors.accountNo = "Please enter Account No";
      }
    }
    if (roleOwner(props?.data?.role) || props?.data?.role === ROLE_LIST.mlp || props?.data?.role === ROLE_LIST.mlo) {
      if (userData.firstAddress === "" || userData.firstAddress === null) {
        errors.firstAddress = "Please enter First Address";
      }
      if (userData.city === "" || userData.city === null) {
        errors.city = "Please enter your city";
      }

      if (userData.zip === "" || userData.zip === null) {
        errors.zip = "Please enter your zip";
      } else if (userData.zip.toString().length != MAXLength.zip) {
        errors.zip = "Please enter valid zip";
      }
    }
    return errors;
  };

  let [resultValidation, setResultValidation] = useState({});
  const handleUpdateBasicInfo = async () => {
    setSpinner(true);

    if (basicInfo) {
      let result = handleValidation();
      if (!Object.values(result).every((x) => x == "")) {
        setResultValidation(result);
        setSpinner(false);
        return;
      }
      let state = ''
      states.forEach((item) => {
        if (item.stateCode === rowValue) {
          state = item.stateCode
        }
      })
      setResultValidation({});
      let data = {
        userCode: props.data.userCode,
        firstName: firstName.value,
        lastName: lastName.value,
        avatarDocId: avatarDocId.value,
        avatarDocFileName: avatarDocFileName.value,
        entityName: entityName.value,
        esignDocId: esignDocId.value,
        esignDocFileName: esignDocFileName.value,
        accountNo: accountNo.value,
        firstAddress: userData.firstAddress,
        secondAddress: userData.secondAddress,
        city: userData.city,
        state: state,
        zip: userData.zip,
      };
      await postApiCallDynamicWithOutReturn({
        data: data,
        refresh: profileCall,
        path: API.ownInfo,
        header: "update",
      });
      // Api call
    } else {
      setSpinner(false);
      setBasicInfo((prev) => !prev);
    }
  };
  const altPhoneValidation = (values) => {
    let errors = {};
    if (altPhone.value === "" || altPhone.value.length !== 10) {
      errors.altPhone = "Please enter valid Alternate Phone No";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      altPhone: altPhone.value,
    },
    validate: (values) => altPhoneValidation(values),

    onSubmit: async (values) => {
      let data = {
        altPhone: altPhone.value,
        userCode: profileData.userCode,
      };
      await postApiCallDynamicWithOutReturn({
        data: data,
        refresh: profileCall,
        path: API.ownInfo,
        header: UPDATE,
      });
      setaltPhoneChange(true);
    },
  });

  const resetAltPhoneForm = () => {
    altPhone.saveValue(profileData.altPhone);
  };

  let profileInfo = [
    <>
      <CustomInput
        required
        disabled={!basicInfo}
        size="small"
        style={!isMobile ? { width: '50%' } : {}}
        label={"First Name"}
        change={firstName}
        error={resultValidation?.firstName}
        maxLength={MAXLength.name}
      />

      <CustomInput
        required
        disabled={!basicInfo}
        size="small"
        style={!isMobile ? { width: '50%' } : {}}
        label={"Last Name"}
        change={lastName}
        error={resultValidation?.lastName}
        maxLength={MAXLength.name}
      />
    </>,
  ];

  if (AccessableRoleForProfileEntityName.includes(props?.data?.role)) {
    profileInfo.push(
      <CustomInput
        disabled={!basicInfo}
        size="small"
        style={!isMobile ? { width: '50%' } : {}}
        label={"Entity Name"}
        change={entityName}
        error={resultValidation?.entityName}
        maxLength={MAXLength.entityName}
      />
    );
  }

  if (roleOwner(props?.data?.role)) {
    profileInfo.push(
      <CustomInput
        required
        disabled={true}
        size="small"
        change={accountNo}
        style={!isMobile ? { width: '50%' } : {}}
        label={"Account Number"}
        error={resultValidation?.accountNo}
        placeholder="12345"
      />
    );
  }

  if (AccessableRoleForAvatarAndSignature.includes(props?.data?.role)) {
    profileInfo.push(
      <Box>
        <InputLabel sx={{ pb: "5px", fontSize: "15px" }}>
          Upload Avatar <Typography variant="small" color="error">
            *
          </Typography>
        </InputLabel>
        {/* Used for  making inline*/}
        <Grid container spacing={1} alignItems="center" style={{ flexWrap: 'nowrap' }}>
          <Grid item xs={9} maxWidth={{ sx: '50%', md: '50%' }} mr={1}>
            <input id="customUpload" type="file" onChange={event => handleUpload(event, avatarDocId.value, "avatar")} hidden disabled={!basicInfo} />
            <label htmlFor={"customUpload"} style={{ display: 'block', width: '100%' }}>
              <Box
                sx={{ background: "#F3F8FF" }}
                border="2px dashed #999999"
                borderRadius={3}
              >
                <Grid container pt={1} pl={2} flexDirection={'row'} flexWrap={'nowrap'} display={'flex'}>
                  <Grid item sx={{ verticalAlign: "center" }}>
                    <FileUploadOutlined></FileUploadOutlined>
                  </Grid>
                  <Grid item px={1} sx={{ verticalAlign: "center" }}>
                    <Typography
                      style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block', overflow: 'hidden', width: '175px' }}
                      variant="body"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {avatarDocFileName.value ? avatarDocFileName.value : `Select your Avatar`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </label>
            <FormHelperText error={customError.avatarSizeError ? true : false}>
              The maximum file size allowed is 500KB.
            </FormHelperText>
          </Grid>
          {/* <Grid item xs={12} sm={2} sx={{ pt: "unset !important" }} ml={5}> */}
          <Avatar src={getImageUrl(props.data.avatarDocId, props.data.avatarDocFileName, 'avatar')} sx={{ width: "70px", height: "70px" }} />
          {/* </Grid> */}
        </Grid>

        {/* For removing the Avatar */}
        <Button
          variant="text"
          color="error"
          sx={{ textTransform: "capitalize" }}
          disabled={!basicInfo}
          onClick={() => { handleRemove("avatar") }}
        >
          Remove Avatar
        </Button>
      </Box>
    );
  }

  if (AccessableRoleForProfile.includes(props?.data?.role)) {
    profileInfo.push(
      <>
        <Box>
          <Typography variant="p">Address</Typography>
          <CustomInput
            disabled={!basicInfo}
            size="small"
            style={!isMobile ? { width: '50%' } : {}}
            value={userData.firstAddress}
            label={"Address Line 1"}
            onChange={(e) => { setUserData({ ...userData, firstAddress: e.target.value }) }}
            maxLength={MAXLength.address}
            error={resultValidation?.firstAddress}
            required
          />
        </Box>
        <CustomInput
          disabled={!basicInfo}
          size="small"
          style={!isMobile ? { width: '50%' } : {}}
          label={"Address Line 2"}
          onChange={(e) => { setUserData({ ...userData, secondAddress: e.target.value }) }}
          maxLength={MAXLength.address}
          value={userData.secondAddress}
          error={resultValidation?.secondAddress}
        />
        <CustomInput
          disabled={!basicInfo}
          size="small"
          style={!isMobile ? { width: '50%' } : {}}
          label={"City"}
          maxLength={MAXLength.city}
          error={resultValidation?.city}
          onChange={(e) => { setUserData({ ...userData, city: e.target.value }) }}
          required
          value={userData.city}
        />
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item md={6} xs={12} style={!isMobile ? { maxWidth: '25%' } : {}}>
            <CustomDropDown
              style={!isMobile ? { width: '100%' } : { width: '50%' }}
              label="State"
              disabled={!basicInfo}
              required
              placeholder={rowValue}
              defaultValue={rowValue}
              rowData={rowValue}
              value={rowValue}
              option={states.map((i) => {
                return {
                  value: i.stateCode,
                  label: i.stateCode,
                };
              })}
              changeCallBack={(e) => setRowValue(e.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              disabled={!basicInfo}
              size="small"
              style={!isMobile ? { width: '50%' } : {}}
              label={"ZIP"}
              onChange={(e) => { setUserData({ ...userData, zip: e.target.value }) }}
              type="number"
              required
              value={userData.zip}
              error={resultValidation?.zip}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  if (AccessableRoleForAvatarAndSignature.includes(props?.data?.role)) {
    profileInfo.push(

      <Box>
        <InputLabel sx={{ pb: "5px", fontSize: "15px" }}>
          Upload E-Signature
        </InputLabel>

        {/* Used for  making inline*/}
        <Grid container spacing={1} alignItems="center" style={{ flexWrap: 'nowrap' }}>
          <Grid item xs={10} maxWidth={{ sx: '50%', md: '50%' }}>
            <input id="esign" type="file" onChange={event => handleUpload(event, esignDocId.value, "esign")} hidden disabled={!basicInfo} />
            <label htmlFor={"esign"} style={{ display: 'block', width: '100%' }}>
              <Box
                sx={{ background: "#F3F8FF" }}
                border="2px dashed #999999"
                borderRadius={3}
              >
                <Grid container pt={1} pl={2} flexWrap={'nowrap'} display={'flex'}>
                  <Grid item sx={{ verticalAlign: "center" }}>
                    <FileUploadOutlined></FileUploadOutlined>
                  </Grid>
                  <Grid item px={1} sx={{ verticalAlign: "center" }}>
                    <Typography
                      id="avatar"
                      variant="body"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {esignDocFileName.value ? esignDocFileName.value : `Select your E-Signature`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </label>
            <FormHelperText error={customError.eSignSizeError ? true : false}>
              The maximum file size allowed is 500KB.
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ pt: "unset !important", width: "70px", height: "70px" }} ml={5}>
            {props.data.esignDocId && props.data.esignDocFileName &&
              <Avatar style={{ borderRadius: '0px', width: 'max-content' }} variant='sq' src={getImageUrl(props.data.esignDocId, props.data.esignDocFileName, 'avatar')} sx={{ width: "100%", height: "100%" }} />
            }
          </Grid>
        </Grid>

        {/* For removing the E-Signature */}
        <Button
          variant="text"
          color="error"
          sx={{ textTransform: "capitalize" }}
          disabled={!basicInfo
          }
          onClick={() => { handleRemove("esign") }}
        >
          Remove E - Signature
        </Button >
      </Box >
    );
  }

  // return (
  //   <>
  //     <Grid container direction={isMobile ? 'column' : 'row'}>
  //       {/* Left Column in md */}
  //       <Grid item xs={12} md={6} my={1} style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}
  //         display={isMobile ? 'flex' : ''}
  //         justifyContent={isMobile ? 'space-between' : ''}
  //         direction={isMobile ? 'row' : 'column'}>
  //         <Box>
  //           <Typography variant="h3">Personal Info</Typography>
  //           <Typography my={1} variant="body1" color="text.secondary">
  //             Update your Name, Avatar
  //           </Typography>
  //         </Box>
  //         <Box display={basicInfo ? "none" : ""}>
  //           <Button
  //             startIcon={<EditOutlined />}
  //             variant="outlined"
  //             onClick={() => {
  //               setBasicInfo((prev) => !prev);
  //             }}
  //           >
  //             Edit
  //           </Button>
  //         </Box>
  //       </Grid>
  //       {/* Right Column in md */}
  //       {/* <Grid item xs={12} md={4} my={1} style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : { width: '100%', display: 'flex' }}> */}
  //       <Grid item xs={12} md={4} my={1} style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}>
  //         <Box style={!isMobile ? { marginLeft: '45px' } : {}}>
  //           {profileInfo}
  //         </Box>

  //         <Box
  //           my={2}
  //           ml={isMobile ? 0 : 6}
  //           display={basicInfo ? "flex" : "none"}
  //         >
  //           {/* Used for  making inline*/}

  //           <Button
  //             variant="outlined"
  //             size="large"
  //             onClick={() => {
  //               setBasicInfo((prev) => !prev);
  //               setResultValidation({});
  //             }}
  //           >
  //             Cancel
  //           </Button>

  //           <Button
  //             variant="contained"
  //             size="large"
  //             style={{ marginLeft: '50px' }}
  //             onClick={handleUpdateBasicInfo}
  //           >
  //             Update
  //           </Button>
  //         </Box>
  //       </Grid>
  //     </Grid>

  //     <hr style={{ borderColor: "#0000001f" }} />

  //     {/* For Email */}
  //     {/* <Grid item xs={12} md={6} my={1} style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}> */}
  //     <IdentityChange identityValue={email} variant={"email"} />
  //     <hr style={{ borderColor: "#0000001f" }} />
  //     {/* </Grid> */}

  //     {/* For Phone */}
  //     <Grid container>
  //       <Grid item xs={12} md={6} my={1} style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}>
  //         <Typography variant="h3">Phone Number</Typography>
  //         <Typography my={1} variant="body1" color="text.secondary">
  //           {"Contact Customer service to update"}
  //           <br /> {"your phone number"}
  //         </Typography>
  //         <Box
  //           display={props?.data?.role === ROLE_LIST.borrower ? null : "none"}
  //         >
  //           <Box display={altPhoneChange ? "" : "none"}>
  //             <Button
  //               startIcon={<EditOutlined />}
  //               variant="outlined"
  //               onClick={() => {
  //                 setaltPhoneChange(false);
  //               }}
  //             >
  //               Edit
  //             </Button>
  //           </Box>
  //         </Box>
  //       </Grid>
  //       <Grid item xs={12} md={4} my={1} style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}>
  //         <Box style={!isMobile ? { marginLeft: '45px' } : {}}>
  //           <CustomInput
  //             change={{ value: usPhoneNumberFormatDefaultValue(phone.value) }}
  //             style={!isMobile ? { width: '50%' } : {}}
  //             sicon={<KSPhoneIcon />}
  //             disabled={true}
  //             label={"Phone Number"}
  //           />
  //         </Box>
  //         {/* <CustomInput
  //           change={{ value: contactNumber.value }}
  //           sicon={<LocalPhoneOutlined />}
  //           disabled={true}
  //           label={"Phone Number"}
  //         /> */}

  //         <Box
  //           my={2}
  //           display={props?.data?.role === ROLE_LIST.borrower ? null : "none"}
  //           style={{ marginLeft: '45px' }}
  //         >
  //           <form onSubmit={formik.handleSubmit}>
  //             <CustomInput
  //               style={!isMobile ? { width: '50%' } : {}}
  //               size="small"
  //               label="Alternate Phone No."
  //               change={altPhone}
  //               type="number"
  //               sicon={<KSPhoneIcon />}
  //               disabled={altPhoneChange}
  //               error={formik.errors.altPhone}
  //             />
  //             {/* This Eleement for field alert */}
  //             {/* <FormHelperText sx={{ color: 'red' }}>Kindly Give your First Name</FormHelperText> */}

  //             <Box
  //               my={2}
  //               display={altPhoneChange ? "none" : "flex"}
  //               justifyContent={"start"}
  //             >
  //               <Button
  //                 size="large"
  //                 color="primary"
  //                 onClick={() => {
  //                   setaltPhoneChange(true);
  //                   resetAltPhoneForm();
  //                 }}
  //                 type="button"
  //                 variant="outlined"
  //               >
  //                 Cancel
  //               </Button>

  //               <Button
  //                 style={{ marginLeft: "50px" }}
  //                 variant="contained"
  //                 size="large"
  //                 color="primary"
  //                 type="submit"
  //               >
  //                 Update
  //               </Button>
  //             </Box>
  //           </form>
  //         </Box>

  //         {/* Use for OTP */}
  //       </Grid>
  //     </Grid>

  //       {/* For Company NMLS ID */}
  //       {roleOwner(props?.data?.role) || props?.data?.role === ROLE_LIST.mlo ? (
  //         <>
  //           <hr style={{ borderColor: "#0000001f" }} />


  //           <IdentityChange identityValue={nmlsId} variant={"nmls"} />


  //         </>
  //       ) : null}
  //     </>
  //   );
  // }

  return (
    <>
      <Grid container direction={isMobile ? 'column' : 'row'}>
        {/* Left Column in md */}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3.6} md={3.6} my={1}
            //style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}
            display={isMobile ? 'flex' : ''}
            justifyContent={isMobile ? 'space-between' : ''}
            direction={isMobile ? 'row' : 'column'}>
            <Box>
              <Typography variant="h3">Personal Info</Typography>
              <Typography my={1} variant="body1" color="text.secondary">
                Update your Name, Avatar
              </Typography>
            </Box>
            <Box display={basicInfo ? "none" : ""}>
              <Button
                startIcon={<EditOutlined />}
                variant="outlined"
                onClick={() => {
                  setBasicInfo((prev) => !prev);
                }}
              >
                Edit
              </Button>
            </Box>
          </Grid>
          {/* Right Column in md */}
          {/* <Grid item xs={12} md={4} my={1} style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : { width: '100%', display: 'flex' }}> */}


          <Grid item xs={12} sm={8} md={8} my={1}
          // style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}
          >
            <Box
              style={!isMobile ? { marginLeft: '45px' } : {}}
            >

              {profileInfo}

            </Box>

            <Box
              my={2}
              ml={isMobile ? 0 : 6}
              display={basicInfo ? "flex" : "none"}
            >
              {/* Used for  making inline*/}

              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  setBasicInfo((prev) => !prev);
                  setResultValidation({});
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="large"
                style={{ marginLeft: '50px' }}
                onClick={handleUpdateBasicInfo}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <hr style={{ borderColor: "#0000001f" }} />

      {/* For Email */}
      {/* <Grid item xs={12} md={6} my={1} style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}> */}
      <IdentityChange identityValue={email} variant={"email"} />
      <hr style={{ borderColor: "#0000001f" }} />
      {/* </Grid> */}

      {/* For Phone */}
      <Grid container>
        <Grid item xs={12} sm={3.6} md={3.6} my={1}
        //style={!isMobile ? { maxWidth: '30%', minWidth: '30%' } : {}}
        >
          <Typography variant="h3">Phone Number</Typography>
          <Typography my={1} variant="body1" color="text.secondary">
            {"Contact Customer service to update"}
            <br /> {"your phone number"}
          </Typography>
          <Box
            display={props?.data?.role === ROLE_LIST.borrower ? null : "none"}
          >
            <Box display={altPhoneChange ? "" : "none"}>
              <Button
                startIcon={<EditOutlined />}
                variant="outlined"
                onClick={() => {
                  setaltPhoneChange(false);
                }}
              >
                Edit
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} my={1}
        //style={!isMobile ? { maxWidth: '70%', minWidth: '70%' } : {}}
        >
          <Box style={!isMobile ? { marginLeft: '45px' } : {}}>
            <CustomInput
              change={{ value: usPhoneNumberFormatDefaultValue(phone.value) }}
              style={!isMobile ? { width: '50%' } : {}}
              sicon={<KSPhoneIcon />}
              disabled={true}
              label={"Phone Number"}
            />
          </Box>
          {/* <CustomInput
            change={{ value: contactNumber.value }}
            sicon={<LocalPhoneOutlined />}
            disabled={true}
            label={"Phone Number"}
          /> */}

          <Box
            my={2}
            display={props?.data?.role === ROLE_LIST.borrower ? null : "none"}
            style={{ marginLeft: '45px' }}
          >
            <form onSubmit={formik.handleSubmit}>
              <CustomInput
                style={!isMobile ? { width: '50%' } : {}}
                size="small"
                label="Alternate Phone No."
                change={altPhone}
                type="number"
                sicon={<KSPhoneIcon />}
                disabled={altPhoneChange}
                error={formik.errors.altPhone}
              />
              {/* This Eleement for field alert */}
              {/* <FormHelperText sx={{ color: 'red' }}>Kindly Give your First Name</FormHelperText> */}

              <Box
                my={2}
                display={altPhoneChange ? "none" : "flex"}
                justifyContent={"start"}
              >
                <Button
                  size="large"
                  color="primary"
                  onClick={() => {
                    setaltPhoneChange(true);
                    resetAltPhoneForm();
                  }}
                  type="button"
                  variant="outlined"
                >
                  Cancel
                </Button>

                <Button
                  style={{ marginLeft: "50px" }}
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            </form>
          </Box>

          {/* Use for OTP */}
        </Grid>
      </Grid>

      {/* For Company NMLS ID */}
      {
        roleOwner(props?.data?.role) || props?.data?.role === ROLE_LIST.mlo ? (
          <>
            <hr style={{ borderColor: "#0000001f" }} />


            <IdentityChange identityValue={nmlsId} variant={"nmls"} />


          </>
        ) : null
      }
    </>
  );
}