import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import React from "react";
import CustomTooltip from './custom-tooltip';

export default function CustomRadioChipWithTooltip({
    data,
    state,
    label,
    field,
    required = false,
    radioWidth,
    labelStyle,
    error = null,
    labelComponent,
    tooltipTitle,
    chipValue
}) {
    const handleClick = (e) => {
        state.saveValue(e.target.id);
    };
    return (
        <Box my={2}>
            <FormControl>
                {labelComponent ? (
                    labelComponent
                ) : (
                    <FormLabel style={labelStyle}>
                        {label}
                        {required ? (
                            <Typography component={"span"} color={"error.main"}>
                                *
                            </Typography>
                        ) : null}
                    </FormLabel>
                )}

                <RadioGroup row style={radioWidth ? { width: radioWidth } : {}}>
                    {data.map((data) => (
                        <React.Fragment key={data.value}>
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                control={
                                    <Radio
                                        id={data.value}
                                        value={data.value}
                                        sx={{ display: "none" }}
                                    />
                                }
                                label={
                                    data.value === chipValue ? (
                                        <CustomTooltip title={tooltipTitle} arrow>
                                            <Chip
                                                label={data.label}
                                                color={
                                                    field?.value == data?.value || state?.value == data?.value
                                                        ? "primary"
                                                        : "default"
                                                }
                                                clickable={true}
                                                variant="outlined"
                                                sx={{ marginRight: 1.5, marginY: 1.5 }}
                                            />
                                        </CustomTooltip>
                                    ) : (
                                        <Chip
                                            label={data.label}
                                            color={
                                                field?.value == data?.value || state?.value == data?.value
                                                    ? "primary"
                                                    : "default"
                                            }
                                            clickable={true}
                                            variant="outlined"
                                            sx={{ marginRight: 1.5, marginY: 1.5 }}
                                        />
                                    )
                                }
                                {...(field ? field : { onClick: handleClick })}
                            />
                        </React.Fragment>
                    ))}
                </RadioGroup>
                {error && (
                    <FormHelperText sx={{ pl: 0, fontSize: 14 }} error>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}
// 