import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { formState } from '../../../../../recoil/profile-atom'
import { useRecoilState } from 'recoil'
import { UPDATE } from '../../../../../constants/constatnt-variable'
import { API } from '../../../../../services/api-config'
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal'
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon'
import { AssetOtherForm } from './assets-other-form'
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'
import CustomTooltip from '../../../../../helpers/custom-tooltip'

export default function LoanAssetsOther({ setValue, assetOtherList, setAssetOtherList }) {

    useEffect(() => {
        setValue('assetOther', assetOtherList)
    }, [assetOtherList])



    const [editState, setEditState] = useState(() => {
        return null
    })


    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(assetOtherList)
            let editItem = list.find((item, i) => i == editState)
            setAssetOtherEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            assetOtherList.splice(deleteState, 1)
            setAssetOtherList([...assetOtherList])
            setDeleteState(null)
        }

    }, [deleteState])


    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "accountType",
                header: "Account type",
                enableColumnActions: false,

            },
            {
                accessorKey: "marketValue",
                header: "Market Value",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography >
                        {`$ ${formatNumericValue(row.original["marketValue"])}`}
                    </Typography>
                ),
            },

        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [assetOtherEdit, setAssetOtherEdit] = useState();


    const handleModalOpen = () => {

        setAssetOtherEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }

    const assetOtherSubmit = async data => {
        let list = _.cloneDeep(assetOtherList)
        data = Object.assign(data, {

        })

        if (editState != null) {
            list[editState] = {
                ...list[editState],
                ...data
            }


            setEditState(null)
        } else {
            list.push(data)

        }

        setAssetOtherList(list)
        handleClose();

    }




    return (
        <>


            <Box >

                <Typography variant='h3' mb={1} mt={3} >{`Other Assets`}</Typography>


                <CustomReactMaterialTable
                    staticLoad={true}
                    staticData={assetOtherList}
                    columns={tableColumn}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableSorting={false}
                    enableToolbarInternalActions={false}
                    renderTopToolbarCustomActions={
                        () => (
                            <>
                                <CustomTooltip
                                    title="Provide information about any additional assets or credits not included in standard categories to create a more comprehensive financial profile."
                                >
                                    <Button onClick={handleModalOpen} variant='outlined' >Add</Button>
                                </CustomTooltip>
                            </>
                        )
                    }
                    enableRowActions={true}
                    renderRowActions={
                        ({ row }) => {

                            return (
                                <Stack direction={'row'}>
                                    <IconButton onClick={() => { setEditState(row.index) }}>
                                        <KSPencilAltIcon />
                                    </IconButton>


                                    <ConfirmationModel
                                        body={<IconButton id={row.index}>
                                            <KSTrashIcon />
                                        </IconButton>}

                                        message={`Are you sure want remove`}
                                        title={'Delete '}
                                        handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                    />

                                </Stack>
                            )
                        }
                    }
                />

            </Box>




            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Other assets`}
                body={
                    <AssetOtherForm
                        onSubmit={assetOtherSubmit}
                        editItem={assetOtherEdit}

                    />
                }
            />


        </>
    )
}
