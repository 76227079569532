import { Box, Button, Container, Grid, List, ListItem, ListItemText, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KSCloudUploadIcon, KSUploadIcon, KSUploadIconDoc } from "../../../../icons/custome-icon";
import { KSTooltip } from "../../../../helpers/ks-tooltip";
import { kstheme } from "../../../../theme/theme";

function PreviewTask({ formData, handleSend, handleCancelPreview, info, contextSupportingDocs }) {



    const [listDocumentTypes, setListDocumentTypes] = useState([])
    const [listSupportingDocs, setListSupportingDocs] = useState([])
    const [infoData, setInfoData] = useState(null)
    const isSmallScreen = useMediaQuery(kstheme.breakpoints.down("sm"));
    useEffect(() => {
        setListDocumentTypes(formData?.requiredDocuments.map(doc => doc.documentType));

        setListSupportingDocs(contextSupportingDocs.map(doc => doc.documentName))
        setInfoData(info)
    }, [formData, contextSupportingDocs]);



    function capitalize(name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }


    return (<>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="left">
            <Box mr={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        <KSUploadIconDoc width={42} height={42} />
                    </Typography>

                </Stack>

            </Box>

            <Box style={{ display: 'flex', flexDirection: 'column', padding: 0, }}>

                <Box>

                    <Typography variant="h6"> </Typography>

                    <Grid item xs={12} md={6} mb={3}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {/* <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                {formData?.taskTypeName}
                            </Typography> */}
                            <KSTooltip title={formData?.taskTypeDescription} arrow>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    {formData?.taskTypeName}
                                </Typography>
                            </KSTooltip>
                            <Typography sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 400, color: '#2F80ED' }}>
                                {formData?.taskPriority}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid container spacing={0} sx={{ flexGrow: 1, alignItems: "center" }}>
                        <Grid item xs={12} md={6} mb={2}>
                            <Stack direction={isSmallScreen ? 'column' : 'row'}
                                alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                spacing={1}
                                sx={{ width: '80%' }}>
                                {/* <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Borrower Name:
                                </Typography>
                                <Typography variant="body1">
                                    {infoData?.borrowerInfos[0]?.firstName}{infoData?.borrowerInfos[0]?.lastName}
                                </Typography> */}
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Borrower Name:
                                </Typography>
                                <Typography variant="body1">
                                    {infoData?.borrowerInfos[0]?.firstName && capitalize(infoData.borrowerInfos[0].firstName)}{' '}
                                    {infoData?.borrowerInfos[0]?.lastName && capitalize(infoData.borrowerInfos[0].lastName)}
                                </Typography>

                            </Stack>

                        </Grid>
                        <Grid item xs={12} md={6} mb={2}>
                            <Stack direction={isSmallScreen ? 'column' : 'row'}
                                alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                spacing={1}
                                sx={{ width: '80%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    MLO Name:
                                </Typography>
                                <Typography variant="body1">
                                    {infoData?.mloInfo?.firstName}{infoData?.mloInfo?.lastName}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} mt={2} mb={2}>

                            <Stack
                                // direction="row"
                                // alignItems="flex-start" // Aligns items to the start of the row
                                // justifyContent="space-between"
                                // spacing={1}
                                // sx={{ width: '80%' }}
                                direction={isSmallScreen ? 'column' : 'row'}
                                alignItems={isSmallScreen ? 'flex-start' : 'flex-start'}
                                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                spacing={1}
                                sx={{ width: '80%' }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Property:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ textAlign: 'right' }} // Aligns the property details to the right
                                >
                                    <Typography variant="body1" sx={{ textAlign: isSmallScreen ? 'left' : 'right' }}>
                                        {[infoData?.loanSummary?.firstAddress,
                                        infoData?.loanSummary?.secondAddress,
                                        infoData?.loanSummary?.unit,
                                        infoData?.loanSummary?.city,
                                        infoData?.loanSummary?.county,
                                        infoData?.loanSummary?.state,
                                        infoData?.loanSummary?.zip]
                                            .filter(Boolean)
                                            .join(', ')}
                                    </Typography>

                                </Typography>
                            </Stack>


                        </Grid>
                        <Grid item xs={12} md={6} mb={2}>
                            <Stack direction={isSmallScreen ? 'column' : 'row'}
                                alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                spacing={1}
                                sx={{ width: '80%' }}>

                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Loan Amount:
                                </Typography>
                                <Typography variant="body1">
                                    {infoData?.loanSummary?.loanAmount
                                        ? `$ ${new Intl.NumberFormat('en-US').format(infoData.loanSummary.loanAmount)}`
                                        : ''}
                                </Typography>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} mb={2}>

                        </Grid>
                        <Grid item xs={12} md={6} mb={2}>
                            <Stack direction={isSmallScreen ? 'column' : 'row'}
                                alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                spacing={1}
                                sx={{ width: '80%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Due Date:
                                </Typography>
                                <Typography variant="body1">
                                    {formData?.dueDate}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} mt={2} mb={2}>
                            <Stack direction={isSmallScreen ? 'column' : 'row'}
                                alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                spacing={1}
                                sx={{ width: '80%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Loan Type:
                                </Typography>
                                <Typography variant="body1">
                                    {infoData?.loanSummary?.loanType}
                                </Typography>
                            </Stack>

                        </Grid>
                        <Grid item xs={12} md={6} mb={2}>
                            <Stack direction={isSmallScreen ? 'column' : 'row'}
                                alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                spacing={1}
                                sx={{ width: '80%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Is lender Condition ?
                                </Typography>
                                <Typography variant="body1">
                                    {formData?.lenderCondition === true ? 'Yes' : 'No'}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12} mt={2} mb={4}>
                            <Typography
                                sx={{
                                    maxWidth: '92%',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {formData?.taskDescription}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} mt={4} mb={2}>
                        {(listSupportingDocs && listSupportingDocs.length > 0) ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Supporting Documents
                                </Typography>
                            </Stack>
                        ) : null}
                    </Grid>

                    <List>
                        {listSupportingDocs.map((item, index) => (

                            <ListItem key={index}>
                                <ListItemText primary={`${index + 1}. ${item}`} />
                            </ListItem>
                        ))}
                    </List>
                    <Grid item xs={12} md={12} mt={4} mb={2}>
                        {(listDocumentTypes && listDocumentTypes.length > 0) ? (
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Required Documents
                                </Typography>
                            </Stack>
                        ) : null}
                    </Grid>


                    <List>
                        {listDocumentTypes.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${index + 1}. ${item}`} />
                            </ListItem>
                        ))}
                    </List>

                    <Grid container spacing={2} mt={3} mb={3}>
                        <Grid item xs={12} md={6}>

                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" gap={2} justifyContent="flex-end">

                                <Button variant="text" onClick={handleCancelPreview}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleSend}>
                                    Send
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>


                </Box >
            </Box>
        </Box>
    </>
    );
}

export default PreviewTask;
