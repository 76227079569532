import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";

export default function LoanSummaryMloMlpConfirmationForm({
  type,
  handleClose,
  handleOpne,
}) {
  return (
    <>
      <Box my={3}>
        <Typography>
          {`Are you sure you want to change the ${type} for this loan?`}
        </Typography>
      </Box>
      <Stack my={2} direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <Button variant="outlined" onClick={handleClose}>
          No
        </Button>
        <Button variant="contained" onClick={handleOpne}>
          Yes
        </Button>
      </Stack>
    </>
  );
}
