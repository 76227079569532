// import { Box, InputLabel, TextField, Typography } from '@mui/material';
// import React from 'react'
// import { usePlacesWidget } from 'react-google-autocomplete';
// import ErrorIcon from "@mui/icons-material/Error";
// import { GOOGLE_MAP_API } from '../constants/constatnt-variable';

// export default function GoogleAutocomplete({
//     error = null,
//     label,
//     required,
//     setValue,
//     name,
//     marginBottom = 3,
//     marginTop = 1,
//     ...props
// }) {

//     // const { ref } = usePlacesWidget({
//     //     apiKey: GOOGLE_MAP_API,
//     //     onPlaceSelected: (place) => {
//     //         setValue(name, place.formatted_address)
//     //     },
//     //     options: {
//     //         // This option configure to only show USA location
//     //         types: ["(regions)"],
//     //         componentRestrictions: { country: "us" },
//     //     }

//     // });

//     const { ref } = usePlacesWidget({
//         apiKey: GOOGLE_MAP_API,
//         onPlaceSelected: (place) => {
//             setValue(name, place.formatted_address);



//             // const addressComponents = place.address_components;
//             // setValue('city', addressComponents.find(component => component.types.includes('locality')).long_name);
//             // setValue('county', addressComponents.find(component => component.types.includes('administrative_area_level_2')).long_name);
//             // setValue('state', addressComponents.find(component => component.types.includes('administrative_area_level_1')).short_name);
//             // setValue('country', addressComponents.find(component => component.types.includes('country')).short_name);
//             // setValue('zip', ''); // You may need to handle zip code separately based on your use case

//             const addressComponents = place.address_components;

//             let locality, county, state, country, city, addressLine1, addressLine2;

//             place.address_components.forEach((data) => {
//                 const { types, long_name, short_name } = data;

//                 if (types.includes('locality')) {
//                     addressLine1 = { long_name, short_name };
//                 } else if (types.includes('administrative_area_level_2')) {
//                     addressLine2 = { long_name, short_name };
//                 } else if (types.includes('administrative_area_level_1')) {
//                     state = { long_name, short_name };
//                 } else if (types.includes('country')) {
//                     country = { long_name, short_name };
//                 }
//             });

//             // Set the values in the form
//             // setValue('city', city,.long_name);
//             setValue('addressLine1', addressLine1.long_name);
//             setValue('addressLine2', addressLine2.long_name);
//             setValue('state', state.short_name);
//             setValue('county', state.long_name);
//         },
//         options: {
//             // This option configure to only show USA location
//             types: ["(address)"],
//             componentRestrictions: { country: "us" },
//         }
//     });

//     return (
//         <Box mb={marginBottom} mt={marginTop}>
//             <InputLabel>
//                 {label}
//                 {required ? (
//                     <Typography variant="small" color="error">
//                         *
//                     </Typography>
//                 ) : null}
//             </InputLabel>
//             <TextField
//                 fullWidth
//                 inputRef={ref}
//                 InputProps={{
//                     endAdornment:
//                         error && props?.id !== "password" ? (
//                             <ErrorIcon color="error" />
//                         ) : null,
//                 }}
//                 {...props}
//                 {...(error && { error: true, helperText: error })}
//             />
//         </Box>
//     )
// }

import { Box, InputLabel, TextField, Typography } from '@mui/material';
import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete';
import ErrorIcon from "@mui/icons-material/Error";
import { GOOGLE_MAP_API } from '../constants/constatnt-variable';

export default function GoogleAutocomplete({
    error = null,
    label,
    required,
    setValue,
    name,
    marginBottom = 3,
    marginTop = 1,
    onStateSelected,
    ...props
}) {
    const { ref } = usePlacesWidget({
        apiKey: GOOGLE_MAP_API,
        onPlaceSelected: (place) => {
            setValue(name, place.formatted_address);


            const addressComponents = place.address_components;


            // Extract state information
            const stateFetched = place.address_components.find(component => component.types.includes('administrative_area_level_1'));

            if (stateFetched && stateFetched.short_name) {
                // Pass selected state back to the parent component
                onStateSelected(stateFetched.short_name);

            }


            let neighborhoodAdd, localityAdd, routeAddress, streetNumberAdd, streetNumber, addressLine1, routeAdd, neighborhood, locality, county, state, country, city, administrativeAreaLevel2, administrativeAreaLevel1, postalCode;

            place.address_components.forEach((data) => {
                const { types, long_name, short_name } = data;
                if (types.includes('street_number')) {
                    streetNumber = { long_name, short_name };
                } else

                    if (types.includes('route')) {
                        routeAdd = { long_name, short_name };
                    } else if (types.includes('neighborhood')) {
                        neighborhood = { long_name, short_name };
                    }
                    else if (types.includes('locality')) {
                        locality = { long_name, short_name };
                    } else if (types.includes('administrative_area_level_2')) {
                        administrativeAreaLevel2 = { long_name, short_name };
                    } else if (types.includes('administrative_area_level_1')) {
                        administrativeAreaLevel1 = { long_name, short_name };
                    } else if (types.includes('country')) {
                        country = { long_name, short_name };
                    } else if (types.includes('postal_code')) {
                        postalCode = { long_name, short_name };
                    }
            });

            localityAdd = locality ? locality.long_name : ''
            routeAddress = routeAdd ? routeAdd.long_name : ''
            neighborhoodAdd = neighborhood ? neighborhood.long_name : ''
            streetNumberAdd = streetNumber ? streetNumber.long_name : ''

            addressLine1 = streetNumberAdd + " " + routeAddress
            setValue('addressLine1', addressLine1);
            setValue('addressLine2', neighborhood ? neighborhood.long_name : '');
            setValue('unit', county ? county.long_name : '');
            setValue('city', locality ? locality.short_name : '');
            //  setValue('state', administrativeAreaLevel1 ? administrativeAreaLevel1.short_name : '');
            setValue('zip', postalCode ? postalCode.short_name : '');
            setValue('county', administrativeAreaLevel2 ? administrativeAreaLevel2.short_name : '');
        },
        options: {

            types: ["address"],
            componentRestrictions: { country: "us" },
        }
    });

    return (
        <Box mb={marginBottom} mt={marginTop}>
            <InputLabel>
                {label}
                {required ? (
                    <Typography variant="small" color="error">
                        *
                    </Typography>
                ) : null}
            </InputLabel>
            <TextField
                fullWidth
                inputRef={ref}
                InputProps={{
                    endAdornment:
                        error && props?.id !== "password" ? (
                            <ErrorIcon color="error" />
                        ) : null,
                }}
                {...props}
                {...(error && {
                    error: true,
                    //helperText: error
                    helperText: (
                        <Typography variant="body2" color="error" style={{ fontSize: 14 }}>
                            {error}
                        </Typography>
                    )


                })}


            />
        </Box>
    )
}

