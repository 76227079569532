import {
    AccessTime,
    EditOutlined,
    Handshake,
    SwapVertOutlined,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { KSUploadIcon } from "../../../../icons/custome-icon";
import { DialogCommonHooks, DocumentDialogCommonHooks } from "../../../../helpers/modal/modal";
import LoanSummaryTaskForm from "../loan-summary-forms/loan-summary-task-from";
import { TimeStampToDateMonth } from "../../../../helpers/time-formater";
import LoanSummaryTaskResolveBorrowerForm from "../loan-summary-forms/loan-summary-task-resolve-borrower-form";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import LoanSummaryTaskResolveBrokerForm from "../loan-summary-forms/loan-summary-task-resolve-broker-form";
import ThirdPartyPreviewTask from "../../../../pages/third-party/third-party-preview";
import { fetchBorrowerDetails, getTaskStatusLabel } from "../../../../pages/third-party/third-party-util";
import BorrowerPreviewTask from "../../../../pages/third-party/borrower-preview";


export default function BorrowerTaskCard({
    formId,
    taskCardData,
    borrowerName,
    taskReload,
}) {








    const [borrowerTaskDetails, setBorrowerTaskDetails] = useState(null);
    const [dotStatus, setDotStatus] = useState(true)

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const res = await fetchBorrowerDetails(taskCardData?.taskCode);
    //         if (res?.status === 200) {
    //             setBorrowerTaskDetails(res.data);

    //         } else {
    //             console.error('Failed to fetch borrower task details');
    //         }
    //     };

    //     fetchData();
    // }, []);








    const { visiblity } = useContext(LoanSummaryParentData);

    const editVisible =
        taskCardData.taskStatus !== "Completed" && visiblity

    // Changing card icon color based on Task type
    let cardIcon, iconBG;
    switch (taskCardData.taskType) {
        case "Download & upload":
            cardIcon = <SwapVertOutlined />;
            iconBG = "#FBAAA7";

            break;

        case "External":
            cardIcon = <Handshake />;
            iconBG = "#B880FF";
            break;

        case "Upload":
            cardIcon = <KSUploadIcon />;
            iconBG = "#8EE1E7";
            break;

        default:
            break;
    }

    // Edit Task Modal
    const [editTask, setEditTask] = useState(false);

    const handleEditTaskModalOpen = () => {
        setEditTask(true);
    };

    const handleEditTaskModalClose = () => {
        setEditTask(false);
    };

    // Resolve Modal Start
    // Broker Resolve Task Modal
    const [resolveTask, setResolveTask] = useState(false);
    const handleResolveTaskModalOpen = () => {
        setResolveTask(true);
    };

    const handleResolveTaskModalClose = () => {
        setResolveTask(false);
    };

    // Borrower Resolve Task Modal
    const [resolveTaskBorrower, setResolveTaskBorrower] = useState(false);
    // const handleResolveTaskBorrowerModalOpen = () => {
    //     setBorrowerTaskDetails(fetchBorrowerDetails(taskCardData?.taskCode))
    //     setResolveTaskBorrower(true);
    // };

    const handleResolveTaskBorrowerModalOpen = async () => {
        const borrowerDetails = await fetchBorrowerDetails(taskCardData?.taskCode);
        if (borrowerDetails) {
            setBorrowerTaskDetails(borrowerDetails);

            setResolveTaskBorrower(true);
        } else {
            console.error("Failed to fetch borrower task details");
        }
    };


    const handleResolveTaskBorrowerModalClose = () => {
        setResolveTaskBorrower(false);
    };
    // Resolve Modal End
    const brokerResolveButton = (
        <Button
            variant="outlined"
            size="small"
            onClick={handleResolveTaskModalOpen}
        >{`Resolve`}</Button>
    );
    // const borrowerResolveButton =
    //     taskCardData.statusInfo !== "Review Pending" ? (
    //         <Button
    //             variant="outlined"
    //             size="small"
    //             onClick={handleResolveTaskBorrowerModalOpen}
    //         >{`Resolve`}</Button>
    //     ) : null;
    const borrowerResolveButton =
        taskCardData.statusInfo !== "created" ? (
            <Button
                variant="outlined"
                size="small"
                onClick={handleResolveTaskBorrowerModalOpen}
            >{`Resolve`}</Button>
        ) : null;

    const resolveVisiblity = visiblity
        ? brokerResolveButton
        : borrowerResolveButton;

    return (
        <>
            <Box bgcolor={"white"} borderRadius={3} p={2} my={{ xs: 2, md: 2 }}>
                {/* Main Frame of Card Start*/}
                <Stack direction={"row"} gap={{ xs: 1, md: 3 }}>
                    {/* Card Icon */}
                    <Avatar sx={{ bgcolor: iconBG }}>{cardIcon}</Avatar>

                    <Grid container spacing={{ xs: 2, md: 1 }}>
                        {/* Card Title with Edit Icon  Start*/}
                        <Grid item xs={12} md={8} lg={9}>
                            <Typography variant="h5">
                                {`${taskCardData.taskType} `}
                                <Box component={"span"} mx={1}>
                                    {/* Task Edit button Start */}
                                    {/* {editVisible ? (
                                        <IconButton
                                            size="small"
                                            sx={{ marginRight: 1 }}
                                            onClick={handleEditTaskModalOpen}
                                        >
                                            <EditOutlined color="primary" sx={{ fontSize: "18px" }} />
                                        </IconButton>
                                    ) : null} */}
                                    {/* Task Edit button End */}

                                    <Typography
                                        component={"span"}
                                        color={"primary"}
                                        variant="body2"
                                        textTransform={"capitalize"}
                                    >
                                        {taskCardData.priority}
                                    </Typography>
                                    {((taskCardData.taskStatus === "COMPLETED"
                                        || taskCardData.taskStatus === "PARTIALCOMPLETE"
                                        || taskCardData.taskStatus === "INCOMPLETE") && dotStatus)

                                        && (
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    backgroundColor: 'red',
                                                    marginLeft: 1,
                                                }}
                                            />
                                        )}
                                </Box>
                            </Typography>
                        </Grid>
                        {/* Card Title with Edit Icon End*/}

                        {/* Card Created and Due Date Start */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            textAlign={{ xs: "left", md: "right" }}
                        >
                            <Typography variant="body2">{`Created on: ${TimeStampToDateMonth(
                                taskCardData.createdTime
                            )}`}</Typography>
                            <Typography
                                variant="body2"
                                color={"warning.main"}
                            >{`Due on: ${TimeStampToDateMonth(
                                taskCardData.dueDate
                            )}`}</Typography>
                        </Grid>
                        {/* Card Created and Due Date End */}

                        {/* Card Descripton Start */}
                        <Grid item xs={12} md={8} lg={9}>
                            <Typography
                                variant="body1"
                                textAlign={"justify"}
                                sx={{
                                    maxWidth: '100%', // Adjusted to ensure it fits the available space
                                    wordBreak: 'break-word',
                                    overflow: 'hidden', // Ensures content doesn't overflow
                                    textOverflow: 'ellipsis', // Adds ellipsis for overflowed text
                                    whiteSpace: 'normal', // Allows the text to wrap
                                    //padding: 1, // Optional: Add padding for better spacing
                                }}
                            >
                                {taskCardData.description}
                            </Typography>
                        </Grid>
                        {/* Card Descripton End */}

                        {/* Card Status and resolve Start */}
                        <Grid item xs={12} md={8} lg={9} pt={1}>
                            <Stack direction={"row"} gap={{ xs: 1, md: 2 }} flexWrap={"wrap"}>
                                <Typography variant="body1" color={"primary"}>
                                    {`Status:`}
                                    <Typography component={"span"} color={"initial"} ml={1}>
                                        {taskCardData.statusInfo}
                                    </Typography>
                                    <Typography component={"span"} color={"initial"} ml={1}>
                                        {getTaskStatusLabel(taskCardData.taskStatus)}
                                    </Typography>
                                </Typography>

                                {taskCardData.taskStatus == "Active" ? resolveVisiblity : null}


                            </Stack>

                        </Grid>
                        {/* Card Status and resolve End */}

                        {/* Card Status Icon Start */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            py={{ xs: 1, md: 0 }}
                            alignSelf={"end"}
                        >
                            {taskCardData.taskStatus !== "Active" ? null : (
                                <Stack
                                    direction={"row"}
                                    gap={1}
                                    alignItems={"center"}
                                    justifyContent={{ xs: "start", md: "end" }}
                                >
                                    <AccessTime color="warning" sx={{ fontSize: "18px" }} />
                                    <Typography variant="body1" color={"warning.main"}>
                                        {`Pending`}
                                    </Typography>
                                </Stack>
                            )}
                            <Button
                                variant="outlined"
                                size="small"

                                onClick={() => {
                                    handleResolveTaskBorrowerModalOpen();
                                    setDotStatus(false);
                                }}
                            >{`Resolve`}</Button>
                        </Grid>
                        {/* Card Status Icon End */}
                    </Grid>
                </Stack>
            </Box>
            {/* Main Frame of Card End */}

            {/* Task Edit Modal */}
            <DialogCommonHooks
                title={`Edit Task for ${borrowerName}`}
                show={editTask}
                handleClose={handleEditTaskModalClose}
                body={
                    <LoanSummaryTaskForm
                        edit
                        formId={formId}
                        taskReload={taskReload}
                        taskFormData={taskCardData}
                        handleClose={handleEditTaskModalClose}
                    />
                }
            />

            {/* Task Resolve Modal */}
            <DialogCommonHooks
                title={taskCardData.taskType}
                show={resolveTask}
                handleClose={handleResolveTaskModalClose}
                body={
                    <LoanSummaryTaskResolveBrokerForm
                        formId={formId}
                        taskResolveData={taskCardData}
                        taskReload={taskReload}
                        handleClose={handleResolveTaskModalClose}
                    />
                }
            />
            <DocumentDialogCommonHooks
                show={resolveTaskBorrower}
                handleClose={handleResolveTaskBorrowerModalClose}

                size={'lg'}
                body={
                    <BorrowerPreviewTask
                        // isPreview={true}
                        //   handleSend={handleSend}
                        handleCancelPreview={handleResolveTaskBorrowerModalClose}
                        formData={borrowerTaskDetails}
                        //   accessCode={accessCode}
                        //  setUploadFile={setUploadFile}
                        taskCode={taskCardData?.taskCode}
                        taskReload={taskReload}
                    />
                }
            />
            {/* <DialogCommonHooks
                title={taskCardData.taskType}
                show={resolveTaskBorrower}
                handleClose={handleResolveTaskBorrowerModalClose}
                body={
                    <LoanSummaryTaskResolveBorrowerForm
                        formId={formId}
                        taskResolveData={taskCardData}
                        taskReload={taskReload}
                        handleClose={handleResolveTaskBorrowerModalClose}
                    />
                }
            /> */}
        </>
    );
}
