import { Typography } from '@mui/material'
import React from 'react'
import MainLoyOut from '../../components/main-layout'

export default function Welcome(props) {
    return (
        <MainLoyOut>
            <Typography variant='h2' mb={2}>Welcome {props?.user?.data?.firstName} !</Typography>
            <Typography>{`Congratulations on your progress! Finish your loan application with ease, and watch your dreams come true, just a few steps away.`}</Typography>
        </MainLoyOut>
    )
}
