import React, { Switch } from "@mui/material";
import { useState } from "react";

export const CustomSwitch = ({ disabled, defaultValue, callBackForSwitch, checkedValue }) => {
    const [checked, setChecked] = useState(() => {
        return defaultValue ? defaultValue : "";
    });

    const handleChange = (event) => {
        setChecked(event.target.checked);

        callBackForSwitch({ state: event.target.checked })
        if (checkedValue) {
            checkedValue(event.target.checked);
        }
    };

    return (
        <Switch
            disabled={disabled}
            checked={checked}
            onChange={handleChange}
        />
    )
}