import { Box, IconButton, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function ConfirmationModel({
  body,
  color,
  variant,
  buttonName = 'Yes',
  message,
  confirmationFields,
  disabled = false,
  title,
  handleSubmit,
  handleOpenTimeCall = () => {
    // Nothing

  },
  ...props
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    handleOpenTimeCall()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOperation = (e) => {
    handleSubmit(e);
    handleClose();
  };

  return (
    <>
      <div onClick={disabled ? null : handleClickOpen}>{body}</div>

      <Dialog
        fullWidth
        maxWidth={props.size}
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          sx={{ fontWeight: "bold", display: title ? "" : "none" }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box width={'100%'} my={2} >
            <Typography mb={2}>{message}</Typography>
            {confirmationFields}
            <Stack direction={'row-reverse'} gap={2} >
              <Button id={body?.props?.id} variant='contained' value='yes' onClick={handleOperation}>{buttonName}</Button>
              <Button variant='text' value='no' onClick={handleClose}>Cancel</Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
