import React, { useEffect, useState } from 'react'
import { AttachMoney } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Stack, Typography, Box } from '@mui/material'
import CustomInput from '../../../../../../../helpers/custom-input'

import { Controller, useForm, useWatch } from 'react-hook-form'
import { PropertyAddress } from '../../../../../../../components/loan-application/property-address'
import { usPhoneNumberFormatDefaultValue } from '../../../../../../../constants/validation'
import { TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater'
import { SecondaryEmployedValidation } from '../../../../../../../constants/validation-rules'
import CustomLargeLabel from '../../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../../helpers/radio-chip'
import { ExtraIncomeValidation } from '../../../../../../../constants/validation-rules'
import CustomDropDown from "../../../../../../../helpers/dropdown/custom-dropdown";
import {
    PAY_FREQUENCY, OWNERSHIP
} from "../../../../../../../constants/constatnt-variable";
import { formatNumericValue, parseNumericValue } from '../../../../../../../helpers/numeric-format'


export default function SecondaryEmploymentForm({ onSubmit, editItem }) {




    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            employmentStatus: editItem?.employmentStatus,
            businessName: editItem?.businessName,
            employNumber: usPhoneNumberFormatDefaultValue(editItem?.employNumber),
            positionOfTitle: editItem?.positionOfTitle,
            startDate: TimeStampToMonthDateYearForInput(editItem?.startDate),

            profession: editItem?.profession,
            monthlyIncome: editItem?.monthlyIncome,
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry ? editItem?.stateOfCountry : null,
            zip: editItem?.zip,


            secondaryEmploymentType: editItem?.secondaryEmploymentType,
            payFrequency: editItem?.payFrequency,
            isSideIncomeAvailable: editItem?.isSideIncomeAvailable,
            avgMonthlyIncome: editItem?.avgMonthlyIncome,
            avgMonthlyOvertime: editItem?.avgMonthlyOvertime,
            militaryEntitlement: editItem?.militaryEntitlement,
            avgMonthlyComission: editItem?.avgMonthlyComission,
            employedByOther: editItem?.employedByOther,
            ownerShip: editItem?.ownerShip,
            country: editItem?.country ? editItem?.country : null,
        }
    })

    const {
        stateofCountry = null,
        isStillWorking,
        isSideIncomeAvailable,
        employedByOther,
        secondaryEmploymentType,
        country = null,
        payFrequency } = useWatch({ control })





    useEffect(() => {
        if (secondaryEmploymentType === "selfEmployed") {
            setValue('employNumber', null);
            setValue('payFrequency', null);
            setValue('isSideIncomeAvailable', null);
            setValue('avgMonthlyIncome', null);
            setValue('avgMonthlyOvertime', null);
            setValue('militaryEntitlement', null);
            setValue('avgMonthlyComissetValue', null);
            setValue('employedByOther', null);
        }
        if (secondaryEmploymentType === "employed") {
            setValue('ownerShip', null);
        }

    }, [secondaryEmploymentType]);

    const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(editItem?.workPhone));
    const [unformatWorkPhone, setUnformatWorkPhone] = useState(editItem?.workPhone || '');


    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }

    function handleWorkPhoneChange(event) {
        let unformattedValue = event.target.value.replace(/\D/g, '');

        if (unformattedValue.length > 10) {
            unformattedValue = unformattedValue.slice(0, 10);
        }

        setUnformatWorkPhone(unformattedValue);
        setFormatWorkPhone(formatPhoneNumber(unformattedValue));
    }

    function handleWorkPhoneChangeDB() {
        setValue('employNumber', unformatWorkPhone)
    }

    return (
        <form id={'secondaryEmployed'} onSubmit={handleSubmit(onSubmit)}>


            <Controller
                name='secondaryEmploymentType'
                control={control}
                rules={SecondaryEmployedValidation.secondaryEmploymentType}
                render={({ field }) => (
                    <CustomRadioChip
                        label={<CustomLargeLabel value={`Employment Status`} required />}
                        {...field}
                        field={field}
                        state={secondaryEmploymentType}
                        data={[
                            { id: 'employed', value: "employed", label: "Employed" },
                            { id: 'selfEmployed', value: "selfEmployed", label: "Self Employed" },
                        ]}
                        error={errors?.secondaryEmploymentType?.message}
                    />
                )}
            />
            {secondaryEmploymentType === 'selfEmployed' ?
                <Controller
                    name='ownerShip'
                    control={control}
                    rules={SecondaryEmployedValidation.ownerShip}
                    render={({ field }) => (
                        <CustomRadioChip
                            {...field}
                            field={field}
                            required
                            label={'Owernship'}
                            data={OWNERSHIP}
                            error={errors?.ownerShip?.message}
                        />
                    )}
                /> : null}

            <Controller
                name='businessName'
                control={control}
                rules={SecondaryEmployedValidation.businessName}
                render={({ field }) => (
                    <CustomInput {...field} {...field} type={'text'} label={`Employer or Business Name`} required error={errors?.businessName?.message} />
                )}
            />
            {secondaryEmploymentType === 'employed' ?

                <Controller
                    name='employNumber'
                    control={control}
                    rules={SecondaryEmployedValidation.employNumber}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type={'text'} label={`Employer Phone Number`}
                            required
                            error={errors?.employNumber?.message}
                            value={formatWorkPhone}
                            onChange={handleWorkPhoneChange}
                            onBlur={handleWorkPhoneChangeDB}
                        />
                    )}
                />

                : null}

            <Controller
                name='positionOfTitle'
                control={control}
                rules={SecondaryEmployedValidation.positionOfTitle}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Position or Title`} required error={errors?.positionOfTitle?.message} />
                )}
            />


            <Controller
                name='startDate'
                control={control}
                rules={SecondaryEmployedValidation.startDate}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type={'date'}
                        label={`Start Date`}
                        required
                        error={errors?.startDate?.message}
                    />
                )}
            />

            <Controller
                name='profession'
                control={control}
                rules={SecondaryEmployedValidation.profession}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Years in this line of profession `}

                        required error={errors?.profession?.message} />
                )}
            />



            <Controller
                name='monthlyIncome'
                control={control}
                rules={SecondaryEmployedValidation.monthlyIncome}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Monthly Income `} sicon={<AttachMoney />}
                        value={formatNumericValue(field.value)}
                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                        required error={errors?.monthlyIncome?.message} />
                )}
            />

            {secondaryEmploymentType === 'employed' ?
                <Controller
                    name="payFrequency"
                    control={control}
                    rules={SecondaryEmployedValidation.payFrequency}
                    render={({ field }) => (
                        <CustomDropDown
                            {...field}
                            field={field}
                            label={'Pay Frequency'}
                            required
                            option={PAY_FREQUENCY}
                            error={errors?.payFrequency?.message}
                        />

                    )}
                />

                : null}
            <Typography variant='h6'>{`Employer or Business Address`}</Typography>
            <PropertyAddress control={control} Controller={Controller} errors={errors} stateOfCountry={stateofCountry} country={country} />

            {secondaryEmploymentType === 'employed' ?
                <>
                    <Stack direction={'column'} justifyContent={'space-between'} py={2} height={'inherit'} overflow={'auto'}>
                        <Box>


                            <Box maxWidth={{ xs: '100%', md: '100%' }} mt={2}>

                                <Controller
                                    name='isSideIncomeAvailable'
                                    control={control}
                                    rules={ExtraIncomeValidation.isSideIncomeAvailable}
                                    render={({ field }) => (
                                        <CustomRadioChip
                                            labelComponent={<CustomLargeLabel value={`Do you receive bonus, military entitlement or commission income?`} required />}
                                            {...field}
                                            field={field}
                                            state={isSideIncomeAvailable}
                                            data={[
                                                { id: 'yes', value: "Yes", label: "Yes" },
                                                { id: 'no', value: "No", label: "No" },
                                            ]}
                                            error={errors?.isSideIncomeAvailable?.message}
                                        />
                                    )}
                                />
                                {isSideIncomeAvailable == 'Yes' ?
                                    <>
                                        <Controller
                                            name='avgMonthlyIncome'
                                            control={control}
                                            rules={ExtraIncomeValidation.avgMonthlyIncome}
                                            render={({ field }) => (
                                                <CustomInput {...field} type={'text'} label={`Average monthly bonus`} sicon={<AttachMoney />}
                                                    value={formatNumericValue(field.value)}
                                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                                    error={errors?.avgMonthlyIncome?.message} />
                                            )}
                                        />

                                        <Controller
                                            name='avgMonthlyOvertime'
                                            control={control}
                                            rules={ExtraIncomeValidation.avgMonthlyOvertime}
                                            render={({ field }) => (
                                                <CustomInput {...field} type={'text'} label={`Average monthly overtime`} sicon={<AttachMoney />}
                                                    value={formatNumericValue(field.value)}
                                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                                    error={errors?.avgMonthlyOvertime?.message} />
                                            )}
                                        />

                                        <Controller
                                            name='militaryEntitlement'
                                            control={control}
                                            rules={ExtraIncomeValidation.militaryEntitlement}
                                            render={({ field }) => (
                                                <CustomInput {...field} type={'text'} label={`Military entitlement `} sicon={<AttachMoney />}
                                                    value={formatNumericValue(field.value)}
                                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                                    error={errors?.militaryEntitlement?.message} />
                                            )}
                                        />

                                        <Controller
                                            name='avgMonthlyComission'
                                            control={control}
                                            rules={ExtraIncomeValidation.avgMonthlyComission}
                                            render={({ field }) => (
                                                <CustomInput {...field} type={'text'} label={`Average monthly commission`} sicon={<AttachMoney />}
                                                    value={formatNumericValue(field.value)}
                                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                                    error={errors?.avgMonthlyComission?.message} />
                                            )}
                                        />
                                    </>
                                    : null
                                }

                            </Box>

                        </Box>
                    </Stack>

                    <FormControlLabel
                        control={
                            <Controller
                                name='employedByOther'
                                control={control}
                                render={({ field }) => (
                                    <Checkbox checked={employedByOther}  {...field} />
                                )}
                            />
                        }
                        label=
                        {`I am employed by a family member, property seller, real estate agent, or other party to the transaction. `}
                    />
                </> : null}






            <Stack direction={'row-reverse'} spacing={2}>
                <Button form={'secondaryEmployed'} variant='contained' type='submit'>
                    {editItem.length != 0 ? 'Update' : 'Add'}
                </Button>
            </Stack>
            {/* </Stack> */}
        </form >
    )
}
