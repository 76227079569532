import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, FormHelperText, Link as MLink, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import useInput from '../../../../../helpers/use-input'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { useForm } from 'react-hook-form'
import { API } from '../../../../../services/api-config'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { UPDATE } from '../../../../../constants/constatnt-variable'
import LoanAssetsOther from "./assets-other"
import LoanAssetsCredit from "./assets-credits"
import LoanCreditsOther from "./credits-other"
import { getApiCallDynamic } from "../../../../../services/api-service";


export default function LoanAssets() {


    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]


    let [formValue, setFormValue] = useRecoilState(formState)

    const handleClose = () => {
        setModalOpen(false);
    };

    const [assetStatus] = useInput({
        data: null,
        status: false,
        initial: null
    });


    const [modalOpen, setModalOpen] = useState(false);



    let [assetCredit, setAssetCredit] = useState([])
    let [assetOther, setAssetOther] = useState([])
    let [creditOther, setCreditOther] = useState([])


    useEffect(() => {
        getApiCallDynamic({
            path: API.formData,
            param: { formId: formValue.id },
        }).then((res) => {
            if (res?.status === 200) {

                setAssetCredit(res.data?.assetsCredits?.page?.assetCredit)
                setAssetOther(res.data?.assetsCredits?.page?.assetOther)
                setCreditOther(res.data?.assetsCredits?.page?.creditOther)
            } else {
                // navigatetoFromPage("/");
            }
            // setSpinner(false);
        });

    }, []);


    const empty = (data) => {
        return data.length == 0;
    }


    const onSubmit = async (data, event) => {
        if (event.target.id == "main") {


            const apiCallData = {
                page: {

                    assetCredit: data.assetCredit,
                    assetOther: data.assetOther,
                    creditOther: data.creditOther,


                },
                formId: formValue.id,
                isComplete: true,
            };

            let updateState, path;

            path = API.assetCredit;
            updateState = {
                ...formValue,
                assetsCredits: {
                    ...formValue.assetsCredits,
                    page: apiCallData.page,
                    isComplete: true,
                },
            };

            const assetsInfoUpdateInRecoil = () => {

                setFormValue(updateState);

                route(location.pathname.replace(/[^/]*$/, 'gift-grants'));
            };

            await postApiCallDynamicWithOutReturn({
                data: apiCallData,
                path: path,
                header: UPDATE,
                refresh: assetsInfoUpdateInRecoil
            });

        }


    };


    const { setValue, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            assetCredit: assetCredit,
            assetOther: assetOther,
            creditOther: creditOther,

        }


    })


    return (
        <>
            <form key={'main'} id='main' onSubmit={handleSubmit(onSubmit)} >
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Box maxWidth={{ xs: '100%', md: '100%' }} mt={2}>

                            <LoanAssetsCredit setValue={setValue} assetCreditList={assetCredit} setAssetCreditList={setAssetCredit} />
                            <LoanAssetsOther setValue={setValue} assetOtherList={assetOther} setAssetOtherList={setAssetOther} />
                            <LoanCreditsOther setValue={setValue} creditOtherList={creditOther} setCreditOtherList={setCreditOther} />




                        </Box>
                    </Box>
                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Link to={'../borrower-info/personal-information'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>
                            <Button form='main' type='submit' endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>

                </Stack>
            </form>


        </>
    )

}
