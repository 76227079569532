

import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { KSCloudDownloadIcon, KSCloudUploadIcon, KSDocumentSearchIcon, LinkedDocumentDisabledIcon, LinkedDocumentEnabledIcon } from '../../icons/custome-icon'
import { postApiCallDynamic, postApiCallDynamicWithOutReturn, uploadFile, uploadFileWithToken } from '../../services/api-service';
import { API } from '../../services/api-config';
import { toastNotify } from '../../helpers/alert-msg';
import { docDownloadResolve, docPreview, docPreviewResolve, downloadReportFromServer, downloadReportFromServerThirdParty } from '../../helpers/file-upload-download';
import { ReactComponent as LinkIcon } from "../../icons/LinkIcon.svg"
import { DocumentDialogCommonHooks } from '../../helpers/modal/modal';
import LinkDocumentConfirm from './link-document-confirm';
import { CREATE, ROLE_LIST } from '../../constants/constatnt-variable';
import { profileState } from '../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { KSTooltip } from '../../helpers/ks-tooltip';

const BorrowerUploadCard = ({ item, formId, accessCode, taskCode, formData, setUploadFile }) => {
    let [profileData, setProfileData] = useRecoilState(profileState);
    const [localFileDetails, setLocalFileDetails] = useState(null);

    const fileUpload = async (event, item) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            try {
                const res = await uploadFile({

                    file: file,
                    serviceType: "Loan",
                    formId: formId,
                    thirdPartyTaskCode: taskCode,
                    path: API.proxyDocumentOthersUpload,

                });




                if (res.status === 201) {
                    toastNotify("success", res.data.message);


                    const updatedData = {
                        ...res?.data,
                        uploadId: item?.id
                    };



                    setUploadFile(updatedData)

                    setLocalFileDetails(res.data);
                } else {
                    toastNotify("error", res.data.message);
                }
            } catch (error) {
                console.error("Upload failed: ", error);
                toastNotify("error", "File upload failed.");
            }
        } else {
            console.log("No file selected");
        }
    };


    const downloadDocument = (item) => {

        const apiUrl = API.proxyDocumentDownload
        const token = accessCode


        let storageKey = item?.resolvedDocStorageKey
        let fileName = item?.resolvedDocFileName
        let docId = item?.resolvedDocId
        if (localFileDetails) {
            storageKey = localFileDetails?.storageKey
            fileName = localFileDetails?.fileName
            docId = localFileDetails?.docId

        }
        else {


        }

        const param = {
            storageKey: storageKey,
            // fileName: fileName,
            id: docId,
            thirdPartyTaskCode: taskCode,
            fileName: item?.resolvedDocFileName
        }


        if (storageKey && fileName) {

            docDownloadResolve(apiUrl, param, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        } else {

        }



    };
    // Add logic for download

    const viewDocument = (item) => {

        const apiUrl = API.proxyDocumentDownload
        const token = accessCode


        let storageKey = item?.resolvedDocStorageKey
        let fileName = item?.resolvedDocFileName
        let docId = item?.resolvedDocId
        if (localFileDetails) {
            storageKey = localFileDetails?.storageKey
            fileName = localFileDetails?.fileName
            docId = localFileDetails?.docId

        }
        else {


        }

        const param = {
            storageKey: storageKey,
            // fileName: fileName,
            id: docId,
            thirdPartyTaskCode: taskCode

        }

        if (storageKey && fileName) {

            docPreviewResolve(apiUrl, param, token)
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        } else {

        }



    };


    const [linkDocumentDetails, setLnkDocumentDetails] = useState(null)
    const [linkModal, setLinkModal] = useState(false);
    const handleLinkModalOpen = (item) => {

        setLnkDocumentDetails(item)
        setLinkModal(true);
    };

    const handleLinkModalClose = () => {
        setLinkModal(false);
    };
    const [linkDetails, setLinkDetails] = useState(null)






    const updateState = (response) => {




        if (response.status === 200) {

            toast.success("Successfully Linked!", {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
            //taskReload()

        }
        else {
            toast.error("An error occurred while Linking Document", {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
        }




    }





    const handleLinkConfirm = async (data) => {


        setLinkDetails(data);

        const borrowerVisibilityStatus = data?.visibility.includes("Borrower");
        const realtorsVisibilityStatus = data?.visibility.includes("Realtors");

        const apiCallData = {
            "loanCode": formData?.loanCode,
            "documentName": linkDocumentDetails?.documentType,
            "fileName": linkDocumentDetails?.resolvedDocFileName,
            "storageKey": linkDocumentDetails?.resolvedDocStorageKey,
            "docType": "LocalUpload",  //changetocheck
            "storageDocId": linkDocumentDetails?.resolvedDocId,
            //"description": "sdfdes",
            "uploadType": "LinkedDocument",
            "linkingFromTask": true,

            "formId": formData?.formId,
            "userDefinedDocType": true,    //hardcode
            "borrowerOnlyDocument": data?.borrowerOnlyDocument,
            "borrowerVisibility": borrowerVisibilityStatus,
            "realtorsVisibility": realtorsVisibilityStatus


        }




        try {


            await postApiCallDynamic({
                data: apiCallData,
                path: API.createLoanDocument,
                header: CREATE,
                refresh: updateState

            });


        } catch (error) {

            toast.error("An error occurred while submitting the form.", {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        //  handleClose()


        handleLinkModalClose()




    }



    return (
        <div>
            <Box sx={{ border: '1px solid lightgray', pX: 1 }} mb={2} padding={1}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >

                    <Box mt={1} mb={1} >
                        <KSTooltip title={item?.documentNote || 'No description available'}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {item?.documentType}
                            </Typography>
                        </KSTooltip>


                        <Typography variant="body2" color="grey">
                            {localFileDetails?.fileName || item?.resolvedDocFileName}
                        </Typography>

                    </Box>

                    <Box>

                        {/* <IconButton
                            edge="end"
                            aria-label="view"
                            color="primary"
                            onClick={() => handleLinkModalOpen(item)}
                            disabled={!(formData?.taskStatus === 'RESOLVED')}
                        >
                            <LinkedDocumentEnabledIcon />
                        </IconButton> */}

                        {formData?.taskStatus === 'RESOLVED' &&
                            <IconButton
                                edge="end"
                                aria-label="view"
                                color="primary"
                                onClick={() => handleLinkModalOpen(item)}
                                disabled={!((profileData.role === ROLE_LIST.mlo
                                    || profileData.role === ROLE_LIST.mlp
                                    || profileData.role === ROLE_LIST.mloOwner
                                    || profileData.role === ROLE_LIST.broker)
                                    && (!item.linkedToLoanDoc))}
                            >

                                {((profileData.role === ROLE_LIST.mlo
                                    || profileData.role === ROLE_LIST.mlp
                                    || profileData.role === ROLE_LIST.mloOwner
                                    || profileData.role === ROLE_LIST.broker)
                                    && (!item.linkedToLoanDoc)
                                ) ? (
                                    <LinkedDocumentEnabledIcon />
                                ) : (
                                    <LinkedDocumentDisabledIcon />
                                )}
                            </IconButton>
                        }

                        <IconButton
                            color="primary"

                            // disabled={!localFileDetails?.fileName}
                            onClick={() => viewDocument(item)}

                        >
                            <KSDocumentSearchIcon />
                        </IconButton>

                        {(profileData.role === ROLE_LIST.mlo
                            || profileData.role === ROLE_LIST.mlp
                            || profileData.role === ROLE_LIST.mloOwner
                            || profileData.role === ROLE_LIST.broker
                        ) &&
                            <IconButton
                                color="primary"

                                // disabled={!localFileDetails?.fileName}
                                onClick={() => downloadDocument(item)}

                            >
                                <KSCloudDownloadIcon />
                            </IconButton>
                        }
                        {!(profileData.role === ROLE_LIST.mlo
                            || profileData.role === ROLE_LIST.mlp
                            || profileData.role === ROLE_LIST.mloOwner
                            || profileData.role === ROLE_LIST.broker)
                            &&
                            <Button
                                component="label"
                                size="small"
                                startIcon={<KSCloudUploadIcon color={"primary"} />}
                                disabled={item?.resolvedDocId}
                            >
                                <input
                                    onChange={(e) => fileUpload(e, item)}
                                    hidden
                                    //  accept="image/*"
                                    type="file"
                                />

                                Upload
                            </Button>
                        }
                    </Box>
                    {/* {localFileDetails && localFileDetails.item === item && (
                    <Typography variant="body2" color="green">
                        {localFileDetails.message}
                    </Typography>
                )} */}

                </Stack>
            </Box>

            {/* Conditionally render success message */}
            {/* {localFileDetails && localFileDetails.item === item && (
                <Typography variant="body2" color="green">
                    {localFileDetails.message}
                </Typography>
            )} */}
            <DocumentDialogCommonHooks
                show={linkModal}
                handleClose={handleLinkModalClose}
                title={'Link Document'}
                body={
                    <LinkDocumentConfirm
                        handleClose={handleLinkModalClose}
                        onSubmit={handleLinkConfirm}
                        editItem={linkDetails}
                        linkDocumentDetails={linkDocumentDetails}
                        taskStatusForLink={formData?.taskStatus}
                    />
                }
            />
        </div>

    );
}

export default BorrowerUploadCard;
