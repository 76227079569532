import { Avatar, Box, Stack, Typography } from "@mui/material";
import AvaterMlo from "../../../../icons/avatar_mlo.png";
import AvaterMlp from "../../../../icons/avatar_mlp.png";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import LoanSummaryMloMlpConfirmationForm from "../loan-summary-forms/loan-summary-mlomlp-confirmation-form";
import LoanSummaryMloMlpChangeForm from "../loan-summary-forms/loan-summary-mlomlp-change-form";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { useRecoilState } from "recoil";
import { profileState } from "../../../../recoil/profile-atom";

export default function LoanSummaryMloMlpProfile({
  data,
  type,
  avatar,
  userType,
  mloMlpList,
  loanCode,
  reload,
}) {
  let [profileData, setProfileData] = useRecoilState(profileState);
  const { visiblity, role } = useContext(LoanSummaryParentData);
  const [mloMlpChangeConfirmation, setMloMlpChangeConfirmation] =
    useState(false);
  const handleMloMlpChangeConfirmationOpen = () => {
    setMloMlpChangeConfirmation(true);
  };
  const handleMloMlpChangeConfirmationClose = () => {
    setMloMlpChangeConfirmation(false);
  };

  const [changeMloMlp, setChangeMloMlp] = useState(false);
  const handleChangeMloMlplOpen = () => {
    setChangeMloMlp(true);
    setMloMlpChangeConfirmation(false);
    userType(type);
  };
  const handleChangeMloMlplClose = () => {
    setChangeMloMlp(false);
    setMloMlpChangeConfirmation(false);
  };
  return (
    <>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <Avatar
          variant="rounded"
          src={
            avatar ? avatar : (type === 'MLO' ? AvaterMlo : AvaterMlp)
          }
          sx={{
            width: 40,
            height: 40,
          }}
        />
        <Box>
          <Typography variant="body1">
            {data?.firstName || data?.lastName
              ? data?.firstName + " " + data?.lastName
              : "Not assigned"}
          </Typography>
          {/* {visiblity !== "Partial" && type !== role ? (
            <Link
              onClick={handleMloMlpChangeConfirmationOpen}
            >{`Change ${type}`}</Link>
          ) : null} */}

          {
            !(profileData.role === 'MLO' && type === 'MLO') && (visiblity !== 'Partial' && type !== role) ? (
              <Link onClick={handleMloMlpChangeConfirmationOpen}>
                {`Change ${type}`}
              </Link>
            ) : null
          }



        </Box>
      </Stack>

      {/* MLO/MLP Change Confirmation Modal */}
      <DialogCommonHooks
        title={`Change ${type}`}
        show={mloMlpChangeConfirmation}
        handleClose={handleMloMlpChangeConfirmationClose}
        body={
          <LoanSummaryMloMlpConfirmationForm
            type={type}
            handleClose={handleMloMlpChangeConfirmationClose}
            handleOpne={handleChangeMloMlplOpen}
          />
        }
      />

      {/* Change MLO/MLP Modal */}
      <DialogCommonHooks
        title={`Change ${type}`}
        show={changeMloMlp}
        handleClose={handleChangeMloMlplClose}
        body={
          <LoanSummaryMloMlpChangeForm
            currentUser={data}
            mloMlpListData={mloMlpList}
            type={type}
            loanCode={loanCode}
            reload={reload}
            handleClose={handleChangeMloMlplClose}
          />
        }
      />
    </>
  );
}
