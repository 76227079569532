import { Box, Button, Grid } from '@mui/material'
import { React } from 'react'
import CustomInput from '../../../helpers/custom-input'
import useInput from '../../../helpers/use-input';
import { useState } from 'react'

export default function NmlsNoteForm({ updateInfo }) {

    let [nmlsNotes] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    const [invitationMessage, setInvitationMessage] = useState('');


    const handleInvitationMessageChange = (event) => {
        setInvitationMessage(event.target.value);
    };

    const remainingChars = 500 - invitationMessage.length;

    return (
        <Grid item xs={12} sm={12} md={12} p={4}>
            <form id="newcCmpNmlsId" onSubmit={(e) => { e.preventDefault(); updateInfo(invitationMessage) }}>
                <div>
                    <CustomInput
                        label="Note"
                        fullWidth
                        size="large"
                        placeholder="Write a note when its invalidate, that will be send to the respective person."
                        multiline
                        rows={10}
                        //change={nmlsNotes}
                        value={invitationMessage}
                        onChange={handleInvitationMessageChange}
                    />
                    <span>{remainingChars} characters remaining</span>
                </div>
                <Box display="flex" justifyContent="flex-end" m={1}>
                    <Button type="submit" variant="contained">Send</Button>
                </Box>
            </form>
        </Grid>
    )
}
