import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import "../../styles/admin.css";
import { useLocation, useNavigate } from "react-router-dom";
import Profile from "./profile";
import Account from "./account";
import { useRecoilState } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import Merge from "./merge";
import Preferences from "./preferences/preferences";
import Website from "./website/website";
import Team from "./team/team";
import MainLoyOut from "../../components/main-layout";
import { SETTING_MENU } from "../../constants/constatnt-variable";
import PropTypes from 'prop-types';
// Not used imports
import _ from "lodash";
import Billing from "./billing/billing";
import Coupon from "./billing/coupon";
import { BillContextProvider } from "./billing/BillContext";
import { AccessableAbleRoleSettings } from "../../constants/access-control";


//for color and responsive font
// let theme = createTheme();
// theme = responsiveFontSizes(theme);

//drawerHeader style
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index, clickEvent) {
  return {
    // id: `simple-tab-${index}`,
    // "aria-controls": `simple-tabpanel-${index}`,
    // href: `#${index}`,
    onClick: clickEvent,
    name: `${index}`,
    value: index,
  };
}

let SETTING_TABS = {
  Profile: SETTING_MENU.Profile,
  Account: SETTING_MENU.Account
};

// Main Component

export default function Settings(props) {
  let [profileData, setProfileData] = useRecoilState(profileState);

  const router = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(null);



  useEffect(() => {

    if ((AccessableAbleRoleSettings.includes(profileData.role)) && !_.isEmpty(profileData)) {
      SETTING_TABS = {
        ...SETTING_TABS,
        Preferences: SETTING_MENU.Preferences,
        Team: SETTING_MENU.Team,
        // Merge: SETTING_MENU.Merge,
        Website: SETTING_MENU.Website,
        Coupon: SETTING_MENU.Coupon,
        Billing: SETTING_MENU.Billing,

      }
    }
    let endpoint = location.pathname.split("/").pop()
    if (Object.values(SETTING_TABS).includes(endpoint)) {
      setValue(endpoint);
    }
    else {
      router(location.pathname.replace(/[^/]*$/, SETTING_TABS.Profile));

    }
  }, [location, profileData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRouting = (event) => {
    router(location.pathname.replace(/[^/]*$/, event.target?.name));
  };



  let tabList = [
    <Tab
      key={SETTING_TABS.Profile}
      label="Profile"
      {...a11yProps(SETTING_TABS.Profile, handleRouting)}
      sx={{ textTransform: "capitalize" }}
    />,

    <Tab
      key={SETTING_TABS.Account}
      label="Account"
      {...a11yProps(SETTING_TABS.Account, handleRouting)}
      sx={{ textTransform: "capitalize" }}
    />,
    // <Tab
    //   key={SETTING_TABS.Billing}
    //   label="Billing"
    //   {...a11yProps(SETTING_TABS.Billing, handleRouting)}
    //   sx={{ textTransform: "capitalize" }}
    // />,

  ]


  let tabPanel = [
    <TabPanel key={SETTING_TABS.Profile} value={value} index={SETTING_TABS.Profile}>
      <Profile data={profileData} />
    </TabPanel>,

    <TabPanel key={SETTING_TABS.Account} value={value} index={SETTING_TABS.Account}>
      <Account {...props} />
    </TabPanel>,
    // <TabPanel key={SETTING_TABS.Billing} value={value} index={SETTING_TABS.Billing}>
    //   <Billing {...props} />
    // </TabPanel>,

  ]




  if ((AccessableAbleRoleSettings.includes(profileData.role)) && !_.isEmpty(profileData)) {
    tabList.push(
      <Tab
        key={SETTING_TABS.Preferences}
        label="Preferences"
        {...a11yProps(SETTING_TABS.Preferences, handleRouting)}
        sx={{ textTransform: "capitalize" }}
      />


    )
    if (profileData?.role == "MLO") {
      tabList.push(
        <Tab
          key={SETTING_TABS.Website}
          label="Website"
          {...a11yProps(SETTING_TABS.Website, handleRouting)}
          sx={{ textTransform: "capitalize" }}
        />
      )
    } else {
      tabList.push([

        <Tab
          key={SETTING_TABS.Team}
          label="Team"
          {...a11yProps(SETTING_TABS.Team, handleRouting)}
          sx={{ textTransform: "capitalize" }}
        />,
        // <Tab
        //   key={SETTING_TABS.Merge}
        //   label="Merge"
        //   {...a11yProps(SETTING_TABS.Merge, handleRouting)}
        //   sx={{ textTransform: "capitalize" }}
        // />,
        <Tab
          key={SETTING_TABS.Website}
          label="Website"
          {...a11yProps(SETTING_TABS.Website, handleRouting)}
          sx={{ textTransform: "capitalize" }}
        />,
        <Tab
          key={SETTING_TABS.Coupon}
          label="Coupon"
          {...a11yProps(SETTING_TABS.Coupon, handleRouting)}
          sx={{ textTransform: "capitalize" }}
        />,
        <Tab
          key={SETTING_TABS.Billing}
          label="Billing"
          {...a11yProps(SETTING_TABS.Billing, handleRouting)}
          sx={{ textTransform: "capitalize" }}
        />,


      ]
      )
    }

    tabPanel.push(
      <TabPanel key={SETTING_TABS.Preferences} value={value} index={SETTING_TABS.Preferences}>
        <Preferences />
      </TabPanel>
    )

    if (profileData?.role == "MLO") {
      tabPanel.push(
        <TabPanel key={SETTING_TABS.Website} value={value} index={SETTING_TABS.Website}>
          {<Website {...props} />}
        </TabPanel>)
    } else {
      tabPanel.push(

        <TabPanel key={SETTING_TABS.Team} value={value} index={SETTING_TABS.Team}>
          <Team />
        </TabPanel>,

        // <TabPanel key={SETTING_TABS.Merge} value={value} index={SETTING_TABS.Merge}>
        //   <Merge {...props} />
        // </TabPanel>,

        <TabPanel key={SETTING_TABS.Website} value={value} index={SETTING_TABS.Website}>
          <Website {...props} />
        </TabPanel>,
        <TabPanel key={SETTING_TABS.Coupon} value={value} index={SETTING_TABS.Coupon}>
          <Coupon {...props} />
        </TabPanel>,

        <TabPanel key={SETTING_TABS.Billing} value={value} index={SETTING_TABS.Billing}>
          <Billing {...props} />
        </TabPanel>,


      )
    }

  }
  return (
    <MainLoyOut>
      <Paper>
        <Box>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

            <Tabs value={value} onChange={handleChange} variant="scrollable"
              allowScrollButtonsMobile >
              {tabList}
            </Tabs>
          </Box>
          {tabPanel}

        </Box>
      </Paper>
    </MainLoyOut>
  );
}
