import {Button, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Path} from "../routes/route-config";
import React from "react";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(Path.index);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <Grid item xs={3}>
        <section>
          <h1 className="font-weight-bold">Unauthorized</h1>
          <br />
          <h4>You do not have access to the requested page.</h4>
          <br />
          <div className="flexGrow">
            <Button onClick={goBack} color="error" variant="outlined">
              Go Back
            </Button>
          </div>
        </section>
      </Grid>
    </Grid>
  );
};

export default Unauthorized;
