import { AttachMoney, } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import {
    CREDIT_TYPE_OTHER,
    ASSET_OWNED_BY,
    ASSET_OWNED_BY_WITHOUT_PARTNER,
    STATUS_OF_REALTOR
} from '../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm } from 'react-hook-form'
import { CreditOtherValidation } from '../../../../../constants/validation-rules'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'


export function CreditOtherForm({ handleClose, onSubmit, editItem, hasPartner }) {



    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            accountType: editItem?.accountType,
            marketValue: editItem?.marketValue,
        }
    })


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box width={'100%'} my={2} >
                    <Box>
                        <Controller
                            name='accountType'
                            control={control}
                            rules={CreditOtherValidation.accountType}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    label={'Credit Type'}
                                    required
                                    option={CREDIT_TYPE_OTHER}
                                    error={errors?.accountType?.message}
                                />
                            )}
                        />


                        <Controller
                            name="marketValue"
                            control={control}
                            rules={CreditOtherValidation.marketValue}
                            render={({ field }) => (
                                <CustomInput {...field} type={'text'} label={'Cash Amount'} required sicon={<AttachMoney />}
                                    value={formatNumericValue(field.value)}
                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                    error={errors?.marketValue?.message} />
                            )}
                        />
                    </Box>


                    <Box textAlign={'right'}>
                        <Button type='submit' variant='contained'>
                            {editItem.length !== 0 ? 'Update' : 'Add'}
                        </Button>
                    </Box>
                </Box>
            </Stack>
        </form >

    )
}