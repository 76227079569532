import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import ConfirmationModel from '../../../../../../../helpers/modal/confirm-modal';
import { DialogCommonHooks } from '../../../../../../../helpers/modal/modal';
import CustomReactMaterialTable from '../../../../../../../helpers/table/react-material-table';
import { DateToTimestapmFormater, TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater';
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../../../icons/custome-icon';
import RetiredForm from './retired-form';
import { formatNumericValue, parseNumericValue } from '../../../../../../../helpers/numeric-format'

export default function Retired({ setValue, retiredList, setRetiredList }) {

    useEffect(() => {
        setValue('retired', retiredList)
    }, [retiredList])


    const [editState, setEditState] = useState(() => {
        return null
    })

    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(retiredList)
            let editItem = list.find((item, i) => i == editState)
            setRetiredEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])

    const [deleteState, setDeleteState] = useState(() => {
        return null
    })

    const retirementDate = (row) => {
        return (
            <Typography>{TimeStampToMonthDateYearForInput(row.retirementDate)}</Typography>
        )
    }


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            retiredList.splice(deleteState, 1)
            setRetiredList([...retiredList])
            setDeleteState(null)
        }

    }, [deleteState])

    const tableColumn = useMemo(
        () => [
            {
                accessorFn: (row) => retirementDate(row),
                accessorKey: "retirementDate",
                header: "Retirement Date",
                enableColumnActions: false,
            },
            {
                accessorKey: "retirementIncome",
                header: "Retirement Income per month",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography >
                        {`$ ${formatNumericValue(row.original["retirementIncome"])}`}
                    </Typography>
                ),

            },
        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [retiredEdit, setRetiredEdit] = useState();

    const handleModalOpen = () => {
        setRetiredEdit([])
        setModalOpen(true);
    }

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
    }

    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }

    const retiredSubmit = async data => {
        let list = _.cloneDeep(retiredList)
        data = Object.assign(data, {
            retirementDate: DateToTimestapmFormater(data?.retirementDate),
        })

        if (editState != null) {
            list[editState] = {
                ...list[editState],
                ...data
            }


            setEditState(null)
        } else {
            list.push(data)

        }

        setRetiredList(list)
        handleClose();

    }


    return (
        <Box>
            <Typography variant='h3' mb={1} mt={3}  >{`Retired Details`}</Typography>

            <CustomReactMaterialTable
                staticLoad={true}
                staticData={retiredList}
                columns={tableColumn}
                enablePagination={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableToolbarInternalActions={false}
                renderTopToolbarCustomActions={
                    () => (
                        <Button onClick={handleModalOpen} variant='outlined' >Add</Button>
                    )
                }
                enableRowActions={true}
                renderRowActions={
                    ({ row }) => {

                        return (
                            <Stack direction={'row'}>
                                <IconButton onClick={() => { setEditState(row.index) }}>
                                    <KSPencilAltIcon />
                                </IconButton>


                                <ConfirmationModel
                                    body={<IconButton id={row.index}>
                                        <KSTrashIcon />
                                    </IconButton>}
                                    message={`Are you sure want remove`}
                                    title={'Delete '}
                                    handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                />

                            </Stack>
                        )
                    }
                }
            />

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Retired Info`}
                body={
                    <RetiredForm
                        onSubmit={retiredSubmit}
                        editItem={retiredEdit}
                    />
                }
            />
        </Box>
    )
}
